import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions
import { getOrganization, getCourses } from "../actions/content_actions";
import { updateUserProfile } from "../actions/user_actions";
import { openAlert } from "../actions/alert_actions";

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import {
    getCampaignComponent,
} from "../helpers/functions";
import { Suspense } from "react";

//Components
import AuthenticatedCampaignDefault from "../components/default/AuthenticatedCampaign/AuthenticatedCampaignDefault";

const useStyles = makeStyles((theme) => ({}));

function AuthenticatedCampaign(props) {
    const classes = useStyles();
    const { userProfile, user } = props;
    const { campaign_key } = props.match.params;
    const organizationName =
        userProfile.data.organization.name || userProfile.data.organization;
    const [AuthenticatedCampaignPage, setAuthenticatedCampaignPage] =
        useState();

    useEffect(() => {
        if (campaign_key) {
            setAuthenticatedCampaignPage(
                getCampaignComponent("welcome", campaign_key)
            );
        }
    }, [campaign_key]);

    useEffect(() => {
        if (userProfile.data != null && userProfile.data.currentCampaign && userProfile.data.currentCampaign === campaign_key) {
            props.getCourses(organizationName);

            //Check if there are completed tasks
            let completedTasksString = null
            try {
                completedTasksString = window.localStorage.getItem("completedTasks");
            } catch(e) {
                console.log(e)
            }
            let completedTasks = JSON.parse(completedTasksString);
            //console.log("completed tasks are", completedTasksString, completedTasks)

            if (
                completedTasksString &&
                completedTasksString !== null &&
                completedTasksString !== "null"
            ) {
                //Get the task id and object associated with it
                let taskID = Object.keys(completedTasks)[0];
                let taskInfo = completedTasks[taskID];

                //Update the user profile
                const uid = user.data.uid;
                let newUserProfile = userProfile.data;
                newUserProfile.completed_tasks[taskID] = taskInfo;
                props.updateUserProfile(newUserProfile, uid);

                //Reset local storage
                try {
                    window.localStorage.setItem("completedTasks", null);
                } catch(e) {
                    console.log(e)
                }

                // Alert the user
                //alert(`You completed a campaign! ${taskInfo.points} points have been added to your profile.`)
                props.openAlert({
                    text: `Congrats on completing your first task! ${taskInfo.points} points have been added to your profile.`,
                });
            }
        }
    }, [userProfile.data]);

    return (
        <Suspense fallback={<AuthenticatedCampaignDefault />}>
            {AuthenticatedCampaignPage ? <AuthenticatedCampaignPage /> : null}
        </Suspense>
    );
}

const mapStateToProps = (state) => ({
    organization: state.content.organization,
    user: state.user.user,
    userProfile: state.user.userProfile,
});

const mapDispatchToProps = {
    getOrganization,
    updateUserProfile,
    openAlert,
    getCourses,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AuthenticatedCampaign)
);
