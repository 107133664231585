import React, { useState } from 'react';

//Styles
import { 
    Popper, 
    Grid,
    Button,
    ClickAwayListener,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography
} from '@mui/material';

function LearnMorePopper(props) {
    const { anchorEl, handleClick, handleConfettiClick } = props
    const [copied, setCopied] = useState(false)
    const supportEmail = "mail@electrifyze.com"

    const handleCopyClick = () => {
        if (/*@cc_on!@*/false || !!document.documentMode) {
            //Internet explorer has unique method
            window.clipboardData.setData('Text', supportEmail);
        } else {
            navigator.clipboard.writeText(supportEmail)
        }

        setCopied(true)
    }

    return (
        <ClickAwayListener onClickAway={handleClick}>
            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                style={{
                    maxWidth: 500, 
                    marginTop: '5px',
                    borderRadius: 5, 
                    padding: "10px",
                    backgroundColor: 'white', 
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
                    position: 'relative',
                    zIndex: 1000000
                }}
            >
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h5" align="right">
                            I’d sign up if I had more confetti…
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="outlined" onClick={handleConfettiClick}>
                            More Confetti, please!
                        </Button>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h5" align="right">
                            Want to read more about EVs For Earth?
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="outlined" onClick={() => window.open("https://lets.electrifyze.us/3ce/", "_blank")}>
                            EVs For Work Homepage
                        </Button>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h5" align="right">
                            Have questions?
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Grid item container alignItems="center" justifyContent="center" style={{border: '1px solid black', borderRadius: 5, padding: 5}}>
                            <Typography style={{marginRight: 15}}>
                                <a
                                    href={`mailto:${supportEmail}?subject=Electrifyze Help`}
                                    id="mail-share-link"
                                >
                                    {supportEmail}
                                </a>
                            </Typography>
                            <Button variant="outlined" onClick={handleCopyClick}>
                                {copied ? "Copied!" : "Copy"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Popper>
        </ClickAwayListener>

    );
}

export default LearnMorePopper
