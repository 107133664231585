import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

//Actions
import { getAllCampaigns } from "../actions/content_actions";

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import {
    Breadcrumbs,
    CircularProgress,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";

// Components
import ProfileWelcomeHeader from "../components/default/Courses/ProfileWelcomeHeader";
import CampaignCard from "../components/default/Campaign/CampaignCard";
import TextDivider from "../components/default/Styled Components/TextDivider";
import { GrayTextBox } from "../components/default/Styled Components/GrayTextBox";

// Assets
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
    breadcrumbBox: {
        height: 23,
        backgroundColor: "rgba(180, 199, 231, 1)",
        padding: 15,
        paddingLeft: 35,
    },
    content: {
        maxWidth: 960,
        margin: "0 auto",
        marginBottom: 30,
        [theme.breakpoints.down('xl')]: {
            marginLeft: 10,
            marginRight: 10,
        },
    },
    accordion: {
        "&:before": {
            backgroundColor: "white",
        },
    },
    expandIcon: {
        height: 50,
        width: 80,
        paddingLeft: "0px !important",
        color: "gold",
        filter: "drop-shadow(1px 1px 0 black) drop-shadow(-1px 1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px -1px 0 black)",
    },
}));

const IconLeftAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    expandIcon: {
        order: -1,
    },
    root: {
        paddingLeft: 0,
    },
}));

function Contests(props) {
    const classes = useStyles();
    const { allCampaigns } = props;

    useEffect(() => {
        props.getAllCampaigns();
    }, []);

    const checkIfCurrentCampaign = (item) => {
        let dateToday = new Date();
        dateToday.setHours(0, 0, 0, 0);
        return (
            moment(item.startDate).isBefore(dateToday) &&
            moment(dateToday).isSameOrBefore(item.endDate)
        );
    };

    return (
        <div>
            <ProfileWelcomeHeader subheading="View contests here!" hidePoints />

            <div className={classes.breadcrumbBox}>
                <Breadcrumbs
                    separator={<NavigateNext fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Typography>Contests</Typography>
                </Breadcrumbs>
            </div>

            <div className={classes.content}>
                {allCampaigns.loading ? (
                    <CircularProgress
                        color="primary"
                        style={{ paddingTop: 10 }}
                    />
                ) : allCampaigns.data != null ? (
                    <div style={{ marginTop: 15 }}>
                        <Accordion
                            defaultExpanded={true}
                            elevation={0}
                            classes={{ root: classes.accordion }}
                        >
                            <IconLeftAccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon
                                        className={classes.expandIcon}
                                    />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <TextDivider
                                    text="Active Contests"
                                    padding="none"
                                />
                            </IconLeftAccordionSummary>
                            <AccordionDetails>
                                {allCampaigns.data.filter((item) =>
                                    checkIfCurrentCampaign(item)
                                ).length > 0 ? (
                                    <Grid
                                        spacing={2}
                                        container
                                        style={{ marginTop: 0 }}
                                    >
                                        {allCampaigns.data
                                            .filter((item) =>
                                                checkIfCurrentCampaign(item)
                                            )
                                            .map((campaign, index) => (
                                                <CampaignCard
                                                    data={campaign}
                                                    index={
                                                        allCampaigns.data
                                                            .length -
                                                        (index + 1)
                                                    }
                                                    key={index}
                                                />
                                            ))}
                                    </Grid>
                                ) : (
                                    <GrayTextBox variant="h5">
                                        There are no active contests right now.
                                        If you’d like to learn about electric
                                        cars, please visit the previously
                                        released contests below.
                                    </GrayTextBox>
                                )}
                            </AccordionDetails>
                        </Accordion>

                        <br />

                        <Accordion
                            elevation={0}
                            classes={{ root: classes.accordion }}
                        >
                            <IconLeftAccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon
                                        className={classes.expandIcon}
                                    />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <TextDivider
                                    text="Past Contests"
                                    padding="none"
                                />
                            </IconLeftAccordionSummary>
                            <AccordionDetails style={{ display: "block" }}>
                                <GrayTextBox
                                    variant="h5"
                                    style={{ marginBottom: 15 }}
                                >
                                    See below for a list of contests that were
                                    previously released. Note: you can still
                                    participate in the activities available for
                                    each contest but will not qualify for
                                    prizes.
                                </GrayTextBox>
                                <Grid
                                    spacing={2}
                                    container
                                    style={{ marginTop: 0 }}
                                >
                                    {allCampaigns.data
                                        .filter(
                                            (item) =>
                                                !checkIfCurrentCampaign(item)
                                        )
                                        .sort(
                                            (a, b) =>
                                                moment(b.startDate).valueOf() -
                                                moment(a.startDate).valueOf()
                                        )
                                        .map((campaign, index) => (
                                            <CampaignCard
                                                data={campaign}
                                                index={index}
                                                key={index}
                                            />
                                        ))}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ) : (
                    <GrayTextBox variant="h5">
                        There are no active contests right now. If you’d like to
                        learn about electric cars, please visit the previously
                        released contests below.
                    </GrayTextBox>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    allCampaigns: state.content.allCampaigns,
});

const mapDispatchToProps = {
    getAllCampaigns,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Contests)
);
