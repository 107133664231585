import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward } from "@fortawesome/free-solid-svg-icons";
import { Grid, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
    explanatoryGridContainer: {
        maxWidth: 960,
        height: "100%",
        margin: "0 auto",
        textAlign: "left",
        [theme.breakpoints.down('xl')]: {
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10,
        },
    },
    explanation: {
        [theme.breakpoints.down('xl')]: {
            fontSize: 14,
        },
    },
    iconGridItem: {
        width: "auto",
        marginRight: 15,
    },
}));

export default function ExplanatoryDiv(props) {
    //Pass theme styles as props
    const classes = useStyles(props);

    var explanatoryContent;

    props.userGotItRight
        ? (explanatoryContent = (
              <div className={props.classes.quizExplanatoryDiv}>
                  <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={classes.explanatoryGridContainer}
                  >
                      <Grid item className={classes.iconGridItem}>
                          <FontAwesomeIcon
                              icon={faAward}
                              color={"#FFD500"}
                              size={"3x"}
                          />
                      </Grid>

                      <Grid item xs>
                          <Typography className={classes.explanation}>
                              {parse(props.renderMessage)}
                          </Typography>
                      </Grid>
                  </Grid>
              </div>
          ))
        : (explanatoryContent = (
              <div className={props.classes.quizExplanatoryDiv}>
                  <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={classes.explanatoryGridContainer}
                  >
                      <Grid item xs>
                          <Typography
                              className={classes.explanation}
                              style={{ textAlign: "center" }}
                          >
                              {parse(props.renderMessage)}
                          </Typography>
                      </Grid>
                  </Grid>
              </div>
          ));

    return explanatoryContent;
}
