import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//Actions
import { openAlert } from "../actions/alert_actions";
import { getUserProfile } from "../actions/user_actions";

//Styles
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import { Typography, Tabs, Tab, CircularProgress } from "@mui/material";

//Components
import ISDRideshareHeader from "../components/la_county/ISDRideshareHeader";
import TextDivider from "../components/default/Styled Components/TextDivider";
import { GrayTextBox } from "../components/default/Styled Components/GrayTextBox";
import { StyledTaskButton } from "../components/default/Styled Components/Buttons";
// import { BrowseCars, CompareCars } from "@greenlightlabs/compare-cars";
// import { TripPlanner } from '@greenlightlabs/electrifyze-trip-planner';
import EVCoachMessage from "../components/default/Login/EVCoachMessage";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100%",
    },
    content: {
        maxWidth: 960,
        margin: "0 auto",
        marginTop: 30,
        marginBottom: 40,
        [theme.breakpoints.down('xl')]: {
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 10,
        },
    },
    main: {
        marginTop: 20,
    },
    courseBox: {
        margin: 10,
    },
    titleBox: {
        height: 120,
        width: "100%",
        backgroundColor: "#4472c4",
    },
    profileImg: {
        height: 100,
        width: 100,
    },
    pointsImg: {
        height: 80,
        width: 80,
        objectFit: "contain",
        color: "white",
    },
    breadcrumbBox: {
        height: 23,
        backgroundColor: "rgba(180, 199, 231, 1)",
        padding: 15,
        paddingLeft: 35,
    },
    link: {
        textDecoration: "none",
        color: "black",
    },
    tab: {
        flex: 1,
        maxWidth: "100% !important",
        backgroundColor: "gold",
        opacity: 1,
        textTransform: "none",
    },
    tabSelected: {
        color: "white",
        backgroundColor: "black",
    },
    indicator: {
        backgroundColor: "gold",
        height: 5,
    },
    tabsFlexContainer: {
        gap: "15px",
    },
    tabContent: {
        maxWidth: 960,
        margin: "0 auto",
        marginTop: 40,
        marginBottom: 50,
        [theme.breakpoints.down('xl')]: {
            marginLeft: 10,
            marginRight: 10,
        },
    },
    tabLabel: {
        fontWeight: 700,
        [theme.breakpoints.down('xl')]: {
            fontSize: ".8rem",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: ".6rem",
        },
    },
    loadingDiv: {
        height: 400,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    tripPlannerBox: {
        position: "relative",
        height: 820,
        [theme.breakpoints.down('lg')]: {
            height: 520,
        },
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    flex: 1,
    maxWidth: "100% !important",
    backgroundColor: "gold",
    opacity: 1,
    textTransform: "none",
    '&.Mui-selected': {
        color: "white",
        backgroundColor: "black",
    },
}));

function ChooseEV(props) {
    const classes = useStyles();
    const { userProfile, user } = props;

    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // This is necessary so we can make sure the package remounts everytime someone navigates to the page
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const handleChange = (event, newValue) => {
        const name = event.currentTarget.name;

        //Extract the name as the index
        //We have to do this because of the arrow icons inside Tabs component
        setValue(parseInt(name));
    };

    const handleProfileUpdate = () => {
        props.getUserProfile(user.data.uid);
    };

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <ISDRideshareHeader
                    title="Choose an EV"
                    subheading="Browse cars and choose the best option for you!"
                />

                <TextDivider text="Activities" />

                <div
                    style={{
                        backgroundColor: "gainsboro",
                        padding: 20,
                        textAlign: "left",
                    }}
                >
                    {/* <Grid container justify="flex-end" style={{marginTop: -40, marginBottom: 10}}>
                        <SelectedCarsMenu />
                    </Grid> */}
                    <Typography variant="h6">
                        The activities below are a fun way to learn about
                        electric vehicles. When you save cars, they will appear
                        under “My Saved Cars”.
                    </Typography>
                </div>
            </div>

            <Tabs
                value={value}
                onChange={handleChange}
                style={{ maxWidth: 960, margin: "0 auto", marginBottom: 30 }}
                classes={{
                    flexContainer: classes.tabsFlexContainer,
                    indicator: classes.indicator,
                }}
            >
                <StyledTab
                    label={
                        <Typography variant="h6" className={classes.tabLabel}>
                            Browse Cars
                        </Typography>
                    }
                    name="0"
                />
                <StyledTab
                    label={
                        <Typography variant="h6" className={classes.tabLabel}>
                            Compare Cars
                        </Typography>
                    }
                    name="1"
                />
                <StyledTab
                    label={
                        <Typography variant="h6" className={classes.tabLabel}>
                            Save with Incentives
                        </Typography>
                    }
                    name="2"
                />
                {/* <Tab 
                    label={
                        <Typography variant="h6" className={classes.tabLabel}>
                            Check Your Trips
                        </Typography>
                    } 
                    name="3"
                    classes={{root: classes.tab, selected: classes.tabSelected}}
                /> */}
            </Tabs>
            {
                loading ? (
                    <div
                        style={{
                            marginTop: 80,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : value === 0 ? (
                    <div className={classes.tabContent}>
                        {/* <BrowseCars handleProfileUpdate={handleProfileUpdate} /> */}
                    </div>
                ) : value === 1 ? (
                    <div className={classes.tabContent}>
                        {/* <CompareCars
                            handleProfileUpdate={handleProfileUpdate}
                        /> */}
                    </div>
                ) : (
                    //value === 2 ?
                    <div className={classes.tabContent}>
                        <EVCoachMessage
                            message={
                                <div>
                                    Did you know that you can save thousands of
                                    dollars when you buy an electric car?
                                    <br />
                                    <br />
                                    Learn all about electric vehicle incentives
                                    below
                                </div>
                            }
                        />

                        <TextDivider text="Federal Government Incentives" />

                        <GrayTextBox variant="h6">
                            The US federal government offers tax credits to
                            reduce the cost of buying a new electric car. The
                            amount you can receive varies by automaker and can
                            range from $3,500 to $7,500. Visit this link to
                            learn more.
                            <div
                                style={{
                                    marginTop: 10,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <a
                                    href="https://www.fueleconomy.gov/feg/taxevb.shtml"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: "none" }}
                                >
                                    <StyledTaskButton>
                                        Find Federal Tax Credits
                                    </StyledTaskButton>
                                </a>
                            </div>
                        </GrayTextBox>

                        <TextDivider text="State and Local Incentives" />

                        <GrayTextBox variant="h6">
                            In addition to the federal tax credits, your local
                            government and electric utility offer incentives to
                            help you buy new or used electric vehicles. Applying
                            for the right incentives might help you save more
                            than $10,000!
                            <div
                                style={{
                                    marginTop: 10,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <a
                                    href="https://driveclean.ca.gov/search-incentives"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: "none" }}
                                >
                                    <StyledTaskButton>
                                        Find Local Incentives
                                    </StyledTaskButton>
                                </a>
                            </div>
                        </GrayTextBox>
                    </div>
                )
                /* :
                <div className={classes.tripPlannerBox}>
                    <TripPlanner />
                </div>
             */
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = {
    openAlert,
    getUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseEV);
