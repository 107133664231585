import {
    ADD_HUBSPOT_CONTACT_BEGIN,
    ADD_HUBSPOT_CONTACT_ERROR,
    ADD_HUBSPOT_CONTACT_SUCCESS,
    ASSOCIATE_HUBSPOT_DEAL_WITH_CONTACT_BEGIN,
    ASSOCIATE_HUBSPOT_DEAL_WITH_CONTACT_ERROR,
    ASSOCIATE_HUBSPOT_DEAL_WITH_CONTACT_SUCCESS,
    CREATE_HUBSPOT_DEAL_BEGIN,
    CREATE_HUBSPOT_DEAL_ERROR,
    CREATE_HUBSPOT_DEAL_SUCCESS,
    UPDATE_HUBSPOT_DEAL_BEGIN,
    UPDATE_HUBSPOT_DEAL_ERROR,
    UPDATE_HUBSPOT_DEAL_SUCCESS,
    UPDATE_HUBSPOT_CONTACT_BEGIN,
    UPDATE_HUBSPOT_CONTACT_ERROR,
    UPDATE_HUBSPOT_CONTACT_SUCCESS,
    CREATE_HUBSPOT_ENGAGEMENT_BEGIN,
    CREATE_HUBSPOT_ENGAGEMENT_ERROR,
    CREATE_HUBSPOT_ENGAGEMENT_SUCCESS,
} from "../constants/action-types-hubspot";

export const addHubspotContactBegin = () => ({
    type: ADD_HUBSPOT_CONTACT_BEGIN,
});
export const addHubspotContactSuccess = (result) => ({
    type: ADD_HUBSPOT_CONTACT_SUCCESS,
    payload: result,
});
export const addHubspotContactError = (error) => ({
    type: ADD_HUBSPOT_CONTACT_ERROR,
    payload: error,
});

export function addHubspotContact(contactData) {
    return (dispatch) => {
        dispatch(addHubspotContactBegin());
        return fetch(
            "https://us-central1-electrifyze-fce77.cloudfunctions.net/addHubspotContact",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(contactData),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.error === "CONTACT_EXISTS") {
                    dispatch(updateHubspotContact(contactData));
                } else {
                    console.log("Success:", data);
                    dispatch(addHubspotContactSuccess(data));
                }
            })
            .catch((error) => {
                const message = `An error has occured: ${error}`;
                dispatch(addHubspotContactError(message));
            });
    };
}

export const updateHubspotContactBegin = () => ({
    type: UPDATE_HUBSPOT_CONTACT_BEGIN,
});
export const updateHubspotContactSuccess = (result) => ({
    type: UPDATE_HUBSPOT_CONTACT_SUCCESS,
    payload: result,
});
export const updateHubspotContactError = (error) => ({
    type: UPDATE_HUBSPOT_CONTACT_ERROR,
    payload: error,
});

export function updateHubspotContact(data) {
    return (dispatch) => {
        dispatch(updateHubspotContactBegin());
        return fetch(
            "https://us-central1-electrifyze-fce77.cloudfunctions.net/updateHubspotContact",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                dispatch(updateHubspotContactSuccess(data));
            })
            .catch((error) => {
                const message = `An error has occured: ${error}`;
                dispatch(updateHubspotContactError(message));
            });
    };
}

export const createHubspotDealBegin = () => ({
    type: CREATE_HUBSPOT_DEAL_BEGIN,
});
export const createHubspotDealSuccess = (result) => ({
    type: CREATE_HUBSPOT_DEAL_SUCCESS,
    payload: result,
});
export const createHubspotDealError = (error) => ({
    type: CREATE_HUBSPOT_DEAL_ERROR,
    payload: error,
});

export function createHubspotDeal(data) {
    return (dispatch) => {
        dispatch(createHubspotDealBegin());
        return fetch(
            "https://us-central1-electrifyze-fce77.cloudfunctions.net/createHubspotDeal",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                dispatch(createHubspotDealSuccess(data));
            })
            .catch((error) => {
                const message = `An error has occured: ${error}`;
                dispatch(createHubspotDealError(message));
            });
    };
}

export const updateHubspotDealBegin = () => ({
    type: UPDATE_HUBSPOT_DEAL_BEGIN,
});
export const updateHubspotDealSuccess = (result) => ({
    type: UPDATE_HUBSPOT_DEAL_SUCCESS,
    payload: result,
});
export const updateHubspotDealError = (error) => ({
    type: UPDATE_HUBSPOT_DEAL_ERROR,
    payload: error,
});

export function updateHubspotDeal(data) {
    return (dispatch) => {
        dispatch(updateHubspotDealBegin());
        return fetch(
            "https://us-central1-electrifyze-fce77.cloudfunctions.net/updateHubspotDeal",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                dispatch(updateHubspotDealSuccess(data));
            })
            .catch((error) => {
                const message = `An error has occured: ${error}`;
                dispatch(updateHubspotDealError(message));
            });
    };
}

export const associateHubspotDealWithContactBegin = () => ({
    type: ASSOCIATE_HUBSPOT_DEAL_WITH_CONTACT_BEGIN,
});
export const associateHubspotDealWithContactSuccess = (result) => ({
    type: ASSOCIATE_HUBSPOT_DEAL_WITH_CONTACT_SUCCESS,
    payload: result,
});
export const associateHubspotDealWithContactError = (error) => ({
    type: ASSOCIATE_HUBSPOT_DEAL_WITH_CONTACT_ERROR,
    payload: error,
});

export function associateHubspotDealWithContact(data) {
    return (dispatch) => {
        dispatch(associateHubspotDealWithContactBegin());
        return fetch(
            "https://us-central1-electrifyze-fce77.cloudfunctions.net/associateHubspotDealWithContact",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                dispatch(associateHubspotDealWithContactSuccess(data));
            })
            .catch((error) => {
                const message = `An error has occured: ${error}`;
                dispatch(associateHubspotDealWithContactError(message));
            });
    };
}

export const createHubspotEngagementBegin = () => ({
    type: CREATE_HUBSPOT_ENGAGEMENT_BEGIN,
});
export const createHubspotEngagementSuccess = (result) => ({
    type: CREATE_HUBSPOT_ENGAGEMENT_SUCCESS,
    payload: result,
});
export const createHubspotEngagementError = (error) => ({
    type: CREATE_HUBSPOT_ENGAGEMENT_ERROR,
    payload: error,
});

export function createHubspotEngagement(data) {
    return (dispatch) => {
        dispatch(createHubspotEngagementBegin());
        return fetch(
            "https://us-central1-electrifyze-fce77.cloudfunctions.net/createHubspotEngagement",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                dispatch(createHubspotEngagementSuccess(data));
            })
            .catch((error) => {
                const message = `An error has occured: ${error}`;
                dispatch(createHubspotEngagementError(message));
            });
    };
}
