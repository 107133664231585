import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Actions
import { getHomePage, getOrganization } from "../../../actions/content_actions";

//Assets
import {
    faCar,
    faHandsHelping,
    faPiggyBank,
} from "@fortawesome/free-solid-svg-icons";

//Styles
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Components
import ISDRideshareHeader from "../../la_county/ISDRideshareHeader";
import TextDivider from "../Styled Components/TextDivider";
import OverviewGridItem from "../AuthenticatedHome/OverviewGridItem";
import { GrayTextBox } from "../Styled Components/GrayTextBox";
import { StyledTaskButton } from "../Styled Components/Buttons";
import NativeFormsDialog from "../Contact/NativeFormsDialog";
import EVCoachMessage from "../Login/EVCoachMessage";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100%",
        //overflowY: 'auto'
    },
    content: {
        maxWidth: 960,
        margin: "0 auto",
        marginTop: 30,
        marginBottom: 40,
        textAlign: "center",
        [theme.breakpoints.down('xl')]: {
            marginLeft: 10,
            marginRight: 10,
        },
    },
    title: {
        marginBottom: 10,
    },
    yellowBar: {
        height: 6,
        width: "100%",
        backgroundColor: "gold",
        marginTop: 30,
        marginBottom: 30,
    },
    prizeGrid: {
        marginTop: 20,
        maxWidth: 400,
        margin: "0 auto",
    },
    cardImage: {
        height: 80,
        objectFit: "contain",
    },
    cardText: {
        textAlign: "left",
    },
    overviewImage: {
        width: 600,
        margin: 30,
    },
    bodyText: {
        fontWeight: 400,
    },
    speechBubble: {
        "&::before": {
            content: "''",
            width: "0px",
            height: "0px",
            position: "relative",
            borderLeft: "20px solid trasparent",
            borderRight: "20px solid gainsboro",
            borderTop: "20px solid gainsboro",
            borderBottom: "20px solid transparent",
            left: "-20px",
            top: "24px",
        },
    },
}));

const overviewItems = [
    /* {
        icon: faClipboardList,
        title: "Learn About EVs",
        description: (
            <Typography>
                You can save thousands of dollars while doing good for the world. <Link to="/ev_learning">Click here to learn more.</Link>
            </Typography>   
        )
    }, */
    {
        icon: faCar,
        title: "Choose an EV",
        description: (
            <Typography>
                Did you know there are over 50 electric car models available for
                sale?{" "}
                <Link to="/choose_ev">Visit this page to browse cars.</Link>
            </Typography>
        ),
    },
    {
        icon: faPiggyBank,
        title: "Find the Best Deal",
        description: (
            <Typography>
                Ready to buy? Talk to your{" "}
                <Link to="/coaching">Electric Car Buying Coach</Link> to get the
                best deal on your purchase.
            </Typography>
        ),
    },
    {
        icon: faHandsHelping,
        title: "Refer a Friend",
        description: (
            <Typography>
                Do you know people who can benefit from this program?{" "}
                <Link to="/refer">Invite others with your referral link.</Link>
            </Typography>
        ),
    },
];

function AuthenticatedHomeDefault(props) {
    const classes = useStyles();

    const { userProfile } = props;
    const [open, toggleDialog] = useState(false);

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <ISDRideshareHeader
                    title="Home"
                    subheading={
                        userProfile.data.first_name +
                        " welcome to Electrifyze LA County!"
                    }
                />

                {/* <Grid container style={{border: '1px solid black', marginTop: 30, marginBottom: 60}}>
                    <Link to='/courses' style={{textDecoration: 'none'}}>
                        <img 
                            src={DriveElectricCampaign} 
                            style={{width: '100%', maxWidth: '100%', maxHeight: '100%'}} 
                            alt="drive-electric"
                        />
                    </Link>
                    <Grid item xs style={{maxHeight: '100%'}}>
                        <img 
                            src={CarGarage} 
                            style={{height: '100%', width: '100%'}} 
                            alt="car-garage"
                        />
                    </Grid>
                    <Grid item xs style={{textAlign: 'right', margin: 20}}>
                        <Typography variant="h3">
                            Electric Car Week
                        </Typography>
                        <br />
                        <Typography variant="h5">
                            Feb 22 - Mar 1
                        </Typography>
                        <Typography variant="body1" className={classes.bodyText}>
                            Free to join - win exciting prices!
                        </Typography>
                        <br />
                        <Link to='/courses' style={{textDecoration: 'none'}}>
                            <StyledTaskButton>
                                Click here to start
                            </StyledTaskButton>
                    </Grid>
                </Grid> */}

                <TextDivider text="What is electrifyze?" />

                <GrayTextBox variant="h6">
                    LA County has launched a program that helps employees and
                    residents of LA County to learn about and adopt electric
                    cars and get the best deal. Your participation in this
                    program is so important to helping us meet our Goal of
                    becoming a fossil fuel free LA County.
                </GrayTextBox>

                <TextDivider text="Overview" />

                <Grid container style={{ maxWidth: 900, margin: "0 auto" }}>
                    {overviewItems.map((item, index) => {
                        return (
                            <OverviewGridItem
                                faIcon={item.icon}
                                title={item.title}
                                description={item.description}
                                key={index}
                            />
                        );
                    })}
                </Grid>

                <TextDivider text="Get the best deal" />

                <EVCoachMessage
                    message={
                        <div>
                            Hi, I’m your Electric Car buying coach. Did you know
                            that you can over $10,000 in incentives and rebates
                            to buy an electric car?
                            <br />
                            <br />I am here to help you learn about electric
                            cars and find great deals!{" "}
                            <Link to="/coaching">Visit this page</Link> to learn
                            more.
                        </div>
                    }
                />

                <TextDivider text="Share Your Thoughts" />

                <GrayTextBox variant="h6">
                    LA County has launched a program that helps employees and
                    residents of LA County to learn about and adopt electric
                    cars and get the best deal. Your participation in this
                    program is so important to helping us meet our Goal of
                    becoming a fossil fuel free LA County.
                    <div
                        style={{
                            marginTop: 10,
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <StyledTaskButton onClick={() => toggleDialog(true)}>
                            Share Your Thoughts
                        </StyledTaskButton>
                    </div>
                </GrayTextBox>
            </div>

            <NativeFormsDialog
                open={open}
                toggleDialog={() => toggleDialog(false)}
                formURL="https://surveys.electrifyze.com/IVkdl1jZmgnZzkEcL1Db"
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganization: (organization) =>
            dispatch(getOrganization(organization)),
        getHomePage: (organization) => dispatch(getHomePage(organization)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AuthenticatedHomeDefault)
);
