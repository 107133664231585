import React from "react";
import clsx from "clsx";

// Styles
import {
    Grid,
    IconButton,
    lighten,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// Assets
import {
    Archive,
    Block,
    Check,
    Delete,
    Unarchive,
} from "@mui/icons-material";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        border: "1px solid gainsboro",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.mode === "light"
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: "1 1 100%",
    },
}));

function AssignmentsTableToolbar(props) {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Tasks Assigned to User
                </Typography>
            )}

            {
                numSelected > 0 ? (
                    <Grid container justifyContent="flex-end">
                        <Tooltip title="Remove Assignment">
                            <IconButton
                                onClick={props.handleRemoveClick}
                                size="large"
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Mark Complete">
                            <IconButton
                                onClick={props.handleMarkCompleteClick}
                                size="large"
                            >
                                <Check />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Mark Incomplete">
                            <IconButton
                                onClick={props.handleMarkIncompleteClick}
                                size="large"
                            >
                                <Block />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Archive">
                            <IconButton
                                onClick={props.handleArchiveClick}
                                size="large"
                            >
                                <Archive />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Unarchive">
                            <IconButton
                                onClick={props.handleUnarchiveClick}
                                size="large"
                            >
                                <Unarchive />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                ) : null
                /* <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
                <FilterList />
            </IconButton>
            </Tooltip> */
            }
        </Toolbar>
    );
}

export default AssignmentsTableToolbar;
