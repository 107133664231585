import React from "react";
import { connect } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";

//Actions
import { signInUser, logout } from "../../../actions/user_actions";
import { getOrganization } from "../../../actions/content_actions";

//Styles
import { Typography, Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import TaskWarningDialog from "./TaskWarningDialog";

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: "none",
        color: "black",
    },
    courseTitle: {
        [theme.breakpoints.down('xl')]: {
            display: "none",
        },
    },
    button: {
        borderColor: theme.palette.info.main,
        color: theme.palette.info.main,
    },
    backButton: {
        borderColor: "rgba(255, 213, 0, 1)",
        margin: 15,
        "&:hover": {
            opacity: 0.7,
        },
    },
}));

function TaskTitleBackBox(props) {
    const classes = useStyles(props);
    const { organization, singleTask, courses, location } = props;
    const isAssignment = location.state && location.state.isAssignment;
    const pathname = useLocation().pathname || null;
    const [openWarning, setOpenWarning] = React.useState(false);

    const handleWarningClickOpen = () => {
        setOpenWarning(true);
    };

    const handleWarningClose = () => {
        setOpenWarning(false);
    };

    const getCourseTitle = (id) => {
        if (courses.data != null) {
            const course = courses.data.find(item => item.id === id)
            return course?.title
        }
    };

    return (
        <div>
            {isAssignment ? (
                <Grid container alignItems="center" justifyContent="center">
                    <Typography
                        variant="h4"
                        className={classes.courseTitle}
                        style={
                            organization !== null && {
                                color: organization.data.fontColor,
                            }
                        }
                    >
                        Actvity Hub
                    </Typography>
                    <Button
                        variant="outlined"
                        className={classes.backButton}
                        onClick={handleWarningClickOpen}
                    >
                        <FontAwesomeIcon
                            icon={faUndo}
                            style={{
                                color: "rgba(255, 213, 0, 1)",
                                marginTop: 5,
                                marginBottom: 5,
                            }}
                        />
                    </Button>
                </Grid>
            ) : pathname.startsWith("/task") ? (
                <Grid container alignItems="center" justifyContent="center">
                    <Typography
                        variant="h4"
                        className={classes.courseTitle}
                        style={
                            organization !== null && {
                                color: organization.data.fontColor,
                            }
                        }
                    >
                        {getCourseTitle(singleTask.data.courseID)}
                    </Typography>
                    <Button
                        variant="outlined"
                        className={classes.backButton}
                        onClick={handleWarningClickOpen}
                        id="test"
                    >
                        <FontAwesomeIcon
                            icon={faUndo}
                            style={{
                                color: "rgba(255, 213, 0, 1)",
                                marginTop: 5,
                                marginBottom: 5,
                            }}
                        />
                    </Button>
                </Grid>
            ) : pathname.startsWith("/transition") &&
              singleTask.data != null ? (
                <Link
                    to={`/sections/${
                        singleTask.data !== null &&
                        singleTask.data.courseID &&
                        singleTask.data.courseID
                    }`}
                    className={classes.link}
                >
                    <Grid container alignItems="center" justifyContent="center">
                        <Typography
                            variant="h4"
                            className={classes.courseTitle}
                            style={
                                organization !== null && {
                                    color: organization.data.fontColor,
                                }
                            }
                        >
                            {getCourseTitle(singleTask.data.courseID)}
                        </Typography>
                        <Button
                            variant="outlined"
                            className={classes.backButton}
                        >
                            <FontAwesomeIcon
                                icon={faUndo}
                                style={{
                                    color: "rgba(255, 213, 0, 1)",
                                    marginTop: 5,
                                    marginBottom: 5,
                                }}
                            />
                        </Button>
                    </Grid>
                </Link>
            ) : null}

            <TaskWarningDialog
                open={openWarning}
                toggleDialog={handleWarningClose}
                path={
                    isAssignment
                        ? "/coaching"
                        : `/sections/${
                              singleTask.data !== null &&
                              singleTask.data.courseID &&
                              singleTask.data.courseID
                          }`
                }
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        organization: state.content.organization,
        singleTask: state.content.singleTask,
        courses: state.content.courses,
    };
};

const mapDispatchToProps = {
    signInUser,
    logout,
    getOrganization,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TaskTitleBackBox)
);
