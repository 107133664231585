import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

// Actions
import { signInUser } from "../../../actions/user_actions";

//Styles
import { makeStyles } from "@mui/styles";
import {
    Typography,
    CircularProgress,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Firebase
import { getFirestore, collection, query, getDocs } from "firebase/firestore"

import { downloadUserCSV } from "./csv_download";
import { styled } from "@mui/system";

// Get Firestore reference
var db = getFirestore()

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    formControl: {
        width: "100%",
        marginTop: 15,
        marginBottom: 15,
    },
}));

const BorderedTableCell = styled(TableCell)(({ theme }) => ({
    border: "1px solid gainsboro",
    whiteSpace: "nowrap",
}));

const organizations = [
    {
        value: "la_county",
        name: "LA County",
        campaigns: [
            {
                value: "rideshare_fair",
                name: "2020 Rideshare Fair",
                course: "PgbSH9lk2GLlHCHvegJd",
                sub_sections: {},
            },
            {
                value: "drive_electric_week",
                name: "Drive Electric Week",
                course: "7YgbfBAHTR8PYLM4Q4wk",
                sub_sections: {
                    WyqVaS1xoN7IOc9CTAq3: {
                        tasks: 3,
                        completion_bonus: 5,
                        completed: 0,
                        quiz: true,
                    },
                    eGPjfoTEKU12u5W1lNss: {
                        tasks: 5,
                        completion_bonus: 5,
                        completed: 0,
                        quiz: true,
                    },
                    GcWDp7nXfxXrU9R9a4Vb: {
                        tasks: 5,
                        completion_bonus: 5,
                        completed: 0,
                        quiz: true,
                    },
                    EEfL99oALjvU8dl16PE9: {
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0,
                    },
                    pGHry5r1XsH5Q7wxUl4f: {
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0,
                    },
                },
            },
            {
                value: "earth_day",
                name: "Earth Day",
                course: "AFRX5MaCGKb4hpqlxH4G",
                sub_sections: {
                    m4sG8wwOra5XvBgOAdf5: {
                        tasks: 2,
                        completion_bonus: 10,
                        completed: 0,
                    },
                    zE13RjCOBqSgfsIR3iYU: {
                        tasks: 5,
                        completion_bonus: 5,
                        completed: 0,
                        quiz: true,
                    },
                    KFpctr07UWJcPHIGDPLl: {
                        tasks: 3,
                        completion_bonus: 5,
                        completed: 0,
                        quiz: true,
                    },
                    hTdcVoLCxu0V4IgHRluC: {
                        tasks: 4,
                        completion_bonus: 5,
                        completed: 0,
                        quiz: true,
                    },
                    kLeBwct9IT19hyf8pr8z: {
                        tasks: 1,
                        completion_bonus: 15,
                        completed: 0,
                    },
                    iuqz8oTU4jOiwJMdcaLr: {
                        tasks: 2,
                        completion_bonus: 15,
                        completed: 0,
                    },
                },
            },
            {
                value: "2021-independence",
                name: "2021 Independence Day",
                course: "BScQ6meMbXZlXkSALuOz",
                sub_sections: {
                    "6VGbXjBDqMZohYFgG914": {
                        tasks: 2,
                        completion_bonus: 10,
                        completed: 0,
                    },
                    LZPHzpeH66fMzFQQZvb3: {
                        tasks: 4,
                        completion_bonus: 20,
                        completed: 0,
                    },
                    eYqdTtnmE0XKPStFB98Q: {
                        tasks: 5,
                        completion_bonus: 20,
                        completed: 0,
                        quiz: true,
                    },
                    pxD2jATTfYYhqY8CE2rB: {
                        tasks: 3,
                        completion_bonus: 10,
                        completed: 0,
                        quiz: true,
                    },
                    PdToD70BlevTOqyf4Wdh: {
                        tasks: 2,
                        completion_bonus: 10,
                        completed: 0,
                    },
                    "1nSHm8xFuLRQtSOyHZAY": {
                        tasks: 1,
                        completion_bonus: 10,
                        completed: 0,
                    },
                },
            },
            {
                value: "2021-rideshare",
                name: "2021 Rideshare Fair",
                course: "vBPrFdhWsSfA4JjhTvEw",
                sub_sections: {
                    n2Vqq2maK1Drv0R9AcuV: {
                        tasks: 2,
                        completion_bonus: 0,
                        completed: 0,
                    },
                    z18QCxUZS8YzLNde2gQq: {
                        tasks: 4,
                        completion_bonus: 0,
                        completed: 0,
                    },
                    BAkD65qBOx6NXk6pkoTq: {
                        tasks: 5,
                        completion_bonus: 0,
                        completed: 0,
                    },
                    fVxynAou9CXYVXGq9Kz1: {
                        tasks: 3,
                        completion_bonus: 0,
                        completed: 0,
                    },
                    SaIblt4b8DmQc8VDzMgy: {
                        tasks: 2,
                        completion_bonus: 0,
                        completed: 0,
                    },
                },
            },
            {
                value: "2022-3ce-earthday",
                name: "2022-3ce-earthday",
                course: "SMjr20wuo516hlhvJvKv",
                sub_sections: {
                    O5D9dLP4qKkDGNYQrCL6: { // 1.1 Welcome to Electric Cars for Earth! [2022-3ce-earthday]
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                    hAE3SdPCGv2IgBuk7JsL: { // 2.1 An Intro to Electric Cars [2022-3ce-earthday]
                        tasks: 5,
                        completion_bonus: 10,
                        completed: 0, // Update this
                    },
                    "1UJFu5ZvwKmQR6ovycJb": { // 2.2 Save Money on Your Next Car [2022-3ce-earthday]
                        tasks: 4,
                        completion_bonus: 10,
                        completed: 0, // Update this
                    },
                    xCzTaSZEyQnrqsIAzfvd: { // 3.1 Creating a Fossil Fuel Free Community [2022-3ce-earthday]
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                },
            },
            {
                value: "2022-lac-earthday",
                name: "2022-lac-earthday",
                course: "LBAih7yvjrI6tkCRCERJ",
                sub_sections: {
                    nDS851FWcVDMcanDA2RB: { // 1.1 Welcome to Electric Cars for Earth! [2022-lac-earthday]
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                    "8qhANqisuQ40ahH4KLPA": { // 2.1 An Intro to Electric Cars [2022-lac-earthday]
                        tasks: 5,
                        completion_bonus: 10,
                        completed: 0, // Update this
                    },
                    NdPkpz5A7JxdUxXpIguX: { // 2.2 Save Money on Your Next Car [2022-lac-earthday]
                        tasks: 4,
                        completion_bonus: 10,
                        completed: 0, // Update this
                    },
                    "9Te6SfHWkZ20XLlac55M": { // 3.1 Creating a Fossil Fuel Free Community [2022-lac-earthday]
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                },
            },
            {
                value: "2022-lac-independence",
                name: "2022-lac-independence",
                course: "ZLlUj8l82hFEO4wHkvoN",
                sub_sections: {
                    CJpiRRHTYYytAyCjzdMF: { // 1.1 Welcome to Independence from Fossil Fuels! [2022-lac-independence]
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                    "JCdXAHA2UyyPUkyek8TN": { // 2.1 Declare Your Independence [2022-lac-independence]
                        tasks: 4,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                    "4nZHjFssbNet9A0PJT30": { // 2.2 An Intro to Electric Cars [2022-lac-independence]
                        tasks: 5,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    "jeQeJrgw2pisECNzZEl5": { // 3.1 Save Money on Your Next Car [2022-lac-independence]
                        tasks: 4,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    "S3Qey7FrcH2jDJHyUTTG": { // 4.1 We’re Creating a Fossil Fuel Free Community [2022-lac-independence]
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                },
            },
            {
                value: "2022-3ce-independence",
                name: "2022-3ce-independence",
                course: "0DdJkIK073oVR6Y8iEN0",
                sub_sections: {
                    "19ggDBPF5noWeGnSycQJ": { // 1.1 Welcome to Independence from Fossil Fuels! [2022-3ce-independence]
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                    "3gmjbT88y2qkTfACPpzq": { // 2.1 Declare Your Independence [2022-3ce-independence]
                        tasks: 4,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                    "Ui6LIkr8IsKq3nbgoynn": { // 2.2 An Intro to Electric Cars [2022-3ce-independence]
                        tasks: 5,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    "rXL3YcYkqX6UtT0wciRi": { // 3.1 Save Money on Your Next Car [2022-3ce-independence]
                        tasks: 4,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    "7wgWlcSTJ7mD7Nv6KWp5": { // 4.1 We're Creating a Fossil Fuel Free Community [2022-3ce-independence]
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                },
            },
            {
                value: "2022-lac-rideshare",
                name: "2022-lac-rideshare",
                course: "W2Si2qJRCicGm4VuzXaX",
                sub_sections: {
                    "eF6UWg9EvnsBdw1fS666": { // 1.1 Creating a Fossil Fuel Free LA [2022-lac-rideshare]
                        tasks: 2,
                        completion_bonus: 10,
                        completed: 0, // Update this
                    },
                    "RfKACMrWtJa7s7wATIw0": { // 2.1 Ridesharing to Work [2022-lac-rideshare]
                        tasks: 4,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    "e4MRpXT5Py1bXTjMh5QW": { // 3.1 Understanding the Basics of Electric Cars [2022-lac-rideshare]
                        tasks: 5,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    "2IFAXvvETMV1hYjMtqlN": { // 3.2 Going Electric Has Never Been Easier [2022-lac-rideshare]
                        tasks: 3,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        // quiz: true,
                    },
                    "fetCLMfuxmjj5pnpshkU": { // 4.1 We're Creating a Fossil Fuel Free Community [2022-3ce-independence]
                        tasks: 1,
                        completion_bonus: 10,
                        completed: 0, // Update this
                    },
                },
            },
            {
                value: "2023-lac-earthday",
                name: "2023-lac-earthday",
                course: "AS4SRYKfo2M0vaEsafRq",
                sub_sections: {
                    Ymg2r6cpkTZWwWSOEW2t: { // 1.1 Welcome to Electric Cars for Earth! [2023-lac-earthday]
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                    "PEGXVvkR4cv2fgBxArdL": { // 2.1 An Intro to Electric Cars [2023-lac-earthday]
                        tasks: 5,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    U56TuTGLbkotPc3W53yx: { // 2.2 Save Money on Your Next Car [2023-lac-earthday]
                        tasks: 4,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    "FBZoWYAJkb6lNFmvGmIr": { // 3.1 Creating a Fossil Fuel Free Community [2023-lac-earthday]
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                },
            },
            {
                value: "2023-lac-rideshare",
                name: "2023-lac-rideshare",
                course: "AiPIJP02tjNPBOoQliyr",
                sub_sections: {
                    "zRSW0tSUlcXLf8ipbzd0": { // 1.1 Creating a Fossil Fuel Free LA [2023-lac-rideshare]
                        tasks: 2,
                        completion_bonus: 10,
                        completed: 0, // Update this
                    },
                    "3wUMxiAJh5SZE09gAwYe": { // 2.1 Ridesharing to Work [2023-lac-rideshare]
                        tasks: 4,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    "0sY2eH3vHrpRV8rkEe3S": { // 3.1 Understanding the Basics of Electric Cars [2023-lac-rideshare]
                        tasks: 5,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    "ygEpZNb6w4ilZ4aCkDYc": { // 3.2 Going Electric Has Never Been Easier [2023-lac-rideshare]
                        tasks: 3,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        // quiz: true,
                    },
                    "ziyHlpLRsQ2DQ4KI61UW": { // 4.1 Share Your Thoughts on the Clean Air Rideshare Fair [2023-lac-rideshare]
                        tasks: 1,
                        completion_bonus: 10,
                        completed: 0, // Update this
                    },
                },
            },
            {
                value: "2024-lac-earthday",
                name: "2024-lac-earthday",
                course: "POK19FUOtIkmyom8wKxm",
                sub_sections: {
                    "P0e68NciJgOqrfUO9Me5": { // 1.1 Welcome to Electric Cars for Earth! [2024-lac-earthday]
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                    "HT6hkC9ELXwBr8qNlOcU": { // 2.1 An Intro to Electric Cars [2024-lac-earthday]
                        tasks: 5,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    "dbcl1ICW4VUvzcjuSzJ5": { // 2.2 Save Money on Your Next Car [2024-lac-earthday]
                        tasks: 4,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    "iEGtNFsa9pLf5ZeUZWNV": { // 3.1 Creating a Fossil Fuel Free Community [2024-lac-earthday]
                        tasks: 2,
                        completion_bonus: 20,
                        completed: 0, // Update this
                    },
                },
            },
            {
                value: "2024-lac-rideshare",
                name: "2024-lac-rideshare",
                course: "vGQKSqxLDAFVIBckDz0m",
                sub_sections: {
                    "mYlVvW3MD3dkNYuk2j7f": { // 1.1 Creating a Fossil Fuel Free LA [2024-lac-rideshare]
                        tasks: 2,
                        completion_bonus: 10,
                        completed: 0, // Update this
                    },
                    "xOdAvQDc7xUDhFd2xngV": { // 2.1 Ridesharing to Work [2024-lac-rideshare]
                        tasks: 4,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    "WLrfGIrbohtI4OhrRQzd": { // 3.1 Understanding the Basics of Electric Cars [2024-lac-rideshare]
                        tasks: 5,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        quiz: true,
                    },
                    "z9RKyq4i6kDdEp6yeY3v": { // 3.2 Going Electric Has Never Been Easier [2024-lac-rideshare]
                        tasks: 3,
                        completion_bonus: 10,
                        completed: 0, // Update this
                        // quiz: true,
                    },
                    "VjIbwagyqSOhJvw5BdrX": { // 4.1 Share Your Thoughts on the Clean Air Rideshare Fair [2024-lac-rideshare]
                        tasks: 1,
                        completion_bonus: 10,
                        completed: 0, // Update this
                    },
                },
            },
        ],
    },
    {
        value: "lane_county",
        name: "Lane County",
        campaigns: [],
    },
    {
        value: "gll",
        name: "GLL",
        campaigns: [],
    },
];

function UserExport(props) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState({
        data: [],
        filteredData: [],
        newUserCount: 0,
    });
    const [selectedOrganization, setSelectedOrganization] = useState();
    const [selectedCampaign, setSelectedCampaign] = useState();

    useEffect(() => {
        if (
            props.user.data != null &&
            props.userProfile.data != null &&
            props.userProfile.data.admin
        ) {
            getUsers()
        }
    }, []);

    const getUsers = async () => {
        setLoading(true);
        try {
            const q = query(collection(db, "users"));

            const querySnapshot = await getDocs(q);
            let data = [];
            querySnapshot.forEach(function (doc) {
                let user = doc.data();
                let points = 0;
                let completed_tasks_count = 0;

                for (let i in user.completed_tasks) {
                    points += user.completed_tasks[i].points;
                    completed_tasks_count += 1;
                }

                user.points = points;
                user.completed_tasks_count = completed_tasks_count;
                user.id = doc.id;
                data.push(user);
            });

            const newUsers = data.filter(
                (item) =>
                    item.date_created &&
                    moment(item.date_created.toDate()).isAfter(
                        moment().subtract(7, "day")
                    )
            );
            setUsers({
                ...users,
                data,
                filteredData: data,
                newUserCount: newUsers.length,
            });
            setLoading(false);
        } catch(error) {
            setLoading(false);

            console.log("Error getting document:", error);
        }
    }

    const handleOrganizationChange = (event) => {
        const organizationName = event.target.value;

        let filteredData = users.data.filter(
            (item) =>
                (item.organization.name &&
                    item.organization.name === organizationName) ||
                item.organization === organizationName
        );

        if (organizationName === "") {
            filteredData = users.data;
            setSelectedOrganization(undefined);
        } else {
            setSelectedOrganization(organizationName);
        }
        const newUsers = filteredData.filter(
            (item) =>
                item.date_created &&
                moment(item.date_created.toDate()).isAfter(
                    moment().subtract(7, "day")
                )
        );

        setUsers({
            ...users,
            filteredData,
            newUserCount: newUsers.length,
        });
    };

    const handleCampaignChange = (event) => {
        const campaignName = event.target.value;

        let filteredData = users.data.filter(
            (item) =>
                (item.organization.name &&
                    item.organization.name === selectedOrganization) ||
                item.organization === selectedOrganization
        );

        if (campaignName !== "") {
            setSelectedCampaign(campaignName);
            filteredData = filteredData.filter(
                (item) =>
                    item.campaigns && item.campaigns.includes(campaignName)
            );
        } else {
            setSelectedCampaign(undefined);
        }

        const newUsers = filteredData.filter(
            (item) =>
                item.date_created &&
                moment(item.date_created.toDate()).isAfter(
                    moment().subtract(7, "day")
                )
        );

        setUsers({
            ...users,
            filteredData,
            newUserCount: newUsers.length,
        });
    };

    const handleDownloadClick = () => {
        const campaigns = organizations.filter(
            (org) => org.value === selectedOrganization
        )[0].campaigns;
        downloadUserCSV(users, selectedCampaign, campaigns);
    };

    return (
        <div className={classes.root}>
            {loading ? (
                <CircularProgress
                    color="primary"
                    style={{ marginTop: 10, marginBottom: 10 }}
                />
            ) : (
                <div>
                    <Typography variant="h6">Filter by:</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel>Organization</InputLabel>
                                <Select
                                    onChange={handleOrganizationChange}
                                    label="Organization"
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {organizations.map(
                                        (organization, index) => {
                                            return (
                                                <MenuItem
                                                    value={organization.value}
                                                >
                                                    {organization.name}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Campaign
                                </InputLabel>
                                <Select
                                    disabled={!selectedOrganization}
                                    onChange={handleCampaignChange}
                                    label="Campaign"
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {selectedOrganization &&
                                        organizations
                                            .filter(
                                                (org) =>
                                                    org.value ===
                                                    selectedOrganization
                                            )[0]
                                            .campaigns.map(
                                                (campaign, index) => {
                                                    return (
                                                        <MenuItem
                                                            value={
                                                                campaign.value
                                                            }
                                                        >
                                                            {campaign.name}
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <TableContainer style={{ marginBottom: 15 }}>
                        <Table>
                            <TableRow>
                                <BorderedTableCell component="th" scope="row">
                                    Profiles count
                                </BorderedTableCell>
                                <BorderedTableCell>
                                    {users.filteredData.length}
                                </BorderedTableCell>
                            </TableRow>
                            <TableRow>
                                <BorderedTableCell component="th" scope="row">
                                    New users in the past week
                                </BorderedTableCell>
                                <BorderedTableCell>
                                    {users.newUserCount}
                                </BorderedTableCell>
                            </TableRow>
                        </Table>
                    </TableContainer>
                    <Button
                        onClick={handleDownloadClick}
                        variant="contained"
                        color="primary"
                    >
                        Download CSV
                    </Button>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    userProfile: state.user.userProfile,
});

const mapDispatchToProps = {
    signInUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserExport);
