import React from "react";
import { Provider } from "react-redux";
import store from "./store/index";

//Components
import Routes from "./Routes";

//Styles
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
} from "@mui/material/styles";

//LogRocket
// import LogRocket from "logrocket";
import { BrowserRouter } from "react-router-dom";

//Initialize LogRocket
if (
    window.location.hostname !== "localhost" &&
    window.location.hostname !== "127.0.0.1"
) {
    // LogRocket.init("tkytup/electrifyze");
}

const theme = createTheme({
    typography: {
        fontFamily: "Helvetica, Roboto, Arial",
    },
    palette: {
        primary: {
            main: "#000000",
        },
        secondary: {
            main: "#11cb5f",
        },
        info: {
            main: "#ffffff",
        },
    },
})

function App(props) {
    return (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    );
}

export default App;
