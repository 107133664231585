import React from "react";

//Styles
import { makeStyles } from "@mui/styles";
import { Divider, Grid, Typography } from "@mui/material";

//Assets

const useStyles = makeStyles((theme) => ({
    imageGridItem: {
        marginTop: 5,
        width: 210,
        [theme.breakpoints.down('xl')]: {
            width: 140,
        },
        [theme.breakpoints.down('lg')]: {
            width: 110,
        },
    },
    logoImage: {
        height: "auto",
        width: "80%",
    },
    textGridItem: {
        textAlign: "left",
    },
    textBox: {
        width: "100%",
    },
    divider: {
        height: 3,
        marginBottom: 10,
    },
    titleText: {
        fontSize: 42,
        fontWeight: 500,
        [theme.breakpoints.down('xl')]: {
            fontSize: 24,
        },
    },
    subheadingText: {
        fontWeight: 400,
        color: "green",
        [theme.breakpoints.down('xl')]: {
            fontSize: "1rem",
        },
    },
}));

function ReferralsHeader(props) {
    //Pass the color from the parent component to the styles
    const classes = useStyles();
    const { characterImage, title, subheading } = props;

    return (
        <Grid container spacing={1}>
            <Grid item className={classes.imageGridItem}>
                <img
                    src={characterImage}
                    className={classes.logoImage}
                    alt="Character"
                />
            </Grid>
            <Grid
                item
                xs
                className={classes.textGridItem}
                container
                alignItems="center"
            >
                <div className={classes.textBox}>
                    <Typography className={classes.titleText}>
                        {title}
                    </Typography>
                    <Divider className={classes.divider} />
                    <Typography variant="h5" className={classes.subheadingText}>
                        {subheading}
                    </Typography>
                </div>
            </Grid>
        </Grid>
    );
}

export default ReferralsHeader;