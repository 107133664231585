import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

// Actions
import { signInUser } from "../../../actions/user_actions";

//Styles
import { makeStyles } from "@mui/styles";

// Components

// Firebase
import { getFirestore, collection, query, getDocs } from "firebase/firestore"

import { CircularProgress } from "@mui/material";
import UserTasks from "./UserTasks";

// Get Firestore reference
const db = getFirestore();

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
}));

function ActivityHubManagement(props) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState({
        data: [],
        filteredData: [],
        newUserCount: 0,
    });

    useEffect(() => {
        if (
            props.user.data != null &&
            props.userProfile.data != null &&
            props.userProfile.data.admin
        ) {
            setLoading(true);

            getUserDocs()
        }
    }, []);

    const getUserDocs = async () => {
        try {
            const q = query(collection(db, "users"));

            const querySnapshot = await getDocs(q);
            let data = [];
            querySnapshot.forEach(function (doc) {
                let user = doc.data();
                let points = 0;
                let completed_tasks_count = 0;

                for (let i in user.completed_tasks) {
                    points += user.completed_tasks[i].points;
                    completed_tasks_count += 1;
                }

                user.points = points;
                user.completed_tasks_count = completed_tasks_count;
                user.id = doc.id;
                data.push(user);
            });

            const newUsers = data.filter(
                (item) =>
                    item.date_created &&
                    moment(item.date_created.toDate()).isAfter(
                        moment().subtract(7, "day")
                    )
            );
            setUsers({
                ...users,
                data,
                filteredData: data,
                newUserCount: newUsers.length,
            });
            setLoading(false);
        } catch(error) {
            setLoading(false);
            console.log("Error getting document:", error);
        }
    }

    return (
        <div className={classes.root}>
            {loading ? (
                <CircularProgress color="primary" />
            ) : (
                <UserTasks users={users} />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    userProfile: state.user.userProfile,
});

const mapDispatchToProps = {
    signInUser,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityHubManagement);
