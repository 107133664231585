import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

//Styles
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
    Box,
    Button,
    CircularProgress,
    DialogTitle,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";

// Firebase
import { fetchSignInMethodsForEmail, getAuth } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

//Components
import { StyledTaskButton } from "../default/Styled Components/Buttons";
import OurCountyBox from "./OurCountyBox";
import EVCoachMessage from "../default/Login/EVCoachMessage";

//Actions
import {
    checkAuthentication,
    signInUser,
    createUser,
    createUserProfile,
    addSendGridContact,
    updateUserProfile,
    updateReferrals,
} from "../../actions/user_actions";
import { updateSendGridContact } from "../../actions/sendgrid_actions";
import { openAlert } from "../../actions/alert_actions";
import { getPreRegistrationSurvey } from "../../actions/survey_actions";

//Components
import ForgotPasswordModal from "../default/Login/ForgotPasswordModal";
import { paramsToObject } from "../../helpers/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Assets
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import ISDRideShare from "../../static/images/isd-rideshare-transparent.png";
import EarthCharacter from "../../static/images/earth-only.png";
import LadyStatuePlugSmile from "../../static/images/lady-statute-charging-plug-smile.png";


const analytics = getAnalytics()

const useStyles = makeStyles((theme) => ({
    dialogBox: {
        margin: 20,
        [theme.breakpoints.down('xl')]: {
            margin: 0,
        },
    },
    dialogPaper: {
        width: "100%",
        [theme.breakpoints.down('xl')]: {
            margin: 16,
        },
        [theme.breakpoints.down('lg')]: {
            margin: 8,
        },
    },
    dialogContent: {
        paddingBottom: 10,
        msOverflowStyle: "scrollbar",
        [theme.breakpoints.down('xl')]: {
            padding: 10,
        },
    },
    backButton: {
        textTransform: "none",
    },
    backButtonIcon: {
        marginRight: 10,
        [theme.breakpoints.down('xl')]: {
            fontSize: 12,
        },
    },
    backButtonText: {
        [theme.breakpoints.down('xl')]: {
            fontSize: 12,
        },
    },
    loginBox: {
        maxWidth: 600,
        margin: "0 auto",
        [theme.breakpoints.down('xl')]: {
            margin: 0,
        },
    },
    registerBox: {
        maxWidth: 700,
        margin: "0 auto",
        [theme.breakpoints.down('xl')]: {
            margin: 0,
        },
    },
    submitTextButtonGrid: {
        maxWidth: 600,
        margin: "0 auto",
        marginBottom: 20,
        [theme.breakpoints.down('lg')]: {
            width: "100%",
            margin: 0,
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10,
        },
    },
    submitButtonItem: {
        marginLeft: 10,
        [theme.breakpoints.down('lg')]: {
            marginLeft: 0,
            marginTop: 10,
        },
    },
    termsAndConditionsText: {
        width: "100%",
        fontSize: 17,
        textAlign: "right",
        [theme.breakpoints.down('xl')]: {
            fontSize: 14,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 10,
        },
    },
    textField: {
        width: "100%",
        marginBottom: 5,
        "& .MuiFormLabel-root": {
            [theme.breakpoints.down('lg')]: {
                fontSize: ".8rem",
            },
        },
    },
    grayOutlinedGrid: {
        marginTop: 20,
        marginBottom: 20,
        padding: 15,
        borderRadius: 20,
        boxShadow: "2px 2px 6px 0px rgba(0,0,0,0.5)",
        [theme.breakpoints.down('xl')]: {
            flexDirection: "column",
        },
    },
    grayOutlinedLeftGridItem: {
        textAlign: "right",
        marginRight: 40,
        paddingRight: 40,
        borderRight: "1px solid gray",
        [theme.breakpoints.down('xl')]: {
            textAlign: "left",
            marginRight: 0,
            paddingRight: 0,
            paddingBottom: 10,
            borderBottom: "1px solid gray",
            borderRight: "none",
        },
    },
    tooltip: {
        minWidth: 400,
        color: "black",
        backgroundColor: "gainsboro",
    },
    arrow: {
        color: "gainsboro",
    },
    coachText: {
        [theme.breakpoints.down('xl')]: {
            fontSize: "1rem",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: ".8rem",
        },
    },
    coachInnerText: {
        textAlign: "center",
        [theme.breakpoints.down('xl')]: {
            fontSize: "1.2rem",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: "1rem",
        },
    },
}));

function LoginRegistrationDialog(props) {
    //Pass the color from the parent component to the styles
    const classes = useStyles();

    const {
        user,
        userProfile,
        visible,
        emailAddress,
        singleTask,
        preRegistrationSurvey,
        location,
    } = props;

    const userOrganization =
        userProfile.data != null &&
        (userProfile.data.currentOrganization ||
            userProfile.data.organization.name ||
            userProfile.data.organization);

    // Specify the Firestore collection name where pre-registration user data should be retrieved for the given campaign key
    const campaignPreRegistrationMap = {
        "2022-3ce-earthday": "2022-3ce-earthday_pre_registration_sign_up",
        "sample-campaign": "2021_rideshare_pre_registration_sign_up",
        "2022-lac-earthday": "2022-lac-earthday_pre_registration_sign_up",
        "2021-rideshare": "2021_rideshare_pre_registration_sign_up",
        "2021-independence": "2021_independence_pre_registration_sign_up",
        "earth_day": "pre_registration_sign_up",
    }

    // Specify the question string for the User's email address in the pre-registration form,
    // Values must be entered here only if the field string is different than just "Email"
    const campaignPreRegistrationEmailField = {
        "2022-lac-earthday": "Your County Email Address",
    }

    const campaignFromURL = location.pathname.slice(1, location.pathname.indexOf("/", 1));
    const currentCampaign = campaignFromURL // === "sample-campaign" ? campaignFromURL : "2021-rideshare";
    const currentCampaignSurvey = campaignPreRegistrationMap[currentCampaign] || "pre_registration_sign_up";
    const currentCampaignSurveyEmailField = campaignPreRegistrationEmailField[currentCampaign];

    const urlParams = new URLSearchParams(location.search);
    const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
    const params = paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}

    // =================================
    // Begin: DEFINING CAMPAIGN-SPECIFIC UI FOR LOGIN / REGISTRATION SCREENS
    // =================================

    const LoginRegistrationData_default = {
        enterEmailScreen: {
            coachImage: ISDRideShare,
            message: (
                <div className={classes.coachText}>
                    <Typography
                        variant="h5"
                        className={classes.coachInnerText}
                    >
                        <b>
                            Welcome to the Clean Air
                            Rideshare Fair!
                        </b>
                    </Typography>
                    <br />
                    <Typography
                        variant="h5"
                        align="left"
                    >
                        Let’s log in so we can track
                        the points that you earn
                        towards the exciting prizes.
                    </Typography>
                </div>
            )
        },
        registrationScreens: {
            nameAndReferralScreen: {
                coachImage: ISDRideShare,
                message: (
                    <div className={classes.coachText}>
                        Looks like you’re new to the
                        Rideshare Fair…
                        <br />
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Welcome!</b>
                        </Typography>
                        Let’s create an account to get
                        started…
                    </div>
                )
            },
            passwordScreen: {
                coachImage: ISDRideShare,
                // Note: We did not define a "message" property here, as the user's first name needs to be injected into the message
                // and the present "loginRegistrationData" does not have access to this information.
            },
            extraDataScreen: {
                showExtraDataScreen: true, // This property is not currently used
                coachImage: ISDRideShare,
                message: (
                    <div className={classes.coachText}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Almost Done...</b>
                        </Typography>
                        <br />
                        Just a few last questions… Then
                        you can get started on the Clean
                        Air Rideshare Fair!
                    </div>
                )
            }
        },
        loginScreen: {
            coachImage: ISDRideShare
        }
    };

    const LoginRegistrationData_3CEEarthDaySample = {
        enterEmailScreen: {
            coachImage: EarthCharacter,
            message: (
                <div className={classes.coachText}>
                    <Typography
                        variant="h5"
                        className={classes.coachInnerText}
                    >
                        <b>
                            Welcome to the EVs for Earth Preview!
                        </b>
                    </Typography>
                    <br />
                    <Typography
                        variant="h5"
                        align="left"
                    >
                        Let’s log in so we can track
                        the points that you earn
                        towards the exciting prizes.
                    </Typography>
                </div>
            )
        },
        registrationScreens: {
            nameAndReferralScreen: {
                coachImage: EarthCharacter,
                message: (
                    <div className={classes.coachText}>
                        Looks like you’re new to the EVs for Earth Preview…
                        <br />
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Welcome!</b>
                        </Typography>
                        Let’s create an account to get started…
                    </div>
                )
            },
            passwordScreen: {
                coachImage: EarthCharacter,
                // Note: We did not define a "message" property here, as the user's first name needs to be injected into the message
                // and the present "loginRegistrationData" does not have access to this information.
            },
            extraDataScreen: {
                showExtraDataScreen: true, // This property is not currently used
                coachImage: EarthCharacter,
                message: (
                    <div className={classes.coachText}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Almost Done...</b>
                        </Typography>
                        <br />
                        Just a few last questions… Then
                        you can get started!
                    </div>
                )
            }
        },
        loginScreen: {
            coachImage: EarthCharacter
        }
    };

    const LoginRegistrationData_3CEEarthDay = {
        enterEmailScreen: {
            coachImage: EarthCharacter,
            message: (
                <div className={classes.coachText}>
                    <Typography
                        variant="h5"
                        className={classes.coachInnerText}
                    >
                        <b>
                            Welcome to the Electric Cars for Earth!
                        </b>
                    </Typography>
                    <Box m={1.5} />
                    <Typography
                        variant="h5"
                        align="left"
                    >
                        Let’s log in so we can track
                        the points that you earn
                        towards the exciting prizes.
                    </Typography>
                </div>
            )
        },
        registrationScreens: {
            nameAndReferralScreen: {
                coachImage: EarthCharacter,
                message: (
                    <div className={classes.coachText}>
                        Looks like you’re new to Electric Cars for Earth…
                        <br />
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Welcome!</b>
                        </Typography>
                        Let’s create an account to get started…
                    </div>
                )
            },
            passwordScreen: {
                coachImage: EarthCharacter,
                // Note: We did not define a "message" property here, as the user's first name needs to be injected into the message
                // and the present "loginRegistrationData" does not have access to this information.
            },
            extraDataScreen: {
                showExtraDataScreen: true, // This property is not currently used
                coachImage: EarthCharacter,
                message: (
                    <div className={classes.coachText}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Almost Done...</b>
                        </Typography>
                        <br />
                        Just a few last questions… Then
                        you can get started!
                    </div>
                )
            }
        },
        loginScreen: {
            coachImage: EarthCharacter
        }
    };

    const LoginRegistrationData_2022_lac_earthday = {
        enterEmailScreen: {
            coachImage: EarthCharacter,
            message: (
                <div className={classes.coachText}>
                    <Typography
                        variant="h5"
                        className={classes.coachInnerText}
                    >
                        <b>
                            Welcome to the Electric Cars for Earth!
                        </b>
                    </Typography>
                    <Box m={1.5} />
                    <Typography
                        variant="h5"
                        align="left"
                    >
                        Let’s log in so we can track
                        the points that you earn
                        towards the exciting prizes.
                    </Typography>
                </div>
            )
        },
        registrationScreens: {
            nameAndReferralScreen: {
                coachImage: EarthCharacter,
                message: (
                    <div className={classes.coachText}>
                        Looks like you’re new to Electric Cars for Earth…
                        <br />
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Welcome!</b>
                        </Typography>
                        Let’s create an account to get started…
                    </div>
                )
            },
            passwordScreen: {
                coachImage: EarthCharacter,
                // Note: We did not define a "message" property here, as the user's first name needs to be injected into the message
                // and the present "loginRegistrationData" does not have access to this information.
            },
            extraDataScreen: {
                showExtraDataScreen: true, // This property is not currently used
                coachImage: EarthCharacter,
                message: (
                    <div className={classes.coachText}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Almost Done...</b>
                        </Typography>
                        <br />
                        Just a few last questions… Then
                        you can get started!
                    </div>
                )
            }
        },
        loginScreen: {
            coachImage: EarthCharacter
        }
    };
    
    const LoginRegistrationData_2022_lac_independence = {
        enterEmailScreen: {
            coachImage: LadyStatuePlugSmile,
            message: (
                <div className={classes.coachText}>
                    <Typography
                        variant="h5"
                        className={classes.coachInnerText}
                    >
                        <b>
                            Welcome to Independence from Fossil Fuels!
                        </b>
                    </Typography>
                    <Box m={1.5} />
                    <Typography
                        variant="h5"
                        align="left"
                    >
                        Let’s log in so we can track
                        the points that you earn
                        towards the exciting prizes.
                    </Typography>
                </div>
            )
        },
        registrationScreens: {
            nameAndReferralScreen: {
                coachImage: LadyStatuePlugSmile,
                message: (
                    <div className={classes.coachText}>
                        Looks like you’re new to Independece from Fossil Fuels…
                        <br />
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Welcome!</b>
                        </Typography>
                        Let’s create an account to get started…
                    </div>
                )
            },
            passwordScreen: {
                coachImage: LadyStatuePlugSmile,
                // Note: We did not define a "message" property here, as the user's first name needs to be injected into the message
                // and the present "loginRegistrationData" does not have access to this information.
            },
            extraDataScreen: {
                showExtraDataScreen: true, // This property is not currently used
                coachImage: LadyStatuePlugSmile,
                message: (
                    <div className={classes.coachText}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Almost Done...</b>
                        </Typography>
                        <br />
                        Just a few last questions… Then
                        you can get started!
                    </div>
                )
            }
        },
        loginScreen: {
            coachImage: LadyStatuePlugSmile
        }
    };

    const LoginRegistrationData_2022_3ce_independence = {
        enterEmailScreen: {
            coachImage: LadyStatuePlugSmile,
            message: (
                <div className={classes.coachText}>
                    <Typography
                        variant="h5"
                        className={classes.coachInnerText}
                    >
                        <b>
                            Welcome to Independence from Fossil Fuels!
                        </b>
                    </Typography>
                    <Box m={1.5} />
                    <Typography
                        variant="h5"
                        align="left"
                    >
                        Let’s log in so we can track
                        the points that you earn
                        towards the exciting prizes.
                    </Typography>
                </div>
            )
        },
        registrationScreens: {
            nameAndReferralScreen: {
                coachImage: LadyStatuePlugSmile,
                message: (
                    <div className={classes.coachText}>
                        Looks like you’re new to Independece from Fossil Fuels…
                        <br />
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Welcome!</b>
                        </Typography>
                        Let’s create an account to get started…
                    </div>
                )
            },
            passwordScreen: {
                coachImage: LadyStatuePlugSmile,
                // Note: We did not define a "message" property here, as the user's first name needs to be injected into the message
                // and the present "loginRegistrationData" does not have access to this information.
            },
            extraDataScreen: {
                showExtraDataScreen: true, // This property is not currently used
                coachImage: LadyStatuePlugSmile,
                message: (
                    <div className={classes.coachText}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Almost Done...</b>
                        </Typography>
                        <br />
                        Just a few last questions… Then
                        you can get started!
                    </div>
                )
            }
        },
        loginScreen: {
            coachImage: LadyStatuePlugSmile
        }
    };
    
    const LoginRegistrationData_2022_lac_rideshare = {
        enterEmailScreen: {
            coachImage: ISDRideShare,
            message: (
                <div className={classes.coachText}>
                    <Typography
                        variant="h5"
                        className={classes.coachInnerText}
                    >
                        <b>
                            Welcome to the 2022 Clean Air Rideshare Fair!
                        </b>
                    </Typography>
                    <Box m={1.5} />
                    <Typography
                        variant="h5"
                        align="left"
                    >
                        Let’s log in so we can track
                        the points that you earn
                        towards the exciting prizes.
                    </Typography>
                </div>
            )
        },
        registrationScreens: {
            nameAndReferralScreen: {
                coachImage: ISDRideShare,
                message: (
                    <div className={classes.coachText}>
                        Looks like you’re new to the Rideshare Fair…
                        <br />
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Welcome!</b>
                        </Typography>
                        Let’s create an account to get started…
                    </div>
                )
            },
            passwordScreen: {
                coachImage: ISDRideShare,
                // Note: We did not define a "message" property here, as the user's first name needs to be injected into the message
                // and the present "loginRegistrationData" does not have access to this information.
            },
            extraDataScreen: {
                showExtraDataScreen: true, // This property is not currently used
                coachImage: ISDRideShare,
                message: (
                    <div className={classes.coachText}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Almost Done...</b>
                        </Typography>
                        <br />
                        Just a few last questions… Then
                        you can get started!
                    </div>
                )
            }
        },
        loginScreen: {
            coachImage: ISDRideShare
        }
    };

    const LoginRegistrationData_lac_etc_training_1_0 = {
        enterEmailScreen: {
            coachImage: ISDRideShare,
            message: (
                <div className={classes.coachText}>
                    <Typography
                        variant="h5"
                        className={classes.coachInnerText}
                    >
                        <b>
                            Welcome to the 2023 ETC Training Game!
                        </b>
                    </Typography>
                    <Box m={1.5} />
                    <Typography
                        variant="h5"
                        align="left"
                    >
                        Let’s log in so we can track
                        the points that you earn.
                    </Typography>
                </div>
            )
        },
        registrationScreens: {
            nameAndReferralScreen: {
                coachImage: ISDRideShare,
                message: (
                    <div className={classes.coachText}>
                        Looks like you’re new to the ETC Training Game…
                        <br />
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Welcome!</b>
                        </Typography>
                        Let’s create an account to get started…
                    </div>
                )
            },
            passwordScreen: {
                coachImage: ISDRideShare,
                // Note: We did not define a "message" property here, as the user's first name needs to be injected into the message
                // and the present "loginRegistrationData" does not have access to this information.
            },
            extraDataScreen: {
                showExtraDataScreen: true, // This property is not currently used
                coachImage: ISDRideShare,
                message: (
                    <div className={classes.coachText}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Almost Done...</b>
                        </Typography>
                        <br />
                        Just a few last questions… Then
                        you can get started!
                    </div>
                )
            }
        },
        loginScreen: {
            coachImage: ISDRideShare
        }
    };

    const LoginRegistrationData_2023_lac_earthday = {
        enterEmailScreen: {
            coachImage: EarthCharacter,
            message: (
                <div className={classes.coachText}>
                    <Typography
                        variant="h5"
                        className={classes.coachInnerText}
                    >
                        <b>
                            Welcome to the Electric Cars for Earth!
                        </b>
                    </Typography>
                    <Box m={1.5} />
                    <Typography
                        variant="h5"
                        align="left"
                    >
                        Let’s log in so we can track
                        the points that you earn
                        towards the exciting prizes.
                    </Typography>
                </div>
            )
        },
        registrationScreens: {
            nameAndReferralScreen: {
                coachImage: EarthCharacter,
                message: (
                    <div className={classes.coachText}>
                        Looks like you’re new to Electric Cars for Earth…
                        <br />
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Welcome!</b>
                        </Typography>
                        Let’s create an account to get started…
                    </div>
                )
            },
            passwordScreen: {
                coachImage: EarthCharacter,
                // Note: We did not define a "message" property here, as the user's first name needs to be injected into the message
                // and the present "loginRegistrationData" does not have access to this information.
            },
            extraDataScreen: {
                showExtraDataScreen: true, // This property is not currently used
                coachImage: EarthCharacter,
                message: (
                    <div className={classes.coachText}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Almost Done...</b>
                        </Typography>
                        <br />
                        Just a few last questions… Then
                        you can get started!
                    </div>
                )
            }
        },
        loginScreen: {
            coachImage: EarthCharacter
        }
    };

    const LoginRegistrationData_2023_lac_rideshare = {
        enterEmailScreen: {
            coachImage: ISDRideShare,
            message: (
                <div className={classes.coachText}>
                    <Typography
                        variant="h5"
                        className={classes.coachInnerText}
                    >
                        <b>
                            Welcome to the 2023 Clean Air Rideshare Fair!
                        </b>
                    </Typography>
                    <Box m={1.5} />
                    <Typography
                        variant="h5"
                        align="left"
                    >
                        Let’s log in so we can track
                        the points that you earn
                        towards the exciting prizes.
                    </Typography>
                </div>
            )
        },
        registrationScreens: {
            nameAndReferralScreen: {
                coachImage: ISDRideShare,
                message: (
                    <div className={classes.coachText}>
                        Looks like you’re new to the Rideshare Fair…
                        <br />
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Welcome!</b>
                        </Typography>
                        Let’s create an account to get started…
                    </div>
                )
            },
            passwordScreen: {
                coachImage: ISDRideShare,
                // Note: We did not define a "message" property here, as the user's first name needs to be injected into the message
                // and the present "loginRegistrationData" does not have access to this information.
            },
            extraDataScreen: {
                showExtraDataScreen: true, // This property is not currently used
                coachImage: ISDRideShare,
                message: (
                    <div className={classes.coachText}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Almost Done...</b>
                        </Typography>
                        <br />
                        Just a few last questions… Then
                        you can get started!
                    </div>
                )
            }
        },
        loginScreen: {
            coachImage: ISDRideShare
        }
    };

    const LoginRegistrationData_2024_lac_earthday = {
        enterEmailScreen: {
            coachImage: EarthCharacter,
            message: (
                <div className={classes.coachText}>
                    <Typography
                        variant="h5"
                        className={classes.coachInnerText}
                    >
                        <b>
                            Welcome to the Electric Cars for Earth!
                        </b>
                    </Typography>
                    <Box m={1.5} />
                    <Typography
                        variant="h5"
                        align="left"
                    >
                        Let’s log in so we can track
                        the points that you earn
                        towards the exciting prizes.
                    </Typography>
                </div>
            )
        },
        registrationScreens: {
            nameAndReferralScreen: {
                coachImage: EarthCharacter,
                message: (
                    <div className={classes.coachText}>
                        Looks like you’re new to Electric Cars for Earth…
                        <br />
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Welcome!</b>
                        </Typography>
                        Let’s create an account to get started…
                    </div>
                )
            },
            passwordScreen: {
                coachImage: EarthCharacter,
                // Note: We did not define a "message" property here, as the user's first name needs to be injected into the message
                // and the present "loginRegistrationData" does not have access to this information.
            },
            extraDataScreen: {
                showExtraDataScreen: true, // This property is not currently used
                coachImage: EarthCharacter,
                message: (
                    <div className={classes.coachText}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Almost Done...</b>
                        </Typography>
                        <br />
                        Just a few last questions… Then
                        you can get started!
                    </div>
                )
            }
        },
        loginScreen: {
            coachImage: EarthCharacter
        }
    };

    const LoginRegistrationData_2024_lac_rideshare = {
        enterEmailScreen: {
            coachImage: ISDRideShare,
            message: (
                <div className={classes.coachText}>
                    <Typography
                        variant="h5"
                        className={classes.coachInnerText}
                    >
                        <b>
                            Welcome to the 2024 Clean Air Rideshare Fair!
                        </b>
                    </Typography>
                    <Box m={1.5} />
                    <Typography
                        variant="h5"
                        align="left"
                    >
                        Let’s log in so we can track
                        the points that you earn
                        towards the exciting prizes.
                    </Typography>
                </div>
            )
        },
        registrationScreens: {
            nameAndReferralScreen: {
                coachImage: ISDRideShare,
                message: (
                    <div className={classes.coachText}>
                        Looks like you’re new to the Rideshare Fair…
                        <br />
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Welcome!</b>
                        </Typography>
                        Let’s create an account to get started…
                    </div>
                )
            },
            passwordScreen: {
                coachImage: ISDRideShare,
                // Note: We did not define a "message" property here, as the user's first name needs to be injected into the message
                // and the present "loginRegistrationData" does not have access to this information.
            },
            extraDataScreen: {
                showExtraDataScreen: true, // This property is not currently used
                coachImage: ISDRideShare,
                message: (
                    <div className={classes.coachText}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <b>Almost Done...</b>
                        </Typography>
                        <br />
                        Just a few last questions… Then
                        you can get started!
                    </div>
                )
            }
        },
        loginScreen: {
            coachImage: ISDRideShare
        }
    };


    let loginRegistrationData;
    switch (currentCampaign) {
        case "2024-lac-rideshare":
            loginRegistrationData=LoginRegistrationData_2024_lac_rideshare;
            break;
        case "2024-lac-earthday":
            loginRegistrationData=LoginRegistrationData_2024_lac_earthday;
            break;
        case "2023-lac-rideshare":
            loginRegistrationData=LoginRegistrationData_2023_lac_rideshare;
            break;
        case "2023-lac-earthday":
            loginRegistrationData=LoginRegistrationData_2023_lac_earthday;
            break;
        case "lac-etc-training-1-0":
            loginRegistrationData=LoginRegistrationData_lac_etc_training_1_0;
            break;
        case "2022-lac-rideshare":
            loginRegistrationData=LoginRegistrationData_2022_lac_rideshare;
            break;
        case "2022-3ce-independence":
            loginRegistrationData=LoginRegistrationData_2022_3ce_independence;
            break;
        case "2022-lac-independence":
            loginRegistrationData=LoginRegistrationData_2022_lac_independence;
            break;
        case "2022-lac-earthday":
            loginRegistrationData=LoginRegistrationData_2022_lac_earthday;
            break;
        case "2022-3ce-earthday":
            loginRegistrationData=LoginRegistrationData_3CEEarthDay;
            break;
        case "sample-campaign":
            loginRegistrationData=LoginRegistrationData_3CEEarthDaySample;
            break;
        default:
            loginRegistrationData=LoginRegistrationData_default;
    }

    // =================================
    // End: DEFINING CAMPAIGN-SPECIFIC UI FOR LOGIN / REGISTRATION SCREENS
    // =================================
    
    
    const [referralEmail, setReferralEmail] = useState(
        params.referral || params["Referral source"]
    );
    const [openTooltip, toggleTooltip] = useState(false);
    const [openEmailTooltip, toggleEmailTooltip] = useState(false);
    const [openForgotPassword, toggleForgotPassword] = useState(false);

    const [auth, setAuth] = useState({
        email: emailAddress || params.email,
        password: "",
    });

    const [confirmAuth, setConfirmAuth] = useState({
        email: "",
        password: "",
    });

    const [profile, setProfile] = useState({
        first_name: "",
        last_name: "",
        email: emailAddress || params.email,
        referred_by: {
            registration: referralEmail || "",
            [currentCampaign]: referralEmail || "",
        },
        completed_tasks: {},
        completed_surveys: {},
        saved_cars: [],
        organization: {
            name: "la_county",
            employee: "",
            department: "",
            worksite: "",
            zipcode: "",
            la_resident: "",
        },
        campaigns: [currentCampaign],
        currentCampaign,
        date_created: new Date(),
        last_login: new Date(),
        assignments: params.new_activity ? [params.new_activity] : [],
    });

    const [status, setStatus] = useState({
        loading: false,
        error: false,
        success: false,
    });

    /*
        Steps include:
        1. "begin"
        2. "login"
        3. "register"
            a) "create_account"
            b) "organization_details"
            c) "activate_account"
    */
    const [step, setStep] = useState("begin");

    useEffect(() => {
        logEvent(analytics, "screen_view", {
            screen_name: "LoginRegistrationDialog",
        });

        if (params.ref) {
            // const referral = atob(params.ref); // This code is used to decode the encoded "ref" parameter. The decoded email address then appears within "Did someone refer you" textfield in login/registration.
            const referral = `Ref: ${params.ref}`; // Instead, I'm leaving it encoded so that referred users don't see the email address of the referring users.
            setReferralEmail(referral);
            setProfile({
                ...profile,
                referred_by: {
                    ...profile.referred_by,
                    registration: referral,
                    [currentCampaign]: referral,
                },
            });
        }

        if (user.data === null || userProfile.data === null)
            props.checkAuthentication();
    }, []);

    useEffect(() => {
        // NOTE: Apr 20, 2022: DISABLED ADDING USERS TO SENDGRID LISTS UPON LOGIN/ACCOUNT CREATION
        if (user.data !== null) {
            const data = {
                list_ids: [
                    "98415624-6a1d-4257-ade8-9cb377cc385f", // 2021-10-Rideshare-Fair
                ],
                contacts: [
                    {
                        email: auth.email,
                        custom_fields: {
                            w22_T: "Yes", // 2021 Rideshare Fair Day completed activity
                        },
                    },
                ],
            };
            // props.updateSendGridContact(data); // Disabled adding users to a SendGrid list upon login/registration. We'll handle email sending as a post-processed operation instead.
        }

        if (
            user.error !== null &&
            (step === "login" ||
                step === "organization_details" ||
                step === "activate_account")
        ) {
            props.openAlert({
                text: user.error.message ? user.error.message : user.error,
            });
        } else if (
            user.data !== null &&
            userProfile.data === null &&
            (step === "organization_details" || step === "activate_account")
        ) {
            //Add profile to sendgrid contact list
            //props.addSendGridContact(profile)
            //Add a new user to firebase database using UID that was created
            props.createUserProfile(user.data.uid, profile);
            //Update referrals collection
            props.updateReferrals();
        }
    }, [user]);

    useEffect(() => {
        if (preRegistrationSurvey.data != null) {
            const referralSource =
                preRegistrationSurvey.data.formData["Referral source"];
            if (referralSource && referralSource !== "") {
                setReferralEmail(referralSource);

                setProfile({
                    ...profile,
                    completed_surveys: {
                        ...profile.completed_surveys,
                        [preRegistrationSurvey.data.form.formName]:
                            preRegistrationSurvey.data.formData,
                    },
                    first_name:
                        preRegistrationSurvey.data.formData["First Name"] || "",
                    last_name:
                        preRegistrationSurvey.data.formData["Last Name"] || "",
                    referred_by: {
                        ...profile.referred_by,
                        registration: referralSource,
                        [currentCampaign]: referralSource,
                    },
                });
            } else {
                setProfile({
                    ...profile,
                    completed_surveys: {
                        ...profile.completed_surveys,
                        [preRegistrationSurvey.data.form.formName]:
                            preRegistrationSurvey.data.formData,
                    },
                    first_name:
                        preRegistrationSurvey.data.formData["First Name"] || "",
                    last_name:
                        preRegistrationSurvey.data.formData["Last Name"] || "",
                });
            }
        }
    }, [preRegistrationSurvey]);

    useEffect(() => {
        if (user.data != null && userProfile.data != null && step === "login") {
            //Add profile to sendgrid contact list
            //props.addSendGridContact(userProfile.data)

            const uid = user.data.uid;
            let newUserProfile = userProfile.data;
            newUserProfile.currentOrganization = "la_county";

            if (preRegistrationSurvey.data != null) {
                newUserProfile.completed_surveys = {
                    ...newUserProfile.completed_surveys,
                    [preRegistrationSurvey.data.form.formName]:
                        preRegistrationSurvey.data.formData,
                };
                newUserProfile.referred_by = {
                    ...userProfile.data.referred_by,
                    [currentCampaign]:
                        preRegistrationSurvey.data.formData[
                            "Referral source"
                        ] || "",
                };
            } else if (referralEmail) {
                //The pre registration referral data takes precedent over what the user entered
                newUserProfile.referred_by = {
                    ...userProfile.data.referred_by,
                    [currentCampaign]: referralEmail,
                };
            }

            newUserProfile.assignments = newUserProfile.assignments || [];
            if (
                params.new_activity &&
                !newUserProfile.assignments.includes(params.new_activity)
            ) {
                newUserProfile.assignments.push(params.new_activity);
            }

            if (
                newUserProfile.campaigns &&
                !newUserProfile.campaigns.includes(currentCampaign)
            ) {
                newUserProfile.campaigns.push(currentCampaign);
            } else {
                newUserProfile.campaigns = [currentCampaign];
            }
            newUserProfile.currentCampaign = currentCampaign

            props.updateUserProfile(newUserProfile, uid);
        }
    }, [user, userProfile]);

    const handleSubmit = () => {
        //I know this should be a redux action but I'm time constrained so here you go
        const firebaseAuth = getAuth();
        setStatus(() => ({
            ...status,
            loading: true,
            error: false,
            success: false,
        }));

        fetchSignInMethodsForEmail(firebaseAuth, auth.email)
            .then(function (response) {
                //If email exists
                if (response.length > 0) {
                    setStatus(() => ({
                        ...status,
                        loading: false,
                        success: true,
                    }));
                    setStep("login");
                } else {
                    setStatus(() => ({
                        ...status,
                        loading: false,
                        error: "A user with this email does not exist. Click the link below to register now!",
                    }));
                    setStep("register");
                }
            })
            .catch(function (error) {
                // An error happened.
                console.log("error", error);
                setStatus(() => ({
                    ...status,
                    loading: false,
                    error: "A user with this email does not exist. Click the link below to register now!",
                }));
                setStep("register");
            });
    };

    const handleChange = (event) => {
        let value = event.target.value;
        const name = event.target.name;
        value = value === "true" ? true : value === "false" ? false : value;

        if (name === "referred_by") {
            setProfile({
                ...profile,
                referred_by: {
                    registration: value,
                    [currentCampaign]: value,
                },
            });
        } else if (
            name === "employee" ||
            name === "department" ||
            name === "worksite" ||
            name === "zipcode" ||
            name === "la_resident"
        ) {
            setProfile({
                ...profile,
                organization: {
                    ...profile.organization,
                    [name]: value,
                },
            });
        } else {
            setProfile({
                ...profile,
                [name]: value,
            });
        }
    };

    const handleChangeAuth = (event) => {
        let value = event.target.value;
        setAuth({
            ...auth,
            [event.target.name]: value,
        });
    };

    const handleChangeConfirmAuth = (event) => {
        let value = event.target.value;
        setConfirmAuth({
            ...confirmAuth,
            [event.target.name]: value,
        });
    };

    const handleChangeEmail = (event) => {
        let value = event.target.value;

        setProfile({
            ...profile,
            email: value,
        });
        setAuth({
            ...auth,
            email: value,
        });
    };

    const handleLogin = () => {
        logEvent(analytics, "select_content", {
            content_type: "login_button_clicked",
        });
        props.signInUser(auth);
    };

    const verifyEmail = () => {
        if (profile.first_name === "" || profile.last_name === "")
            props.openAlert({
                text: "Please enter your first and last name.",
            });
        else if (confirmAuth.email !== profile.email)
            props.openAlert({
                text: "Email addresses do not match. Please try again.",
            });
        else {
            logEvent(analytics, "select_content", {
                content_type: "registration_confirm_email_button_clicked",
            });
            setStep("create_account");
        }
    };

    const validateNextStep = () => {
        switch (step) {
            case "begin":
                if (
                    !auth.email ||
                    auth.email === "" ||
                    !auth.email.match(/\S+@\S+\.\S+/)
                ) {
                    props.openAlert({
                        text: "Please enter a valid email.",
                    });
                } else {
                    logEvent(analytics, "select_content", {
                        content_type:
                            "login_registration_lets_go_button_clicked",
                    });
                    handleSubmit();
                    props.getPreRegistrationSurvey(
                        currentCampaignSurvey,
                        auth.email,
                        currentCampaignSurveyEmailField
                    );
                }
                break;

            case "login":
                break;

            case "register":
                break;

            case "create_account":
                if (auth.password !== confirmAuth.password) {
                    props.openAlert({
                        text: "Password do not match. Please try again.",
                    });
                } else if (auth.password.length < 6) {
                    props.openAlert({
                        text: "Password must be six characters.",
                    });
                } else {
                    logEvent(analytics, "select_content", {
                        content_type: "registration_next_button_clicked",
                    });
                    // setStep("organization_details"); // TO DO: I want to disable the additional registration screen where we ask for organization-specific details. Can re-add this later with NativeForms if we want.
                    props.createUser(auth); // Creates the user account and advances to the next screen
                    setStep("activate_account");
                }
                break;

            case "organization_details": // TO DO: I want to conditionally disable the additional registration screen where we ask for organization-specific details. Can re-add this later with NativeForms if we want.
                if (
                    profile.employee === "" ||
                    (profile.employee === true &&
                        (profile.department === "" ||
                            profile.worksite === "" ||
                            profile.zipcode === "")) ||
                    profile.la_resident === ""
                ) {
                    props.openAlert({
                        text: "Form is incomplete. Please fill in all fields before moving to the next step.",
                    });
                } else {
                    // props.createUser(auth); // Moved this up to the "create_account" step instead, since we don't want to show the organization_details screen
                    logEvent(analytics, "select_content", {
                        content_type: "registration_all_done_button_clicked",
                    });
                    setStep("activate_account");
                }

                break;

            case "activate_account":
                //Send verificaation email
                break;

            default:
                break;
        }
    };

    return (
        <React.Fragment>
            {visible &&
            user.data != null &&
            userProfile.data != null &&
            params.forward ? (
                <Redirect to={params.forward} />
            ) : visible &&
              user.data != null &&
              userProfile.data != null &&
              userOrganization === "la_county" &&
              singleTask &&
              singleTask.routeToSyllabus ? (
                <Redirect to={`/sections/${singleTask.courseID}`} />
            ) : visible && user.data != null && userProfile.data != null && userProfile.data.currentCampaign === currentCampaign ? (
                <Redirect to={`/${currentCampaign}/welcome`} />
            ) : (
                <Dialog
                    open={visible}
                    maxWidth="md"
                    //onBackdropClick={handleClose }
                    classes={{
                        paper: classes.dialogPaper,
                    }}
                >
                    <DialogTitle style={{ borderBottom: "1px solid black" }}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs container justifyContent="flex-start">
                                <OurCountyBox
                                    color="black"
                                    registrationModal={true}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: "auto" }}
                                container
                                justifyContent="flex-end"
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => props.history.goBack()}
                                    className={classes.backButton}
                                >
                                    <FontAwesomeIcon
                                        icon={faUndo}
                                        size="xl"
                                        className={classes.backButtonIcon}
                                    />
                                    <Typography
                                        className={classes.backButtonText}
                                    >
                                        Go back
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogTitle>

                    <DialogContent classes={{ root: classes.dialogContent }}>
                        <div className={classes.dialogBox}>
                            {status.loading ||
                            user.loading ||
                            userProfile.loading ? (
                                <div
                                    style={{
                                        width: "100%",
                                        height: 300,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            ) : step === "begin" ? (
                                <div>
                                    <EVCoachMessage
                                        coachImage={loginRegistrationData.enterEmailScreen.coachImage}
                                        message={loginRegistrationData.enterEmailScreen.message}
                                    />
                                    <Grid
                                        container
                                        alignItems="center"
                                        className={classes.submitTextButtonGrid}
                                    >
                                        <Grid item xs={12} sm>
                                            <TextField
                                                defaultValue={auth.email}
                                                onChange={handleChangeEmail}
                                                onKeyDown={(e) =>
                                                    e.key === "Enter" &&
                                                    validateNextStep()
                                                }
                                                name="email"
                                                label="Email"
                                                autoFocus
                                                className={classes.textField}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            className={classes.submitButtonItem}
                                        >
                                            <StyledTaskButton
                                                onClick={validateNextStep}
                                            >
                                                Let's Go!
                                            </StyledTaskButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            ) : step === "login" ? (
                                <div className={classes.loginBox}>
                                    <EVCoachMessage
                                        coachImage={loginRegistrationData.loginScreen.coachImage}
                                        message={
                                            <div className={classes.coachText}>
                                                Looks like you already have an
                                                account…
                                                <Box m={1.5} />
                                                <Typography
                                                    variant="h5"
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <b>Welcome back!</b>
                                                </Typography>
                                            </div>
                                        }
                                    />
                                    <Grid
                                        container
                                        alignItems="flex-end"
                                        className={classes.loginBox}
                                    >
                                        <Grid
                                            item
                                            xs
                                            style={{ msFlex: "1 1 auto" }}
                                        >
                                            <TextField
                                                value={auth.email}
                                                onChange={handleChangeAuth}
                                                disabled
                                                name="email"
                                                label="Email"
                                                className={classes.textField}
                                            />
                                            <br />
                                            <br />
                                            <TextField
                                                value={auth.password}
                                                onChange={handleChangeAuth}
                                                onKeyDown={(e) =>
                                                    e.key === "Enter" &&
                                                    handleLogin()
                                                }
                                                name="password"
                                                label="ENTER PASSWORD"
                                                type="password"
                                                autoFocus
                                                className={classes.textField}
                                            />
                                            <Typography variant="caption">
                                                <a
                                                    href="#"
                                                    onClick={() =>
                                                        toggleForgotPassword(
                                                            true
                                                        )
                                                    }
                                                >
                                                    Forgot your password?
                                                </a>
                                            </Typography>
                                            <ForgotPasswordModal
                                                emailAddress={auth.email}
                                                visible={openForgotPassword}
                                                handleClose={() =>
                                                    toggleForgotPassword(false)
                                                }
                                                forward={
                                                    singleTask &&
                                                    singleTask.routeToSyllabus
                                                        ? `/sections/${singleTask.courseID}`
                                                        : `/${campaignFromURL}/welcome/` // Improvement for later - can also add URL query params, e.g. for referrals
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            className={classes.grayOutlinedGrid}
                                        >
                                            <Grid
                                                item
                                                xs
                                                className={
                                                    classes.grayOutlinedLeftGridItem
                                                }
                                            >
                                                <Typography variant="h6">
                                                    Did someone refer you?
                                                </Typography>
                                                <br />
                                                <Typography variant="body1">
                                                    If you were referred by
                                                    someone else, enter their
                                                    full name or email address…
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <TextField
                                                    //This is unique because you need to use the URL parms
                                                    value={referralEmail}
                                                    onChange={handleChange}
                                                    name="referred_by"
                                                    label="Referred By"
                                                    className={
                                                        classes.textField
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <StyledTaskButton
                                                onClick={handleLogin}
                                            >
                                                Login
                                            </StyledTaskButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            ) : step === "register" ? (
                                <div className={classes.registerBox}>
                                    <EVCoachMessage
                                        coachImage={loginRegistrationData.registrationScreens.nameAndReferralScreen.coachImage}
                                        message={loginRegistrationData.registrationScreens.nameAndReferralScreen.message}
                                    />
                                    <Grid
                                        container
                                        alignItems="center"
                                        className={classes.grayOutlinedGrid}
                                    >
                                        <Grid
                                            item
                                            xs
                                            className={
                                                classes.grayOutlinedLeftGridItem
                                            }
                                        >
                                            <Typography variant="h6">
                                                We’ll create an account for you
                                                with this email address
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                value={profile.email}
                                                disabled
                                                name="email"
                                                label="Email"
                                                className={classes.textField}
                                            />
                                            <Typography variant="caption">
                                                Want to use a different email?{" "}
                                                <a
                                                    href="#"
                                                    onClick={() =>
                                                        setStep("begin")
                                                    }
                                                >
                                                    Go back...
                                                </a>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        alignItems="center"
                                        className={classes.grayOutlinedGrid}
                                    >
                                        <Grid
                                            item
                                            xs
                                            className={
                                                classes.grayOutlinedLeftGridItem
                                            }
                                        >
                                            <Typography variant="h6">
                                                What's your name?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                value={profile.first_name}
                                                onChange={handleChange}
                                                autoFocus
                                                name="first_name"
                                                label="First Name"
                                                className={classes.textField}
                                            />
                                            <TextField
                                                value={profile.last_name}
                                                onChange={handleChange}
                                                name="last_name"
                                                label="Last Name"
                                                className={classes.textField}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        alignItems="center"
                                        className={classes.grayOutlinedGrid}
                                    >
                                        <Grid
                                            item
                                            xs
                                            className={
                                                classes.grayOutlinedLeftGridItem
                                            }
                                        >
                                            <Typography variant="h6">
                                                Did someone refer you?
                                            </Typography>
                                            <br />
                                            <Typography variant="body1">
                                                If you were referred by someone
                                                else, enter their full name or
                                                email address…
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                value={
                                                    profile.referred_by
                                                        .registration
                                                }
                                                onChange={handleChange}
                                                name="referred_by"
                                                label="Referred By"
                                                className={classes.textField}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        alignItems="center"
                                        className={classes.submitTextButtonGrid}
                                    >
                                        <Grid item xs>
                                            <TextField
                                                value={confirmAuth.email}
                                                onChange={
                                                    handleChangeConfirmAuth
                                                }
                                                onKeyDown={(e) =>
                                                    e.key === "Enter" &&
                                                    verifyEmail()
                                                }
                                                name="email"
                                                label="RE-ENTER YOUR EMAIL ADDRESS"
                                                classes={{
                                                    root: classes.textField,
                                                    label: classes.textFieldLabel,
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            className={classes.submitButtonItem}
                                        >
                                            <StyledTaskButton
                                                onClick={verifyEmail}
                                            >
                                                Confirm Email
                                            </StyledTaskButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            ) : step === "create_account" ? (
                                <div>
                                    <EVCoachMessage
                                        coachImage={loginRegistrationData.registrationScreens.passwordScreen.coachImage}
                                        message={
                                            <div className={classes.coachText}>
                                                <Typography
                                                    variant="h5"
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <b>
                                                        Welcome,{" "}
                                                        {profile.first_name}!
                                                    </b>
                                                </Typography>
                                                <Box m={1.5} />
                                                Let’s create an account to get
                                                started…
                                            </div>
                                        }
                                    />
                                    <Grid
                                        container
                                        alignItems="center"
                                        className={classes.grayOutlinedGrid}
                                    >
                                        <Grid
                                            item
                                            xs
                                            className={
                                                classes.grayOutlinedLeftGridItem
                                            }
                                        >
                                            <Typography variant="h6">
                                                We’ll create an account for you
                                                with this email address
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                value={profile.email}
                                                disabled
                                                name="email"
                                                label="Email"
                                                className={classes.textField}
                                            />
                                            <Typography variant="caption">
                                                Want to use a different email?{" "}
                                                <a
                                                    href="#"
                                                    onClick={() =>
                                                        setStep("begin")
                                                    }
                                                >
                                                    Go back...
                                                </a>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        alignItems="center"
                                        className={classes.grayOutlinedGrid}
                                    >
                                        <Grid
                                            item
                                            xs
                                            className={
                                                classes.grayOutlinedLeftGridItem
                                            }
                                        >
                                            <Typography variant="h5">
                                                Choose a Password
                                            </Typography>
                                            <Typography variant="body1">
                                                Password must be at least six
                                                characters
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                value={auth.password}
                                                onChange={handleChangeAuth}
                                                autoFocus
                                                name="password"
                                                label="Create a Password"
                                                type="password"
                                                className={classes.textField}
                                            />
                                            <TextField
                                                value={confirmAuth.password}
                                                onChange={
                                                    handleChangeConfirmAuth
                                                }
                                                onKeyDown={(e) =>
                                                    e.key === "Enter" &&
                                                    validateNextStep()
                                                }
                                                name="password"
                                                label="Confirm Password"
                                                type="password"
                                                className={classes.textField}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        style={{ marginTop: 25 }}
                                    >
                                        <Grid
                                            item
                                            xs
                                            container
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="body1"
                                                className={
                                                    classes.termsAndConditionsText
                                                }
                                            >
                                                By creating an account, you
                                                agree to our&nbsp;
                                                {/* <ClickAwayListener onClickAway={() => toggleTooltip(false)}>
                                                    <div style={{display: 'inline', textAlign: 'left'}}> */}
                                                <Tooltip
                                                    /* PopperProps={{
                                                                disablePortal: true,
                                                            }}
                                                            //onClose={() => toggleTooltip(false)}
                                                            open={openTooltip}
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener */
                                                    classes={{
                                                        tooltip:
                                                            classes.tooltip,
                                                        arrow: classes.arrow,
                                                    }}
                                                    title={
                                                        <Typography
                                                            variant="body1"
                                                            style={{
                                                                padding: 10,
                                                            }}
                                                        >
                                                            We’re grateful for
                                                            every person who
                                                            joins us in creating
                                                            a “Fossil Fuel Free
                                                            community”. We
                                                            strive to protect
                                                            your privacy and
                                                            always be respectful
                                                            in how we
                                                            communicate and work
                                                            together. If you’d
                                                            like, you can read
                                                            our{" "}
                                                            <a href="">
                                                                Terms and
                                                                Conditions
                                                            </a>
                                                            , and{" "}
                                                            <a href="">
                                                                Privacy Policy
                                                            </a>
                                                            .
                                                        </Typography>
                                                    }
                                                    arrow
                                                >
                                                    <a
                                                        href="#"
                                                        onClick={() =>
                                                            toggleTooltip(true)
                                                        }
                                                    >
                                                        Terms,
                                                    </a>
                                                </Tooltip>
                                                {/* </div>
                                                </ClickAwayListener> */}
                                                <br />
                                                and consent to receive&nbsp;
                                                {/* <ClickAwayListener onClickAway={() => toggleEmailTooltip(false)}>
                                                    <div style={{display: 'inline', textAlign: 'left'}}> */}
                                                <Tooltip
                                                    /* PopperProps={{
                                                                disablePortal: true,
                                                            }}
                                                            onClose={() => toggleEmailTooltip(false)}
                                                            open={openEmailTooltip}
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener */
                                                    classes={{
                                                        tooltip:
                                                            classes.tooltip,
                                                        arrow: classes.arrow,
                                                    }}
                                                    title={
                                                        <Typography
                                                            variant="body1"
                                                            style={{
                                                                padding: 10,
                                                            }}
                                                        >
                                                            We’re grateful for
                                                            every person who
                                                            joins us in creating
                                                            a “Fossil Fuel Free
                                                            community”. Towards
                                                            this goal, we will
                                                            email you from time
                                                            to time. We always
                                                            strive to be
                                                            respectful of your
                                                            inbox and you can
                                                            unsubscribe anytime.
                                                        </Typography>
                                                    }
                                                    arrow
                                                >
                                                    <a
                                                        href="#"
                                                        onClick={() =>
                                                            toggleEmailTooltip(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        occasional emails.
                                                    </a>
                                                </Tooltip>
                                                {/* </div>
                                                </ClickAwayListener> */}
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ marginLeft: 30 }}>
                                            <StyledTaskButton
                                                onClick={validateNextStep}
                                            >
                                                Next
                                            </StyledTaskButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            ) : step === "organization_details" ? (
                                <div>
                                    <EVCoachMessage
                                        coachImage={loginRegistrationData.registrationScreens.extraDataScreen.coachImage}
                                        message={loginRegistrationData.registrationScreens.extraDataScreen.message}
                                    />
                                    <Grid
                                        container
                                        alignItems="center"
                                        className={classes.grayOutlinedGrid}
                                    >
                                        <Grid
                                            item
                                            xs={9}
                                            className={
                                                classes.grayOutlinedLeftGridItem
                                            }
                                        >
                                            <Typography variant="h6">
                                                Are you an employee of the
                                                County of Los Angeles?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <RadioGroup
                                                name="employee"
                                                value={
                                                    profile.organization
                                                        .employee
                                                }
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel
                                                    value={true}
                                                    control={<Radio />}
                                                    label="Yes"
                                                />
                                                <FormControlLabel
                                                    value={false}
                                                    control={<Radio />}
                                                    label="No"
                                                />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                    {profile.organization.employee === true && (
                                        <Grid
                                            container
                                            alignItems="center"
                                            className={classes.grayOutlinedGrid}
                                        >
                                            <Grid
                                                item
                                                xs
                                                className={
                                                    classes.grayOutlinedLeftGridItem
                                                }
                                            >
                                                <Typography variant="h6">
                                                    Where do you work?
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <TextField
                                                    value={
                                                        profile.organization
                                                            .department
                                                    }
                                                    onChange={handleChange}
                                                    autoFocus
                                                    name="department"
                                                    label="Department"
                                                    className={
                                                        classes.textField
                                                    }
                                                />
                                                <TextField
                                                    value={
                                                        profile.organization
                                                            .worksite
                                                    }
                                                    onChange={handleChange}
                                                    name="worksite"
                                                    label="Worksite Address"
                                                    className={
                                                        classes.textField
                                                    }
                                                />
                                                <TextField
                                                    value={
                                                        profile.organization
                                                            .zipcode
                                                    }
                                                    onChange={handleChange}
                                                    onKeyDown={(e) =>
                                                        e.key === "Enter" &&
                                                        validateNextStep()
                                                    }
                                                    name="zipcode"
                                                    label="Worksite Zip Code"
                                                    className={
                                                        classes.textField
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid
                                        container
                                        alignItems="center"
                                        className={classes.grayOutlinedGrid}
                                    >
                                        <Grid
                                            item
                                            xs={9}
                                            className={
                                                classes.grayOutlinedLeftGridItem
                                            }
                                        >
                                            <Typography variant="h6">
                                                Do you live in Los Angeles
                                                County?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <RadioGroup
                                                name="la_resident"
                                                value={
                                                    profile.organization
                                                        .la_resident
                                                }
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel
                                                    value={true}
                                                    control={<Radio />}
                                                    label="Yes"
                                                />
                                                <FormControlLabel
                                                    value={false}
                                                    control={<Radio />}
                                                    label="No"
                                                />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        style={{
                                            marginTop: 25,
                                            marginBottom: 10,
                                        }}
                                    >
                                        <StyledTaskButton
                                            onClick={validateNextStep}
                                        >
                                            All done!
                                        </StyledTaskButton>
                                    </Grid>
                                </div>
                            ) : /* : step === "activate_account" ?
                                <div>
                                    <EVCoachMessage 
                                        message={
                                            <div>
                                                <Typography variant="h5" style={{textAlign: 'center'}}>
                                                    <b>
                                                        Let’s Activate Your Account…
                                                    </b>
                                                </Typography>
                                                <br />
                                                We sent you an email to verify and activate your account. Just click the link in the email and you’re good to go!
                                                <br />
                                                <br />
                                                Go ahead, I’ll wait… Just click the “Keep Going” button when you’re done.

                                            </div>
                                        }
                                    />
                                    <Grid container justify="flex-end">
                                        <StyledTaskButton>
                                            Let's Keep Going...
                                        </StyledTaskButton>
                                    </Grid>
                                </div> */
                            null}
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        preRegistrationSurvey: state.survey.preRegistrationSurvey,
    };
};

const mapDispatchToProps = {
    checkAuthentication,
    signInUser,
    openAlert,
    createUser,
    createUserProfile,
    addSendGridContact,
    updateUserProfile,
    updateReferrals,
    getPreRegistrationSurvey,
    updateSendGridContact,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(LoginRegistrationDialog)
);
