import React from "react";
import { connect } from "react-redux";

//Actions

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Divider, Typography } from "@mui/material";

//Assets
import BonusPrizeLaneCounty from "../../../static/images/Bonus-Price-Lane-County.png";
import BonusPrizeDriveElectric from "../../../static/images/bonus-prize-drive-electric.png";
import { CustomDialogTitle } from "../Styled Components/DialogTitle";

const useStyles = makeStyles((theme) => ({
    dialogBox: {
        margin: 30,
        [theme.breakpoints.down('xl')]: {
            margin: 0,
        },
    },
    closeButton: {
        minWidth: 0,
    },
}));

function ReferralModal(props) {
    //Pass the color from the parent component to the styles
    const classes = useStyles();

    const { visible, handleClose, userProfile } = props;
    const organization =
        userProfile.data.currentOrganization ||
        userProfile.data.organization.name ||
        userProfile.data.organization;
    const referralURL = `${window.location.origin}/campaign/?referral=${userProfile.data.email}`;

    React.useEffect(() => {
        //onsole.log("origin", window.location)
    }, []);

    return (
        <Dialog
            open={visible}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            fullWidth={true}
            maxWidth="md"
            //style={{width: 600}}
        >
            <CustomDialogTitle onClose={handleClose}>
                {organization === "lane_county"
                    ? "BONUS Prize: Refer Your Friends & Colleagues to Win"
                    : "BONUS Prize: Refer Your Fellow Employees to Win"}
            </CustomDialogTitle>

            <DialogContent
                style={{ overflow: "auto", msOverflowStyle: "scrollbar" }}
            >
                <div className={classes.dialogBox}>
                    <div>
                        {/* <div style={{margin: 40, textAlign: 'center'}}>
                            <div style={{marginBottom: 10, width: '100%', height: 100, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                <img 
                                    src={GiftCard}
                                    style={{height: '100%'}}
                                    alt="gift-card"
                                />
                            </div>
                            <Typography variant="h5">
                                $100 gift card
                            </Typography>
                        </div> */}
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={
                                    organization === "lane_county"
                                        ? BonusPrizeLaneCounty
                                        : BonusPrizeDriveElectric
                                }
                                style={{ width: "100%" }}
                                alt="bonus-prize"
                            />
                        </div>
                    </div>

                    <Divider style={{ marginTop: 40, marginBottom: 40 }} />

                    <div>
                        <Typography variant="h5" style={{ fontWeight: 500 }}>
                            {organization === "lane_county"
                                ? "How to Get Credit for Referring Others"
                                : "How to Get Credit for Referring Your Fellow Employees"}
                        </Typography>
                        <Typography
                            variant="h6"
                            style={{
                                marginTop: 5,
                                marginBottom: 20,
                                fontWeight: 300,
                            }}
                        >
                            {organization === "lane_county"
                                ? "Send an email to your colleagues with your referral link, here’s an example:"
                                : "Send an email to your fellow employees with your referral link, here’s an example:"}
                        </Typography>

                        <div
                            style={{
                                marginBottom: 10,
                                border: "solid",
                                borderWidth: 1,
                                borderRadius: 15,
                            }}
                        >
                            <div style={{ height: "100%", margin: 15 }}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        height: "100%",
                                        paddingRight: 15,
                                        display: "inline",
                                        borderRight: "solid",
                                        borderRightWidth: 1,
                                    }}
                                >
                                    Subject
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        display: "inline",
                                        paddingLeft: 15,
                                    }}
                                >
                                    {organization === "lane_county"
                                        ? "Electric Vehicles 101"
                                        : "LA County's Drive-Electric contest ends soon"}
                                </Typography>
                            </div>
                        </div>

                        <div
                            style={{
                                border: "solid",
                                borderWidth: 1,
                                borderRadius: 15,
                            }}
                        >
                            <Typography variant="body1" style={{ margin: 15 }}>
                                {organization === "lane_county" ? (
                                    <div>
                                        Hi !
                                        <br />
                                        <br />
                                        I signed up for Electric Vehicles 101.
                                        It’s got a lot of great information
                                        about electric cars and incentives for
                                        going electric. And they’ve got cool
                                        prizes for participating. I’m reaching
                                        out because I thought you’d want to know
                                        about it…
                                        <br />
                                        <br />
                                        Here’s my referral link if you want to
                                        sign up and join the Electric Vehicles
                                        101 as well:
                                        <br />
                                        <a
                                            href={`${window.location.origin}/lane_county/register?referral=${userProfile.data.email}`}
                                            target="_blank" rel="noreferrer"
                                        >
                                            {window.location.origin}
                                            /lane_county/register?referral=
                                            {userProfile.data.email}
                                        </a>
                                        <br />
                                        <br />
                                        Sincerely,
                                        <br />
                                    </div>
                                ) : (
                                    <div>
                                        Greetings -
                                        <br />
                                        <br />
                                        As you may know, LA County launched
                                        Drive-Electric week to help our
                                        community become fossil fuel-free.
                                        <br />
                                        <br />
                                        During this virtual event you can learn
                                        about electric cars, how to save money
                                        with incentives, and why driving
                                        electric is good for LA County. You can
                                        also win exciting prizes!
                                        <br />
                                        <br />
                                        The event runs from February 22 to March
                                        1. Please register by clicking on the
                                        link below:
                                        <br />
                                        <a
                                            href={referralURL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {referralURL}
                                        </a>
                                        <br />
                                        <br />
                                        Enjoy the event!
                                    </div>
                                )}
                                {userProfile.data.first_name}
                            </Typography>
                        </div>
                    </div>

                    {/* <Divider  style={{marginTop: 40,  marginBottom: 40}} />

                    <div style={{marginTop: 20, marginBottom: 20}} >
                        <iframe
                            width="100%"
                            height="450"
                            src="https://player.vimeo.com/video/469887167"
                            allowfullscreen="allowfullscreen"
                            mozallowfullscreen="mozallowfullscreen" 
                            msallowfullscreen="msallowfullscreen" 
                            oallowfullscreen="oallowfullscreen" 
                            webkitallowfullscreen="webkitallowfullscreen"
                            allow="autoplay; fullscreen"
                        >
                        </iframe>
                    </div> */}
                </div>
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralModal);
