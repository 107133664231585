import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

// Components
import PageHeader from "../components/default/Styled Components/PageHeader";
import ActivityHubManagement from "../components/default/Admin/ActivityHubManagement";
import UserExport from "../components/default/Admin/UserExport";
import CoachingProfiles from "../components/default/Admin/CoachingProfiles";

// Action
import { signInUser } from "../actions/user_actions";
import { paramsToObject } from "../helpers/functions";

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import {
    Typography,
    CircularProgress,
    Button,
    AccordionSummary,
    Accordion,
    AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

// Assets
import GLLLogo from "../static/images/GLL-logo.png";

// Firebase
import { getFirestore, collection, query, getDocs } from "firebase/firestore"

const db = getFirestore();

const useStyles = makeStyles((theme) => ({
    root: {
        height: "calc(100vh - 90px)",
        width: "100%",
    },
    main: {
        minHeight: "100%",
        maxWidth: 960,
        margin: "0 auto",
        paddingBottom: 40,
    },
}));

function Admin(props) {
    const classes = useStyles();
    const { user } = props;
    const [loading, setLoading] = useState(false);
    const [coachingSurveyData, setCoachingSurveyData] = useState([]);

    const urlParams = new URLSearchParams(props.location.search);
    const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
    const params = paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}

    useEffect(() => {
        if (
            !params.coachingProfile &&
            user.data != null &&
            props.userProfile.data != null &&
            props.userProfile.data.admin
        ) {
            getCoachingSurveys()
        }
    }, [user]);

    const getCoachingSurveys = async () => {
        setLoading(true);

        // This should be added as a redux action
        try {
            const q = query(collection(db, "car_coaching_survey"));
            const querySnapshot = await getDocs(q);
            
            setLoading(false);

            let data = [];
            querySnapshot.forEach(function (doc) {
                data.push(doc.data());
            });
            data.sort((a, b) => b.timestamp - a.timestamp);

            setCoachingSurveyData(data);
        } catch(error) {
            setLoading(false);

            console.log("Error getting document:", error);
        }
    }

    const getNavigatePurchaseList = (list) => {
        const navigatePurchaseList = [
            "Learn the basics about electric cars",
            "Find models that meet my needs",
            "See if EVs make sense for me",
            "Find incentives for me to buy an EV",
            "Help me arrange test drives for EVs",
            "Find inventory on an EV to buy",
            "Negotiate the best deal on an EV",
            "Help me make a purchase",
        ];

        var new_list = "";
        for (let i in list) {
            if (list[i] === true) {
                new_list = new_list.concat(navigatePurchaseList[i] + "; ");
            }
        }

        return new_list;
    };

    const downloadCSV = () => {
        const rows = [
            [
                "First name",
                "Last name",
                "Email",
                "Phone number",
                "Zip code",
                "Purchase timeline",
                "Makes & Models",
                "Test Drive",
                "Preferences",
                "Price",
                "Navigate purchase",
                "Anything else",
                "Purchase Type",
                "Timestamp",
            ],
        ];

        for (let i in coachingSurveyData) {
            let survey = coachingSurveyData[i];
            rows.push([
                survey.first_name
                    ? survey.first_name.replace(/,/g, " ")
                    : undefined,
                survey.last_name
                    ? survey.last_name.replace(/,/g, " ")
                    : undefined,
                survey.email ? survey.email.replace(/,/g, " ") : undefined,
                survey.phone_number
                    ? survey.phone_number.replace(/,/g, " ")
                    : undefined,
                survey.state ? survey.state.replace(/,/g, " ") : undefined,
                survey.purchase_timeline
                    ? survey.purchase_timeline.replace(/,/g, "; ")
                    : undefined,
                survey.makes_models
                    ? survey.makes_models.replace(/,/g, "; ")
                    : undefined,
                survey.test_drive
                    ? survey.test_drive.replace(/,/g, "; ")
                    : undefined,
                survey.preferences
                    ? survey.preferences
                          .replace(/,/g, "; ")
                          .replace(/\n/g, "; ")
                          .replace(/\r/g, "; ")
                    : undefined,
                survey.price
                    ? survey.price.toString().replace(/,/g, "; ")
                    : undefined,
                getNavigatePurchaseList(survey.navigate_purchase),
                survey.anything_else
                    ? survey.anything_else.toString().replace(/,/g, "; ")
                    : undefined,
                survey.purchase_type
                    ? survey.purchase_type.toString().replace(/,/g, "; ")
                    : undefined,
                survey.timestamp ? survey.timestamp.toDate() : undefined,
            ]);
        }

        var csvContent = "";

        rows.forEach(function (rowArray) {
            var row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var download = function (content, fileName, mimeType) {
            var a = document.createElement("a");
            mimeType = mimeType || "application/octet-stream";

            if (navigator.msSaveBlob) {
                // IE10
                navigator.msSaveBlob(
                    new Blob([content], {
                        type: mimeType,
                    }),
                    fileName
                );
            } else if (URL && "download" in a) {
                //html5 A[download]
                a.href = URL.createObjectURL(
                    new Blob([content], {
                        type: mimeType,
                    })
                );
                a.setAttribute("download", fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                window.location.href =
                    "data:application/octet-stream," +
                    encodeURIComponent(content); // only this mime type is supported
            }
        };

        download(csvContent, "dowload.csv", "text/csv;encoding:utf-8");
    };

    return (
        <div className={classes.root}>
            {(props.user.data === null ||
                (props.userProfile.data != null &&
                    !props.userProfile.data.admin)) && <Redirect to="/login" />}
            <div className={classes.main}>
                <div className="first-tour-step">
                    <PageHeader
                        image={GLLLogo}
                        title="Admin Dashboard"
                        subheading="Admin key required for access"
                    />
                </div>

                <Accordion
                    className="second-tour-step"
                    defaultExpanded={params.coachingProfile}
                >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="h5">New Coaching</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "block" }}>
                        {
                            // Removed CoachingProfiles component as it was causing an error.
                            // <CoachingProfiles />
                        }
                        <Typography variant="body1">
                            Removed CoachingProfiles component from here, as it was causing an error.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                {!params.coachingProfile && (
                    <div>
                        <Accordion defaultExpanded={params.coachingProfile}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h5">
                                    Old Coaching
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: "block" }}>
                                <Typography
                                    variant="h6"
                                    style={{ marginBottom: 10 }}
                                >
                                    React Based Coaching Survey Data:
                                </Typography>
                                {loading ? (
                                    <CircularProgress color="primary" />
                                ) : coachingSurveyData.length > 0 ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={downloadCSV}
                                    >
                                        Download CSV
                                    </Button>
                                ) : (
                                    <Typography
                                        variant="body1"
                                        style={{ marginBottom: 10 }}
                                    >
                                        Error: Data not available
                                    </Typography>
                                )}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h5">
                                    User Profiles Data
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UserExport />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="third-tour-step">
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h5">
                                    Activity Hub Management
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ActivityHubManagement />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h5">
                                    Past Campaigns
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: 10 }}
                                    onClick={() =>
                                        props.history.push("/past_campaigns")
                                    }
                                >
                                    View here
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signInUser: (params) => dispatch(signInUser(params)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
