import {
    UPDATE_SEND_GRID_CONTACT_BEGIN,
    UPDATE_SEND_GRID_CONTACT_ERROR,
    UPDATE_SEND_GRID_CONTACT_SUCCESS,
} from "../constants/action-types-sendgrid";

/* export const updateEarthDayPreRegistrationContactBegin = () => ({ type: UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_BEGIN });
export const updateEarthDayPreRegistrationContactSuccess = (result) => ({ type: UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_SUCCESS, payload: result });
export const updateEarthDayPreRegistrationContactError = error => ({ type: UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_ERROR, payload: error });

export function updateEarthDayPreRegistrationContact(emailAddress) {
    return dispatch => {
        dispatch(updateEarthDayPreRegistrationContactBegin());

        const data = {
            "list_ids": [
                "004d190d-1aa4-411d-9410-d6a2bb6aa59b",
            ],
            "contacts": [
                {
                    "email": emailAddress,
                    "custom_fields": {
                        w15_T: "Yes" // Earth Day completed activity
                    }
                }
            ],
        };

        return fetch("https://api.sendgrid.com/v3/marketing/contacts", {
            method: "PUT",
            headers: {
                // eslint-disable-next-line max-len
                "Authorization": "Bearer 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",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((data) => {
                return data.json();
            })
            .then((response) => {
                dispatch(updateEarthDayPreRegistrationContactSuccess(response))
            })
            .catch((error) => {
                const message = `An error has occured: ${error}`;
                dispatch(updateEarthDayPreRegistrationContactError(message))
            });
    };
} */

export const updateSendGridContactBegin = () => ({
    type: UPDATE_SEND_GRID_CONTACT_BEGIN,
});
export const updateSendGridContactSuccess = (result) => ({
    type: UPDATE_SEND_GRID_CONTACT_SUCCESS,
    payload: result,
});
export const updateSendGridContactError = (error) => ({
    type: UPDATE_SEND_GRID_CONTACT_ERROR,
    payload: error,
});

export function updateSendGridContact(data) {
    return (dispatch) => {
        dispatch(updateSendGridContactBegin());
        return fetch("https://api.sendgrid.com/v3/marketing/contacts", {
            method: "PUT",
            headers: {
                // eslint-disable-next-line max-len
                Authorization:
                    "Bearer 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",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((data) => {
                return data.json();
            })
            .then((response) => {
                dispatch(updateSendGridContactSuccess(response));
            })
            .catch((error) => {
                const message = `An error has occured: ${error}`;
                dispatch(updateSendGridContactError(message));
            });
    };
}
