import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { getAllTasks } from "../../../actions/content_actions";
import { updateUserProfileField } from "../../../actions/user_actions";
import { setSelectedUserProfile } from "../../../actions/admin_actions";
import { paramsToObject } from "../../../helpers/functions";

//Styles
import { makeStyles } from "@mui/styles";
import {
    Typography,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { styled } from "@mui/system";

// Components

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        border: "1px solid gainsboro",
    },
    tableTitle: {
        marginTop: 15,
        marginBottom: 5,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    '&.MuiTableCell-head': {
        fontWeight: 600,
        backgroundColor: "darkgray",
        whiteSpace: "nowrap",
    },
}));


function UserTasks(props) {
    const classes = useStyles();
    const { users, coachingProfiles, selectedUserProfile } = props;

    const [selectedCoachingProfile, setSelectedCoachingProfile] = useState();

    const urlParams = new URLSearchParams(props.location.search);
    const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
    const params = paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}

    useEffect(() => {
        if (params.coachingProfile) {
            let profile = coachingProfiles.data.filter(
                (item) => item.email === params.coachingProfile
            );
            if (profile.length > 0) {
                setSelectedCoachingProfile(profile[0]);
            }
        }
    }, []);

    const isRegisteredUser = () => {
        const registeredUser = users.data.filter(
            (item) => item.email === selectedCoachingProfile.email
        );
        if (registeredUser.length > 0) {
            return "Yes";
        } else {
            return "No";
        }
    };

    const getQuestionOrderIndex = (question, questionsList) => {
        let order = 0;
        let questionFound = false;
        for (let i in questionsList) {
            if (questionsList[i].question === question) {
                order = i;
                questionFound = true;
            }
        }

        if (!questionFound) {
            for (let i in questionsList) {
                for (let x in questionsList[i].value) {
                    if (
                        questionsList[i].value[x].question &&
                        questionsList[i].value[x].question === question
                    ) {
                        order = i;
                    }
                }
            }
        }

        if (!questionFound) {
            if (question === "First Name") {
                order = -4;
            } else if (question === "Last Name") {
                order = -3;
            } else if (question === "Email") {
                order = -2;
            } else if (question === "Referral") {
                order = -1;
            }
        }

        return order;
    };

    const getSortedForm = (survey) => {
        const form = survey.formData;
        // Sort the responses based on how they appear to the user in Native Forms
        const sortedForm = Object.keys(form)
            .sort((a, b) => {
                const orderA = getQuestionOrderIndex(a, survey.questions);
                const orderB = getQuestionOrderIndex(b, survey.questions);
                return orderA - orderB;
            })
            .reduce(
                (acc, key) => ({
                    ...acc,
                    [key]: form[key],
                }),
                {}
            );

        return sortedForm;
    };

    const getFTBDLink = () => {
        let link = "https://surveys.electrifyze.com/Y0b1dVY51jZmgnZzkEcL1Db/?";

        if (selectedCoachingProfile) {
            link += `First Name=${selectedCoachingProfile.first_name}&`;
            link += `Last Name=${selectedCoachingProfile.last_name}&`;
            link += `Email=${selectedCoachingProfile.email}&`;
            link += `Phone number=${selectedCoachingProfile.phone_number}&`;

            if (selectedCoachingProfile.additional_surveys) {
                for (let i in selectedCoachingProfile.additional_surveys) {
                    const form =
                        selectedCoachingProfile.additional_surveys[i].formData;

                    for (let prop in form) {
                        if (prop === "Zip code") {
                            link += `Zip code=${form[prop]}&`;
                        } else if (prop === "City") {
                            link += `City=${form[prop]}&`;
                        } else if (prop === "State") {
                            link += `State=${form[prop]}&`;
                        }
                    }
                }
            }
        }

        return link;
    };

    return (
        <div style={{ width: "100%" }}>
            <Typography variant="h6" style={{ marginBottom: 10 }}>
                Select a Coaching Profile
            </Typography>
            <Autocomplete
                options={
                    coachingProfiles.data != null ? coachingProfiles.data : []
                }
                getOptionLabel={(option) => option.email}
                style={{ width: "100%" }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="User Email"
                        variant="outlined"
                    />
                )}
                onChange={(event, newValue) => {
                    setSelectedCoachingProfile(newValue);
                }}
                defaultValue={
                    params.coachingProfile &&
                    coachingProfiles.data.filter(
                        (item) => item.email === params.coachingProfile
                    )[0]
                }
            />

            {selectedCoachingProfile && (
                <div style={{ marginTop: 20 }}>
                    <Typography variant="h6" className={classes.tableTitle}>
                        Profile Data
                    </Typography>
                    <TableContainer className={classes.tableContainer}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>
                                        First Name
                                    </StyledTableCell>
                                    <StyledTableCell>Last Name</StyledTableCell>
                                    {/* <StyledTableCell>Registered User</StyledTableCell> */}
                                    <StyledTableCell>
                                        Phone Number
                                    </StyledTableCell>
                                    <StyledTableCell>FTBD Link</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {selectedCoachingProfile.first_name}
                                    </TableCell>
                                    <TableCell>
                                        {selectedCoachingProfile.last_name}
                                    </TableCell>
                                    {/* <TableCell>{isRegisteredUser()}</TableCell> */}
                                    <TableCell>
                                        {selectedCoachingProfile.phone_number
                                            ? selectedCoachingProfile.phone_number
                                            : "Not saved"}
                                    </TableCell>
                                    <TableCell>{getFTBDLink()}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {selectedCoachingProfile.initial_survey && (
                        <div>
                            <Typography
                                variant="h6"
                                className={classes.tableTitle}
                            >
                                Initial Survey Response
                            </Typography>
                            <TableContainer className={classes.tableContainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>
                                                Question
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                Answer
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(
                                            selectedCoachingProfile
                                                .initial_survey.formData
                                        ).map((key, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{key}</TableCell>
                                                    <TableCell>
                                                        {
                                                            selectedCoachingProfile
                                                                .initial_survey
                                                                .formData[key]
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )}

                    {selectedCoachingProfile.additional_surveys &&
                    selectedCoachingProfile.additional_surveys.length > 0 ? (
                        selectedCoachingProfile.additional_surveys.map(
                            (survey, index) => {
                                const form = getSortedForm(survey);
                                return (
                                    <div key={index}>
                                        <Typography
                                            variant="h6"
                                            className={classes.tableTitle}
                                        >
                                            {survey.label
                                                ? survey.label
                                                : survey.form &&
                                                  survey.form.label
                                                ? survey.form.label
                                                : "Additional Survey"}
                                        </Typography>
                                        <TableContainer
                                            className={classes.tableContainer}
                                        >
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>
                                                            Question
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            Answer
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Object.keys(form).map(
                                                        (key, index) => {
                                                            return (
                                                                <TableRow
                                                                    key={index}
                                                                >
                                                                    <TableCell>
                                                                        {key}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            survey
                                                                                .formData[
                                                                                key
                                                                            ]
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                );
                            }
                        )
                    ) : selectedCoachingProfile.follow_up_survey_completed ? (
                        <Typography variant="h6" className={classes.tableTitle}>
                            Depracated profile format. Please refer to Native
                            Forms for follow up surveys.
                        </Typography>
                    ) : (
                        <Typography variant="h6" className={classes.tableTitle}>
                            No additional surveys on profile.
                        </Typography>
                    )}
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    allTasks: state.content.allTasks,
    selectedUserProfile: state.admin.selectedUserProfile,
});

const mapDispatchToProps = {
    getAllTasks,
    updateUserProfileField,
    setSelectedUserProfile,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UserTasks)
);
