import React, { Suspense } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";

//Helpers
import {
    getOrganizationComponent,
} from "../helpers/functions";

const useStyles = makeStyles((theme) => ({
    content: {
        width: "100%",
        minHeight: "calc(100vh - 114px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

function Refer(props) {
    const classes = useStyles();
    const { userProfile } = props;

    const OrganizationReferPage = getOrganizationComponent(
        userProfile.data.currentOrganization ||
            userProfile.data.organization.name ||
            userProfile.data.organization,
        "Refer"
    );

    return (
        <Suspense fallback={<></>}>
            <OrganizationReferPage />
        </Suspense>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Refer));
