import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

//Actions
import { getUserProfile, updateUserProfile } from "../actions/user_actions";
import {
    getCourses,
    getSingleTask,
    getSections,
    getSubSections,
    getTasks,
    getCourseMetrics,
} from "../actions/content_actions";
import { postSendGridEmail } from "../actions/email_actions";

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";

//Components
import Quiz from "../components/default/Task/Quiz";
import Lesson from "../components/default/Task/Lesson";
import Article from "../components/default/Task/Article";
import DefaultTask from "../components/default/Task/DefaultTask";
import Survey from "../components/default/Task/Survey";
import Video from "../components/default/Task/Video";
import Activity from "../components/default/Task/Activity";
import CoachingSurveyDialog from "../components/default/Coaching/CoachingSurveyDialog";

// Firebase
import { logEvent, getAnalytics } from "firebase/analytics";

const analytics = getAnalytics()

const useStyles = makeStyles((theme) => ({
    root: {
        flex: "1 1 auto",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
}));

function Task(props) {
    const classes = useStyles();
    const {
        userProfile,
        tasks,
        courses,
        sections,
        sub_sections,
        organization,
        location,
    } = props;

    const isAssignment = location.state && location.state.isAssignment;
    const singleTask = props.singleTask.data;
    const { id, campaign } = props.match.params;
    const [visible, toggleCoachingDialog] = useState(false);

    useEffect(() => {
        if (courses.data === null && userProfile.data != null)
            props.getCourses(
                userProfile.data.currentOrganization ||
                    userProfile.data.organization.name ||
                    userProfile.data.organization
            );

        if (props.match.path === "/campaigns/:id") {
            props.getSingleTask(id);
        } else {
            props.getSingleTask(id);
        }
    }, [id]);

    useEffect(() => {
        if (courses.data != null && singleTask != null)
            props.getSections(singleTask.courseID);
    }, [courses.data]);

    useEffect(() => {
        if (courses.data != null && singleTask != null)
            props.getSections(singleTask.courseID);
    }, [singleTask]);

    useEffect(() => {
        if (sections.data != null) {
            for (let i in sections.data) {
                props.getSubSections(sections.data[i].id);
            }
        }
        //console.log(sections.data)
    }, [sections.data]);

    useEffect(() => {
        if (
            sub_sections.data != null &&
            sections.data != null &&
            Object.keys(sub_sections.data).length === sections.data.length
        ) {
            for (let prop in sub_sections.data) {
                let subSection = sub_sections.data[prop];

                for (let i in subSection) {
                    props.getTasks(subSection[i].id);
                }
            }
        }
    }, [sub_sections.data]);

    useEffect(() => {
        if (
            tasks.data != null &&
            singleTask != null &&
            singleTask.type === "survey"
        ) {
            let taskGroup = tasks.data[singleTask.subSectionID];

            for (let i in taskGroup) {
                let task = taskGroup[i];
                if (task.id === id) {
                    try {
                        if (parseInt(i) + 1 < taskGroup.length) {
                            let nextTask = taskGroup[parseInt(i) + 1];
                            window.localStorage.setItem("nextTaskID", nextTask.id);
                        } else {
                            window.localStorage.setItem(
                                "courseID",
                                singleTask.courseID
                            );
                        }
                    } catch(e) {
                        console.log(e)
                    }
                }
            }
        }
    }, [tasks.data]);

    const updateUser = (
        event,
        survey = undefined,
        points = singleTask.points,
        isAssignment = false,
        showCoachingDialog = false
    ) => {
        logEvent(analytics, "select_content", {
            content_type: "task",
            item_id: id,
        });

        const uid = props.user.data.uid;
        let userProfile = props.userProfile.data;

        userProfile.completed_tasks[id] = {
            points: points,
            section_id: singleTask.sectionID,
            sub_section_id: singleTask.subSectionID,
            course_id: singleTask.courseID,
            type: singleTask.type,
            time: Date.now(),
        };

        /* if (survey) {
            if (!userProfile.completed_surveys) {
                userProfile.completed_surveys = {}
            }
            userProfile.completed_surveys[survey.form.formName] = survey.formData

            const carBuyingQuestion = survey.formData["Are you in the market to buy a car?"]
            if (carBuyingQuestion && carBuyingQuestion === "Yes - I am in the market!") {
                //Send welcome email
                const data = {
                    personalizations: [
                    {
                        to: [
                        {
                            email: "electrifyze-signups@greenlight-labs.com"
                        }
                        ],
                        subject: "User is in the market to buy a car",
                        dynamic_template_data: {
                            formName: survey.form.formName,
                            first_name: userProfile.first_name,
                            last_name: userProfile.last_name,
                            email: userProfile.email
                        },
                    }
                    ],
                    from: {
                        email: "hello@electrifyze.com",
                        name: "Electrifyze"
                    },
                    reply_to: {
                        email: "hello@electrifyze.com",
                        name: "Electrifyze"
                    },
                    template_id: "d-9a14e7e387024594b8fc13fa4e714bec"
                }
                props.postSendGridEmail(data)
            }
        } */

        try {
            window.localStorage.setItem("currentTaskID", id);
            window.localStorage.setItem(
                "currentSubSectionID",
                singleTask.subSectionID
            );
        } catch(e) {
            console.log(e)
        }


        props.updateUserProfile(userProfile, uid);
        props.getCourseMetrics(userProfile, singleTask.courseID, uid);

        if (isAssignment) {
            props.history.push("/coaching");
        } else if (showCoachingDialog) {
            toggleCoachingDialog(true);
        } else {
            props.history.push("/transition");
        }
    };

    const getComponentForTask = () => {
        switch (singleTask.type) {
            case "quiz":
                return (
                    <Quiz
                        singleTask={singleTask}
                        taskID={id}
                        isAssignment={isAssignment}
                    />
                );

            case "lesson":
                return (
                    <Lesson
                        singleTask={singleTask}
                        taskID={id}
                        updateUser={updateUser}
                        isAssignment={isAssignment}
                    />
                );

            case "article":
                return (
                    <Article
                        singleTask={singleTask}
                        updateUser={updateUser}
                        isAssignment={isAssignment}
                    />
                );

            case "video":
                return (
                    <Video
                        singleTask={singleTask}
                        updateUser={updateUser}
                        isAssignment={isAssignment}
                    />
                );

            case "survey":
                return (
                    <Survey
                        singleTask={singleTask}
                        updateUser={updateUser}
                        isAssignment={isAssignment}
                    />
                );

            case "activity":
                return (
                    <Activity
                        singleTask={singleTask}
                        taskID={id}
                        updateUser={updateUser}
                        isAssignment={isAssignment}
                    />
                );

            default:
                return (
                    <DefaultTask
                        singleTask={singleTask}
                        updateUser={updateUser}
                        isAssignment={isAssignment}
                    />
                );
        }
    };

    const handleClose = () => {
        props.history.push("/transition");
    };

    return (
        <div className={classes.root}>
            {(campaign && singleTask != null) ||
            (singleTask != null && userProfile.data != null) ? (
                getComponentForTask()
            ) : singleTask != null ? (
                <Redirect to="/home" />
            ) : null}

            <CoachingSurveyDialog
                email={userProfile.data != null && userProfile.data.email}
                visible={visible}
                handleClose={handleClose}
                fromTask
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        courses: state.content.courses,
        sections: state.content.sections,
        sub_sections: state.content.sub_sections,
        singleTask: state.content.singleTask,
        tasks: state.content.tasks,
        organization: state.content.organization,
    };
};

const mapDispatchToProps = {
    updateUserProfile,
    getSections,
    getCourses,
    getSingleTask,
    getSubSections,
    getTasks,
    getUserProfile,
    getCourseMetrics,
    postSendGridEmail,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Task));
