export const ADD_HUBSPOT_CONTACT_BEGIN = "ADD_HUBSPOT_CONTACT_BEGIN";
export const ADD_HUBSPOT_CONTACT_ERROR = "ADD_HUBSPOT_CONTACT_ERROR";
export const ADD_HUBSPOT_CONTACT_SUCCESS = "ADD_HUBSPOT_CONTACT_SUCCESS";

export const UPDATE_HUBSPOT_CONTACT_BEGIN = "UPDATE_HUBSPOT_CONTACT_BEGIN";
export const UPDATE_HUBSPOT_CONTACT_ERROR = "UPDATE_HUBSPOT_CONTACT_ERROR";
export const UPDATE_HUBSPOT_CONTACT_SUCCESS =
    "UPDATE_HUBSPOT_CONTACT_SUCCESS_H";

export const CREATE_HUBSPOT_DEAL_BEGIN = "CREATE_HUBSPOT_DEAL_BEGIN";
export const CREATE_HUBSPOT_DEAL_ERROR = "CREATE_HUBSPOT_DEAL_ERROR";
export const CREATE_HUBSPOT_DEAL_SUCCESS = "CREATE_HUBSPOT_DEAL_SUCCESS";

export const UPDATE_HUBSPOT_DEAL_BEGIN = "UPDATE_HUBSPOT_DEAL_BEGIN";
export const UPDATE_HUBSPOT_DEAL_ERROR = "UPDATE_HUBSPOT_DEAL_ERROR";
export const UPDATE_HUBSPOT_DEAL_SUCCESS = "UPDATE_HUBSPOT_DEAL_SUCCESS";

export const ASSOCIATE_HUBSPOT_DEAL_WITH_CONTACT_BEGIN =
    "ASSOCIATE_HUBSPOT_DEAL_WITH_CONTACT_BEGIN";
export const ASSOCIATE_HUBSPOT_DEAL_WITH_CONTACT_ERROR =
    "ASSOCIATE_HUBSPOT_DEAL_WITH_CONTACT_ERROR";
export const ASSOCIATE_HUBSPOT_DEAL_WITH_CONTACT_SUCCESS =
    "ASSOCIATE_HUBSPOT_DEAL_WITH_CONTACT_SUCCESS";

export const CREATE_HUBSPOT_ENGAGEMENT_BEGIN =
    "CREATE_HUBSPOT_ENGAGEMENT_BEGIN";
export const CREATE_HUBSPOT_ENGAGEMENT_ERROR =
    "CREATE_HUBSPOT_ENGAGEMENT_ERROR";
export const CREATE_HUBSPOT_ENGAGEMENT_SUCCESS =
    "CREATE_HUBSPOT_ENGAGEMENT_SUCCESS";
