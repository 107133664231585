import React from "react";
import urls from "../constants/urls.json";

export const getOrganizationForDomain = () => {
    const organization = urls[window.location.hostname];

    if (organization) return organization;
    else return undefined;
};

export const getOrganizationRouteForPath = (path, organization) => {
    const uniqueDomain = urls[window.location.hostname];

    if (uniqueDomain) return path;
    else return `/${organization}${path}`;
};

// Convert url params into an easily readable object
export const paramsToObject = (entries) => {
    let result = {};
    for (let entry of entries) {
        // each 'entry' is a [key, value] tupple
        const [key, value] = entry;
        result[key] = value;
    }
    return result;
};

export const getOrganizationComponent = (organization, file) => {
    return React.lazy(() =>
        import(`../components/${organization}/${file}`).catch((err) => {
            //File does not exist
        })
    );
};

export const getCampaignComponent = (page, file) => {
    return React.lazy(() =>
        import(`../components/campaigns/${page}/${file}`).catch((err) => {
            //File does not exist
        })
    );
};
