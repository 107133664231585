import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Actions
import { getAllTasks } from "../../../actions/content_actions";
import { updateUserProfileField } from "../../../actions/user_actions";
import { setSelectedUserProfile } from "../../../actions/admin_actions";

//Styles
import { makeStyles } from "@mui/styles";
import {
    Typography,
    Button,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Checkbox,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { styled } from "@mui/system";

// Components
import AssignmentsTableToolbar from "./AssignmentsTableToolbar";
import AssignTaskToUser from "./AssignTaskToUser";

// Firebase
import { getFirestore, collection, query, getDocs, where } from "firebase/firestore"

import ActivityHubAdminDialog from "./ActivityHubAdminDialog";

// Get Firestore reference
var db = getFirestore()

const useStyles = makeStyles((theme) => ({}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    '&.MuiTableCell-head': {
        fontWeight: 600,
        backgroundColor: "darkgray",
        whiteSpace: "nowrap",
    },
}));

function UserTasks(props) {
    const classes = useStyles();
    const { users, allTasks, selectedUserProfile } = props;

    const [selected, setSelected] = React.useState([]);
    const [dialogOpen, toggleDialog] = useState(false);

    useEffect(() => {
        props.getAllTasks();
    }, []);

    const handleOpen = () => {
        toggleDialog(true);
    };

    const handleClose = () => {
        toggleDialog(false);
    };

    const handleRemoveClick = () => {
        let assignments = selectedUserProfile.data.assignments || [];
        for (let i in selected) {
            assignments.splice(assignments.indexOf(selected[i]), 1);
        }

        const data = {
            assignments: assignments,
        };

        props.setSelectedUserProfile({
            ...selectedUserProfile.data,
            assignments,
        });
        setSelected([]);
        props.updateUserProfileField(selectedUserProfile.data.id, data);
    };

    const handleMarkCompleteClick = async () => {
        console.log("This test is working");
        let completed_tasks = selectedUserProfile.data.completed_tasks || [];
        let archived_tasks = selectedUserProfile.data.archived_tasks || [];

        for (let i in selected) {
            const taskID = selected[i];
            const taskData =
                allTasks.data != null &&
                allTasks.data.filter((item) => item.id === taskID)[0];

            try {
                taskData["subSectionID"] = taskData["sub-section"].id;
                // This should be a redux action but there was most likely a time constraint
                const q1 = query(
                    collection(db, "fl_content"),
                    where("_fl_meta_.env", "==", "production"),
                    where("_fl_meta_.locale", "==", "en-US"),
                    where("_fl_meta_.schema", "==", "sub-sections"),
                    where("id", "==", taskData["sub-section"].id)
                );
                let subSection = await getDocs(q1);

                taskData["sectionID"] = subSection.docs[0].data().section.id;

                const q2 = query(
                    collection(db, "fl_content"),
                    where("_fl_meta_.env", "==", "production"),
                    where("_fl_meta_.locale", "==", "en-US"),
                    where("_fl_meta_.schema", "==", "sections"),
                    where("id", "==", taskData["sectionID"])
                );
                let section = await getDocs(q2);

                taskData["courseID"] = section.docs[0].data().course.id;
            } catch (error) {
                console.log("Error getting task data", error);
            }

            const data = {
                points: taskData.points,
                section_id: taskData.sectionID,
                sub_section_id: taskData.subSectionID,
                course_id: taskData.courseID,
                type: taskData.type,
                time: Date.now(),
            };

            completed_tasks[taskID] = data;

            if (archived_tasks.includes(taskID)) {
                archived_tasks = archived_tasks.filter(
                    (item) => item !== taskID
                );
            }
        }

        const data = {
            completed_tasks,
            archived_tasks,
        };

        props.setSelectedUserProfile({
            ...selectedUserProfile.data,
            completed_tasks,
            archived_tasks,
        });
        setSelected([]);
        props.updateUserProfileField(selectedUserProfile.data.id, data);
    };

    const handleMarkIncompleteClick = () => {
        let completed_tasks = selectedUserProfile.data.completed_tasks || [];

        for (let i in selected) {
            const taskID = selected[i];
            if (completed_tasks[taskID]) {
                delete completed_tasks[taskID];
            }
        }

        const data = {
            completed_tasks: completed_tasks,
        };

        props.setSelectedUserProfile({
            ...selectedUserProfile.data,
            completed_tasks,
        });
        setSelected([]);
        props.updateUserProfileField(selectedUserProfile.data.id, data);
    };

    const handleClick = (event, taskID) => {
        const selectedIndex = selected.indexOf(taskID);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, taskID);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleArchiveClick = () => {
        let archived_tasks = selectedUserProfile.data.archived_tasks || [];

        for (let i in selected) {
            const taskID = selected[i];
            if (
                selectedUserProfile.data.completed_tasks &&
                !selectedUserProfile.data.completed_tasks[taskID] &&
                !archived_tasks.includes(taskID)
            ) {
                archived_tasks.push(taskID);
            }
        }

        const data = {
            archived_tasks,
        };

        props.setSelectedUserProfile({
            ...selectedUserProfile.data,
            archived_tasks,
        });
        setSelected([]);
        props.updateUserProfileField(selectedUserProfile.data.id, data);
    };

    const handleUnarchiveClick = () => {
        let archived_tasks = selectedUserProfile.data.archived_tasks || [];

        for (let i in selected) {
            const taskID = selected[i];
            if (archived_tasks.includes(taskID)) {
                archived_tasks = archived_tasks.filter(
                    (item) => item !== taskID
                );
            }
        }

        const data = {
            archived_tasks,
        };

        props.setSelectedUserProfile({
            ...selectedUserProfile.data,
            archived_tasks,
        });
        setSelected([]);
        props.updateUserProfileField(selectedUserProfile.data.id, data);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    return (
        <div style={{ width: "100%" }}>
            <Typography variant="h6" style={{ marginBottom: 10 }}>
                Select a User
            </Typography>
            <Autocomplete
                options={users.data != null ? users.data : []}
                getOptionLabel={(option) => option.email}
                style={{ width: "100%" }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="User Email"
                        variant="outlined"
                    />
                )}
                onChange={(event, newValue) => {
                    props.setSelectedUserProfile(newValue);
                }}
            />

            {selectedUserProfile.data != null && (
                <div style={{ marginTop: 20 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                    >
                        View Activity Hub
                    </Button>

                    <ActivityHubAdminDialog
                        open={dialogOpen}
                        handleClose={handleClose}
                    />

                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <AssignmentsTableToolbar
                            numSelected={selected.length}
                            handleRemoveClick={handleRemoveClick}
                            handleMarkCompleteClick={handleMarkCompleteClick}
                            handleMarkIncompleteClick={
                                handleMarkIncompleteClick
                            }
                            handleArchiveClick={handleArchiveClick}
                            handleUnarchiveClick={handleUnarchiveClick}
                        />
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell padding="checkbox"></StyledTableCell>
                                        <StyledTableCell>
                                            Task ID
                                        </StyledTableCell>
                                        <StyledTableCell>Type</StyledTableCell>
                                        <StyledTableCell>Name</StyledTableCell>
                                        {/* <StyledTableCell>Description</StyledTableCell> */}
                                        <StyledTableCell>
                                            Status
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedUserProfile.data.assignments ? (
                                        selectedUserProfile.data.assignments.map(
                                            (task, index) => {
                                                const isItemSelected =
                                                    isSelected(task);
                                                const taskData =
                                                    (allTasks.data != null &&
                                                        allTasks.data.filter(
                                                            (item) =>
                                                                item.id === task
                                                        )[0]) ||
                                                    {};

                                                return (
                                                    <TableRow
                                                        key={index}
                                                        onClick={(event) =>
                                                            handleClick(
                                                                event,
                                                                task
                                                            )
                                                        }
                                                        selected={
                                                            isItemSelected
                                                        }
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={
                                                                    isItemSelected
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {task}
                                                        </TableCell>
                                                        <TableCell>
                                                            {taskData.type &&
                                                                taskData.type.toUpperCase()}
                                                        </TableCell>
                                                        <TableCell>
                                                            {taskData.title}
                                                        </TableCell>
                                                        {/* <TableCell>
                                                        {taskData.previewDescription}
                                                    </TableCell> */}
                                                        <TableCell>
                                                            {selectedUserProfile
                                                                .data
                                                                .completed_tasks &&
                                                            selectedUserProfile
                                                                .data
                                                                .completed_tasks[
                                                                task
                                                            ]
                                                                ? "Complete"
                                                                : selectedUserProfile
                                                                      .data
                                                                      .archived_tasks &&
                                                                  selectedUserProfile.data.archived_tasks.includes(
                                                                      task
                                                                  )
                                                                ? "Archived"
                                                                : "Incomplete"}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )
                                    ) : (
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>
                                                There are no assigned tasks for
                                                this user.
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <AssignTaskToUser />
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    allTasks: state.content.allTasks,
    selectedUserProfile: state.admin.selectedUserProfile,
});

const mapDispatchToProps = {
    getAllTasks,
    updateUserProfileField,
    setSelectedUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTasks);
