import React from "react";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Typography, Popper } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    popper: {
        maxWidth: 300,
        borderRadius: 5,
        backgroundColor: "white",
        boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.75)",
        position: "relative",
        zIndex: 1,
    },
}));

function PointsPopper(props) {
    const classes = useStyles();
    const { anchorEl, open } = props;

    return (
        <Popper
            //id={id}
            open={open}
            anchorEl={anchorEl}
            container={anchorEl && anchorEl.parentNode}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            className={classes.popper}
        >
            <Typography className={classes.typography}>
                Uh oh! You've missed out on some of the bonus points in this
                section...
            </Typography>
        </Popper>
    );
}

export default PointsPopper;
