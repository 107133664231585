import React from "react";

//Styles
import { makeStyles } from "@mui/styles";
import { Divider, Grid, Typography } from "@mui/material";

const useStyles = (variant, padding) =>
    makeStyles((theme) => ({
        gridContainer: {
            paddingTop:
                padding === "none" ? 0 : variant === "regular" ? 10 : 40,
            paddingBottom:
                padding === "none" ? 0 : variant === "regular" ? 15 : 20,
        },
        text: {
            fontWeight: variant === "regular" ? 300 : 600,
            marginRight: 15,
            marginLeft: variant === "regular" ? 15 : 0,
        },
        divider: {
            height: variant === "regular" ? 1 : 3,
            backgroundColor: "black",
        },
    }));

function TextDivider(props) {
    const { text, variant, padding } = props;

    //Pass the variant from the parent component to the styles
    const classes = useStyles(variant, padding)(props);

    return (
        <Grid container alignItems="center" className={classes.gridContainer}>
            {variant === "regular" && (
                <Grid item style={{ width: 50 }}>
                    <Divider className={classes.divider} />
                </Grid>
            )}
            <Grid item>
                <Typography
                    variant={variant === "regular" ? "body1" : "h5"}
                    className={classes.text}
                >
                    {text}
                </Typography>
            </Grid>
            <Grid item xs>
                <Divider className={classes.divider} />
            </Grid>
        </Grid>
    );
}

export default TextDivider;
