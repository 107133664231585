import React, { useEffect } from "react";
import { connect } from "react-redux";

//Styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { StyledTaskButton } from "../components/default/Styled Components/Buttons";

//Assets
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

//Routing
import { withRouter } from "react-router-dom";

//Actions
import { updateUserProfile } from "../actions/user_actions";
import { getSingleTask, getCourseMetrics } from "../actions/content_actions";

function SurveyComplete(props) {
    const singleTask = props.singleTask.data;
    const currentTaskID = window.localStorage.getItem("currentTaskID");
    const nextTaskID = window.localStorage.getItem("nextTaskID");
    const courseID = window.localStorage.getItem("courseID");

    useEffect(() => {
        props.getSingleTask(currentTaskID);
    }, []);

    useEffect(() => {
        if (singleTask !== null) {
            const uid = props.user.data.uid;
            let userProfile = props.userProfile.data;

            const urlParams = new URLSearchParams(props.location.search);
            const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
            const params = paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}
            //console.log("params", params)

            if (!userProfile.surveys) userProfile["surveys"] = {};

            //Add the survey to firebase
            userProfile.surveys[currentTaskID] = params;

            //Mark the task as complete
            userProfile.completed_tasks[currentTaskID] = {
                points: singleTask.points,
                section_id: singleTask.sectionID,
                sub_section_id: singleTask.subSectionID,
                course_id: singleTask.courseID,
                type: singleTask.type,
                time: Date.now(),
            };

            props.updateUserProfile(userProfile, uid);
            props.getCourseMetrics(userProfile.completed_tasks, courseID);
        }
    }, [singleTask]);

    const paramsToObject = (entries) => {
        let result = {};
        for (let entry of entries) {
            // each 'entry' is a [key, value] tupple
            const [key, value] = entry;
            result[key] = value;
        }
        return result;
    };

    const handleClick = () => {
        /* if (nextTaskID !== null && nextTaskID !== "null") {
            window.top.location.replace(`https://www.electrifyze.com/task/${nextTaskID}`)
            props.history.push('/transition')

        } else {
            window.top.location.replace(`https://www.electrifyze.com/sections/${courseID}`)
        } */

        window.top.location.replace("https://www.electrifyze.com/transition");
    };

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <FontAwesomeIcon
                icon={faCheckCircle}
                size="6x"
                color="green"
                style={{ marginBottom: 15 }}
            />
            <Typography variant="h6">Mission Accomplished!</Typography>
            <Typography variant="body1">
                Thanks for submitting your responses.
            </Typography>
            <StyledTaskButton onClick={handleClick} style={{ margin: 30 }}>
                Next Task
            </StyledTaskButton>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        singleTask: state.content.singleTask,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSingleTask: (id) => dispatch(getSingleTask(id)),
        updateUserProfile: (userProfile, uid) =>
            dispatch(updateUserProfile(userProfile, uid)),
        getCourseMetrics: (userProfile, courseID, userID) =>
            dispatch(getCourseMetrics(userProfile, courseID, userID)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SurveyComplete)
);
