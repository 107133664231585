import React, { useEffect } from "react";
import { connect } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import "../../../static/styles/App.css";
import clsx from "clsx";

//Components
import Slides from "./Slides";

//Actions
import { getLesson } from "../../../actions/content_actions";

import parse from "html-react-parser";
import { withRouter } from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: "1 1 auto",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    slideImageOuterBox: {
        flex: "1 1 auto",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    slideImageInnerBox: {
        flex: "1 1 auto",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    slideTextBox: {
        margin: 40,
    },
}));

function Lesson(props) {
    //Pass theme styles as props
    const classes = useStyles(props);
    const { taskID, lesson, isAssignment } = props;
    const isCampaign =
        window.location.pathname.startsWith("/campaigns") ||
        props.match.path === "/:campaign/task/:id";

    useEffect(() => {
        props.getLesson(taskID);
    }, []);

    const getComponentsList = () => {
        const slides = lesson.data.slides;
        let componentsList = [];

        for (let i in slides) {
            var htmlString = slides[i].content;
            componentsList.push({
                element: (
                    <div
                        className={clsx({
                            [classes.slideImageOuterBox]:
                                slides[i].type !== "text",
                            [classes.slideTextBox]: slides[i].type === "text",
                        })}
                    >
                        {slides[i].type === "image" ? (
                            <div className={classes.slideImageInnerBox}>
                                <img
                                    src={slides[i].imageLink}
                                    style={{
                                        maxHeight: "100%",
                                        maxWidth: "100%",
                                        msFlex: "0 0 auto",
                                    }}
                                    useMap={"#workmap" + i}
                                    alt="slide-map"
                                />
                                <map id={"workmap" + i} name={"workmap" + i}>
                                    {slides[i].imageMapAreas ? (
                                        slides[i].imageMapAreas.map(
                                            (area, index) => {
                                                return (
                                                    <area
                                                        shape="rect"
                                                        coords={
                                                            area.coordinates &&
                                                            area.coordinates !=
                                                                null
                                                                ? area.coordinates
                                                                : "0,0,0,0"
                                                        }
                                                        alt="Computer"
                                                        href={area.link}
                                                        target="_blank"
                                                    />
                                                );
                                            }
                                        )
                                    ) : (
                                        //This filler is so that the image-map-resizer library doesn't break the code
                                        <area
                                            shape="rect"
                                            coords="0,0,0,0"
                                            alt="empty-area"
                                        />
                                    )}
                                </map>
                            </div>
                        ) : (
                            parse(htmlString)
                        )}
                    </div>
                ),
            });
        }

        return componentsList;
    };

    return (
        <div className={classes.root}>
            {lesson.data != null && lesson.data.slides.length > 0 && (
                <Slides
                    singleTask={props.singleTask}
                    content={getComponentsList()}
                    type={isCampaign ? "campaign" : "lesson"}
                    updateUser={props.updateUser}
                    isAssignment={isAssignment}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lesson: state.content.lesson,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLesson: (id) => dispatch(getLesson(id)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Lesson));
