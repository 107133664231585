import React from "react";
import { connect } from "react-redux";

//Styles
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Typography } from "@mui/material";

//Assets
import { CustomDialogTitle } from "../../default/Styled Components/DialogTitle";

const useStyles = makeStyles((theme) => ({
    dialogBox: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('xl')]: {
            margin: 0,
        },
    },
    closeButton: {
        minWidth: 0,
    },
}));

function EmailReferDialog(props) {
    //Pass the color from the parent component to the styles
    const classes = useStyles();

    const { visible, handleClose, campaign, email } = props;
    const referralURL = `https://share.electrifyze.com/la_county/campaigns/${
        campaign || "2021-earthday"
    }.html?ref=${btoa(email)}`;

    React.useEffect(() => {}, []);

    return (
        <Dialog open={visible} fullWidth={true} maxWidth="md">
            <CustomDialogTitle onClose={handleClose} custom={true}>
                <div>
                    <Typography variant="h6" style={{ fontWeight: 500 }}>
                        How to Get Credit for Referring Your Fellow Employees
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 300 }}>
                        Send an email to your fellow employees with your
                        referral link, here’s an example:
                    </Typography>
                </div>
            </CustomDialogTitle>

            <DialogContent
                style={{ overflow: "auto", msOverflowStyle: "scrollbar" }}
            >
                <div className={classes.dialogBox}>
                    <div>
                        <div
                            style={{
                                marginBottom: 10,
                                border: "solid",
                                borderWidth: 1,
                                borderRadius: 15,
                            }}
                        >
                            <div style={{ height: "100%", margin: 15 }}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        height: "100%",
                                        paddingRight: 15,
                                        display: "inline",
                                        borderRight: "solid",
                                        borderRightWidth: 1,
                                    }}
                                >
                                    Subject
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        display: "inline",
                                        paddingLeft: 15,
                                    }}
                                >
                                    Virtual Contest Invite: Clean Air Rideshare
                                    Fair
                                </Typography>
                            </div>
                        </div>

                        <div
                            style={{
                                border: "solid",
                                borderWidth: 1,
                                borderRadius: 15,
                            }}
                        >
                            <Typography variant="body1" style={{ margin: 15 }}>
                                <div>
                                    Greetings -
                                    <br />
                                    <br />
                                    In celebration of Clean Air Day, I signed up
                                    to join the County’s annual Clean Air
                                    Rideshare Fair!
                                    <br />
                                    <br />
                                    They’ll introduce you to County-sponsored
                                    programs and incentives that help you save
                                    time, save money, and reduce hassle on your
                                    commute to work, and programs that can help
                                    you save thousands on your next car
                                    purchase… And they’re offering prizes to
                                    participate too!
                                    <br />
                                    <br />
                                    I’m sharing my referral link so I can get
                                    credit for referring you :)
                                    <br />
                                    <a
                                        href={referralURL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {referralURL}
                                    </a>
                                    <br />
                                    <br />I signed up already - Hope you can
                                    join too - It’ll be fun!
                                </div>
                            </Typography>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailReferDialog);
