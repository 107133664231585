import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Styles
import { makeStyles } from "@mui/styles";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    link: {
        marginLeft: 10
    }
}));

function TaskWarningDialog(props) {
    const classes = useStyles(props);
    const { open, toggleDialog, path } = props;

    return (
        <Dialog
            open={open}
            onClose={toggleDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
        >
            <DialogTitle id="alert-dialog-title">
                Are you sure you want exit? Your progress for this task will not
                be saved
            </DialogTitle>

            <DialogActions>
                <Button
                    onClick={toggleDialog}
                    variant="outlined"
                    color="primary"
                >
                    Cancel
                </Button>
                <Link to={path} className={classes.link}>
                    <Button
                        onClick={toggleDialog}
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        Yes
                    </Button>
                </Link>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    organization: state.content.organization,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TaskWarningDialog);
