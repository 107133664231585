import {
    ADD_PRE_REGISTRATION_SURVEY_BEGIN,
    ADD_PRE_REGISTRATION_SURVEY_SUCCESS,
    ADD_PRE_REGISTRATION_SURVEY_ERROR,
    GET_PRE_REGISTRATION_SURVEY_BEGIN,
    GET_PRE_REGISTRATION_SURVEY_SUCCESS,
    GET_PRE_REGISTRATION_SURVEY_ERROR,
} from "../constants/action-types-survey";

// Firebase
import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore"

// Get Firestore reference
const db = getFirestore();

export const addPreRegistrationSurveyBegin = () => ({
    type: ADD_PRE_REGISTRATION_SURVEY_BEGIN,
});
export const addPreRegistrationSurveySuccess = (result) => ({
    type: ADD_PRE_REGISTRATION_SURVEY_SUCCESS,
    payload: result,
});
export const addPreRegistrationSurveyError = (error) => ({
    type: ADD_PRE_REGISTRATION_SURVEY_ERROR,
    payload: error,
});

export const addPreRegistrationSurvey = (collectionName, data) => async (dispatch) => {
    dispatch(addPreRegistrationSurveyBegin());
    try {
        const response = await addDoc(collection(db, collectionName), data)
        console.log("Document successfully written!", response);
        dispatch(addPreRegistrationSurveySuccess(data));
    } catch(error) {
        console.error("Error creating document: ", error);
        dispatch(addPreRegistrationSurveyError(error));
    }
}

export const getPreRegistrationSurveyBegin = () => ({
    type: GET_PRE_REGISTRATION_SURVEY_BEGIN,
});
export const getPreRegistrationSurveySuccess = (result) => ({
    type: GET_PRE_REGISTRATION_SURVEY_SUCCESS,
    payload: result,
});
export const getPreRegistrationSurveyError = (error) => ({
    type: GET_PRE_REGISTRATION_SURVEY_ERROR,
    payload: error,
});

export const getPreRegistrationSurvey = (collectionName, email, key) => async (dispatch) => {
    dispatch(getPreRegistrationSurveyBegin());
    try {
        const q = query(
            collection(db, collectionName),
            where(`formData.${key || "Email"}`, "==", email)
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            data.push(doc.data());
        });
        if (data.length > 0) {
            dispatch(getPreRegistrationSurveySuccess(data[0]));
        } else {
            dispatch(
                getPreRegistrationSurveyError("No document exists.")
            );
        }
    } catch(error) {
        dispatch(getPreRegistrationSurveyError(error));
    }
};
