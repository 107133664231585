import {
    SEND_TRIGGER_EMAIL_BEGIN,
    SEND_TRIGGER_EMAIL_ERROR,
    SEND_TRIGGER_EMAIL_SUCCESS,
    POST_SEND_GRID_EMAIL_BEGIN,
    POST_SEND_GRID_EMAIL_ERROR,
    POST_SEND_GRID_EMAIL_SUCCESS,
} from "../constants/action-types-email";

const initialState = {
    triggerEmail: {
        data: null,
        loading: false,
        error: null,
    },
};

const email = (state = initialState, action) => {
    switch (action.type) {
        case SEND_TRIGGER_EMAIL_BEGIN:
            return {
                ...state,
                triggerEmail: { data: null, loading: true, error: null },
            };

        case SEND_TRIGGER_EMAIL_ERROR:
            return {
                ...state,
                triggerEmail: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case SEND_TRIGGER_EMAIL_SUCCESS:
            return {
                ...state,
                triggerEmail: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case POST_SEND_GRID_EMAIL_BEGIN:
            return {
                ...state,
                sendGridEmail: { data: null, loading: true, error: null },
            };

        case POST_SEND_GRID_EMAIL_ERROR:
            return {
                ...state,
                sendGridEmail: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case POST_SEND_GRID_EMAIL_SUCCESS:
            return {
                ...state,
                sendGridEmail: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        default:
            return state;
    }
};

export default email;
