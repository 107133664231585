import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions
import { getHomePage, getOrganization } from "../../../../../actions/content_actions";

//Styles
import "../../../../../static/styles/App.css";
import { Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Components
import TextDivider from "../../../../default/Styled Components/TextDivider";
import { GoldCircleButton } from "../../../../default/Styled Components/Buttons";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        marginTop: 20,
        marginBottom: 15,
        [theme.breakpoints.down('lg')]: {
            flexDirection: "column",
        },
    },
    gridItem: {
        [theme.breakpoints.down('lg')]: {
            marginBottom: 15,
        },
    },
    divider: {
        width: "100%",
        backgroundColor: "gainsboro",
        height: 3,
    },
}));

function HowReferWorks(props) {
    const classes = useStyles();

    const { steps, title } = props;

    return (
        <div>
            <TextDivider text="How it works" />

            <Typography variant="h6">
                Referring people {title && `to ${title}`} is as easy as 1-2-3.
            </Typography>

            <Grid container className={classes.gridContainer}>
                {steps.map((step, index) => {
                    return (
                        <Grid item xs className={classes.gridItem}>
                            <Grid container>
                                <Grid item xs container alignItems="center">
                                    {index !== 0 && (
                                        <Divider className={classes.divider} />
                                    )}
                                </Grid>
                                <Grid item>
                                    <GoldCircleButton>
                                        {index + 1}
                                    </GoldCircleButton>
                                </Grid>
                                <Grid item xs container alignItems="center">
                                    {index !== 2 && (
                                        <Divider className={classes.divider} />
                                    )}
                                </Grid>
                            </Grid>
                            {step}
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganization: (organization) =>
            dispatch(getOrganization(organization)),
        getHomePage: (organization) => dispatch(getHomePage(organization)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HowReferWorks)
);
