import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Assets
import CheckMark from "../../../static/images/check-solid.svg";
import { faGift } from "@fortawesome/free-solid-svg-icons";

//Styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
    button: {
        display: "block",
        width: "100%",
        textTransform: "none",
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: "left",
    },
    taskLabelGridContainer: {
        [theme.breakpoints.down('lg')]: {
            flexDirection: "column",
            paddingRight: "0px !important",
        },
    },
    taskLabelImageGridItem: {
        width: 30,
        marginRight: 25,
        [theme.breakpoints.down('lg')]: {
            marginRight: 0,
            marginBottom: 5,
        },
    },
    iconBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    icon: {
        fontSize: 25,
        width: 25,
        height: 25,
    },
    iconSmall: {
        fontSize: 20,
        width: 20,
        height: 20,
    },
    goldIconWrapper: {
        width: 30,
        height: 30,
        borderRadius: 15,
        backgroundColor: "rgba(255, 213, 0, 1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    link: {
        textDecoration: "none",
    },
    pointsGridItem: {
        [theme.breakpoints.down('lg')]: {
            display: "flex",
            alignItems: "flex-end",
            paddingTop: "0px !important",
            paddingBottom: "0px !important",
            marginTop: -10,
            marginBottom: 10,
        },
    },
    pointsLabelText: {
        [theme.breakpoints.down('lg')]: {
            marginLeft: 10,
        },
    },
}));

function BonusTaskItem(props) {
    const classes = useStyles();
    const { type, success, points } = props;

    const getImageForType = (type) => {
        switch (type) {
            case "completion":
            case "perfect_score":
                if (success)
                    return (
                        <div className={classes.iconBox}>
                            <div className={classes.goldIconWrapper}>
                                <img
                                    src={CheckMark}
                                    style={{ width: 20, height: 20 }}
                                    alt="checkmark"
                                />
                            </div>
                        </div>
                    );
                else
                    return (
                        <div className={classes.iconBox}>
                            <FontAwesomeIcon
                                icon={faGift}
                                className={classes.icon}
                            />
                        </div>
                    );

            default:
                return (
                    <div className={classes.iconBox}>
                        <div className={classes.goldIconWrapper}>
                            <img
                                src={CheckMark}
                                style={{ width: 20, height: 20 }}
                                alt="checkmark"
                            />
                        </div>
                    </div>
                );
        }
    };

    return (
        <div style={{ marginTop: 10 }}>
            <Button className={classes.button}>
                <Grid container spacing={4} alignItems="center">
                    <Grid
                        item
                        xs={3}
                        container
                        alignItems="center"
                        className={classes.taskLabelGridContainer}
                    >
                        <Grid item className={classes.taskLabelImageGridItem}>
                            {getImageForType(type)}
                        </Grid>
                        <Grid item xs>
                            <Typography
                                variant="body2"
                                style={{ textTransform: "uppercase" }}
                            >
                                BONUS
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            {type === "completion"
                                ? "Complete all tasks in this section"
                                : "Get a perfect score on the quiz in this section"}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={2}
                        className={classes.pointsGridItem}
                    >
                        <Typography variant="caption">
                            +
                            <Typography
                                variant="body1"
                                style={{ display: "inline", fontWeight: 500 }}
                            >
                                {success ? points : 0}&nbsp;
                            </Typography>
                            / {points}
                        </Typography>
                        <br />
                        <Typography
                            variant="caption"
                            className={classes.pointsLabelText}
                        >
                            Points
                        </Typography>
                    </Grid>
                </Grid>
            </Button>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BonusTaskItem)
);
