import React from "react";

//Native Forms
import NativeForms from "native-forms-react";

//Styles
import Dialog from "@mui/material/Dialog";
import { CustomDialogTitle } from "../Styled Components/DialogTitle";

function NativeFormsDialog(props) {
    const { toggleDialog, open, formURL, handleSend } = props;

    return (
        <Dialog
            onClose={toggleDialog}
            aria-labelledby="simple-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth="sm"
        >
            <CustomDialogTitle onClose={toggleDialog} />
            <div
                style={{
                    overflowY: "auto",
                    paddingBottom: 10,
                    msOverflowStyle: "scrollbar",
                }}
            >
                <NativeForms form={formURL} onBeforeSend={handleSend} />
            </div>
        </Dialog>
    );
}

export default NativeFormsDialog;
