import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    paper: {
        minHeight: 170,
        padding: 15,
        paddingBottom: 5,
        border: "solid",
        borderWidth: 1,
        borderTopWidth: 8,
    },
    pointsImg: {
        height: 80,
        width: 80,
        objectFit: "contain",
        color: "white",
    },
    link: {
        textDecoration: "none",
        color: "black",
    },
    typography: {
        padding: theme.spacing(2),
    },
}));

function CampaignCard(props) {
    const classes = useStyles();
    const { data, index } = props;

    const getBorderColor = (colorIndex) => {
        const colors = [
            "#4472c4",
            "green",
            "purple",
            "orange",
            "red",
            "gold",
            "blue",
        ];
        if (colorIndex && colorIndex >= 0 && colors[colorIndex]) {
            return colors[colorIndex];
        } else if (colorIndex) {
            return getBorderColor(colorIndex - 7);
        } else {
            return colors[0];
        }
    };

    return (
        <Grid item lg={6} xs={12}>
            <Link
                to={{
                    pathname: `/${data.keyIdentifier}/welcome`,
                    /* state: {
                            id: course.id,
                            title: course.title
                        } */
                }}
                className={classes.link}
            >
                <Paper
                    elevation={3}
                    className={classes.paper}
                    style={{ borderColor: getBorderColor(index) }}
                >
                    <Grid container direction="column">
                        <Grid item xs style={{ msFlex: "1 1 auto" }}>
                            <Typography
                                variant="h5"
                                style={{ fontWeight: 400 }}
                            >
                                {data.title}
                            </Typography>
                        </Grid>
                        <Grid item xs style={{ msFlex: "1 1 auto" }}>
                            <Typography variant="body2">
                                {data.startDate ? (
                                    <i>
                                        {moment(data.startDate).format("ll")} -{" "}
                                        {(data.endDate &&
                                            moment(data.endDate).format(
                                                "ll"
                                            )) ||
                                            "Present"}
                                    </i>
                                ) : (
                                    <i>Ongoing</i>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography variant="body1" style={{ marginTop: 10 }}>
                        {data.description}
                    </Typography>
                </Paper>
            </Link>
        </Grid>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignCard);
