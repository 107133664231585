import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions
import { signInUser, getUserProfile } from "../../../actions/user_actions";
import { getSubSections, getTasks } from "../../../actions/content_actions";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Typography, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";

// Components
import SubSection from "./SubSection";

const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: 60,
        marginBottom: 40,
        [theme.breakpoints.down('xl')]: {
            paddingTop: 30,
            marginBottom: 20,
        },
    },
    gridContainer: {
        [theme.breakpoints.down('xl')]: {
            flexDirection: "column",
        },
    },
    titleGridItem: {
        [theme.breakpoints.down('xl')]: {
            maxWidth: "100%",
        },
    },
    subSectionGridItem: {
        [theme.breakpoints.down('xl')]: {
            maxWidth: "100%",
        },
    },
    numberGridItem: {
        [theme.breakpoints.down('xl')]: {
            flex: "1 1 auto",
            maxWidth: "auto",
        },
    },
}));

function SectionItem(props) {
    const classes = useStyles();
    const { section, index, course } = props;
    const [timeData, setTimeData] = useState([]);
    const [totalTime, setTotalTime] = useState(0);

    React.useEffect(() => {
        if (
            props.sub_sections.data === null ||
            !props.sub_sections.data[section.id]
        ) {
            //console.log("Section id", section.id)
            props.getSubSections(section.id);
        }
    }, []);

    const addTime = (data) => {
        // The following is kind of a stupid way of calculating total time for a section
        // The reason I did it is because it was quick and didn't require complex looping or API calls
        const { sub_section } = data;

        let newTimeData = timeData;
        let newTime = 0;

        let index = -1;
        for (let i in newTimeData) {
            if (newTimeData[i].sub_section === sub_section) {
                index = i;
            }
        }

        //If the sub section is already being tracked then override it, otherwise push the data
        if (index === -1) {
            newTimeData.push(data);
        } else {
            newTimeData[index] = data;
        }

        //Loop through sub sections in list to calculate total time
        for (let i in newTimeData) {
            newTime += parseInt(newTimeData[i].ssTime);
        }

        setTotalTime(newTime);
        setTimeData(newTimeData);
    };

    return (
        <div>
            <Divider />

            <div className={classes.content}>
                <Grid container spacing={4} className={classes.gridContainer}>
                    <Grid
                        item
                        xs={3}
                        container
                        className={classes.titleGridItem}
                    >
                        <Grid item xs={2} className={classes.numberGridItem}>
                            <Typography variant="h6">{index + 1}.</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="h6">
                                {section.title}
                            </Typography>
                            <Typography variant="body1">
                                <i>
                                    {totalTime} {totalTime > 1 ? "mins" : "min"}
                                </i>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={9} className={classes.subSectionGridItem}>
                        {props.sub_sections.data != null &&
                            props.sub_sections.data[section.id] &&
                            props.sub_sections.data[section.id].map(
                                (sub_section, index) => {
                                    return (
                                        <SubSection
                                            sub_section={sub_section}
                                            section={section}
                                            course={course}
                                            key={index}
                                            addTime={addTime}
                                        />
                                    );
                                }
                            )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        sections: state.content.sections,
        sub_sections: state.content.sub_sections,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signInUser: (params) => dispatch(signInUser(params)),
        getUserProfile: (id) => dispatch(getUserProfile(id)),
        getSubSections: (section) => dispatch(getSubSections(section)),
        getTasks: (sub_section) => dispatch(getTasks(sub_section)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SectionItem)
);
