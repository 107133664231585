import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Actions
import { signInUser, getUserProfile } from "../../../actions/user_actions";
import { getTasks } from "../../../actions/content_actions";

//Assets
import CheckMark from "../../../static/images/check-solid.svg";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import ListAltIcon from "@mui/icons-material/ListAlt";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
    button: {
        display: "block",
        width: "100%",
        textTransform: "none",
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: "left",
    },
    taskLabelGridContainer: {
        [theme.breakpoints.down('lg')]: {
            flexDirection: "column",
            paddingRight: "0px !important",
        },
    },
    taskLabelImageGridItem: {
        width: 30,
        marginRight: 25,
        [theme.breakpoints.down('lg')]: {
            marginRight: 0,
            marginBottom: 5,
        },
    },
    iconBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    icon: {
        fontSize: 25,
        width: 25,
        height: 25,
    },
    goldIconWrapper: {
        width: 30,
        height: 30,
        borderRadius: 15,
        backgroundColor: "rgba(255, 213, 0, 1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    link: {
        textDecoration: "none",
    },
    pointsGridItem: {
        [theme.breakpoints.down('lg')]: {
            display: "flex",
            alignItems: "flex-end",
            paddingTop: "0px !important",
            paddingBottom: "0px !important",
            marginTop: -10,
            marginBottom: 10,
        },
    },
    pointsCountText: {
        display: "inline",
        fontWeight: 500,
    },
    pointsLabelText: {
        [theme.breakpoints.down('lg')]: {
            marginLeft: 10,
        },
    },
}));

function TaskItem(props) {
    const classes = useStyles();
    const { task, userProfile } = props;

    const getImageForType = (type) => {
        switch (type) {
            case "lesson":
                return (
                    <div className={classes.iconBox}>
                        <NoteAddIcon className={classes.icon} />
                    </div>
                );

            case "video":
                return (
                    <div className={classes.iconBox}>
                        <OndemandVideoIcon className={classes.icon} />
                    </div>
                );

            case "article":
                return (
                    <div className={classes.iconBox}>
                        <AssignmentIcon className={classes.icon} />
                    </div>
                );

            case "quiz":
                return (
                    <div className={classes.iconBox}>
                        <CheckBoxIcon className={classes.icon} />
                    </div>
                );

            case "survey":
                return (
                    <div className={classes.iconBox}>
                        <HelpOutlineIcon className={classes.icon} />
                    </div>
                );

            case "action":
                return (
                    <div className={classes.iconBox}>
                        <DirectionsWalkIcon className={classes.icon} />
                    </div>
                );

            case "project":
                return (
                    <div className={classes.iconBox}>
                        <ListAltIcon className={classes.icon} />
                    </div>
                );

            case "activity":
                return (
                    <div className={classes.iconBox}>
                        <FontAwesomeIcon
                            icon={faTasks}
                            className={classes.icon}
                        />
                    </div>
                );

            case "complete":
            default:
                return (
                    <div className={classes.iconBox}>
                        <div className={classes.goldIconWrapper}>
                            <img
                                src={CheckMark}
                                style={{ width: 20, height: 20 }}
                                alt="checkmark"
                            />
                        </div>
                    </div>
                );
        }
    };

    return (
        <div style={{ marginTop: 10 }}>
            <Link to={`/task/${task.id}`} className={classes.link}>
                <Button className={classes.button}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid
                            item
                            xs={3}
                            container
                            alignItems="center"
                            justifyContent="center"
                            className={classes.taskLabelGridContainer}
                        >
                            <Grid
                                item
                                className={classes.taskLabelImageGridItem}
                            >
                                {getImageForType(
                                    userProfile.data != null &&
                                        userProfile.data.completed_tasks &&
                                        userProfile.data.completed_tasks[
                                            task.id
                                        ]
                                        ? "complete"
                                        : task.typeLabel
                                        ? task.typeLabel
                                        : task.type
                                )}
                            </Grid>
                            <Grid item xs>
                                <Typography
                                    variant="body2"
                                    style={{ textTransform: "uppercase" }}
                                >
                                    {task.typeLabel
                                        ? task.typeLabel
                                        : task.type}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Typography
                                variant="body1"
                                style={{ fontWeight: 500 }}
                            >
                                {task.title}
                            </Typography>
                            {task.time && (
                                <Typography variant="caption">
                                    {task.time} {task.time > 1 ? "mins" : "min"}
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={2}
                            className={classes.pointsGridItem}
                        >
                            <Typography variant="caption">
                                +
                                <Typography
                                    variant="body1"
                                    className={classes.pointsCountText}
                                >
                                    {userProfile.data != null &&
                                    userProfile.data.completed_tasks &&
                                    userProfile.data.completed_tasks[task.id]
                                        ? userProfile.data.completed_tasks[
                                              task.id
                                          ].points
                                        : 0}
                                </Typography>{" "}
                                / {task.points ? task.points : 0}
                            </Typography>
                            <br />
                            <Typography
                                variant="caption"
                                className={classes.pointsLabelText}
                            >
                                Points
                            </Typography>
                        </Grid>
                    </Grid>
                </Button>
            </Link>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        tasks: state.content.tasks,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signInUser: (params) => dispatch(signInUser(params)),
        getUserProfile: (id) => dispatch(getUserProfile(id)),
        getTasks: (sub_section) => dispatch(getTasks(sub_section)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TaskItem)
);
