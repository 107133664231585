import React from "react";
import { connect } from "react-redux";

//Assets
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { CardGiftcard } from "@mui/icons-material";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Typography, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
    titleBox: {
        height: 120,
        width: "100%",
        backgroundColor: "#4472c4",
        [theme.breakpoints.down('xl')]: {
            height: 80,
        },
    },
    profileImageGridItem: {
        width: 140,
        [theme.breakpoints.down('xl')]: {
            display: "none",
        },
    },
    nameGridItem: {
        [theme.breakpoints.down('xl')]: {
            marginLeft: 10,
        },
    },
    profileImg: {
        height: 100,
        width: 100,
    },
    nameLabel: {
        fontWeight: 600,
        color: "white",
        [theme.breakpoints.down('xl')]: {
            fontSize: "1.5rem",
        },
    },
    descriptionLabel: {
        color: "white",
        [theme.breakpoints.down('xl')]: {
            fontSize: "1rem",
        },
    },
    pointsGridContainer: {
        width: "auto",
        [theme.breakpoints.down('xl')]: {
            width: 130,
            flexDirection: "column",
        },
    },
    pointsImg: {
        height: 80,
        width: 80,
        objectFit: "contain",
        color: "white",
        [theme.breakpoints.down('xl')]: {
            height: 40,
            width: 40,
        },
    },
    pointsLabelGridItem: {
        width: "auto",
        paddingRight: 30,
        paddingLeft: 10,
        [theme.breakpoints.down('xl')]: {
            minHeight: "40%",
            padding: 0,
        },
    },
    pointsLabel: {
        width: 70,
        color: "white",
        [theme.breakpoints.down('xl')]: {
            width: "auto",
        },
    },
}));

function ProfileWelcomeHeader(props) {
    const classes = useStyles();
    const {
        user,
        userProfile,
        courseMetrics,
        campaign,
        subheading,
        hidePoints,
    } = props;

    const getUserPoints = () => {
        let points = 0;
        for (let key in courseMetrics.data) {
            points += courseMetrics.data[key].completedPointsCounter;
        }

        return points;
    };

    return (
        <Grid container className={classes.titleBox}>
            <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className={classes.profileImageGridItem}
            >
                <AccountCircleIcon
                    className={classes.profileImg}
                    style={{ color: "white" }}
                />
            </Grid>
            <Grid
                item
                xs
                container
                alignItems="center"
                className={classes.nameGridItem}
            >
                <div>
                    <Typography variant="h3" className={classes.nameLabel}>
                        {userProfile.data != null &&
                            userProfile.data.first_name +
                                " " +
                                userProfile.data.last_name}
                    </Typography>
                    <Typography
                        variant="h5"
                        className={classes.descriptionLabel}
                    >
                        {subheading ||
                            (campaign.data != null &&
                                "is learning about electric cars and competing to win cool prizes") ||
                            "Begin your EV learning journey!"}
                    </Typography>
                </div>
            </Grid>
            {!hidePoints &&
                (courseMetrics.loading ? (
                    <Grid
                        item
                        xs={1}
                        container
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CircularProgress style={{ color: "white" }} />
                    </Grid>
                ) : (
                    courseMetrics.data !== null && (
                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="center"
                            className={classes.pointsGridContainer}
                        >
                            <Grid
                                item
                                xs
                                container
                                alignItems="center"
                                justifyContent="center"
                                style={{ msFlex: "1 1 auto" }}
                            >
                                <CardGiftcard className={classes.pointsImg} />
                            </Grid>
                            <Grid item className={classes.pointsLabelGridItem}>
                                <Typography
                                    variant="h5"
                                    className={classes.pointsLabel}
                                >
                                    {getUserPoints()} points
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                ))}
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        courseMetrics: state.content.courseMetrics,
        campaign: state.content.campaign,
    };
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileWelcomeHeader);
