import React from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";

//Actions
import {
    getCourses,
    getCampaign,
    getCampaignFromKey,
} from "../../../actions/content_actions";
import { updateUserProfile } from "../../../actions/user_actions";
import { openAlert } from "../../../actions/alert_actions";

//Assets

//Styles
import { makeStyles } from "@mui/styles";
import { Typography, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// Components
import BonusPrizeBanner from "../Styled Components/BonusPrizeBanner";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import ProfileWelcomeHeader from "../Courses/ProfileWelcomeHeader";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100%",
    },
    main: {
        margin: 20,
        height: 80,
        backgroundPosition: "center center",
    },
    courseBox: {
        margin: 10,
    },
    titleBox: {
        height: 120,
        width: "100%",
        backgroundColor: "#4472c4",
    },
    profileImg: {
        height: 100,
        width: 100,
    },
    pointsImg: {
        height: 80,
        width: 80,
        objectFit: "contain",
        color: "white",
    },
    breadcrumbBox: {
        height: 23,
        backgroundColor: "rgba(180, 199, 231, 1)",
        padding: 15,
        paddingLeft: 35,
    },
    link: {
        textDecoration: "none",
        color: "black",
    },
}));

function AuthenticatedCampaignDefault(props) {
    const classes = useStyles();
    const { userProfile, campaign, organization, getCampaignFromKey } = props;
    const { campaign_key } = props.match.params;

    const userOrganization =
        userProfile.data != null &&
        (userProfile.data.currentOrganization ||
            userProfile.data.organization.name ||
            userProfile.data.organization);

    React.useEffect(() => {
        if (campaign_key) {
            getCampaignFromKey(campaign_key);
        }
    }, [campaign_key]);

    return (
        <div className={classes.root}>
            <ProfileWelcomeHeader />

            <div className={classes.breadcrumbBox}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Link to="/contests" className={classes.link}>
                        <Typography color="textPrimary">Contests</Typography>
                    </Link>
                    <Typography color="textPrimary">
                        {campaign.data !== null && campaign.data.title}
                    </Typography>
                </Breadcrumbs>
            </div>

            {(userOrganization === "la_county" ||
                userOrganization === "lane_county") && <BonusPrizeBanner />}

            <div className={classes.main}>
                <Grid container spacing={6} style={{ width: "100%" }}>
                    {campaign.data != null ? (
                        <div style={{ width: "100%" }}>
                            <div
                                style={{
                                    maxWidth: 960,
                                    margin: "0 auto",
                                    marginTop: 30,
                                    marginBottom: 30,
                                }}
                            >
                                {parse(campaign.data.welcomePage)}
                            </div>
                        </div>
                    ) : campaign.loading ? (
                        <div
                            style={{
                                width: "100%",
                                height: 600,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    ) : (
                        <Typography variant="h6">
                            Campaign does not exist
                        </Typography>
                    )}
                </Grid>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        courses: state.content.courses,
        courseMetrics: state.content.courseMetrics,
        organization: state.content.organization,
        campaign: state.content.campaign,
    };
};

const mapDispatchToProps = {
    getCourses,
    getCampaign,
    getCampaignFromKey,
    updateUserProfile,
    openAlert,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AuthenticatedCampaignDefault)
);
