import {
    ADD_HUBSPOT_CONTACT_BEGIN,
    ADD_HUBSPOT_CONTACT_ERROR,
    ADD_HUBSPOT_CONTACT_SUCCESS,
    CREATE_HUBSPOT_DEAL_BEGIN,
    CREATE_HUBSPOT_DEAL_ERROR,
    CREATE_HUBSPOT_DEAL_SUCCESS,
    CREATE_HUBSPOT_ENGAGEMENT_BEGIN,
    CREATE_HUBSPOT_ENGAGEMENT_ERROR,
    CREATE_HUBSPOT_ENGAGEMENT_SUCCESS,
} from "../constants/action-types-hubspot";

const initialState = {
    hubspotContact: {
        data: null,
        loading: false,
        error: null,
    },
    hubspotDeal: {
        data: null,
        loading: false,
        error: null,
    },
    hubspotEngagement: {
        data: null,
        loading: false,
        error: null,
    },
};

const hubspot = (state = initialState, action) => {
    switch (action.type) {
        case ADD_HUBSPOT_CONTACT_BEGIN:
            return {
                ...state,
                hubspotContact: { data: null, loading: true, error: null },
            };

        case ADD_HUBSPOT_CONTACT_ERROR:
            return {
                ...state,
                hubspotContact: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case ADD_HUBSPOT_CONTACT_SUCCESS:
            return {
                ...state,
                hubspotContact: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        /* case UPDATE_HUBSPOT_CONTACT_BEGIN:
            return {...state, hubspotContact: {data: null, loading: true, error: null}}

        case UPDATE_HUBSPOT_CONTACT_ERROR:
            return {...state, hubspotContact: {data: null, loading: false, error: action.payload}}

        case UPDATE_HUBSPOT_CONTACT_SUCCESS:
            return {...state, hubspotContact: {data: action.payload, loading: false, error: null}}     */

        case CREATE_HUBSPOT_DEAL_BEGIN:
            return {
                ...state,
                hubspotDeal: { data: null, loading: true, error: null },
            };

        case CREATE_HUBSPOT_DEAL_ERROR:
            return {
                ...state,
                hubspotDeal: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case CREATE_HUBSPOT_DEAL_SUCCESS:
            return {
                ...state,
                hubspotDeal: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        /* case UPDATE_HUBSPOT_DEAL_BEGIN:
            return {...state, hubspotDeal: {data: null, loading: true, error: null}}

        case UPDATE_HUBSPOT_DEAL_ERROR:
            return {...state, hubspotDeal: {data: null, loading: false, error: action.payload}}

        case UPDATE_HUBSPOT_DEAL_SUCCESS:
            return {...state, hubspotDeal: {data: action.payload, loading: false, error: null}} */

        case CREATE_HUBSPOT_ENGAGEMENT_BEGIN:
            return {
                ...state,
                hubspotEngagement: { data: null, loading: true, error: null },
            };

        case CREATE_HUBSPOT_ENGAGEMENT_ERROR:
            return {
                ...state,
                hubspotEngagement: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case CREATE_HUBSPOT_ENGAGEMENT_SUCCESS:
            return {
                ...state,
                hubspotEngagement: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        default:
            return state;
    }
};

export default hubspot;
