import React, { useState, useEffect } from "react";
import AnswerBubble from "./AnswerBubble";
import ExplanatoryDiv from "./ExplanatoryDiv";
import QuestionHeader from "./QuestionHeader";
import "./handleSubject.css";
import { Grid } from "@mui/material";

function questionShuffle(responseList) {
    //console.log("Shuffling responses");

    for (let i = responseList.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [responseList[i], responseList[j]] = [responseList[j], responseList[i]];
    }

    return responseList;
}

export default function QuizAreaContainer(props) {
    const [explanatoryDivVisible, setExplanatoryDivVisible] = useState(false);
    const [explanatoryDivContent, setExplanatoryDivContent] = useState([]);
    const [clickedAnswer, setClickedAnswer] = useState("");
    const [correctAnswerID, setCorrectAnswerID] = useState("");
    const [responsesShuffled, setResponsesShuffled] = useState(false);

    useEffect(() => {
        setExplanatoryDivVisible(false);
        setExplanatoryDivContent([]);
        setClickedAnswer("");
        setResponsesShuffled(false);
    }, [props.nextQuestionClick]);

    //console.log(`Props responseList`, props.question.responses);

    let responseList =
        props.question.responses != null ? props.question.responses : null;
    //console.log(responseList);

    if (
        responseList != null &&
        !props.question.responsesOrdered &&
        !responsesShuffled
    ) {
        responseList = questionShuffle(responseList);
        setResponsesShuffled(true);
    }

    const handleResponseClick = (
        uniqueKey,
        standardExplanation,
        whetherTrue
    ) => {
        let correctID = "";

        for (var i = 0; i < responseList.length; i++) {
            if (responseList[i].response.correct) {
                correctID = responseList[i].uniqueKey;
                break;
            }
        }

        //If this is the first response clicked for a given question
        if (!explanatoryDivVisible) props.setQuestionAnswered(props.index);

        props.handleCorrectAnswer(whetherTrue);
        setExplanatoryDivVisible(true);
        setExplanatoryDivContent([whetherTrue, standardExplanation]);
        setClickedAnswer(uniqueKey);
        setCorrectAnswerID(correctID);

        return null;
    };

    const nextQuestionClick = () => {
        setResponsesShuffled(false);
        //console.log(`Responses shuffled set to `, responsesShuffled);
        setExplanatoryDivVisible(false);
        setExplanatoryDivContent([]);
        setClickedAnswer("");
        //console.log(`setClickedAnswer: ${setClickedAnswer}`);

        return null;
    };

    const answersToRender = responseList.map((response) => {
        return (
            <AnswerBubble
                {...response}
                classes={props.classes}
                key={response.uniqueKey}
                handleResponseClick={handleResponseClick}
                clickedAnswer={clickedAnswer}
                correctAnswerID={correctAnswerID}
            />
        );
    });

    var renderImage =
        props.question.image !== "" ? (
            <div className="questionImage">{props.question.image}</div>
        ) : null;

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={props.classes.quizContainer}
        >
            <Grid item xs className={props.classes.quizContentContainer}>
                <div className={props.classes.scrollableQuizContainer}>
                    <QuestionHeader
                        classes={props.classes}
                        questionToRender={props.question.question}
                    />
                    {
                        //renderImage
                    }
                    <ul className={props.classes.answerContainer}>
                        {answersToRender}
                    </ul>
                </div>
            </Grid>
            {explanatoryDivVisible ? (
                <Grid item style={{ height: "auto", width: "100%" }}>
                    <ExplanatoryDiv
                        classes={props.classes}
                        userGotItRight={explanatoryDivContent[0]}
                        renderMessage={explanatoryDivContent[1]}
                        nextQuestionClick={nextQuestionClick}
                    />
                </Grid>
            ) : null}
        </Grid>
    );
}
