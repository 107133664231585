import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions
import { signInUser, getUserProfile } from "../../../actions/user_actions";
import { getTasks } from "../../../actions/content_actions";

//Components
import TaskItem from "./TaskItem";
import PointsPopper from "./PointsPopper";
import BonusTaskItem from "./BonusTaskItem";

//Assets
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Typography, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
    root: {},
    main: {
        height: 80,
        width: "100%",
        backgroundPosition: "center center",
        display: "grid",
        placeItems: "center center",
        [theme.breakpoints.down("xl")]: {
            height: 500,
        },
        [theme.breakpoints.down("lg")]: {
            height: 350,
        },
    },
    accordionSummary: {
        [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
        },
    },
    expandMoreButton: {
        [theme.breakpoints.down("lg")]: {
            padding: 2,
        },
    },
    pointsGridItem: {
        width: 120,
        [theme.breakpoints.down("lg")]: {
            marginTop: 10,
            width: "100%",
        },
    },
    form: {
        padding: 40,
        borderRadius: 10,
        backgroundColor: "white",
    },
    courseBox: {
        margin: 10,
    },
    titleBox: {
        height: 120,
        width: "100%",
        backgroundColor: "#4472c4",
    },
    profileImg: {
        height: 100,
        width: 100,
    },
    pointsImg: {
        height: 80,
        width: 80,
        objectFit: "contain",
        color: "white",
    },
    link: {
        textDecoration: "none",
    },
    expanded: {
        marginTop: "10px !important",
        marginBottom: "0px !important",
    },
    totalPoints: {
        display: "inline",
    },
    totalPointsAnimated: {
        display: "inline",
        animation: `$myEffect 3000ms ease`,
        animationIterationCount: "infinite",
    },
    "@keyframes myEffect": {
        "0%": {
            color: "#FF0000",
        },
        "20%": {
            color: "#FF7F00",
        },
        "40%": {
            color: "#FFD400",
        },
        "60%": {
            color: "#00FF00",
        },
        "80%": {
            color: "#0000FF",
        },
        "100%": {
            color: "#9400D3",
        },
    },
}));

function SubSection(props) {
    const classes = useStyles();
    const { sub_section, section, course, userProfile } = props;

    const [totalPoints, setTotalPoints] = React.useState(0);
    const [earnedPoints, setEarnedPoints] = React.useState(0);
    const [totalTime, setTotalTime] = React.useState(0);
    const [completedTasks, setCompletedTasks] = React.useState(undefined);
    const [bonusPoints, setBonusPoints] = useState({
        completion: 0,
        perfect_score: 0,
    });
    const [taskComponents, setTaskComponents] = useState();

    const tasksForSubSection =
        props.tasks.data != null && props.tasks.data[sub_section.id]
            ? props.tasks.data[sub_section.id]
            : undefined;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickAway = (event) => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    React.useEffect(() => {
        //console.log("Sub section id is", sub_section.id)
        //if (props.tasks.data === null || !props.tasks.data[sub_section.id]) {
        props.getTasks(sub_section.id);
        //}

        let completed_tasks = userProfile.data.completed_tasks;

        let points = 0;
        let completed = 0;
        for (let prop in completed_tasks) {
            if (completed_tasks[prop].sub_section_id === sub_section.id) {
                completed += 1;
                points += completed_tasks[prop].points;
            }
        }

        setCompletedTasks(completed);
        setEarnedPoints(points);
    }, []);

    React.useEffect(() => {
        if (props.tasks.data != null && props.tasks.data[sub_section.id]) {
            getTasks();

            const tasks = props.tasks.data;

            let points = 0;
            let time = 0;
            if (tasks[sub_section.id]) {
                for (let i in tasks[sub_section.id]) {
                    if (tasks[sub_section.id][i].points > 0) {
                        points += tasks[sub_section.id][i].points;
                    }

                    if (tasks[sub_section.id][i].time > 0)
                        time += parseInt(tasks[sub_section.id][i].time);

                    //This might be a bit confusing but essentially were checking the completed tasks to see if the given tasks still exists
                    //If it does exist remove it's count and point from the counter for sub section
                    /* if (!userProfile.data.completed_tasks[tasks[sub_section.id][i].id]) {
                        let completed_tasks = userProfile.data.completed_tasks;

                        let points = 0
                        let completed = 0
                        for (let prop in completed_tasks) {
                            if (completed_tasks[prop].sub_section_id === sub_section.id) {
                                completed += 1
                                points += completed_tasks[prop].points
                            }
                        }
                
                        setCompletedTasks(completed - 1)
                        setEarnedPoints(points - tasks[sub_section.id][i].points)
                    } */
                }
            }

            if (
                sub_section.completionBonus &&
                tasksForSubSection &&
                completedTasks > 0 &&
                completedTasks === tasksForSubSection.length
            ) {
                setBonusPoints({
                    ...bonusPoints,
                    completion: sub_section.completionBonusPoints || 10,
                });
            }

            setTotalPoints(points);
            setTotalTime(time);

            if (
                // For 2021-LACounty-Rideshare campaign's second task.
                props.tasks.data[sub_section.id][1] &&
                props.tasks.data[sub_section.id][1].id ===
                    "6pkSWjwWkDhdP0E9F77G" && // 2nd task in 2021-LACounty-Rideshare
                userProfile.data != null &&
                !userProfile.data.completed_tasks["6pkSWjwWkDhdP0E9F77G"] // 2nd task in 2021-LACounty-Rideshare
            ) {
                setExpanded(sub_section.id);
            } else if (
                // For 2022-lac-earthday campaign's second task.
                props.tasks.data[sub_section.id][1] &&
                props.tasks.data[sub_section.id][1].id ===
                    "ey0LHpNbdxQIXXPVC5jD" && // 2nd task in 2022-lac-earthday
                userProfile.data != null &&
                !userProfile.data.completed_tasks["ey0LHpNbdxQIXXPVC5jD"] // 2nd task in 2022-lac-earthday
            ) {
                setExpanded(sub_section.id);
            } else if (
                // For 2022-3ce-earthday campaign's second task.
                props.tasks.data[sub_section.id][1] &&
                props.tasks.data[sub_section.id][1].id ===
                    "t8QagzF5X2TCZhYDbsOt" && // 2nd task in 2022-3ce-earthday
                userProfile.data != null &&
                !userProfile.data.completed_tasks["t8QagzF5X2TCZhYDbsOt"] // 2nd task in 2022-3ce-earthday
            ) {
                setExpanded(sub_section.id);
            } else if (
                // For 2022-lac-independence campaign's second task.
                props.tasks.data[sub_section.id][1] &&
                props.tasks.data[sub_section.id][1].id ===
                    "cjY2yM6FkA7vGvIj7Mse" && // 2nd task in 2022-lac-independence
                userProfile.data != null &&
                !userProfile.data.completed_tasks["cjY2yM6FkA7vGvIj7Mse"] // 2nd task in 2022-lac-independence
            ) {
                setExpanded(sub_section.id);
            } else if (
                // For 2022-3ce-independence campaign's second task.
                props.tasks.data[sub_section.id][1] &&
                props.tasks.data[sub_section.id][1].id ===
                    "8yqQKMXtHVXSMG6vurPv" && // 2nd task in 2022-3ce-independence
                userProfile.data != null &&
                !userProfile.data.completed_tasks["8yqQKMXtHVXSMG6vurPv"] // 2nd task in 2022-3ce-independence
            ) {
                setExpanded(sub_section.id);
            } else if (
                // For 2022-lac-rideshare campaign's second task.
                props.tasks.data[sub_section.id][1] &&
                props.tasks.data[sub_section.id][1].id ===
                    "EAD34DeQOwjVoC8O0llz" && // 2nd task in 2022-lac-rideshare
                userProfile.data != null &&
                !userProfile.data.completed_tasks["EAD34DeQOwjVoC8O0llz"] // 2nd task in 2022-lac-rideshare
            ) {
                setExpanded(sub_section.id);
            } else if (
                // For 2023-lac-earthday campaign's second task.
                props.tasks.data[sub_section.id][1] &&
                props.tasks.data[sub_section.id][1].id ===
                    "cnqnKaH2idJoLfOfagPC" && // 2nd task in 2023-lac-earthday
                userProfile.data != null &&
                !userProfile.data.completed_tasks["cnqnKaH2idJoLfOfagPC"] // 2nd task in 2023-lac-earthday
            ) {
                setExpanded(sub_section.id);
            } else if (
                // For 2023-lac-rideshare campaign's second task.
                props.tasks.data[sub_section.id][1] &&
                props.tasks.data[sub_section.id][1].id ===
                    "AqGHSEGk1V8ynoREJlwi" && // 2nd task in 2023-lac-rideshare
                userProfile.data != null &&
                !userProfile.data.completed_tasks["AqGHSEGk1V8ynoREJlwi"] // 2nd task in 2023-lac-rideshare
            ) {
                setExpanded(sub_section.id);
            } else if (
                // For 2024-lac-earthday campaign's second task.
                props.tasks.data[sub_section.id][1] &&
                props.tasks.data[sub_section.id][1].id ===
                    "WisMWNEL43sk1j3gjrRv" && // 2nd task in 2024-lac-earthday
                userProfile.data != null &&
                !userProfile.data.completed_tasks["WisMWNEL43sk1j3gjrRv"] // 2nd task in 2024-lac-earthday
            ) {
                setExpanded(sub_section.id);
            } else if (
                // For 2024-lac-rideshare campaign's second task.
                props.tasks.data[sub_section.id][1] &&
                props.tasks.data[sub_section.id][1].id ===
                    "lt7B8ccz5IWyhDvZbztA" && // 2nd task in 2024-lac-rideshare
                userProfile.data != null &&
                !userProfile.data.completed_tasks["lt7B8ccz5IWyhDvZbztA"] // 2nd task in 2024-lac-rideshare
            ) {
                setExpanded(sub_section.id);
            }
        }
    }, [props.tasks.data]);

    useEffect(() => {
        props.addTime({
            ssTime: totalTime,
            sub_section: sub_section.id,
        });
    }, [totalTime]);

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getTasks = () => {
        const completed_tasks = userProfile.data.completed_tasks;
        let perfect_score = false;

        let tasks = [];
        let taskWithPointerArrow; // Placeholder for JSX of task with pointer arrow indicating next task.

        for (let i in props.tasks.data[sub_section.id]) {
            const task = props.tasks.data[sub_section.id][i];

            if (
                task.type === "quiz" &&
                sub_section.pointsBonus &&
                completed_tasks[task.id] &&
                completed_tasks[task.id].points === task.points
            ) {
                perfect_score = true;
                setBonusPoints({
                    ...bonusPoints,
                    perfect_score: sub_section.pointsBonusPoints || 20,
                });
            }

            taskWithPointerArrow = ( // JSX for task with pointer arrow next to it, indicating next task.
                <div style={{ position: "relative" }}>
                    <Typography
                        variant="h6"
                        style={{
                            position: "absolute",
                            top: 20,
                            left: -210,
                            fontWeight: 600,
                        }}
                    >
                        Your Next Task
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            style={{ fontSize: 24, marginLeft: 10 }}
                        />
                    </Typography>
                    <TaskItem
                        task={task}
                        course={course}
                        sub_section={sub_section}
                        section={section}
                    />
                </div>
            );

            if (
                // For 2021-LACounty-Rideshare campaign's second task.
                task.id === "6pkSWjwWkDhdP0E9F77G" && // 2nd task in 2021-LACounty-Rideshare
                userProfile.data != null &&
                !userProfile.data.completed_tasks["6pkSWjwWkDhdP0E9F77G"] // 2nd task in 2021-LACounty-Rideshare
            ) {
                tasks.push(taskWithPointerArrow);
            } else if (
                // For 2022-lac-earthday campaign's second task
                task.id === "ey0LHpNbdxQIXXPVC5jD" && // 2nd task in 2022-lac-earthday
                userProfile.data != null &&
                !userProfile.data.completed_tasks["ey0LHpNbdxQIXXPVC5jD"] // 2nd task in 2022-lac-earthday
            ) {
                tasks.push(taskWithPointerArrow);
            } else if (
                // For 2022-3ce-earthday campaign's second task
                task.id === "t8QagzF5X2TCZhYDbsOt" && // 2nd task in 2022-3ce-earthday
                userProfile.data != null &&
                !userProfile.data.completed_tasks["t8QagzF5X2TCZhYDbsOt"] // 2nd task in 2022-3ce-earthday
            ) {
                tasks.push(taskWithPointerArrow);
            } else if (
                // For 2022-lac-independence campaign's second task
                task.id === "cjY2yM6FkA7vGvIj7Mse" && // 2nd task in 2022-lac-independence
                userProfile.data != null &&
                !userProfile.data.completed_tasks["cjY2yM6FkA7vGvIj7Mse"] // 2nd task in 2022-lac-independence
            ) {
                tasks.push(taskWithPointerArrow);
            } else if (
                // For 2022-3ce-independence campaign's second task
                task.id === "8yqQKMXtHVXSMG6vurPv" && // 2nd task in 2022-3ce-independence
                userProfile.data != null &&
                !userProfile.data.completed_tasks["8yqQKMXtHVXSMG6vurPv"] // 2nd task in 2022-3ce-independence
            ) {
                tasks.push(taskWithPointerArrow);
            } else if (
                // For 2022-lac-rideshare campaign's second task
                task.id === "EAD34DeQOwjVoC8O0llz" && // 2nd task in 2022-lac-rideshare
                userProfile.data != null &&
                !userProfile.data.completed_tasks["EAD34DeQOwjVoC8O0llz"] // 2nd task in 2022-lac-rideshare
            ) {
                tasks.push(taskWithPointerArrow);
            } else if (
                // For 2023-lac-earthday campaign's second task
                task.id === "cnqnKaH2idJoLfOfagPC" && // 2nd task in 2023-lac-earthday
                userProfile.data != null &&
                !userProfile.data.completed_tasks["cnqnKaH2idJoLfOfagPC"] // 2nd task in 2023-lac-earthday
            ) {
                tasks.push(taskWithPointerArrow);
            } else if (
                // For 2023-lac-rideshare campaign's second task
                task.id === "AqGHSEGk1V8ynoREJlwi" && // 2nd task in 2023-lac-rideshare
                userProfile.data != null &&
                !userProfile.data.completed_tasks["AqGHSEGk1V8ynoREJlwi"] // 2nd task in 2023-lac-rideshare
            ) {
                tasks.push(taskWithPointerArrow);
            } else if (
                // For 2024-lac-earthday campaign's second task
                task.id === "WisMWNEL43sk1j3gjrRv" && // 2nd task in 2023-lac-earthday
                userProfile.data != null &&
                !userProfile.data.completed_tasks["WisMWNEL43sk1j3gjrRv"] // 2nd task in 2024-lac-earthday
            ) {
                tasks.push(taskWithPointerArrow);
            } else if (
                // For 2024-lac-rideshare campaign's second task
                task.id === "lt7B8ccz5IWyhDvZbztA" && // 2nd task in 2024-lac-rideshare
                userProfile.data != null &&
                !userProfile.data.completed_tasks["lt7B8ccz5IWyhDvZbztA"] // 2nd task in 2024-lac-rideshare
            ) {
                tasks.push(taskWithPointerArrow);
            } else {
                // For all other tasks, i.e. anything that is not the second task in the campaigns defined in above if / elseif
                tasks.push(
                    <TaskItem
                        task={task}
                        course={course}
                        sub_section={sub_section}
                        section={section}
                    />
                );
            }
        }

        if (sub_section.completionBonus || sub_section.pointsBonus)
            tasks.push(<Divider style={{ height: 3, marginTop: 5 }} />);

        if (sub_section.completionBonus)
            tasks.push(
                <BonusTaskItem
                    type="completion"
                    points={sub_section.completionBonusPoints || 20}
                    success={
                        tasksForSubSection &&
                        completedTasks === tasksForSubSection.length
                    }
                />
            );

        if (sub_section.pointsBonus)
            tasks.push(
                <BonusTaskItem
                    type="perfect_score"
                    points={sub_section.pointsBonusPoints || 20}
                    success={perfect_score}
                />
            );

        setTaskComponents(tasks);
    };

    return (
        <div style={{ marginBottom: 20 }} key={props.key}>
            <Accordion
                expanded={expanded === sub_section.id}
                onChange={handleChange(sub_section.id)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{
                        root: classes.accordionSummary,
                        expanded: classes.expanded,
                        expandIcon: classes.expandMoreButton,
                    }}
                >
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.mainGridContainer}
                    >
                        <Grid
                            item
                            container
                            style={{
                                width: 60,
                                marginRight: 15,
                                justifyContent: "center",
                            }}
                        >
                            {/* <div style={{width: 60, height: 60, borderRadius: 30, backgroundColor: 'rgba(255, 213, 0, 1)', display: 'grid', placeItems: 'center center'}}>
                                <img src={CheckMark} style={{width: 40, height: 40}} />
                            </div> */}
                            <div style={{ width: 60, height: 60 }}>
                                <div style={{ position: "absolute" }}>
                                    <CircularProgress
                                        variant="determinate"
                                        value={100}
                                        style={{
                                            color: "lightgray",
                                            width: 60,
                                            height: 60,
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ position: "absolute", zIndex: 1 }}
                                >
                                    <CircularProgress
                                        variant="determinate"
                                        value={
                                            tasksForSubSection != undefined &&
                                            tasksForSubSection.length != 0
                                                ? (completedTasks /
                                                      tasksForSubSection.length) *
                                                  100
                                                : 0
                                        }
                                        style={{
                                            color: "rgba(255, 213, 0, 1)",
                                            width: 60,
                                            height: 60,
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        position: "absolute",
                                        height: 60,
                                        width: 60,
                                        zIndex: 2,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        style={{ fontWeight: 700 }}
                                    >
                                        {tasksForSubSection != undefined &&
                                        tasksForSubSection.length != 0
                                            ? Math.round(
                                                  (completedTasks /
                                                      tasksForSubSection.length) *
                                                      100
                                              )
                                            : 0}
                                        %
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <Typography
                                variant="h5"
                                style={{ fontWeight: 500 }}
                            >
                                {sub_section.title}
                            </Typography>
                            <Typography variant="caption">
                                <i>
                                    {totalTime} {totalTime > 1 ? "mins" : "min"}
                                </i>
                            </Typography>
                        </Grid>
                        <Grid item className={classes.pointsGridItem}>
                            <Typography
                                variant="body1"
                                style={{
                                    whiteSpace: "nowrap",
                                    display: "inline",
                                }}
                            >
                                {"+ "}
                            </Typography>
                            <Typography
                                variant="h6"
                                style={{ display: "inline" }}
                            >
                                {earnedPoints +
                                    bonusPoints.completion +
                                    bonusPoints.perfect_score}
                            </Typography>
                            {tasksForSubSection != undefined &&
                            completedTasks / tasksForSubSection.length === 1 &&
                            earnedPoints < totalPoints ? (
                                <Typography
                                    onMouseOver={handleClick}
                                    onMouseLeave={handleClickAway}
                                    className={classes.totalPointsAnimated}
                                >
                                    {" /"} {totalPoints} Points
                                </Typography>
                            ) : (
                                <Typography className={classes.totalPoints}>
                                    {" /"} {totalPoints} Points
                                </Typography>
                            )}

                            <PointsPopper open={open} anchorEl={anchorEl} />
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                    <Typography
                        variant="h6"
                        style={{ fontWeight: 300, marginLeft: 10 }}
                    >
                        {sub_section.description}
                    </Typography>
                    {props.tasks.loading || props.tasks.data === null ? (
                        <CircularProgress />
                    ) : (
                        props.tasks.data != null &&
                        props.tasks.data[sub_section.id] &&
                        taskComponents
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        tasks: state.content.tasks,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signInUser: (params) => dispatch(signInUser(params)),
        getUserProfile: (id) => dispatch(getUserProfile(id)),
        getTasks: (sub_section) => dispatch(getTasks(sub_section)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SubSection)
);
