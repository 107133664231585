import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Actions
import { getHomePage, getOrganization } from "../../../../../actions/content_actions";

//Styles
import "../../../../../static/styles/App.css";
import {
    Box,
    Dialog,
    DialogContent,
    Grid,
    Typography,
    ButtonBase
} from "@mui/material";
import { makeStyles } from "@mui/styles";

//Components
import TextDivider from "../../../../default/Styled Components/TextDivider";


import { CustomDialogTitle } from "../../../../default/Styled Components/DialogTitle";
import ReferShareButtons from "./ReferShareButtons";
import HowReferWorks from "./HowReferWorks";

//Assets
// import GiftCard250 from "../../../../../static/images/GiftCard-250.png";
import iPad from "../static/images/ipad-compressed.png";

//Helpers

const useStyles = makeStyles((theme) => ({
    gridItemText: {
        marginTop: 15,
        marginLeft: 50,
        marginRight: 50,
        textAlign: "center",
    },
    giftCardGridContainer: {
        margin: 15,
        [theme.breakpoints.down('lg')]: {
            margin: 0,
        },
    },
    giftCardGridItem: {
        marginRight: 20,
        [theme.breakpoints.down('lg')]: {
            marginRight: 0,
        },
    },
    divider: {
        width: "100%",
        backgroundColor: "gainsboro",
        height: 3,
    },
    dialogPaper: {
        [theme.breakpoints.down('lg')]: {
            margin: 16,
        },
    },
    dialogText: {
        [theme.breakpoints.down('lg')]: {
            fontSize: "1rem",
        },
    },
}));

function ReferInfoBox(props) {
    const classes = useStyles();

    const { email, campaign } = props;
    const [open, toggleDialog] = useState(false);
    const preventDefault = (event) => event.preventDefault();

    const handleClick = (event) => {
        preventDefault(event);
        toggleDialog(true);
    };
    return (
        <div>
            <HowReferWorks
                steps={[
                    <Typography variant="h6" className={classes.gridItemText}>
                        Copy your referral link (see below)
                    </Typography>,
                    <Typography
                        variant="h5"
                        className={classes.gridItemText}
                        style={{ textAlign: "center" }}
                    >
                        <b>Share!</b>
                    </Typography>,
                    <div>
                        <Typography
                            variant="h6"
                            className={classes.gridItemText}
                        >
                            Sit Back and Relax
                        </Typography>
                        <Typography variant='body2' align="center">
                            We’ll give you credit for each referral who joins from your shares.
                        </Typography>
                    </div>,
                ]}
            />

            <ReferShareButtons email={email} campaign={campaign} />

            <TextDivider text="Why Refer?" />

            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Typography variant="h6">
                    <b>
                        <u>1. To win!</u>
                    </b>
                </Typography>
                <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    className={classes.giftCardGridContainer}
                >
                    <Grid item xs={4} sm={3} container justifyContent="center">
                        <img
                            src={iPad}
                            style={{
                                maxHeight: "100%",
                                maxWidth: "100%",
                                objectFit: "contain",
                            }}
                            alt="Gift Card"
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="body1">
                            Each referral gives you a chance to <b>win an Apple iPad.</b>
                            <Box m={1.0} />
                            More referrals = More chances to win!
                            <Box m={1.0} />
                            Curious to learn more? <Link to="#" onClick={handleClick}>Read the contest rules</Link>.
                        </Typography>
                    </Grid>
                </Grid>

                <Typography variant="h6">
                    <b>
                        <u>2. To help your community become fossil fuel free</u>
                    </b>
                </Typography>
                <Typography variant="body1" style={{ margin: 15 }}>
                    A fossil fuel free community – that’s what we’re after! That
                    requires everyone to understand the benefits of choosing
                    an electric car…
                    <Box m={1.0} />
                    By referring your peers, you’ll help accelerate our
                    transition to a fossil fuel free community.
                </Typography>
            </div>

            <Dialog
                //onClose={() => toggleDialog(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                maxWidth="md"
                classes={{ paper: classes.dialogPaper }}
            >
                <CustomDialogTitle onClose={() => toggleDialog(false)}>
                    <b>Referral Contest Rules</b>
                </CustomDialogTitle>
                <DialogContent>
                    <Box m={1.5} />
                    <Grid container alignItems="center">
                        <Grid item xs={3} className={classes.giftCardGridItem}>
                            <img
                                src={iPad}
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                alt="iPad"
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography
                                variant="body1"
                                className={classes.dialogText}
                            >
                                <ul>
                                    <li>
                                        You can <b>win an Apple iPad</b> by
                                        encouraging others to
                                        participate.
                                    </li>
                                    <Box m={1.0} />
                                    <li>
                                        You earn one point for every LA County employee
                                        who registers with your referral link.
                                        Each point will become one raffle entry
                                        for the Apple iPad giveaway.
                                    </li>
                                    <Box m={1.0} />
                                    <li>
                                        Only participants of the LA County "2023 Electric Cars for Earth"
                                        event are eligible for the referral prize.
                                    </li>
                                    <Box m={1.0} />
                                    <li>
                                        Referral prize is available to be won only by LA County employees.
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganization: (organization) =>
            dispatch(getOrganization(organization)),
        getHomePage: (organization) => dispatch(getHomePage(organization)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReferInfoBox)
);
