import {
    GET_ORGANIZATION_BEGIN,
    GET_ORGANIZATION_ERROR,
    GET_ORGANIZATION_SUCCESS,
    GET_COURSES_BEGIN,
    GET_COURSES_ERROR,
    GET_COURSES_SUCCESS,
    GET_SECTIONS_BEGIN,
    GET_SECTIONS_ERROR,
    GET_SECTIONS_SUCCESS,
    GET_SUB_SECTIONS_BEGIN,
    GET_SUB_SECTIONS_ERROR,
    GET_SUB_SECTIONS_SUCCESS,
    GET_TASKS_BEGIN,
    GET_TASKS_ERROR,
    GET_TASKS_SUCCESS,
    GET_ALL_TASKS_BEGIN,
    GET_ALL_TASKS_ERROR,
    GET_ALL_TASKS_SUCCESS,
    GET_QUIZ_BEGIN,
    GET_QUIZ_ERROR,
    GET_QUIZ_SUCCESS,
    GET_LESSON_BEGIN,
    GET_LESSON_ERROR,
    GET_LESSON_SUCCESS,
    GET_ACTIVITY_BEGIN,
    GET_ACTIVITY_ERROR,
    GET_ACTIVITY_SUCCESS,
    GET_COURSE_METRICS_BEGIN,
    GET_COURSE_METRICS_ERROR,
    GET_COURSE_METRICS_SUCCESS,
    GET_SINGLE_TASK_BEGIN,
    GET_SINGLE_TASK_ERROR,
    GET_SINGLE_TASK_SUCCESS,
    GET_HOME_PAGE_BEGIN,
    GET_HOME_PAGE_ERROR,
    GET_HOME_PAGE_SUCCESS,
    GET_CAMPAIGN_BEGIN,
    GET_CAMPAIGN_ERROR,
    GET_CAMPAIGN_SUCCESS,
    GET_ALL_CAMPAIGNS_BEGIN,
    GET_ALL_CAMPAIGNS_ERROR,
    GET_ALL_CAMPAIGNS_SUCCESS,
    GET_ASSIGNMENTS_BEGIN,
    GET_ASSIGNMENTS_ERROR,
    GET_ASSIGNMENTS_SUCCESS,
    GET_INCENTIVES_BEGIN,
    GET_INCENTIVES_ERROR,
    GET_INCENTIVES_SUCCESS,
    GET_EV101_BEGIN,
    GET_EV101_ERROR,
    GET_EV101_SUCCESS,
} from "../constants/action-types-content";

const initialState = {
    organization: {
        data: null,
        loading: false,
        error: null,
    },
    campaign: {
        data: null,
        loading: false,
        error: null,
    },
    allCampaigns: {
        data: null,
        loading: false,
        error: null,
    },
    homePage: {
        data: null,
        loading: false,
        error: null,
    },
    courses: {
        data: null,
        loading: false,
        error: null,
    },
    sections: {
        data: null,
        loading: false,
        error: null,
    },
    sub_sections: {
        data: null,
        loading: false,
        error: null,
    },
    tasks: {
        data: null,
        loading: false,
        error: null,
    },
    allTasks: {
        data: null,
        loading: false,
        error: null,
    },
    quiz: {
        data: null,
        loading: false,
        error: null,
    },
    lesson: {
        data: null,
        loading: false,
        error: null,
    },
    activity: {
        data: null,
        loading: false,
        error: null,
    },
    courseMetrics: {
        data: null,
        loading: false,
        error: null,
    },
    singleTask: {
        data: null,
        loading: false,
        error: null,
    },
    assignments: {
        data: null,
        loading: false,
        error: null,
    },
    incentives: {
        data: null,
        loading: false,
        error: null,
    },
    ev101: {
        data: null,
        loading: false,
        error: null,
    },
};

const content = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORGANIZATION_BEGIN:
            return {
                ...state,
                organization: { data: null, loading: true, error: null },
            };

        case GET_ORGANIZATION_ERROR:
            return {
                ...state,
                organization: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case GET_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: {
                    data: action.payload || null,
                    loading: false,
                    error: null,
                },
            };

        case GET_CAMPAIGN_BEGIN:
            return {
                ...state,
                campaign: { data: null, loading: true, error: null },
            };

        case GET_CAMPAIGN_ERROR:
            return {
                ...state,
                campaign: { data: null, loading: false, error: action.payload },
            };

        case GET_CAMPAIGN_SUCCESS:
            return {
                ...state,
                campaign: { data: action.payload, loading: false, error: null },
            };

        case GET_ALL_CAMPAIGNS_BEGIN:
            return {
                ...state,
                allCampaigns: { data: null, loading: true, error: null },
            };

        case GET_ALL_CAMPAIGNS_ERROR:
            return {
                ...state,
                allCampaigns: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case GET_ALL_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                allCampaigns: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case GET_HOME_PAGE_BEGIN:
            return {
                ...state,
                homePage: { data: null, loading: true, error: null },
            };

        case GET_HOME_PAGE_ERROR:
            return {
                ...state,
                homePage: { data: null, loading: false, error: action.payload },
            };

        case GET_HOME_PAGE_SUCCESS:
            return {
                ...state,
                homePage: { data: action.payload, loading: false, error: null },
            };

        case GET_COURSES_BEGIN:
            return {
                ...state,
                courses: { data: null, loading: true, error: null },
            };

        case GET_COURSES_ERROR:
            return {
                ...state,
                courses: { data: null, loading: false, error: action.payload },
            };

        case GET_COURSES_SUCCESS:
            return {
                ...state,
                courses: { data: action.payload, loading: false, error: null },
            };

        case GET_SECTIONS_BEGIN:
            return {
                ...state,
                sections: { data: null, loading: true, error: null },
            };

        case GET_SECTIONS_ERROR:
            return {
                ...state,
                sections: { data: null, loading: false, error: action.payload },
            };

        case GET_SECTIONS_SUCCESS: {
            let sectionsData = action.payload;

            sectionsData.sort((a, b) => a.order - b.order);

            return {
                ...state,
                sections: { data: sectionsData, loading: false, error: null },
            };
        }

        case GET_SUB_SECTIONS_BEGIN:
            return { ...state, sub_sections: { loading: true, error: null } };

        case GET_SUB_SECTIONS_ERROR:
            return {
                ...state,
                sub_sections: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case GET_SUB_SECTIONS_SUCCESS: {
            let data = action.payload.data;

            data.sort((a, b) => a.order - b.order);

            let sectionID = action.payload.section;
            let sub_sections = state.sub_sections.data;

            if (sub_sections == null) {
                sub_sections = {};
            }

            sub_sections[sectionID] = data;

            // Create a new list so that we can sort the object
            let sortable = [];
            for (let prop in sub_sections) {
                sortable.push([prop, sub_sections[prop]]);
            }

            sortable.sort(function (a, b) {
                return a[1].order - b[1].order;
            });

            let objSorted = {};
            sortable.forEach(function (item) {
                objSorted[item[0]] = item[1];
            });

            sub_sections = objSorted;

            return {
                ...state,
                sub_sections: {
                    data: sub_sections,
                    loading: false,
                    error: null,
                },
            };
        }

        case GET_TASKS_BEGIN:
            return { ...state, tasks: { loading: true, error: null } };

        case GET_TASKS_ERROR:
            return {
                ...state,
                tasks: { data: null, loading: false, error: action.payload },
            };

        case GET_TASKS_SUCCESS: {
            let taskData = action.payload.data;

            // Tasks have an optional sortOrder field in the CMS than can be set to overwrite regular order
            taskData.sort(
                (a, b) => (a.sortOrder || a.order) - (b.sortOrder || b.order)
            );

            let sub_sectionID = action.payload.sub_section;
            let tasks = state.tasks.data;

            if (tasks == null) {
                tasks = {};

                tasks[sub_sectionID] = taskData;
            } else {
                tasks[sub_sectionID] = taskData;

                // Create a new list so that we can sort the object
                let sortable = [];
                for (let prop in tasks) {
                    sortable.push([prop, tasks[prop]]);
                }

                sortable.sort(function (a, b) {
                    // Tasks have an optional sortOrder field in the CMS than can be set to overwrite regular order
                    return (
                        (a[1].sortOrder || a[1].order) -
                        (b[1].sortOrder || b[1].order)
                    );
                });

                let objSorted = {};
                sortable.forEach(function (item) {
                    objSorted[item[0]] = item[1];
                });

                tasks = objSorted;
            }

            return {
                ...state,
                tasks: { data: tasks, loading: false, error: null },
            };
        }

        case GET_ALL_TASKS_BEGIN:
            return {
                ...state,
                allTasks: { data: null, loading: true, error: null },
            };

        case GET_ALL_TASKS_ERROR:
            return {
                ...state,
                allTasks: { data: null, loading: false, error: action.payload },
            };

        case GET_ALL_TASKS_SUCCESS:
            return {
                ...state,
                allTasks: { data: action.payload, loading: false, error: null },
            };

        case GET_QUIZ_BEGIN:
            return {
                ...state,
                quiz: { data: null, loading: true, error: null },
            };

        case GET_QUIZ_ERROR:
            return {
                ...state,
                quiz: { data: null, loading: false, error: action.payload },
            };

        case GET_QUIZ_SUCCESS:
            return {
                ...state,
                quiz: { data: action.payload, loading: false, error: null },
            };

        case GET_LESSON_BEGIN:
            return { ...state, lesson: { loading: true, error: null } };

        case GET_LESSON_ERROR:
            return {
                ...state,
                lesson: { data: null, loading: false, error: action.payload },
            };

        case GET_LESSON_SUCCESS:
            return {
                ...state,
                lesson: { data: action.payload, loading: false, error: null },
            };

        case GET_ACTIVITY_BEGIN:
            return { ...state, activity: { loading: true, error: null } };

        case GET_ACTIVITY_ERROR:
            return {
                ...state,
                activity: { data: null, loading: false, error: action.payload },
            };

        case GET_ACTIVITY_SUCCESS:
            return {
                ...state,
                activity: { data: action.payload, loading: false, error: null },
            };

        case GET_COURSE_METRICS_BEGIN:
            return {
                ...state,
                courseMetrics: {
                    ...state.courseMetrics,
                    loading: true,
                    error: null,
                },
            };

        case GET_COURSE_METRICS_ERROR:
            return {
                ...state,
                courseMetrics: {
                    ...state.courseMetrics,
                    loading: false,
                    error: action.payload,
                },
            };

        case GET_COURSE_METRICS_SUCCESS: {
            const courseID = action.payload.courseID;
            const courseData = action.payload.result;

            let metrics = {};
            if (state.courseMetrics.data !== null) {
                metrics = state.courseMetrics.data;
            }

            // Add the metrics for the given course as a key value pair
            metrics[courseID] = courseData;

            return {
                ...state,
                courseMetrics: { data: metrics, loading: false, error: null },
            };
        }

        case GET_SINGLE_TASK_BEGIN:
            return {
                ...state,
                singleTask: { data: null, loading: true, error: null },
            };

        case GET_SINGLE_TASK_ERROR:
            return {
                ...state,
                singleTask: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case GET_SINGLE_TASK_SUCCESS:
            return {
                ...state,
                singleTask: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case GET_ASSIGNMENTS_BEGIN:
            return {
                ...state,
                assignments: { data: null, loading: true, error: null },
            };

        case GET_ASSIGNMENTS_ERROR:
            return {
                ...state,
                assignments: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case GET_ASSIGNMENTS_SUCCESS:
            return {
                ...state,
                assignments: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case GET_INCENTIVES_BEGIN:
            return {
                ...state,
                incentives: { data: null, loading: true, error: null },
            };

        case GET_INCENTIVES_ERROR:
            return {
                ...state,
                incentives: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case GET_INCENTIVES_SUCCESS: {
            let incentiveData = action.payload;
            // The order key is automatically added by Flamelink
            incentiveData.sort((a, b) => a.order - b.order);
            return {
                ...state,
                incentives: {
                    data: incentiveData,
                    loading: false,
                    error: null,
                },
            };
        }

        case GET_EV101_BEGIN:
            return {
                ...state,
                ev101: { data: null, loading: true, error: null },
            };

        case GET_EV101_ERROR:
            return {
                ...state,
                ev101: { data: null, loading: false, error: action.payload },
            };

        case GET_EV101_SUCCESS: {
            // The order key is automatically added by Flamelink
            const sortedData = action.payload.sort((a, b) => a.order - b.order);
            return {
                ...state,
                ev101: { data: sortedData, loading: false, error: null },
            };
        }
        
        default:
            return state;
    }
};

export default content;
