import React from "react";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Typography, Popper } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    popper: {
        maxWidth: 300,
        borderRadius: 5,
        backgroundColor: "white",
        boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.75)",
        position: "relative",
        zIndex: 1,
    },
}));

function CourseMetricsPopper(props) {
    const classes = useStyles();
    const { open, anchorEl, handleClose } = props;

    return (
        <Popper
            //id={id}
            open={open}
            anchorEl={anchorEl}
            container={anchorEl && anchorEl.parentNode}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            className={classes.popper}
        >
            <Typography className={classes.typography}>
                {anchorEl && anchorEl.getAttribute("id") === "pointsButton"
                    ? "Complete tasks... Earn Points... Win cool prizes!"
                    : anchorEl &&
                      anchorEl.getAttribute("id") === "referralButton"
                    ? "BONUS Prize: Refer Your Fellow Employees to Win"
                    : "Complete all tasks to earn a bonus multiplier on your points. Get a perfect score on all quizzes to unlock a HUGE bonus!"}
            </Typography>
        </Popper>
    );
}

export default CourseMetricsPopper;
