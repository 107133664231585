import React from "react";

//Styles
import { Button, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

//Components
import ReferralModal from "../Courses/ReferralModal";

const useStyles = makeStyles((theme) => ({
    warningBox: {
        width: "100%",
        height: 34,
        textAlign: "center",
        background:
            "linear-gradient(90deg, rgba(42,191,32,1) 0%, rgba(42,138,164,1) 50%, rgba(215,41,228,1) 100%)",
        padding: 8,
    },
}));

function BonusPrizeBanner(props) {
    const classes = useStyles();

    const [visible, toggleModal] = React.useState(false);

    return (
        <div>
            <Button
                style={{ width: "100%", margin: 0, padding: 0 }}
                onClick={() => toggleModal(true)}
            >
                <div className={classes.warningBox}>
                    <Typography variant="h6" style={{ color: "white" }}>
                        &#8680;&nbsp;&nbsp;BONUS Prize – Click to Learn How to
                        Win&nbsp;&nbsp;&#8678;
                    </Typography>
                </div>
            </Button>

            <ReferralModal
                visible={visible}
                handleClose={() => toggleModal(false)}
            />
        </div>
    );
}

export default BonusPrizeBanner;
