import {
    CREATE_COACHING_PROFILE_BEGIN,
    CREATE_COACHING_PROFILE_ERROR,
    CREATE_COACHING_PROFILE_SUCCESS,
    GET_COACHING_PROFILE_BEGIN,
    GET_COACHING_PROFILE_ERROR,
    GET_COACHING_PROFILE_SUCCESS,
    UPDATE_COACHING_PROFILE_BEGIN,
    UPDATE_COACHING_PROFILE_ERROR,
    UPDATE_COACHING_PROFILE_SUCCESS,
} from "../constants/action-types-coaching";

const initialState = {
    coachingProfile: {
        data: null,
        loading: false,
        error: null,
    },
};

const coaching = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_COACHING_PROFILE_BEGIN:
            return {
                ...state,
                coachingProfile: { data: null, loading: true, error: null },
            };

        case CREATE_COACHING_PROFILE_ERROR:
            return {
                ...state,
                coachingProfile: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case CREATE_COACHING_PROFILE_SUCCESS:
            return {
                ...state,
                coachingProfile: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case GET_COACHING_PROFILE_BEGIN:
            return {
                ...state,
                coachingProfile: {
                    ...state.coachingProfile,
                    loading: true,
                    error: null,
                },
            };

        case GET_COACHING_PROFILE_ERROR:
            return {
                ...state,
                coachingProfile: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case GET_COACHING_PROFILE_SUCCESS:
            return {
                ...state,
                coachingProfile: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case UPDATE_COACHING_PROFILE_BEGIN:
            return {
                ...state,
                coachingProfile: {
                    ...state.coachingProfile,
                    loading: true,
                    error: null,
                },
            };

        case UPDATE_COACHING_PROFILE_ERROR:
            return {
                ...state,
                coachingProfile: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case UPDATE_COACHING_PROFILE_SUCCESS:
            return {
                ...state,
                coachingProfile: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        default:
            return state;
    }
};

export default coaching;
