import React, { useEffect, Suspense, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions
import { getOrganization } from "../actions/content_actions";

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";

// Components
import LoginForm from "../components/default/Login/LoginForm";

//Helpers
import {
    getOrganizationComponent,
    getOrganizationForDomain,
    paramsToObject,
} from "../helpers/functions";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "calc(100vh - 90px)",
    },
    main: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

function Login(props) {
    const classes = useStyles();

    const [visible, toggleModal] = React.useState(true);

    const { organization } = props;
    const organizationFromURL =
        props.match.params.organization || getOrganizationForDomain();

    const urlParams = new URLSearchParams(props.location.search);
    const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
    const params = paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}

    const [OrganizationLoginComponent, setOrganizationLoginComponent] =
        useState(
            getOrganizationComponent(organizationFromURL, "LoginRegistration")
        );

    useEffect(() => {
        if (organizationFromURL) {
            //setOrganizationLoginComponent(getOrganizationComponent(organizationFromURL, 'LoginRegistration'))
            props.getOrganization(organizationFromURL);
        }
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                {organizationFromURL ? (
                    <Suspense fallback={<></>}>
                        <OrganizationLoginComponent
                            visible={visible}
                            handleClose={() => toggleModal(false)}
                        />
                    </Suspense>
                ) : (
                    <LoginForm forward={params.forward} />
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        organization: state.content.organization,
    };
};

const mapDispatchToProps = {
    getOrganization,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
