import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";

function ScrollToTop(props) {
    const { pathname } = useLocation();
    const { organization } = props;

    useEffect(() => {
        if (/*@cc_on!@*/ false || !!document.documentMode) {
            //Internet explorer does not support scrollTo
        } else {
            window.scrollTo(0, 0);

            //The root often takes on the scroll in the app
            document.querySelector("#root").scrollTo(0, 0);
        }
    }, [pathname, organization]);

    return <React.Fragment>{props.children}</React.Fragment>;
}

const mapStateToProps = (state) => {
    return {
        organization: state.content.organization,
    };
};

const mapDispatchToProps = {};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ScrollToTop)
);
