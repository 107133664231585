import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//Actions
import { closeAlert } from "../../../actions/alert_actions";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: 20,
        paddingBottom: 0,
    },
    dialogActions: {
        padding: 20,
    },
}));

function AlertDialog(props) {
    //Pass the color from the parent component to the styles
    const classes = useStyles();
    const { alert } = props;
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (alert.data !== null) setVisible(true);
        else setVisible(false);
    }, [alert]);

    if (alert.data !== null)
        return (
            <Dialog open={visible} fullWidth={true} maxWidth="xs">
                <DialogContent className={classes.dialogContent}>
                    <Typography variant="h6">{alert.data.text}</Typography>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={props.closeAlert}
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        );
    else return null;
}

const mapStateToProps = (state) => {
    return {
        alert: state.alert.alert,
    };
};

const mapDispatchToProps = {
    closeAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
