import {
    faBalanceScaleLeft,
    faCalendarAlt,
    faCar,
    faChalkboardTeacher,
    faPiggyBank,
    faSearchDollar,
    faShoppingCart,
    faWarehouse,
} from "@fortawesome/free-solid-svg-icons";

export const navigatePurchaseList = [
    {
        title: "Learn the basics about electric cars",
        icon: faChalkboardTeacher,
    },
    {
        title: "Find models that meet my needs",
        icon: faCar,
    },
    {
        title: "See if EVs make sense for me",
        icon: faBalanceScaleLeft,
    },
    {
        title: "Find incentives for me to buy an EV",
        icon: faSearchDollar,
    },
    {
        title: "Help me arrange test drives for EVs",
        icon: faCalendarAlt,
    },
    {
        title: "Find inventory on an EV to buy",
        icon: faWarehouse,
    },
    {
        title: "Negotiate the best deal on an EV",
        icon: faPiggyBank,
    },
    {
        title: "Help me make a purchase",
        icon: faShoppingCart,
    },
];
