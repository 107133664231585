import React, { useState } from "react";

//Styles
import {
    Popper,
    Grid,
    Button,
    ClickAwayListener,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
} from "@mui/material";
import { StyledTaskButton } from "../../../../default/Styled Components/Buttons";

function MoreChancesToWinPopper(props) {
    const {
        anchorEl,
        handleClickAway,
        handleReferClick,
        handleBackToSyllabusClick,
    } = props;

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                style={{
                    maxWidth: 500,
                    marginTop: "5px",
                    borderRadius: 5,
                    padding: "10px",
                    backgroundColor: "white",
                    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.75)",
                    position: "relative",
                    zIndex: 1000000,
                }}
            >
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            <b>Win the Referral Prize</b>
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="body1" align="right">
                            Help create a fossil fuel free community &{" "}
                            <b style={{ color: "red" }}>win an Apple iPad</b>{" "}
                            or <b style={{ color: "red" }}>an Apple Watch.</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={5} style={{ marginLeft: 10 }}>
                        <StyledTaskButton onClick={handleReferClick}>
                            <Typography variant="h6">
                                <b>Refer a Friend</b>
                            </Typography>
                        </StyledTaskButton>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            <b>Earn Bonus Points</b>
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="body1" align="right">
                            Get a perfect score on all quizzes to earn huge
                            bonus points.
                        </Typography>
                    </Grid>
                    <Grid item xs={5} style={{ marginLeft: 10 }}>
                        <Button
                            variant="outlined"
                            onClick={handleBackToSyllabusClick}
                        >
                            Back to Syllabus
                        </Button>
                    </Grid>
                </Grid>
            </Popper>
        </ClickAwayListener>
    );
}

export default MoreChancesToWinPopper;
