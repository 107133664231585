import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers/index";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// Firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Pulled directly from Firebase dashboard
const firebaseConfig = {
    apiKey: "AIzaSyBIQXNubHlPeW7SglUAGBQOzaGw6vY-gOc",
    authDomain: "electrifyze-fce77.firebaseapp.com",
    databaseURL: "https://electrifyze-fce77.firebaseio.com",
    projectId: "electrifyze-fce77",
    storageBucket: "electrifyze-fce77.appspot.com",
    messagingSenderId: "138326632126",
    appId: "1:138326632126:web:d7d033bc7386377d81a0ef",
};

//Initialize firebase
initializeApp(firebaseConfig);
getAnalytics();

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;
