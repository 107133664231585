import React from "react";
import { connect } from "react-redux";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { StyledTaskButton } from "../Styled Components/Buttons";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
}));

function DefaultTask(props) {
    const classes = useStyles(props);

    const { organization, singleTask } = props;

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="column"
                style={{ height: "100%", textAlign: "center" }}
            >
                <Grid
                    item
                    xs
                    container
                    direction="column"
                    style={{ height: "100%", width: "100%" }}
                >
                    <Grid item xs>
                        <Typography variant="h3">{singleTask.title}</Typography>
                        <Typography variant="h5">
                            {singleTask.description}
                        </Typography>
                        <Typography variant="body1">
                            {singleTask.content}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    alignItems="center"
                    style={
                        organization.data && organization.data != null
                            ? {
                                  height: 100,
                                  backgroundColor:
                                      organization.data.backgroundColor,
                                  color: organization.data.fontColor,
                              }
                            : {
                                  height: 100,
                                  backgroundColor: "black",
                                  color: "white",
                              }
                    }
                >
                    <Grid item xs={2} container alignItems="center">
                        <Grid item xs>
                            <Typography
                                variant="h6"
                                style={{
                                    textTransform: "capitalize",
                                    marginLeft: 20,
                                }}
                            >
                                {singleTask.title}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={8} container justifyContent="center">
                        <StyledTaskButton
                            onClick={props.updateUser}
                            style={{ margin: 10 }}
                        >
                            Next Task
                        </StyledTaskButton>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                    ></Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        organization: state.content.organization,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTask);
