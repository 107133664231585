import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Actions
import {
    signInUser,
    getUserProfile,
    updateUserProfile,
} from "../actions/user_actions";
import {
    getSections,
    getCourseMetrics,
    getCourses,
    getCampaign,
} from "../actions/content_actions";
import { openAlert } from "../actions/alert_actions";

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import clsx from "clsx";

//Assets
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloudsOnly from "../static/images/clouds-only.png";
import CloudsBalloonsOnly from "../static/images/2021-independence-clouds-balloons.png";
import EarthBottomLandscape from "../static/images/earth-bottom-landscape.jpg";
import IndependenceBottomLandscape from "../components/la_county/CampaignComponents/2022-lac-independence/static/images/2022-lac-independence-landing-bottom-landscape.png";
import LACRideshareBottomLandscape from "../components/la_county/CampaignComponents/2022-lac-rideshare/static/images/2022-lac-rideshare-landing-bottom-landscape.png";
import EarthDayPostLoginTitleImage from "../static/images/earth-day-post-login-title.png";
import SampleCampaignPostLoginTitleImage from "../static/images/sample-campaign-post-login-title.png";
import RideshareTitleImage from "../static/images/2021-rideshare-title.png";
import RideshareBottomLandscape from "../static/images/2021-rideshare-bottom-landscape.jpg";
import CCCEEarthDayWelcomeTitleImage from "../components/3ce/CampaignComponents/2022-3ce-earthday/static/images/2022-3ce-earthday-welcome-title.png";
import LACEarthDayWelcomeTitleImage from "../components/la_county/CampaignComponents/2022-lac-earthday/static/images/2022-lac-earthday-welcome-title.png";
import LACIndependenceWelcomeTitleImage from "../components/la_county/CampaignComponents/2022-lac-independence/static/images/2022-lac-independence-welcome-title.png";
import CCCEIndependenceWelcomeTitleImage from "../components/3ce/CampaignComponents/2022-3ce-independence/static/images/2022-3ce-independence-welcome-title.png";
import LACRideshareWelcomeTitleImage from "../components/la_county/CampaignComponents/2022-lac-rideshare/static/images/2022-lac-rideshare-welcome-title.png";
import LACETCTrainingTitleImage from "../components/la_county/CampaignComponents/lac-etc-training-1-0/static/images/lac-etc-training-1-0-title.png";

//Components
import SectionItem from "../components/default/Sections/SectionItem";
import CourseMetricsPopper from "../components/default/Courses/CourseMetricsPopper";
import LearningProgressGrid from "../components/default/Sections/LearningProgressGrid";
import CampaignCompletionDialog_SampleCampaign from "../components/3ce/CampaignComponents/sample-campaign/CamaignCompletionDialog";
import CampaignCompletionDialog_2022_3ce_earthday from "../components/3ce/CampaignComponents/2022-3ce-earthday/CampaignCompletion/CamaignCompletionDialog";
import CampaignCompletionDialog_2022_lac_earthday from "../components/la_county/CampaignComponents/2022-lac-earthday/CampaignCompletion/CamaignCompletionDialog";
import CampaignCompletionDialog_2022_lac_independence from "../components/la_county/CampaignComponents/2022-lac-independence/CampaignCompletion/CamaignCompletionDialog";
import CampaignCompletionDialog_2022_3ce_independence from "../components/3ce/CampaignComponents/2022-3ce-independence/CampaignCompletion/CamaignCompletionDialog";
import CampaignCompletionDialog_2022_lac_rideshare from "../components/la_county/CampaignComponents/2022-lac-rideshare/CampaignCompletion/CamaignCompletionDialog";
import CampaignCompletionDialog_lac_etc_training_1_0 from "../components/la_county/CampaignComponents/lac-etc-training-1-0/CampaignCompletion/CamaignCompletionDialog";
import CampaignCompletionDialog_2023_lac_earthday from "../components/la_county/CampaignComponents/2023-lac-earthday/CampaignCompletion/CamaignCompletionDialog";
import CampaignCompletionDialog_2023_lac_rideshare from "../components/la_county/CampaignComponents/2023-lac-rideshare/CampaignCompletion/CamaignCompletionDialog";
import CampaignCompletionDialog_2024_lac_earthday from "../components/la_county/CampaignComponents/2024-lac-earthday/CampaignCompletion/CamaignCompletionDialog";
import CampaignCompletionDialog_2024_lac_rideshare from "../components/la_county/CampaignComponents/2024-lac-rideshare/CampaignCompletion/CamaignCompletionDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100%",
        //overflowY: 'auto'
    },
    main: {
        height: "auto",
        width: "100%",
        backgroundPosition: "center center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("xl")]: {
            //height: 500
        },
        [theme.breakpoints.down("lg")]: {
            //height: 350
        },
    },
    centeredContent: {
        width: "100%",
        maxWidth: 960,
        padding: 40,
        marginTop: 20,
        marginBottom: 40,
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        [theme.breakpoints.down("xl")]: {
            padding: 20,
            marginLeft: 10,
            marginRight: 10,
        },
    },
    blueBackground: {
        backgroundColor: "#6DCFF6",
    },
    form: {
        padding: 40,
        borderRadius: 10,
        backgroundColor: "white",
    },
    courseBox: {
        margin: 10,
    },
    titleBox: {
        height: 120,
        width: "100%",
        backgroundColor: "#4472c4",
    },
    titleText: {
        [theme.breakpoints.down("lg")]: {
            fontSize: "2.4rem",
        },
    },
    profileImg: {
        height: 100,
        width: 100,
    },
    pointsImg: {
        height: 80,
        width: 80,
        objectFit: "contain",
        color: "white",
    },
    breadcrumbBox: {
        height: 23,
        backgroundColor: "rgba(180, 199, 231, 1)",
        padding: 15,
        paddingLeft: 35,
    },
    link: {
        textDecoration: "none",
        color: "black",
    },
    titleContent: {
        backgroundImage: `url("${CloudsOnly}")`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        paddingTop: 40,
    },
    independenceTitleContent: {
        backgroundImage: `url("${CloudsBalloonsOnly}")`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        paddingTop: 40,
    },
    earthDayTitleBox: {
        maxWidth: 960,
        padding: 40,
        margin: "0 auto",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        [theme.breakpoints.down("xl")]: {
            padding: 20,
            marginLeft: 10,
            marginRight: 10,
        },
    },
    earthDayTitleImage: {
        maxWidth: "100%",
    },
    earthCityContent: {
        backgroundImage: `url("${EarthBottomLandscape}")`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        height: 600,
        [theme.breakpoints.down("xl")]: {
            height: 450,
        },
        [theme.breakpoints.down("lg")]: {
            height: 250,
        },
    },
    independenceCityContent: {
        backgroundImage: `url("${IndependenceBottomLandscape}")`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        height: 600,
        [theme.breakpoints.down("xl")]: {
            height: 450,
        },
        [theme.breakpoints.down("lg")]: {
            height: 250,
        },
    },
    LACRideshareBottomLandscapeContent: {
        backgroundImage: `url("${LACRideshareBottomLandscape}")`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        height: 600,
        [theme.breakpoints.down("xl")]: {
            height: 450,
        },
        [theme.breakpoints.down("lg")]: {
            height: 250,
        },
    },
    rideshareCityContent: {
        backgroundImage: `url("${RideshareBottomLandscape}")`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        height: 600,
        [theme.breakpoints.down("xl")]: {
            height: 450,
        },
        [theme.breakpoints.down("lg")]: {
            height: 250,
        },
    },
}));

function Sections(props) {
    const classes = useStyles();
    const { title } = props.location.state || 0;
    const { id } = props.match.params;
    const {
        sections,
        userProfile,
        tasks,
        courseMetrics,
        courses,
        campaign,
        organization,
    } = props;
    const campaignKey = undefined;

    const [courseInfo, setCourseInfo] = useState(false);
    const [showCampaignCompletionDialog, setShowCampaignCompletionDialog] =
        useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickAway = (event) => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (courses.data === null && userProfile.data !== null)
            props.getCourses(
                userProfile.data.currentOrganization ||
                    userProfile.data.organization.name ||
                    userProfile.data.organization
            );

        //if (sections.data === null)
        props.getSections(id);

        //console.log(userProfile.data)

        if (courseMetrics.data === null && userProfile.data !== null)
            props.getCourseMetrics(userProfile.data, id, props.user.data.uid);
    }, []);

    useEffect(() => {
        if (courses.data !== null)
            setCourseInfo(courses.data.filter((course) => course.id === id)[0]);
    }, [courses.data]);

    useEffect(() => {
        if (userProfile.data !== null) {
            props.getCourseMetrics(userProfile.data, id, props.user.data.uid);

            //Check if there are completed tasks
            let completedTasksString = null;
            try {
                completedTasksString =
                    window.localStorage.getItem("completedTasks");
            } catch (e) {
                console.log(e);
            }
            let completedTasks = JSON.parse(completedTasksString);
            //console.log("completed tasks are", completedTasksString, completedTasks)

            if (
                completedTasksString &&
                completedTasksString !== null &&
                completedTasksString !== "null"
            ) {
                //Get the task id and object associated with it
                let taskID = Object.keys(completedTasks)[0];
                let taskInfo = completedTasks[taskID];
                //console.log("The completed task is ", taskInfo)

                //Update the user profile
                const uid = props.user.data.uid;
                let newUserProfile = userProfile.data;
                newUserProfile.completed_tasks[taskID] = taskInfo;

                props.updateUserProfile(newUserProfile, uid);

                //console.log("The new profile is ", newUserProfile)

                //Reset local storage
                try {
                    window.localStorage.setItem("completedTasks", null);
                } catch (e) {
                    console.log(e);
                }
                // Alert the user
                //alert(`You completed a campaign! ${taskInfo.points} points have been added to your profile.`)
                props.openAlert({
                    text: `Congrats on completing your first task! ${taskInfo.points} points have been added to your profile.`,
                });
            }
        }
    }, [userProfile.data]);

    useEffect(() => {
        if (
            organization.data !== null &&
            props.organization.data.activeCampaign &&
            props.organization.data.activeCampaign.length !== 0
        )
            props.getCampaign(organization.data.activeCampaign);
    }, [organization.data]);

    useEffect(() => {
        const courseComplete =
            !courseMetrics.loading &&
            courseMetrics.data != null &&
            courseMetrics.data[id] &&
            courseMetrics.data[id].completedTasksPercentage === 100;
        if (courseComplete && userProfile.data != null) {
            // window.localStorage.setItem("sampleCampaignCompleted", "true")
            // BELOW is an example of how to send a message from an iFrame to a parent of the iFrame.
            // window.parent && window.parent.postMessage(
            //     {
            //         sender: "electrifyze-learning",
            //         message: "sampleCampaignCompleted"
            //     },
            //     "*"
            // );
            if (
                userProfile.data.currentCampaign === "sample-campaign" ||
                userProfile.data.currentCampaign === "2022-3ce-earthday" ||
                userProfile.data.currentCampaign === "2022-lac-earthday" ||
                userProfile.data.currentCampaign === "2022-lac-independence" ||
                userProfile.data.currentCampaign === "2022-3ce-independence" ||
                userProfile.data.currentCampaign === "2022-lac-rideshare" ||
                userProfile.data.currentCampaign === "2023-lac-earthday" ||
                userProfile.data.currentCampaign === "2023-lac-rideshare" ||
                userProfile.data.currentCampaign === "2024-lac-earthday" ||
                userProfile.data.currentCampaign === "2024-lac-rideshare" ||
                userProfile.data.currentCampaign === "lac-etc-training-1-0"
            ) {
                setShowCampaignCompletionDialog(true);
            }
        }
    }, [userProfile.data, courseMetrics]);

    return (
        <div className={classes.root}>
            {courseInfo &&
                id !== "vGQKSqxLDAFVIBckDz0m" && // 2024-lac-rideshare
                id !== "POK19FUOtIkmyom8wKxm" && // 2024-lac-earthday
                id !== "AiPIJP02tjNPBOoQliyr" && // 2023-lac-rideshare
                id !== "AS4SRYKfo2M0vaEsafRq" && // 2023-lac-earthday
                id !== "pjaCg8xiMHNxhmAsdjla" && // lac-etc-training-1-0
                id !== "W2Si2qJRCicGm4VuzXaX" && // 2022-lac-rideshare
                id !== "0DdJkIK073oVR6Y8iEN0" && // 2022-3ce-independence
                id !== "ZLlUj8l82hFEO4wHkvoN" && // 2022-lac-independence
                id !== "AFRX5MaCGKb4hpqlxH4G" && // LA County, 2021 Electric Cars for Earth
                id !== "LBAih7yvjrI6tkCRCERJ" && // 2022-lac-earthday
                id !== "SMjr20wuo516hlhvJvKv" && // 2022-3ce-earthday
                id !== "naDTJAHxaD6GdsEv9gaj" && // Electric Cars for Earth Demo, for lets.electrifyze.us/3ce
                id !== "BScQ6meMbXZlXkSALuOz" && // LA County, 2021 Independence from Fossil Fuels
                id !== "vBPrFdhWsSfA4JjhTvEw" && ( // 2021 Clean Air Rideshare Fair
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.titleBox}
                    >
                        <Grid
                            item
                            xs={1}
                            container
                            justifyContent="center"
                            alignItems="center"
                        ></Grid>
                        <Grid
                            item
                            xs={10}
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                            <div>
                                <Typography
                                    variant="h3"
                                    style={{ color: "white", fontWeight: 500 }}
                                >
                                    {courseInfo.title}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                    }}
                                >
                                    <i>
                                        {courseMetrics.data !== null &&
                                            courseMetrics.data[id] &&
                                            (courseMetrics.data[id]
                                                .timeCounter < 60
                                                ? courseMetrics.data[id]
                                                      .timeCounter + " mins"
                                                : Math.floor(
                                                      courseMetrics.data[id]
                                                          .timeCounter / 60
                                                  ) === 1
                                                ? Math.floor(
                                                      courseMetrics.data[id]
                                                          .timeCounter / 60
                                                  ) +
                                                  " hr and " +
                                                  (courseMetrics.data[id]
                                                      .timeCounter %
                                                      60) +
                                                  " mins"
                                                : Math.floor(
                                                      courseMetrics.data[id]
                                                          .timeCounter / 60
                                                  ) +
                                                  " hrs and " +
                                                  (courseMetrics.data[id]
                                                      .timeCounter %
                                                      60) +
                                                  " mins")}
                                    </i>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={1} container alignItems="center"></Grid>
                    </Grid>
                )}

            {id !== "AFRX5MaCGKb4hpqlxH4G" && // LA County, 2021 Electric Cars for Earth
                id !== "vGQKSqxLDAFVIBckDz0m" && // 2024-lac-rideshare
                id !== "POK19FUOtIkmyom8wKxm" && // 2024-lac-earthday
                id !== "AiPIJP02tjNPBOoQliyr" && // 2023-lac-rideshare
                id !== "AS4SRYKfo2M0vaEsafRq" && // 2023-lac-earthday
                id !== "pjaCg8xiMHNxhmAsdjla" && // lac-etc-training-1-0
                id !== "W2Si2qJRCicGm4VuzXaX" && // 2022-lac-rideshare
                id !== "0DdJkIK073oVR6Y8iEN0" && // 2022-3ce-independence
                id !== "ZLlUj8l82hFEO4wHkvoN" && // 2022-lac-independence
                id !== "LBAih7yvjrI6tkCRCERJ" && // 2022-lac-earthday
                id !== "SMjr20wuo516hlhvJvKv" && // 2022-3ce-earthday
                id !== "naDTJAHxaD6GdsEv9gaj" && // Electric Cars for Earth Demo, for lets.electrifyze.us/3ce
                id !== "BScQ6meMbXZlXkSALuOz" && // LA County, 2021 Independence from Fossil Fuels
                id !== "vBPrFdhWsSfA4JjhTvEw" && ( // 2021 Clean Air Rideshare Fair
                    <div className={classes.breadcrumbBox}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            {courseInfo.evLearningCourse && (
                                <Link
                                    to="/ev_learning"
                                    className={classes.link}
                                >
                                    Learn About EVs
                                </Link>
                            )}
                            {!courseInfo.evLearningCourse && (
                                <Link to="/contests" className={classes.link}>
                                    Contests
                                </Link>
                            )}
                            {!courseInfo.evLearningCourse && (
                                <Link
                                    to={`${campaignKey}/welcome`}
                                    style={{
                                        pointerEvents: !campaignKey && "none",
                                    }}
                                    className={classes.link}
                                >
                                    Campaign
                                </Link>
                            )}

                            <Typography color="textPrimary">
                                {courseInfo && courseInfo.title}
                            </Typography>
                        </Breadcrumbs>
                    </div>
                )}

            {id === "AFRX5MaCGKb4hpqlxH4G" ? ( // LA County, 2021 Electric Cars for Earth
                <div className={classes.titleContent}>
                    <div className={classes.earthDayTitleBox}>
                        <img
                            src={EarthDayPostLoginTitleImage}
                            className={classes.earthDayTitleImage}
                            alt="earth-day-title"
                        />
                    </div>
                </div>
            ) : id === "vBPrFdhWsSfA4JjhTvEw" ? ( // LA County 2021 Rideshare Fair
                <div className={classes.titleContent}>
                    <div className={classes.earthDayTitleBox}>
                        <img
                            src={RideshareTitleImage}
                            className={classes.earthDayTitleImage}
                            alt="earth-day-title"
                        />
                    </div>
                </div>
            ) : id === "naDTJAHxaD6GdsEv9gaj" ? ( // Electric Cars for Earth - Demo, for 3CE Sample
                <div className={classes.titleContent}>
                    <div className={classes.earthDayTitleBox}>
                        <img
                            src={SampleCampaignPostLoginTitleImage}
                            className={classes.earthDayTitleImage}
                            alt="earth-day-title"
                        />
                    </div>
                </div>
            ) : id === "SMjr20wuo516hlhvJvKv" ? ( // 2022-3ce-earthday
                <div className={classes.titleContent}>
                    <div className={classes.earthDayTitleBox}>
                        <img
                            src={CCCEEarthDayWelcomeTitleImage}
                            className={classes.earthDayTitleImage}
                            alt="earth-day-title"
                        />
                    </div>
                </div>
            ) : id === "LBAih7yvjrI6tkCRCERJ" ? ( // 2022-lac-earthday
                <div className={classes.titleContent}>
                    <div className={classes.earthDayTitleBox}>
                        <img
                            src={LACEarthDayWelcomeTitleImage}
                            className={classes.earthDayTitleImage}
                            alt="earth-day-title"
                        />
                    </div>
                </div>
            ) : id === "ZLlUj8l82hFEO4wHkvoN" ? ( // 2022-lac-independence
                <div className={classes.titleContent}>
                    <div className={classes.earthDayTitleBox}>
                        <img
                            src={LACIndependenceWelcomeTitleImage}
                            className={classes.earthDayTitleImage}
                            alt="independence-title"
                        />
                    </div>
                </div>
            ) : id === "0DdJkIK073oVR6Y8iEN0" ? ( // 2022-3ce-independence
                <div className={classes.titleContent}>
                    <div className={classes.earthDayTitleBox}>
                        <img
                            src={CCCEIndependenceWelcomeTitleImage}
                            className={classes.earthDayTitleImage}
                            alt="independence-title"
                        />
                    </div>
                </div>
            ) : id === "W2Si2qJRCicGm4VuzXaX" ? ( // 2022-lac-rideshare
                <div className={classes.titleContent}>
                    <div className={classes.earthDayTitleBox}>
                        <img
                            src={LACRideshareWelcomeTitleImage}
                            className={classes.earthDayTitleImage}
                            alt="independence-title"
                        />
                    </div>
                </div>
            ) : id === "pjaCg8xiMHNxhmAsdjla" ? ( // lac-etc-training-1-0
                <div className={classes.titleContent}>
                    <div className={classes.earthDayTitleBox}>
                        <img
                            src={LACETCTrainingTitleImage}
                            className={classes.earthDayTitleImage}
                            alt="independence-title"
                        />
                    </div>
                </div>
            ) : id === "AS4SRYKfo2M0vaEsafRq" ? ( // 2023-lac-earthday
                <div className={classes.titleContent}>
                    <div className={classes.earthDayTitleBox}>
                        <img
                            src={LACEarthDayWelcomeTitleImage}
                            className={classes.earthDayTitleImage}
                            alt="earth-day-title"
                        />
                    </div>
                </div>
            ) : id === "AiPIJP02tjNPBOoQliyr" ? ( // 2023-lac-rideshare
                <div className={classes.titleContent}>
                    <div className={classes.earthDayTitleBox}>
                        <img
                            src={LACRideshareWelcomeTitleImage}
                            className={classes.earthDayTitleImage}
                            alt="independence-title"
                        />
                    </div>
                </div>
            ) : id === "POK19FUOtIkmyom8wKxm" ? ( // 2024-lac-earthday
                <div className={classes.titleContent}>
                    <div className={classes.earthDayTitleBox}>
                        <img
                            src={LACEarthDayWelcomeTitleImage}
                            className={classes.earthDayTitleImage}
                            alt="earth-day-title"
                        />
                    </div>
                </div>
            ) : id === "vGQKSqxLDAFVIBckDz0m" ? ( // 2024-lac-rideshare
                <div className={classes.titleContent}>
                    <div className={classes.earthDayTitleBox}>
                        <img
                            src={LACRideshareWelcomeTitleImage}
                            className={classes.earthDayTitleImage}
                            alt="independence-title"
                        />
                    </div>
                </div>
            ) : (
                id === "BScQ6meMbXZlXkSALuOz" && ( // LA County, 2021 Independence from Fossil Fuels
                    <div className={classes.independenceTitleContent}>
                        <div className={classes.earthDayTitleBox}>
                            {/* <img src={EarthDayPostLoginTitleImage} className={classes.earthDayTitleImage} alt="earth-day-title" /> */}
                            <Typography
                                variant="h1"
                                align="center"
                                className={classes.titleText}
                            >
                                <b>{courseInfo.title}</b>
                            </Typography>
                        </div>
                    </div>
                )
            )}

            <div
                className={clsx(classes.main, {
                    [classes.blueBackground]:
                        id === "AFRX5MaCGKb4hpqlxH4G" || // LA County, 2021 Electric Cars for Earth
                        id === "vGQKSqxLDAFVIBckDz0m" || // 2024-lac-rideshare
                        id === "POK19FUOtIkmyom8wKxm" || // 2024-lac-earthday
                        id === "AiPIJP02tjNPBOoQliyr" || // 2023-lac-rideshare
                        id === "AS4SRYKfo2M0vaEsafRq" || // 2023-lac-earthday
                        id === "pjaCg8xiMHNxhmAsdjla" || // lac-etc-training-1-0
                        id === "W2Si2qJRCicGm4VuzXaX" || // 2022-lac-rideshare
                        id === "0DdJkIK073oVR6Y8iEN0" || // 2022-3ce-independence
                        id === "ZLlUj8l82hFEO4wHkvoN" || // 2022-lac-independence
                        id === "LBAih7yvjrI6tkCRCERJ" || // 2022-lac-earthday
                        id === "SMjr20wuo516hlhvJvKv" || // 2022-3ce-earthday
                        id === "naDTJAHxaD6GdsEv9gaj" || // Electric Cars for Earth Demo, for lets.electrifyze.us/3ce
                        id === "BScQ6meMbXZlXkSALuOz" || // LA County, 2021 Independence from Fossil Fuels
                        id === "vBPrFdhWsSfA4JjhTvEw", // 2021 Clean Air Rideshare Fair
                })}
            >
                <div className={classes.centeredContent}>
                    <div style={{ marginBottom: 30 }}>
                        {courseMetrics.loading ? (
                            <Grid
                                container
                                alignItems="center"
                                style={{ height: 100, marginLeft: 15 }}
                            >
                                <CircularProgress />
                            </Grid>
                        ) : courseMetrics.data !== null &&
                          courseMetrics.data[id] ? (
                            <LearningProgressGrid
                                courseID={id}
                                courseMetrics={courseMetrics.data[id]}
                                handleClick={handleClick} // No longer seems to be used
                                handleClickAway={handleClickAway} // No longer seems to be used
                                courseInfo={courseInfo}
                            />
                        ) : null}
                        <Typography variant="h6" style={{ fontWeight: 500 }}>
                            Module Description
                        </Typography>
                        <Typography variant="h6" style={{ fontWeight: 300 }}>
                            {courseInfo && courseInfo.description}
                        </Typography>

                        <CourseMetricsPopper open={open} anchorEl={anchorEl} />
                    </div>
                    {props.sections.loading ? (
                        <CircularProgress />
                    ) : props.sections.data !== null ? (
                        Object.keys(props.sections.data).map((key, index) => {
                            let section = props.sections.data[key];
                            return (
                                <SectionItem
                                    section={section}
                                    course={{ id: id, title: title }}
                                    key={index}
                                    index={index}
                                />
                            );
                        })
                    ) : null}
                </div>
            </div>

            {id === "AFRX5MaCGKb4hpqlxH4G" && ( // LA County, 2021 Electric Cars for Earth
                <div className={classes.earthCityContent} />
            )}
            {id === "vGQKSqxLDAFVIBckDz0m" && ( // 2024-lac-rideshare
                <div className={classes.LACRideshareBottomLandscapeContent} />
            )}
            {id === "POK19FUOtIkmyom8wKxm" && ( // 2024-lac-earthday
                <div className={classes.earthCityContent} />
            )}
            {id === "AiPIJP02tjNPBOoQliyr" && ( // 2023-lac-rideshare
                <div className={classes.LACRideshareBottomLandscapeContent} />
            )}
            {id === "AS4SRYKfo2M0vaEsafRq" && ( // 2023-lac-earthday
                <div className={classes.earthCityContent} />
            )}
            {id === "pjaCg8xiMHNxhmAsdjla" && ( // lac-etc-training-1-0
                <div className={classes.LACRideshareBottomLandscapeContent} />
            )}
            {id === "W2Si2qJRCicGm4VuzXaX" && ( // 2022-lac-rideshare
                <div className={classes.LACRideshareBottomLandscapeContent} />
            )}
            {id === "0DdJkIK073oVR6Y8iEN0" && ( // 2022-3ce-independence
                <div className={classes.independenceCityContent} />
            )}
            {id === "ZLlUj8l82hFEO4wHkvoN" && ( // 2022-lac-independence
                <div className={classes.independenceCityContent} />
            )}
            {id === "LBAih7yvjrI6tkCRCERJ" && ( // 2022-lac-earthday
                <div className={classes.earthCityContent} />
            )}
            {id === "SMjr20wuo516hlhvJvKv" && ( // 2022-3ce-earthday
                <div className={classes.earthCityContent} />
            )}
            {id === "naDTJAHxaD6GdsEv9gaj" && ( // Electric Cars for Earth Demo, for lets.electrifyze.us/3ce
                <div className={classes.earthCityContent} />
            )}
            {id === "BScQ6meMbXZlXkSALuOz" && ( // LA County, 2021 Independence from Fossil Fuels
                <div className={classes.independenceCityContent} />
            )}
            {id === "vBPrFdhWsSfA4JjhTvEw" && ( // 2021 Clean Air Rideshare Fair
                <div className={classes.rideshareCityContent} />
            )}

            {/* BEGIN - CAMPAIGN COMPLETION DIALOG */}
            {showCampaignCompletionDialog &&
                id === "naDTJAHxaD6GdsEv9gaj" && ( // Electric Cars for Earth - Demo, for 3CE Sample
                    <CampaignCompletionDialog_SampleCampaign
                        open={showCampaignCompletionDialog}
                        toggleDialog={() =>
                            setShowCampaignCompletionDialog(
                                !showCampaignCompletionDialog
                            )
                        }
                    />
                )}

            {showCampaignCompletionDialog &&
                id === "vGQKSqxLDAFVIBckDz0m" && ( // 2024-lac-rideshare
                    <CampaignCompletionDialog_2024_lac_rideshare
                        open={showCampaignCompletionDialog}
                        toggleDialog={() =>
                            setShowCampaignCompletionDialog(
                                !showCampaignCompletionDialog
                            )
                        }
                    />
                )}

            {showCampaignCompletionDialog &&
                id === "POK19FUOtIkmyom8wKxm" && ( // 2024-lac-earthday
                    <CampaignCompletionDialog_2024_lac_earthday
                        open={showCampaignCompletionDialog}
                        toggleDialog={() =>
                            setShowCampaignCompletionDialog(
                                !showCampaignCompletionDialog
                            )
                        }
                    />
                )}

            {showCampaignCompletionDialog &&
                id === "AiPIJP02tjNPBOoQliyr" && ( // 2023-lac-rideshare
                    <CampaignCompletionDialog_2023_lac_rideshare
                        open={showCampaignCompletionDialog}
                        toggleDialog={() =>
                            setShowCampaignCompletionDialog(
                                !showCampaignCompletionDialog
                            )
                        }
                    />
                )}

            {showCampaignCompletionDialog &&
                id === "AS4SRYKfo2M0vaEsafRq" && ( // 2023-lac-earthday
                    <CampaignCompletionDialog_2023_lac_earthday
                        open={showCampaignCompletionDialog}
                        toggleDialog={() =>
                            setShowCampaignCompletionDialog(
                                !showCampaignCompletionDialog
                            )
                        }
                    />
                )}

            {showCampaignCompletionDialog &&
                id === "pjaCg8xiMHNxhmAsdjla" && ( // lac-etc-training-1-0
                    <CampaignCompletionDialog_lac_etc_training_1_0
                        open={showCampaignCompletionDialog}
                        toggleDialog={() =>
                            setShowCampaignCompletionDialog(
                                !showCampaignCompletionDialog
                            )
                        }
                    />
                )}
            {showCampaignCompletionDialog &&
                id === "W2Si2qJRCicGm4VuzXaX" && ( // 2022-lac-rideshare
                    <CampaignCompletionDialog_2022_lac_rideshare
                        open={showCampaignCompletionDialog}
                        toggleDialog={() =>
                            setShowCampaignCompletionDialog(
                                !showCampaignCompletionDialog
                            )
                        }
                    />
                )}
            {showCampaignCompletionDialog &&
                id === "0DdJkIK073oVR6Y8iEN0" && ( // 2022-3ce-independence
                    <CampaignCompletionDialog_2022_3ce_independence
                        open={showCampaignCompletionDialog}
                        toggleDialog={() =>
                            setShowCampaignCompletionDialog(
                                !showCampaignCompletionDialog
                            )
                        }
                    />
                )}
            {showCampaignCompletionDialog &&
                id === "ZLlUj8l82hFEO4wHkvoN" && ( // 2022-lac-independence
                    <CampaignCompletionDialog_2022_lac_independence
                        open={showCampaignCompletionDialog}
                        toggleDialog={() =>
                            setShowCampaignCompletionDialog(
                                !showCampaignCompletionDialog
                            )
                        }
                    />
                )}
            {showCampaignCompletionDialog &&
                id === "SMjr20wuo516hlhvJvKv" && ( // 2022-3ce-earthday
                    <CampaignCompletionDialog_2022_3ce_earthday
                        open={showCampaignCompletionDialog}
                        toggleDialog={() =>
                            setShowCampaignCompletionDialog(
                                !showCampaignCompletionDialog
                            )
                        }
                    />
                )}
            {showCampaignCompletionDialog &&
                id === "LBAih7yvjrI6tkCRCERJ" && ( // 2022-lac-earthday
                    <CampaignCompletionDialog_2022_lac_earthday
                        open={showCampaignCompletionDialog}
                        toggleDialog={() =>
                            setShowCampaignCompletionDialog(
                                !showCampaignCompletionDialog
                            )
                        }
                    />
                )}

            {/* END - CAMPAIGN COMPLETION DIALOG */}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        sections: state.content.sections,
        sub_sections: state.content.sub_sections,
        tasks: state.content.tasks,
        courseMetrics: state.content.courseMetrics,
        courses: state.content.courses,
        campaign: state.content.campaign,
        organization: state.content.organization,
    };
};

const mapDispatchToProps = {
    signInUser,
    getUserProfile,
    getSections,
    getCourses,
    getCourseMetrics,
    getCampaign,
    updateUserProfile,
    openAlert,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Sections)
);
