import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Styles
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";

// Components
import { CustomDialogTitle } from "../Styled Components/DialogTitle";
import Coaching from "../../../pages/Coaching";
import AdminSnackbar from "./AdminSnackbar";
import AssignTaskToUser from "./AssignTaskToUser";

const useStyles = makeStyles((theme) => ({}));

function ActivityHubAdminDialog(props) {
    //Pass the color from the parent component to the styles
    const classes = useStyles();

    const { open, handleClose, selectedUserProfile } = props;

    return (
        <Dialog open={open} fullScreen>
            <CustomDialogTitle onClose={handleClose} dark>
                Selected User:&nbsp; <b>{selectedUserProfile.data.email}</b>
            </CustomDialogTitle>

            <Coaching />

            <AdminSnackbar
                component={
                    <div style={{ minWidth: 960 }}>
                        <AssignTaskToUser />
                    </div>
                }
            />
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    selectedUserProfile: state.admin.selectedUserProfile,
});

const mapDispatchToProps = {};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ActivityHubAdminDialog)
);
