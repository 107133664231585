import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

// Styles
import { makeStyles } from "@mui/styles";
import { Dialog, Fab, Typography } from "@mui/material";

// Assets
import { Launch } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: "fixed",
        backgroundColor: "white",
        bottom: 15,
        right: 20,
        zIndex: 10000,
    },
    icon: {
        fontSize: "1.4rem",
        marginRight: 10,
    },
    text: {
        textTransform: "none",
        fontWeight: 600,
    },
    dialog: {
        padding: 15,
    },
    popover: {
        padding: 15,
        transform: "translate(0px, -10px) !important",
    },
}));

function AdminSnackbar(props) {
    const { component } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <React.Fragment>
            <Fab
                variant="extended"
                onClick={handleClick}
                classes={{ root: classes.fab }}
            >
                <Launch className={classes.icon} />
                <Typography variant="body1" className={classes.text}>
                    Admin
                </Typography>
            </Fab>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                classes={{ paper: classes.dialog }}
            >
                {component}
            </Dialog>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminSnackbar)
);
