import React from "react";
import { connect } from "react-redux";

//Styles
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";

// Components
import { CustomDialogTitle } from "../../default/Styled Components/DialogTitle";
import Refer from "../Refer";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        [theme.breakpoints.down('lg')]: {
            margin: 16,
        },
    },
    dialogContent: {
        paddingLeft: 30,
        paddingRight: 30,
        overflowY: "auto",
        msOverflowStyle: "scrollbar",
        [theme.breakpoints.down('xl')]: {
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
}));

function ReferDialog(props) {
    //Pass the color from the parent component to the styles
    const classes = useStyles();

    const { visible, handleClose, userProfile } = props;

    return (
        <Dialog
            onClose={handleClose}
            open={visible}
            maxWidth="md"
            classes={{ paper: classes.dialogPaper }}
        >
            <CustomDialogTitle onClose={handleClose} />
            <div className={classes.dialogContent}>
                <Refer
                    email={userProfile.data != null && userProfile.data.email}
                />
            </div>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferDialog);
