export const UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_BEGIN =
    "UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_BEGIN";
export const UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_ERROR =
    "UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_ERROR";
export const UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_SUCCESS =
    "UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_SUCCESS";

export const UPDATE_SEND_GRID_CONTACT_BEGIN = "UPDATE_SEND_GRID_CONTACT_BEGIN";
export const UPDATE_SEND_GRID_CONTACT_ERROR = "UPDATE_SEND_GRID_CONTACT_ERROR";
export const UPDATE_SEND_GRID_CONTACT_SUCCESS =
    "UPDATE_SEND_GRID_CONTACT_SUCCESS";
