import {
    GET_USER_BEGIN,
    GET_USER_ERROR,
    GET_USER_SUCCESS,
    CREATE_USER_BEGIN,
    CREATE_USER_ERROR,
    CREATE_USER_SUCCESS,
    CREATE_USER_PROFILE_BEGIN,
    CREATE_USER_PROFILE_ERROR,
    CREATE_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_BEGIN,
    GET_USER_PROFILE_ERROR,
    GET_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_BEGIN,
    UPDATE_USER_PROFILE_ERROR,
    UPDATE_USER_PROFILE_SUCCESS,
    LOGOUT_BEGIN,
    LOGOUT_ERROR,
    LOGOUT_SUCCESS,
    SET_USER,
    SEND_PASSWORD_RESET_EMAIL_BEGIN,
    SEND_PASSWORD_RESET_EMAIL_SUCCESS,
    SEND_PASSWORD_RESET_EMAIL_ERROR,
    VERIFY_PASSWORD_RESET_BEGIN,
    VERIFY_PASSWORD_RESET_SUCCESS,
    VERIFY_PASSWORD_RESET_ERROR,
    RESET_PASSWORD_BEGIN,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    ADD_SENDGRID_CONTACT_BEGIN,
    ADD_SENDGRID_CONTACT_ERROR,
    ADD_SENDGRID_CONTACT_SUCCESS,
    UPDATE_REFERRALS_BEGIN,
    UPDATE_REFERRALS_SUCCESS,
    UPDATE_REFERRALS_ERROR,
} from "../constants/action-types-user";

// Firebase
import { getFirestore, setDoc, getDoc, updateDoc, doc } from "firebase/firestore"
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";

// LogRocket
// import LogRocket from "logrocket";

// Helpers
import moment from "moment";

// Get Firestore reference
const db = getFirestore();
const auth = getAuth();

export const createUserBegin = () => ({ type: CREATE_USER_BEGIN });
export const createUserSuccess = (result) => ({
    type: CREATE_USER_SUCCESS,
    payload: result,
});
export const createUserError = (error) => ({
    type: CREATE_USER_ERROR,
    payload: error,
});

export function createUser(params) {
    return (dispatch) => {
        dispatch(createUserBegin());
        return createUserWithEmailAndPassword(auth, params.email, params.password)
            .then((response) => {
                console.log("The user response is", response);
                dispatch(createUserSuccess(response.user));
            })
            .catch((error) => {
                // LogRocket.captureException(error);
                dispatch(createUserError(error));
            });
    };
}

export const createUserProfileBegin = () => ({
    type: CREATE_USER_PROFILE_BEGIN,
});
export const createUserProfileSuccess = (result) => ({
    type: CREATE_USER_PROFILE_SUCCESS,
    payload: result,
});
export const createUserProfileError = (error) => ({
    type: CREATE_USER_PROFILE_ERROR,
    payload: error,
});

export const createUserProfile = (id, profile) => async (dispatch) => {
    dispatch(createUserProfileBegin());
    try {
        const document = await setDoc(doc(db, "users", id), profile)
        console.log("Document successfully written!", document);
        dispatch(createUserProfileSuccess(profile));
    } catch(error) {
        console.error("Error creating document: ", error);
        // LogRocket.captureException(error);
        dispatch(createUserProfileError(error));
    }
}

export const signInUserBegin = () => ({ type: GET_USER_BEGIN });
export const signInUserSuccess = (result) => ({
    type: GET_USER_SUCCESS,
    payload: result,
});
export const signInUserError = (error) => ({
    type: GET_USER_ERROR,
    payload: error,
});

export function signInUser(params) {
    return (dispatch) => {
        dispatch(signInUserBegin());
        return signInWithEmailAndPassword(auth, params.email, params.password)
            .then((response) => {
                dispatch(getUserProfile(response.user.uid, true));
                dispatch(signInUserSuccess(response.user));
            })
            .catch((error) => {
                // LogRocket.captureException(error);
                dispatch(signInUserError(error));
            });
    };
}

export const getUserProfileBegin = () => {
    return { type: GET_USER_PROFILE_BEGIN };
};
export const getUserProfileSuccess = (result) => ({
    type: GET_USER_PROFILE_SUCCESS,
    payload: result,
});
export const getUserProfileError = (error) => ({
    type: GET_USER_PROFILE_ERROR,
    payload: error,
});

export const getUserProfile = (id, updateLastLogin = false) => async (dispatch) => {
    dispatch(getUserProfileBegin());
    try {
        const document = await getDoc(doc(db, "users", id))
        if (document.exists) {
            let userProfile = document.data();
            console.log("Document data:", userProfile);
            dispatch(getUserProfileSuccess(userProfile));

            // if (updateLastLogin) {
            //     userProfile.last_login = new Date();
            //     dispatch(updateUserProfile(userProfile, id));
            // }
        } else {
            // document.data() will be undefined in this case
            console.log("No such document!");
            dispatch(getUserProfileError("No such document!"));
        }
    } catch(error) {
        console.log("Error getting document:", error);
        // LogRocket.captureException(error);
        dispatch(getUserProfileError(error));
    }
}

export const updateUserProfileBegin = () => ({
    type: UPDATE_USER_PROFILE_BEGIN,
});
export const updateUserProfileSuccess = (result) => ({
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: result,
});
export const updateUserProfileError = (error) => ({
    type: UPDATE_USER_PROFILE_ERROR,
    payload: error,
});

export const updateUserProfile = (userProfile, id) => async (dispatch) => {
    //dispatch(updateUserProfileBegin());
    try {
        const document = await setDoc(doc(db, "users", id), userProfile)
        console.log("Document successfully written!", userProfile);
        dispatch(updateUserProfileSuccess(userProfile));
    } catch(error) {
        console.error("Error writing document: ", error);
        // LogRocket.captureException(error);
        dispatch(updateUserProfileError(error));
    }
}

export const updateUserProfileField = (id, fieldKeyValueData) => async (dispatch) => {
    try {
        const document = await updateDoc(doc(db, "users", id), fieldKeyValueData)
        console.log("Document successfully written!", document.data());
    } catch(error) {
        console.error("Error writing document: ", error);
        // LogRocket.captureException(error);
    }
}

export function checkAuthentication() {
    return (dispatch) => {
        dispatch(signInUserBegin());
        return onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in.
                var uid = user.uid;
                console.log("UID is: " + uid);
                dispatch(getUserProfile(uid));
                dispatch(signInUserSuccess(user));
            } else {
                // User is signed out.
                dispatch(
                    signInUserError(
                        "You must login to the platform before accessing this page."
                    )
                );
            }
        });
    };
}

export const logoutBegin = () => ({ type: LOGOUT_BEGIN });
export const logoutSuccess = (result) => ({
    type: LOGOUT_SUCCESS,
    payload: result,
});
export const logoutError = (error) => ({ type: LOGOUT_ERROR, payload: error });

export function logout() {
    return (dispatch) => {
        dispatch(logoutBegin());
        return signOut(auth)
            .then(function (success) {
                console.log("Successful logout!");
                dispatch(logoutSuccess(success));
            })
            .catch(function (error) {
                console.error("Error logging out: ", error);
                // LogRocket.captureException(error);
                dispatch(updateUserProfileError(error));
            });
    };
}

export const sendPasswordResetEmailBegin = () => ({
    type: SEND_PASSWORD_RESET_EMAIL_BEGIN,
});
export const sendPasswordResetEmailSuccess = (result) => ({
    type: SEND_PASSWORD_RESET_EMAIL_SUCCESS,
    payload: result,
});
export const sendPasswordResetEmailError = (error) => ({
    type: SEND_PASSWORD_RESET_EMAIL_ERROR,
    payload: error,
});

export function sendPasswordResetEmail(data) {
    return (dispatch) => {
        dispatch(sendPasswordResetEmailBegin());
        return fetch(
            "https://us-central1-electrifyze-fce77.cloudfunctions.net/sendPasswordResetEmail",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((data) => {
                console.log("Password reset email has been sent!");
                dispatch(sendPasswordResetEmailSuccess(data));
            })
            .catch((error) => {
                console.error("Error send password reset email: ", error);
                dispatch(sendPasswordResetEmailError(error));
            });
    };
}

export const verifyPasswordResetBegin = () => ({
    type: VERIFY_PASSWORD_RESET_BEGIN,
});
export const verifyPasswordResetSuccess = (result) => ({
    type: VERIFY_PASSWORD_RESET_SUCCESS,
    payload: result,
});
export const verifyPasswordResetError = (error) => ({
    type: VERIFY_PASSWORD_RESET_ERROR,
    payload: error,
});

export const verifyPasswordReset = (id) => async (dispatch) => {
    dispatch(verifyPasswordResetBegin());
    try {
        const document = await getDoc(doc(db, "password_reset_verification", id))
        if (document.exists) {
            const data = document.data();
            console.log("Document data:", data);
            if (moment().isBefore(data.expiration))
                dispatch(verifyPasswordResetSuccess(data));
            else
                dispatch(verifyPasswordResetError("Link has expired."));
        } else {
            // document.data() will be undefined in this case
            console.log("No such document!");
            dispatch(verifyPasswordResetError("Invalid reset code."));
        }
    } catch(error) {
        console.log("Error getting document:", error);
        dispatch(verifyPasswordResetError("Invalid reset code."));
    }
}

export const resetPasswordBegin = () => ({ type: RESET_PASSWORD_BEGIN });
export const resetPasswordSuccess = (result) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: result,
});
export const resetPasswordError = (error) => ({
    type: RESET_PASSWORD_ERROR,
    payload: error,
});

export function resetPassword(data) {
    return (dispatch) => {
        dispatch(resetPasswordBegin());
        return fetch(
            "https://us-central1-electrifyze-fce77.cloudfunctions.net/updateUserPassword",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((data) => {
                console.log("Password has been reset!");
                dispatch(resetPasswordSuccess(data));
            })
            .catch((error) => {
                console.error("Error resetting password: ", error);
                dispatch(resetPasswordError(error));
            });
    };
}

export const addSendGridContactBegin = () => ({
    type: ADD_SENDGRID_CONTACT_BEGIN,
});
export const addSendGridContactSuccess = (result) => ({
    type: ADD_SENDGRID_CONTACT_SUCCESS,
    payload: result,
});
export const addSendGridContactError = (error) => ({
    type: ADD_SENDGRID_CONTACT_ERROR,
    payload: error,
});

export function addSendGridContact(data) {
    return (dispatch) => {
        dispatch(addSendGridContactBegin());
        return fetch(
            "https://us-central1-electrifyze-fce77.cloudfunctions.net/updateLACountyContacts",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                dispatch(addSendGridContactSuccess(data));
            })
            .catch((error) => {
                console.log("Error adding contact to SendGrid.");
                dispatch(addSendGridContactError(error));
            });
    };
}

export const updateReferralsBegin = () => ({ type: UPDATE_REFERRALS_BEGIN });
export const updateReferralsSuccess = (result) => ({
    type: UPDATE_REFERRALS_SUCCESS,
    payload: result,
});
export const updateReferralsError = (error) => ({
    type: UPDATE_REFERRALS_ERROR,
    payload: error,
});

export function updateReferrals() {
    return (dispatch) => {
        dispatch(updateReferralsBegin());
        return fetch(
            "https://us-central1-electrifyze-fce77.cloudfunctions.net/updateReferralsCollection",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                dispatch(updateReferralsSuccess(data));
            })
            .catch((error) => {
                console.log("Error updating referrals.");
                dispatch(updateReferralsError(error));
            });
    };
}

export const setUser = (result) => ({ type: SET_USER, payload: result });
