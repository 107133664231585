import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Actions
import { getAllTasks } from "../../../actions/content_actions";
import { updateUserProfileField } from "../../../actions/user_actions";
import { setSelectedUserProfile } from "../../../actions/admin_actions";

//Styles
import { makeStyles } from "@mui/styles";
import { Button, Grid, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";

const useStyles = makeStyles((theme) => ({}));

function AssignTaskToUser(props) {
    const classes = useStyles();
    const { allTasks, selectedUserProfile } = props;

    const [selectedTask, setSelectedTask] = useState();

    useEffect(() => {
        props.getAllTasks();
    }, []);

    const handleAssignClick = () => {
        let assignments = selectedUserProfile.data.assignments || [];
        assignments.push(selectedTask.id);

        const data = {
            assignments: assignments,
        };

        props.updateUserProfileField(selectedUserProfile.data.id, data);
        props.setSelectedUserProfile({
            ...selectedUserProfile.data,
            assignments,
        });
    };

    return (
        <Grid container align="center" spacing={2}>
            <Grid item xs>
                <Autocomplete
                    id="combo-box-demo"
                    options={
                        allTasks.data != null
                            ? allTasks.data.filter(
                                  (task) =>
                                      task.type !== "subsection" &&
                                      task.type !== "section" &&
                                      task.type !== "course"
                              )
                            : []
                    }
                    getOptionLabel={(option) =>
                        `${option.id} ${option.type} ${option.cmsTitle}`
                    }
                    renderOption={(option) => {
                        return (
                            <Grid container>
                                <Grid item xs={4}>
                                    {option.id}
                                    <br />
                                    {option.type && option.type.toUpperCase()}
                                </Grid>
                                <Grid item xs>
                                    {option.cmsTitle}
                                    <br />
                                    Status:{" "}
                                    {selectedUserProfile.data.completed_tasks &&
                                    selectedUserProfile.data.completed_tasks[
                                        option.id
                                    ]
                                        ? "Complete"
                                        : "Incomplete"}
                                </Grid>
                            </Grid>
                        );
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Task ID"
                            variant="outlined"
                            error={
                                selectedUserProfile.data != null &&
                                selectedUserProfile.data.assignments &&
                                selectedTask &&
                                (selectedUserProfile.data.assignments.length ===
                                    10 ||
                                    selectedUserProfile.data.assignments.includes(
                                        selectedTask.id
                                    ))
                            }
                            helperText={
                                selectedUserProfile.data != null &&
                                selectedTask &&
                                selectedUserProfile.data.assignments &&
                                selectedUserProfile.data.assignments.includes(
                                    selectedTask.id
                                )
                                    ? "This task has been assigned to the user."
                                    : selectedUserProfile.data != null &&
                                      selectedUserProfile.data.assignments &&
                                      selectedUserProfile.data.assignments
                                          .length === 10 &&
                                      "Maximum of 10 assignments are allowed at this time."
                            }
                        />
                    )}
                    disabled={selectedUserProfile.data == null}
                    onChange={(event, newValue) => {
                        setSelectedTask(newValue);
                    }}
                />
            </Grid>
            <Grid item>
                <Button
                    onClick={handleAssignClick}
                    style={{ marginTop: 10 }}
                    disabled={
                        selectedUserProfile.data == null ||
                        !selectedTask ||
                        (selectedUserProfile.data != null &&
                            selectedTask &&
                            selectedUserProfile.data.assignments &&
                            (selectedUserProfile.data.assignments.length ===
                                10 ||
                                selectedUserProfile.data.assignments.includes(
                                    selectedTask.id
                                )))
                    }
                    variant="contained"
                    color="primary"
                >
                    Assign Task
                </Button>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    allTasks: state.content.allTasks,
    selectedUserProfile: state.admin.selectedUserProfile,
});

const mapDispatchToProps = {
    getAllTasks,
    updateUserProfileField,
    setSelectedUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignTaskToUser);
