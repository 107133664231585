import React, { useState } from "react";
import { connect } from "react-redux";

//Native Forms
import NativeForms from "native-forms-react";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { StyledTaskButton } from "../Styled Components/Buttons";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    taskTitle: {
        textTransform: "capitalize",
        marginLeft: 20,
        textAlign: "left",
    },
    rightGridItem: {
        [theme.breakpoints.down('xl')]: {
            display: "none",
        },
    },
}));

function Survey(props) {
    const classes = useStyles(props);

    const { userProfile, organization, singleTask, isAssignment } = props;
    const [formData, setFormData] = useState({
        completed: false,
        response: undefined,
    });

    const getParams = () => {
        //console.log(userProfile.data.surveys)

        if (
            userProfile.data.surveys &&
            userProfile.data.surveys[singleTask.id]
        ) {
            let params = new URLSearchParams(
                userProfile.data.surveys[singleTask.id]
            ).toString();
            params = params.replace(/\+/g, "%20");
            //console.log("params", params)
            return params;
        } else if (
            singleTask.autofillFields &&
            singleTask.autofillFields.length > 0
        ) {
            let params = "";

            for (let i in singleTask.autofillFields) {
                if (singleTask.autofillFields[i].type === "name")
                    params = params.concat(
                        `&${singleTask.autofillFields[i].fieldName}=${
                            userProfile.data.first_name +
                            " " +
                            userProfile.data.last_name
                        }`
                    );
                else if (singleTask.autofillFields[i].type === "email")
                    params = params.concat(
                        `&${singleTask.autofillFields[i].fieldName}=${userProfile.data.email}`
                    );
                else if (singleTask.autofillFields[i].type === "employeeID")
                    params = params.concat(
                        `&${singleTask.autofillFields[i].fieldName}=${userProfile.data.employee_id}`
                    );
                else if (singleTask.autofillFields[i].type === "department")
                    params = params.concat(
                        `&${singleTask.autofillFields[i].fieldName}=${userProfile.data.department}`
                    );
                else if (singleTask.autofillFields[i].type === "firstName")
                    params = params.concat(
                        `&${singleTask.autofillFields[i].fieldName}=${userProfile.data.first_name}`
                    );
                else if (singleTask.autofillFields[i].type === "lastName")
                    params = params.concat(
                        `&${singleTask.autofillFields[i].fieldName}=${userProfile.data.last_name}`
                    );
            }

            return params;
        } else {
            return `name=${
                userProfile.data.first_name + " " + userProfile.data.last_name
            }&email=${userProfile.data.email}&employee id=${
                userProfile.data.employee_id
            }&department=${userProfile.data.department}`;
        }
    };

    const handleClick = (event) => {
        if (
            (formData.response.form.formName === "ToLR6g" &&
                formData.response.formData[
                    "Are you in the market to buy a car?"
                ] !== "Nope - I have no plans to buy a car anytime soon." &&
                formData.response.formData[
                    "By when do you need to get your next car?"
                ] !== "I have no plans to buy a car anytime soon" &&
                formData.response.formData[
                    "Would you like help from a FREE Electric Car Buying Coach?"
                ] !== "No, thanks.") ||
            (formData.response.form.formName === "u5Vr" &&
                formData.response.formData[
                    "Would you like help from a FREE Electrifyze Car Buying Coach?"
                ] === "Yes, please!")
        ) {
            props.updateUser(
                event,
                formData.response,
                undefined,
                undefined,
                true
            );
        } else {
            props.updateUser(event, formData.response, undefined, undefined);
        }
    };

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="column"
                style={{ height: "100%", textAlign: "center" }}
            >
                <Grid
                    item
                    xs
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ msFlex: "1 1 auto", overflowY: "auto" }}
                >
                    {singleTask.content &&
                    singleTask.content.startsWith(
                        // "https://f.nativeforms.com"
                        "https://surveys.electrifyze.com"
                    ) ? (
                        <div
                            style={{
                                height: "100%",
                                width: "100%",
                                textAlign: "left",
                            }}
                        >
                            <NativeForms
                                form={singleTask.content + "?" + getParams()}
                                onBeforeSend={(event) =>
                                    setFormData({
                                        completed: true,
                                        response: event,
                                    })
                                }
                            />
                        </div>
                    ) : (
                        singleTask.content && (
                            <iframe
                                src={singleTask.content + "?" + getParams()}
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                id="testiframe"
                                title="testiframe"
                            />
                        )
                    )}
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    style={
                        organization.data && organization.data != null
                            ? {
                                  height: 100,
                                  backgroundColor:
                                      organization.data.backgroundColor,
                                  color: organization.data.fontColor,
                              }
                            : {
                                  height: 100,
                                  backgroundColor: "black",
                                  color: "white",
                              }
                    }
                >
                    <Grid item xs container alignItems="flex-start">
                        <Typography variant="h6" className={classes.taskTitle}>
                            {singleTask.title}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        style={{ width: "auto" }}
                        container
                        alignItems="center"
                        justifyContent="center"
                    >
                        {singleTask.displayNextTaskButton && (
                            <StyledTaskButton onClick={props.updateUser}>
                                I Completed This Survey
                            </StyledTaskButton>
                        )}
                        {formData.completed && isAssignment ? (
                            <StyledTaskButton
                                onClick={(event) =>
                                    props.updateUser(
                                        event,
                                        formData.response,
                                        undefined,
                                        isAssignment
                                    )
                                }
                            >
                                Complete Activity
                            </StyledTaskButton>
                        ) : formData.completed ? (
                            <StyledTaskButton onClick={handleClick}>
                                Next Task
                            </StyledTaskButton>
                        ) : null}
                    </Grid>
                    <Grid item xs className={classes.rightGridItem}></Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
        organization: state.content.organization,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
