import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Styles
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Grid,
    TextField,
    Typography,
    Step,
    StepLabel,
    Stepper,
    Box,
    Fade,
} from "@mui/material";
import StepConnector from "@mui/material/StepConnector";

// Assets
import {
    faCheckCircle,
    faListAlt,
} from "@fortawesome/free-solid-svg-icons";

// Native Forms
import NativeForms from "native-forms-react";

// Components
import {
    StyledBlackButton,
    StyledGoldButton,
    StyledTaskButton,
} from "../Styled Components/Buttons";
import EVCoachMessage from "../Login/EVCoachMessage";

// Actions
import {
    getCoachingProfile,
    updateCoachingProfile,
} from "../../../actions/coaching_actions";
import {
    postSendGridEmail,
    sendTriggerEmail,
} from "../../../actions/email_actions";
import { updateUserProfile } from "../../../actions/user_actions";
import { updateSendGridContact } from "../../../actions/sendgrid_actions";
import {
    addHubspotContact,
    createHubspotDeal,
    associateHubspotDealWithContact,
    createHubspotEngagement,
    updateHubspotContact,
    updateHubspotDeal,
} from "../../../actions/hubspot_actions";

// Helpers
import {
    getOrganizationForDomain,
    paramsToObject,
} from "../../../helpers/functions";
import { getHTMLAdmin } from "../../../helpers/email";

// Firebase
import { getAnalytics, logEvent } from "firebase/analytics";
import { styled } from "@mui/system";

const analytics = getAnalytics()

const useStyles = makeStyles((theme) => ({
    root: {
        overflowY: "auto",
    },
    introBox: {
        padding: 20,
        paddingTop: 0,
    },
    descriptionText: {
        marginBottom: 20,
    },
    textFieldHeader: {
        marginBottom: 10,
    },
    textField: {
        minWidth: "100%",
    },
    /* emailGrid: {
        maxWidth: 500,
        margin: '0 auto'
    } */
    styledButtonLabel: {
        fontSize: 20,
        lineHeight: 1.2,
    },
    bounce: {
        animation: `$bounce 3s ${theme.transitions.easing.easeInOut} infinite`,
    },
    "@keyframes bounce": {
        "0%, 33%, 7%, 17%, 27%": {
            webkitTransform: "translateY(0)",
            msTransform: "translateY(0)",
            transform: "translateY(0)",
        },
        "13%": {
            webkitTransform: "translateY(-30px)",
            msTransform: "translateY(-30px)",
            transform: "translateY(-30px)",
        },
        "20%": {
            webkitTransform: "translateY(-15px)",
            msTransform: "translateY(-15px)",
            transform: "translateY(-15px)",
        },
    },
}));

function StepIcon(props) {
    const { upcoming, userProfile, id, type } = props;

    var iconStyles = {
        width: 45,
        height: 45,
        color: "rgba(255, 213, 0, 1)",
    };

    if (
        userProfile.data != null &&
        userProfile.data.completed_tasks &&
        !userProfile.data.completed_tasks[id]
    )
        iconStyles.color = "white";

    return (
        <div style={{ zIndex: 1, minWidth: 80 }}>
            <Typography
                variant="body1"
                style={{
                    textTransform: "capitalize",
                    color: "white",
                    margin: 10,
                    textAlign: "center",
                }}
            >
                {type}
            </Typography>
            <FontAwesomeIcon
                icon={
                    userProfile.data != null &&
                    userProfile.data.completed_tasks &&
                    !userProfile.data.completed_tasks[id]
                        ? faListAlt
                        : faCheckCircle
                }
                style={iconStyles}
            />
        </div>
    );
}

const Connector = styled(StepConnector)(({ theme }) => ({
    ['& .MuiStepConnector-line']: {
        height: 2,
        border: 0,
        backgroundColor: "gray",
        borderRadius: 1,
    },
}));

const CoachMessageBreak = <br style={{ lineHeight: 10 }} />;

function CoachingSurveys(props) {
    //Pass theme through props
    const classes = useStyles(props);
    const {
        // user,
        userProfile,
        handleClose,
        // showNextButton,
        // nextButtonClicked,
        fromTask,
        defaultEmail,
        coachingProfile,
        hubspotContact,
        hubspotDeal,
        open,
    } = props;

    const [email, setEmail] = useState(defaultEmail);
    const [activeStep, setActiveStep] = useState(-1);
    const [width, setWidth] = useState(window.innerWidth);
    const [displayEmailCoach, setDisplayEmailCoach] = useState(false);
    const [existingUser, setExistingUser] = useState(false);

    const organizationName = props.match.params.organization;
    const urlParams = new URLSearchParams(props.location.search);
    const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
    const params = paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}

    let referralEmail = params.referral;
    if (params.ref) {
        referralEmail = atob(params.ref);
    }
    if (fromTask) {
        referralEmail = "2021 Clean Air Rideshare Fair";
    }

    useEffect(() => {
        if (
            coachingProfile.data != null &&
            coachingProfile.data.email === email &&
            activeStep === -1
        ) {
            setDisplayEmailCoach(true);
            setExistingUser(true);
        } else if (coachingProfile.error != null) {
            setActiveStep(0);
        }
    }, [coachingProfile]);

    useEffect(() => {
        setActiveStep(-1);
        setDisplayEmailCoach(false);
    }, [open]);

    useEffect(() => {
        if (hubspotContact.data != null && hubspotDeal.data != null) {
            const data = {
                dealID: hubspotDeal.data.id,
                contactID: hubspotContact.data.vid,
            };
            props.associateHubspotDealWithContact(data);

            const engagementData = {
                engagement: {
                    active: true,
                    type: "NOTE",
                    timestamp: Date.now(),
                },
                associations: {
                    contactIds: [parseInt(hubspotContact.data.vid)],
                    dealIds: [parseInt(hubspotDeal.data.id)],
                },
                metadata: {
                    body: `Link for coaching data is: ${
                        window.location.origin
                    }/admin/?coachingProfile=${encodeURIComponent(email)}`,
                },
            };
            props.createHubspotEngagement(engagementData);
        }
    }, [hubspotContact, hubspotDeal]);

    useEffect(() => {
        if (
            hubspotContact.data != null &&
            hubspotDeal.data != null &&
            activeStep === 4 &&
            coachingProfile.data != null
        ) {
            let noteString = "";
            for (let i in coachingProfile.data.additional_surveys) {
                const survey = coachingProfile.data.additional_surveys[i];
                noteString += survey.label;
                noteString += "<br/>";
                noteString += JSON.stringify(survey.formData);
                noteString += "<br/><br/>";
            }

            const engagementData = {
                engagement: {
                    active: true,
                    type: "NOTE",
                    timestamp: Date.now(),
                },
                associations: {
                    contactIds: [parseInt(hubspotContact.data.vid)],
                    dealIds: [parseInt(hubspotDeal.data.id)],
                },
                metadata: {
                    body: noteString,
                },
            };
            props.createHubspotEngagement(engagementData);
        }
    }, [hubspotContact, hubspotDeal, activeStep]);

    const handleChange = (event) => {
        const value = event.target.value;

        setEmail(value);
    };
    const handleStartClick = () => {
        logEvent(analytics, "select_content", {
            content_type: "coaching_get_started_button_clicked",
        });

        // Get profile and handle action with hook
        props.getCoachingProfile(email);
    };

    const getNewCoachingProfile = (currentCoachingProfile, event) => {
        let newCoachingProfile = currentCoachingProfile;
        newCoachingProfile.additional_surveys =
            newCoachingProfile.additional_surveys || [];

        let newSurvey = event;
        // Replace any undefined fields with empty string as Firestore does not accept it
        for (let prop in newSurvey.formData) {
            if (
                newSurvey.formData[prop] === undefined ||
                newSurvey.formData[prop] === "undefined"
            ) {
                newSurvey.formData[prop] = "";
            }
        }

        newSurvey.label = newSurvey.form.label;
        newSurvey.formName = newSurvey.form.formName;

        // Keep the questions so that we can grab the IDs
        newSurvey.questions = [];
        for (let i in newSurvey.form.pages) {
            let page = newSurvey.form.pages[i];
            newSurvey.questions.push(...page.blocks);
        }

        // It's not worth it keep .form because sometime it has undefined
        delete newSurvey.form;
        newCoachingProfile.additional_surveys.push(newSurvey);

        // Check if there is a phone numner - If so, then add it
        if (newSurvey.formData["Phone Number"]) {
            const phoneNumber = newSurvey.formData["Phone Number"];
            newCoachingProfile.phone_number = phoneNumber;

            const hubspotContactData = {
                properties: [
                    { property: "email", value: email },
                    { property: "phone", value: phoneNumber },
                ],
            };
            props.updateHubspotContact(hubspotContactData);
        }

        return newCoachingProfile;
    };

    const handleSend = (event) => {
        logEvent(analytics, "select_content", {
            content_type: `coaching_survey_${activeStep + 1}_submitted`,
        });

        if (activeStep === 0) {
            const profile = {
                first_name: event.formData["First Name"],
                last_name: event.formData["Last Name"],
                email: email,
                //follow_up_survey_completed: false,
                //initial_survey: event
            };

            let newCoachingProfile = getNewCoachingProfile(profile, event);

            props.updateCoachingProfile(email, newCoachingProfile);
            setActiveStep((activeStep) => activeStep + 1);

            const contactData = {
                list_ids: ["3d689795-8782-4c23-b71b-b8ad5b4f8a0e"],
                contacts: [
                    {
                        first_name: event.formData["First Name"],
                        last_name: event.formData["Last Name"],
                        email: email,
                        custom_fields: {
                            //w17_T: "No", // Coaching follow up survey completed
                            w18_D: new Date(), // Coaching initial survey date
                        },
                    },
                ],
            };
            props.updateSendGridContact(contactData);

            const hubspotContactData = {
                properties: [
                    { property: "email", value: email },
                    {
                        property: "firstname",
                        value: event.formData["First Name"],
                    },
                    {
                        property: "lastname",
                        value: event.formData["Last Name"],
                    },
                    {
                        property: "organization",
                        value:
                            organizationName ||
                            getOrganizationForDomain() ||
                            "la_county",
                    },
                ],
            };
            props.addHubspotContact(hubspotContactData);

            const hubspotDealData = {
                properties: {
                    dealname: email,
                    dealstage: "15956877", // Add to onboarding stage
                    pipeline: "default",
                    purchase_timeline:
                        event.formData[
                            "When do you plan to get your next car?"
                        ],
                    organization:
                        organizationName || getOrganizationForDomain(),
                },
            };
            props.createHubspotDeal(hubspotDealData);
        } else {
            let newCoachingProfile = getNewCoachingProfile(
                coachingProfile.data,
                event
            );

            props.updateCoachingProfile(
                coachingProfile.data.email,
                newCoachingProfile
            );

            const contactData = {
                list_ids: ["3d689795-8782-4c23-b71b-b8ad5b4f8a0e"],
                contacts: [
                    {
                        email: coachingProfile.data.email,
                        custom_fields: {
                            w17_T: "Yes", // Coaching follow up survey completed
                        },
                    },
                ],
            };
            props.updateSendGridContact(contactData);

            if (activeStep === 4) {
                const hubspotDealData = {
                    dealID: hubspotDeal.data.id,
                    payload: {
                        properties: {
                            finished_onboarding_survey_: "Yes",
                        },
                    },
                };
                props.updateHubspotDeal(hubspotDealData);

                let emailAddress = "electrifyze-signups@greenlight-labs.com";
                if (
                    props.homePage.data !== null &&
                    props.homePage.data.organization === "lane_county"
                ) {
                    emailAddress =
                        "electrifyze-signups-lane_county@greenlight-labs.com";
                }

                let title = "New Car Coaching Form Submitted";
                // Add purchase timeline to email title
                if (
                    coachingProfile.data.additional_surveys[0].formData[
                        "When do you plan to get your next car?"
                    ]
                ) {
                    title += ` - ${coachingProfile.data.additional_surveys[0].formData["When do you plan to get your next car?"]}`;
                }
                props.sendTriggerEmail(
                    emailAddress,
                    title,
                    getHTMLAdmin(
                        coachingProfile.data,
                        hubspotContact.data != null && hubspotContact.data.vid
                    )
                );
            }

            setActiveStep((activeStep) => activeStep + 1);
        }
    };

    const steps = [
        {
            message: (
                <Fade key={0} in={activeStep === 0} timeout={2000}>
                    <div>
                        <Typography
                            variant="h5"
                            align="center"
                            style={{ fontWeight: 600 }}
                        >
                            1. Getting to Know You
                        </Typography>
                        <Box m={1.5} />
                        <Typography variant="h6">
                            First, I have a few questions so we can get to know
                            where you’re at in your car buying journey, and
                            what’s important to you.
                        </Typography>
                    </div>
                </Fade>
            ),
            content: (
                <Fade key={0} in={activeStep === 0} timeout={2000}>
                    <div style={{ border: "1px solid black" }}>
                        <NativeForms
                            form={`https://surveys.electrifyze.com/waOZUWZ1jZmgnZzkEcL1Db/?Email=${email}${
                                referralEmail
                                    ? `&Referral=${referralEmail}`
                                    : ""
                            }${
                                userProfile.data != null
                                    ? `&First Name=${userProfile.data.first_name}&Last Name=${userProfile.data.last_name}`
                                    : ""
                            }`}
                            onBeforeSend={handleSend}
                        />
                    </div>
                </Fade>
            ),
        },
        {
            message: (
                <Fade key={1} in={activeStep === 1} timeout={2000}>
                    <div>
                        <Typography
                            variant="h5"
                            align="center"
                            style={{ fontWeight: 600 }}
                        >
                            2. What are you looking for in your next car?
                        </Typography>
                        <Box m={1.5} />
                        <Typography variant="h6">
                            Shopping for a car is exciting, and maybe a little
                            intimidating. Let's learn a little about what you're
                            looking for so we can help you find it!
                        </Typography>
                    </div>
                </Fade>
            ),
            content: (
                <Fade key={1} in={activeStep === 1} timeout={2000}>
                    <div style={{ border: "1px solid black" }}>
                        <NativeForms
                            form={`https://surveys.electrifyze.com/g2YzFjRn1jZmgnZzkEcL1Db/?Email=${email}${
                                referralEmail
                                    ? `&Referral=${referralEmail}`
                                    : ""
                            }${
                                userProfile.data != null
                                    ? `&First Name=${userProfile.data.first_name}&Last Name=${userProfile.data.last_name}`
                                    : ""
                            }`}
                            onSend={handleSend}
                        />
                    </div>
                </Fade>
            ),
        },
        /* {
            message: (
                <Fade key={2} in={activeStep === 2} timeout={2000}>
                    <div>
                        <Typography variant="h5" align="center" style={{fontWeight: 600}}>
                            3. How Can We Help You?
                        </Typography>
                        <Box m={1.5} />
                        <Typography variant="h6">
                            Tell us a little more about what you're looking for, so we can do our best to help.
                        </Typography>
                    </div>
                </Fade>
            ),
            content: (
                <Fade key={2} in={activeStep === 2} timeout={2000}>
                    <div style={{border: '1px solid black'}}>
                        <NativeForms
                            form={`https://surveys.electrifyze.com/wY1RnbW1jZmgnZzkEcL1Db/?Email=${email}${referralEmail ? `&Referral=${referralEmail}` : ""}${userProfile.data != null ? `&First Name=${userProfile.data.first_name}&Last Name=${userProfile.data.last_name}` : ""}`}
                            onSend={handleSend}
                        />
                    </div>
                </Fade>
            )
        },  */ {
            message: (
                <Fade key={2} in={activeStep === 2} timeout={2000}>
                    <div>
                        <Typography
                            variant="h5"
                            align="center"
                            style={{ fontWeight: 600 }}
                        >
                            3. How Can We Get In Touch with You?
                        </Typography>
                        <Box m={1.5} />
                        <Typography variant="h6">
                            Your <b>Electrifyze Car Buying Coach</b> is here to
                            make car shopping easy. How can we get in touch so
                            we can jump in to help you out?
                        </Typography>
                    </div>
                </Fade>
            ),
            content: (
                <Fade key={2} in={activeStep === 2} timeout={2000}>
                    <div style={{ border: "1px solid black" }}>
                        <NativeForms
                            form={`https://surveys.electrifyze.com/gDZ6Zjbs1jZmgnZzkEcL1Db/?Email=${email}${
                                referralEmail
                                    ? `&Referral=${referralEmail}`
                                    : ""
                            }${
                                userProfile.data != null
                                    ? `&First Name=${userProfile.data.first_name}&Last Name=${userProfile.data.last_name}`
                                    : ""
                            }`}
                            onSend={handleSend}
                        />
                    </div>
                </Fade>
            ),
        },
        {
            message: (
                <Fade key={3} in={activeStep === 3} timeout={2000}>
                    <div>
                        <Typography
                            variant="h5"
                            align="center"
                            style={{ fontWeight: 600 }}
                        >
                            4. Let’s Set a Goal!
                        </Typography>
                        <Box m={1.5} />
                        <Typography variant="h6">
                            Your Electrifyze Car Buying Coach is here to make
                            hybrid and electric car shopping easier than it's
                            ever been.
                        </Typography>
                        <Box m={1.5} />
                        <Typography variant="h6">
                            If you have a target for when you need your next car
                            by, your Electrifyze Car Buying Coach will help you
                            meet it!
                        </Typography>
                    </div>
                </Fade>
            ),
            content: (
                <Fade key={3} in={activeStep === 3} timeout={2000}>
                    <div style={{ border: "1px solid black" }}>
                        <NativeForms
                            form={`https://surveys.electrifyze.com/QUBJjbN1jZmgnZzkEcL1Db/?Email=${email}${
                                referralEmail
                                    ? `&Referral=${referralEmail}`
                                    : ""
                            }${
                                userProfile.data != null
                                    ? `&First Name=${userProfile.data.first_name}&Last Name=${userProfile.data.last_name}`
                                    : ""
                            }`}
                            onSend={handleSend}
                        />
                    </div>
                </Fade>
            ),
        },
    ];

    const handleBrowseCarsClick = () => {
        props.history.push("/browse");
    };

    const handleEmailCoachClick = () => {
        setDisplayEmailCoach(true);
    };

    const [copied, setCopied] = useState(false);

    const handleCopyClick = () => {
        if (/*@cc_on!@*/ false || !!document.documentMode) {
            //Internet explorer has unique method
            window.clipboardData.setData("hello@electrifyze.com");
        } else {
            navigator.clipboard.writeText("hello@electrifyze.com");
        }

        setCopied(true);
    };

    return (
        <div className={classes.root}>
            {displayEmailCoach ? (
                <div className={classes.introBox}>
                    <EVCoachMessage
                        noSidePadding
                        message={
                            <div>
                                {existingUser ? (
                                    <div>
                                        <Typography
                                            variant="h6"
                                            align="center"
                                            style={{ fontWeight: 600 }}
                                        >
                                            You’re already signed up!
                                        </Typography>
                                        <Box m={1.5} />
                                        <Typography
                                            variant="h6"
                                            align="left"
                                            style={{ maxWidth: 500 }}
                                        >
                                            Look like you’ve already signed up
                                            for Electrifyze… Have questions for
                                            your coach? Get in touch!
                                        </Typography>
                                        <br />
                                    </div>
                                ) : (
                                    <div>
                                        <Typography
                                            variant="h6"
                                            style={{ maxWidth: 500 }}
                                        >
                                            You’re signed up! An Electrifyze Car
                                            Buying Coach will reach out to you
                                            soon…
                                        </Typography>
                                        <br />
                                        <Typography
                                            variant="h5"
                                            align="center"
                                            style={{ fontWeight: 600 }}
                                        >
                                            Want to get started now?
                                        </Typography>
                                        <Box m={1.5} />
                                    </div>
                                )}
                                <Grid container alignItems="center" spacing={3}>
                                    <Grid
                                        item
                                        xs={4}
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <Typography align="right">
                                            Contact your Electrifyze Coach:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                padding: 10,
                                                border: "1px solid black",
                                                borderRadius: 10,
                                            }}
                                        >
                                            <Grid
                                                item
                                                container
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Grid
                                                    item
                                                    xs
                                                    container
                                                    alignItems="center"
                                                >
                                                    <a
                                                        href={`mailto:hello@electrifyze.com?subject=Electrifyze Coaching Help`}
                                                        id="mail-share-link"
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            className={
                                                                classes.urlText
                                                            }
                                                        >
                                                            hello@electrifyze.com
                                                        </Typography>
                                                    </a>
                                                </Grid>
                                                <Grid item>
                                                    <StyledGoldButton
                                                        variant="contained"
                                                        size="small"
                                                        onClick={
                                                            handleCopyClick
                                                        }
                                                    >
                                                        {copied
                                                            ? "Copied"
                                                            : "Copy"}
                                                    </StyledGoldButton>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    />
                </div>
            ) : activeStep === -1 ? (
                <div className={classes.introBox}>
                    <EVCoachMessage
                        noSidePadding
                        message={
                            <div>
                                <Typography variant="h6">
                                    It looks like you’re in the market for a
                                    car… That’s so exciting!
                                </Typography>
                                <Box m={1.5} />
                                <Typography variant="h6">
                                    I’m your <b>Electrifyze Car Buying Coach</b>
                                    . I’m here to make your car shopping journey
                                    easier than ever before!
                                </Typography>
                                <Box m={1.5} />
                                <Typography variant="h6">
                                    Let’s get started with a few questions so I
                                    can understand how to help you… It will just
                                    take a few minutes.
                                </Typography>
                            </div>
                        }
                    />

                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        style={{ maxWidth: 500, margin: "0 auto" }}
                    >
                        <Grid item xs>
                            <TextField
                                label="Email"
                                name="email"
                                defaultValue={email}
                                onChange={handleChange}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item>
                            <StyledBlackButton
                                onClick={handleStartClick}
                                className={classes.bounce}
                            >
                                Get Started
                            </StyledBlackButton>
                        </Grid>
                    </Grid>
                </div>
            ) : activeStep === 4 && fromTask ? (
                <div className={classes.introBox}>
                    <EVCoachMessage
                        noSidePadding
                        message={
                            <div>
                                <Typography variant="h6">
                                    You made it! I’m all done with the questions
                                    for now.
                                </Typography>
                                <Box m={1.5} />
                                <Typography variant="h6">
                                    You’re well on your way to starting your car
                                    buying journey, with help from your very own
                                    Electrifyze Car Buying Coach!
                                </Typography>
                                <Box m={1.5} />
                                <Typography variant="h6">
                                    <b>
                                        One of our expert Coaches will review
                                        your responses and reach out shortly to
                                        get started!
                                    </b>
                                </Typography>
                            </div>
                        }
                    />

                    <Grid container justifyContent="flex-end">
                        <StyledBlackButton
                            onClick={handleClose}
                            className={classes.bounce}
                        >
                            Return to the Contest
                        </StyledBlackButton>
                    </Grid>
                </div>
            ) : activeStep === 4 && !fromTask ? (
                <div className={classes.introBox}>
                    <EVCoachMessage
                        noSidePadding
                        message={
                            <div>
                                <Typography variant="h6">
                                    You’re signed up! An Electrifyze Car Buying
                                    <br />
                                    Coach will reach out to you soon…
                                </Typography>
                                <br />
                                <Typography
                                    variant="h5"
                                    align="center"
                                    style={{ fontWeight: 600 }}
                                >
                                    Want to get started now?
                                </Typography>
                                <Box m={1.5} />
                                <Grid container spacing={3}>
                                    <Grid
                                        item
                                        xs
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <StyledTaskButton
                                            onClick={handleBrowseCarsClick}
                                            classes={{
                                                label: classes.styledButtonLabel,
                                            }}
                                            style={{ minWidth: 210 }}
                                        >
                                            Browse Cars
                                            <br />
                                            {"&"} Incentives
                                        </StyledTaskButton>
                                    </Grid>
                                    <Grid item xs>
                                        <StyledTaskButton
                                            onClick={handleEmailCoachClick}
                                            classes={{
                                                label: classes.styledButtonLabel,
                                            }}
                                            style={{ minWidth: 210 }}
                                        >
                                            Email My
                                            <br />
                                            Electrifyze Coach
                                        </StyledTaskButton>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    />
                </div>
            ) : (
                <div>
                    <Stepper
                        activeStep={activeStep}
                        //alternativeLabel={width < 600 ? false : true}
                        connector={<Connector />}
                        className={classes.stepper}
                        //orientation={width < 600 ? "vertical" : "horizontal"}
                    >
                        {steps.map((step) => (
                            <Step key={step}>
                                <StepLabel />
                            </Step>
                        ))}
                    </Stepper>

                    {activeStep !== 6 && (
                        <div className={classes.introBox}>
                            <EVCoachMessage
                                noSidePadding
                                message={steps[activeStep].message}
                            />

                            {steps[activeStep].content}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        coachingProfile: state.coaching.coachingProfile,
        hubspotContact: state.hubspot.hubspotContact,
        hubspotDeal: state.hubspot.hubspotDeal,
        user: state.user.user,
        userProfile: state.user.userProfile,
        homePage: state.content.homePage,
    };
};

const mapDispatchToProps = {
    getCoachingProfile,
    updateCoachingProfile,
    updateUserProfile,
    postSendGridEmail,
    updateSendGridContact,
    addHubspotContact,
    updateHubspotContact,
    createHubspotDeal,
    associateHubspotDealWithContact,
    createHubspotEngagement,
    updateHubspotDeal,
    sendTriggerEmail,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CoachingSurveys)
);
