import React from "react";
import { connect } from "react-redux";

//Actions
import { getCourses, getCampaign } from "../actions/content_actions";
import { updateUserProfile } from "../actions/user_actions";
import { openAlert } from "../actions/alert_actions";

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Typography, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";

// Components
import CourseItem from "../components/default/Courses/CourseItem";
import ISDRideshareHeader from "../components/la_county/ISDRideshareHeader";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100%",
        //overflowY: 'auto'
    },
    content: {
        maxWidth: 1200,
        margin: "0 auto",
        marginTop: 30,
        marginBottom: 40,
        [theme.breakpoints.down('xl')]: {
            marginLeft: 10,
            marginRight: 10,
        },
    },
    main: {
        marginTop: 20,
    },
    courseBox: {
        margin: 10,
    },
    titleBox: {
        height: 120,
        width: "100%",
        backgroundColor: "#4472c4",
    },
    profileImg: {
        height: 100,
        width: 100,
    },
    pointsImg: {
        height: 80,
        width: 80,
        objectFit: "contain",
        color: "white",
    },
    breadcrumbBox: {
        height: 23,
        backgroundColor: "rgba(180, 199, 231, 1)",
        padding: 15,
        paddingLeft: 35,
    },
    link: {
        textDecoration: "none",
        color: "black",
    },
}));

function LearnAboutEV(props) {
    const classes = useStyles();
    const { userProfile } = props;

    const userOrganization =
        userProfile.data != null &&
        (userProfile.data.currentOrganization ||
            userProfile.data.organization.name ||
            userProfile.data.organization);

    React.useEffect(() => {
        if (userProfile.data != null) props.getCourses(userOrganization);
    }, []);

    React.useEffect(() => {
        if (userProfile.data != null) {
            props.getCourses(userOrganization);

            //Check if there are completed tasks
            let completedTasksString = null
            try {
                completedTasksString = window.localStorage.getItem("completedTasks");
            } catch(e) {
                console.log(e)
            }
            let completedTasks = JSON.parse(completedTasksString);
            //console.log("completed tasks are", completedTasksString, completedTasks)

            if (
                completedTasksString &&
                completedTasksString !== null &&
                completedTasksString !== "null"
            ) {
                //Get the task id and object associated with it
                let taskID = Object.keys(completedTasks)[0];
                let taskInfo = completedTasks[taskID];
                //console.log("The completed task is ", taskInfo)

                //Update the user profile
                const uid = props.user.data.uid;
                let newUserProfile = userProfile.data;
                newUserProfile.completed_tasks[taskID] = taskInfo;

                props.updateUserProfile(newUserProfile, uid);

                //console.log("The new progile is ", newUserProfile)

                //Reset local storage
                try {
                    window.localStorage.setItem("completedTasks", null);
                } catch(e) {
                    console.log(e)
                }

                // Alert the user
                //alert(`You completed a campaign! ${taskInfo.points} points have been added to your profile.`)
                props.openAlert({
                    text: `Congrats on completing your first task! ${taskInfo.points} points have been added to your profile.`,
                });
            }
        }
    }, [userProfile.data]);

    const getEVCourses = () => {
        //Filter for only EV courses
        let evCourses = props.courses.data.filter(
            (course) => course.evLearningCourse
        );

        //Now sort by order
        evCourses.sort((a, b) => a.order - b.order);

        return evCourses;
    };

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <ISDRideshareHeader
                    title="Learn About EVs"
                    subheading="Find out why electric cars are good for you and LA"
                />

                <Grid
                    container
                    alignItems="center"
                    style={{ marginTop: 40, marginBottom: 20 }}
                >
                    <Grid item>
                        <Typography variant="h5">Learning Modules</Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider
                            style={{
                                marginLeft: 15,
                                height: 3,
                                backgroundColor: "black",
                            }}
                        />
                    </Grid>
                </Grid>

                <div className={classes.main}>
                    <Grid container spacing={6}>
                        {props.courses.data != null &&
                            props.courses.data.length > 0 &&
                            getEVCourses().map((course, index) => {
                                return (
                                    <CourseItem
                                        course={course}
                                        key={index}
                                        index={index}
                                    />
                                );
                            })}
                    </Grid>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        courses: state.content.courses,
        courseMetrics: state.content.courseMetrics,
        organization: state.content.organization,
        campaign: state.content.campaign,
    };
};

const mapDispatchToProps = {
    getCourses,
    getCampaign,
    updateUserProfile,
    openAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(LearnAboutEV);
