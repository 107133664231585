import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//Assets

//Styles
import { makeStyles, useTheme } from "@mui/styles";
import { Typography, useMediaQuery } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    text: {
        fontWeight: 600,
        "&:after": {
            content: "'|'",
        },
    },
    anim: {
        "&:after": {
            animation: "$blink 1s step-end infinite",
        },
    },
    "@keyframes blink": {
        "0%": {
            opacity: 1,
        },
        "100%": {
            opacity: 1,
        },
        "50%": {
            opacity: 0,
        },
    },
}));

function TypeWriter(props) {
    const classes = useStyles(props);
    const theme = useTheme();
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('lg'));

    const { textToBeTyped, italicize } = props;

    const [state, setState] = useState({
        typeText: "",
        showAnim: false,
    });

    useEffect(() => {
        var index = 0,
            isAdding = true,
            textToBeTypedIndex = 0;

        const playAnim = () => {
            setTimeout(
                function () {
                    // set the text of typeText to a substring of the text to be typed using index.
                    setState({
                        ...state,
                        typeText: textToBeTyped[textToBeTypedIndex].slice(
                            0,
                            index
                        ),
                    });

                    if (isAdding) {
                        // adding text
                        if (index > textToBeTyped[textToBeTypedIndex].length) {
                            // no more text to add
                            isAdding = false;
                            setState((prevState) => ({
                                ...prevState,
                                showAnim: true,
                            }));
                            //break: wait 2s before playing again
                            setTimeout(function () {
                                setState({
                                    ...state,
                                    showAnim: false,
                                });
                                playAnim();
                            }, 2000);
                            return;
                        } else {
                            // increment index by 1
                            index++;
                        }
                    } else {
                        // removing text
                        if (index === 0) {
                            // no more text to remove
                            //switch to next text in text array
                            isAdding = true;
                            textToBeTypedIndex =
                                (textToBeTypedIndex + 1) % textToBeTyped.length;
                        } else {
                            // decrement index by 1
                            index--;
                        }
                    }
                    // call itself
                    playAnim();
                },
                isAdding ? 120 : 60
            );
        };

        playAnim();
    }, []);

    return (
        <Typography
            variant={isExtraSmall ? "body1" : "h6"}
            className={clsx(classes.text, {
                [classes.anim]: state.showAnim,
            })}
        >
            {italicize ? <i>{state.typeText}</i> : state.typeText}
        </Typography>
    );
}

const mapStateToProps = (state) => ({
    organization: state.content.organization,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TypeWriter);
