import React, { useEffect } from "react";
import { connect } from "react-redux";

//Actions
import { getCourses, getCampaign } from "../actions/content_actions";
import { updateUserProfile } from "../actions/user_actions";
import { openAlert } from "../actions/alert_actions";

//Assets

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Typography, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// Components
import CourseItem from "../components/default/Courses/CourseItem";
import ProfileWelcomeHeader from "../components/default/Courses/ProfileWelcomeHeader";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100%",
        //overflowY: 'auto'
    },
    main: {
        maxWidth: 1200,
        margin: "0 auto",
        marginTop: 30,
        marginBottom: 40,
        [theme.breakpoints.down('xl')]: {
            marginLeft: 10,
            marginRight: 10,
        },
    },
    courseBox: {
        margin: 10,
    },
    breadcrumbBox: {
        height: 23,
        backgroundColor: "rgba(180, 199, 231, 1)",
        padding: 15,
        paddingLeft: 35,
    },
    link: {
        textDecoration: "none",
        color: "black",
    },
}));

function Courses(props) {
    const classes = useStyles();
    const { user, userProfile, organization, courses, courseMetrics } = props;

    const userOrganization =
        userProfile.data != null &&
        (userProfile.data.currentOrganization ||
            userProfile.data.organization.name ||
            userProfile.data.organization);

    useEffect(() => {
        if (userProfile.data != null) props.getCourses(userOrganization);
    }, []);

    useEffect(() => {
        if (userProfile.data != null) {
            props.getCourses(userOrganization);
        }
    }, [userProfile.data]);

    useEffect(() => {
        if (
            organization.data != null &&
            organization.data.activeCampaign &&
            organization.data.activeCampaign.length != 0
        ) {
            //console.log("campaign", organization.data.activeCampaign)
            props.getCampaign(organization.data.activeCampaign);
        }
    }, [organization.data]);

    const getUserPoints = () => {
        let points = 0;
        for (let key in courseMetrics.data) {
            points += courseMetrics.data[key].completedPointsCounter;
        }

        return points;
    };

    const getNonEVCourses = () => {
        //Filter for only EV courses
        let nonEVCourses = courses.data.filter(
            (course) => !course.evLearningCourse
        );

        //Now sort by order
        nonEVCourses.sort((a, b) => a.order - b.order);

        return nonEVCourses;
    };

    return (
        <div className={classes.root}>
            <ProfileWelcomeHeader />

            <div className={classes.breadcrumbBox}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Link to="/home" className={classes.link}>
                        Home
                    </Link>
                    <Typography>Courses</Typography>
                </Breadcrumbs>
            </div>

            <div className={classes.main}>
                <Grid container spacing={6}>
                    {courses.loading ? (
                        <CircularProgress style={{ marginLeft: 30 }} />
                    ) : courses.data != null && courses.data.length > 0 ? (
                        // Filter out the EV Learning courses
                        getNonEVCourses().map((course, index) => {
                            return <CourseItem course={course} key={index} />;
                        })
                    ) : courses.data != null && courses.data.length === 0 ? (
                        <Typography variant="h4" style={{ marginLeft: 30 }}>
                            No courses to display
                        </Typography>
                    ) : null}
                </Grid>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        courses: state.content.courses,
        courseMetrics: state.content.courseMetrics,
        organization: state.content.organization,
    };
};

const mapDispatchToProps = {
    getCourses,
    getCampaign,
    updateUserProfile,
    openAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
