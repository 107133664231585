import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions
import { getOrganization } from "../actions/content_actions";

//Assets
import Museum from "../static/images/museum.jpg";
import ElectrifyzeLogo from "../static/images/Electrifyze-Logo-black.svg";
import ISDRideshareLogo from "../static/images/isd-rideshare.png";

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";

// Components
import Footer from "../components/default/Styled Components/Footer";

//Helpers
import { getOrganizationForDomain } from "../helpers/functions";

const useStyles = makeStyles((theme) => ({
    main: {
        padding: 120,
        width: "calc(100% - 240px)",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        [theme.breakpoints.down('xl')]: {
            padding: 60,
            width: "calc(100% - 120px)",
        },
        [theme.breakpoints.down('xl')]: {
            padding: 20,
            width: "calc(100% - 40px)",
        },
    },
    titleFormBox: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        width: "50%",
        margin: "0 auto",
        padding: 40,
        textAlign: "center",
        [theme.breakpoints.down('xl')]: {
            width: "55%",
            marginTop: 0,
        },
        [theme.breakpoints.down('xl')]: {
            width: "70%",
            fontSize: 38,
            padding: 20,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 32,
            width: "85%",
        },
    },
    topBottomSloganText: {
        color: "white",
        fontWeight: 500,
        fontSize: 55,
        [theme.breakpoints.down('xl')]: {
            fontSize: 60,
            marginTop: 0,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
            marginBottom: 10,
        },
    },
    bigText: {
        color: "white",
        fontWeight: 500,
        [theme.breakpoints.down('xl')]: {
            fontSize: 60,
            marginTop: 0,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
            marginBottom: 10,
        },
    },
    descriptionBox: {
        width: "100%",
        textAlign: "center",
    },
    descriptionSubBox: {
        margin: 40,
        marginLeft: "25%",
        marginRight: "25%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        [theme.breakpoints.down('xl')]: {
            marginLeft: "5%",
            marginRight: "5%",
        },
    },
    topBar: {
        height: 6,
        width: 100,
        backgroundColor: "black",
        marginBottom: 8,
        [theme.breakpoints.down('xl')]: {
            height: 4,
        },
    },
    descriptionTitle1: {
        width: 700,
        fontSize: 40,
        margin: 0,
        marginTop: 10,
        [theme.breakpoints.down('xl')]: {
            fontSize: 32,
            width: "90%",
        },
    },
    descriptionText: {
        width: "100%",
        fontSize: 24,
        [theme.breakpoints.down('xl')]: {
            fontSize: 18,
        },
        textAlign: "left",
    },
    logoImageBox: {
        height: 80,
        width: 250,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 5,
    },
    countyLogoImageBox: {
        height: 70,
        width: 70,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 10,
    },
    countyText: {
        fontSize: 42,
        textDecoration: "none !important",
        fontFamily: "Quicksand",
        fontWeight: 600,
    },
    electrifyzeLACountyBox: {
        width: 300,
        margin: "0 auto",
        marginBottom: 50,
        textAlign: "center",
    },
    video: {
        width: "100%",
        height: 600,
        [theme.breakpoints.down('xl')]: {
            height: 250,
        },
    },
}));

function AuthenticatedAbout(props) {
    const classes = useStyles(props);

    const { getOrganization } = props;
    const organization = props.match.params.organization
        ? props.match.params.organization.toLowerCase()
        : getOrganizationForDomain();

    useEffect(() => {
        if (organization) {
            getOrganization(organization);
        }
    }, [organization, getOrganization]);

    return (
        <React.Fragment>
            <div>
                <div
                    style={{
                        position: "relative",
                        margin: 0,
                        padding: 0,
                        overflow: "auto",
                        minHeight: "calc(100vh - 114px)",
                    }}
                >
                    <div
                        className={classes.main}
                        style={{ backgroundImage: "url(" + Museum + ")" }}
                    >
                        <div className={classes.titleFormBox}>
                            <Typography
                                variant="h2"
                                className={classes.topBottomSloganText}
                            >
                                Helping YOU
                            </Typography>
                            <Typography
                                variant="h1"
                                className={classes.bigText}
                            >
                                Create a Fossil Fuel Free LA
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.descriptionBox}>
                        <div className={classes.descriptionSubBox}>
                            <div className={classes.topBar} />

                            <Grid
                                container
                                style={{ width: 350, margin: 10 }}
                                alignItems="center"
                            >
                                {/* <Grid item xs container alignItems="center">
                                        <Grid item>
                                            <div className={classes.countyLogoImageBox}>
                                                <img src={OurCountyLogo} alt="logo" style={{maxHeight: '100%', maxWidth: '100%'}}/>
                                            </div>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="h3" className={classes.countyText}>
                                                OurCounty
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{width: 'auto'}}>
                                        <Typography variant="h4">
                                            &nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;
                                        </Typography>
                                    </Grid> */}
                                <Grid item xs container alignItems="center">
                                    <img
                                        src={ElectrifyzeLogo}
                                        style={{
                                            width: "100%",
                                            maxHeight: "100%",
                                            maxWidth: "100%",
                                        }}
                                        alt="electrifyze-logo"
                                    />
                                </Grid>
                            </Grid>

                            <br />
                            <Typography className={classes.descriptionText}>
                                LA County has established the boldest county
                                sustainability plan in the nation. This plan is
                                organized around 12 goals that describe a shared
                                vision for a sustainable Los Angeles County. You
                                can learn more about this plan by viewing the
                                video below or visiting the&nbsp;
                                <a
                                    href="https://ourcountyla.lacounty.gov/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    OurCounty website.
                                </a>
                            </Typography>
                            <br />
                            <Typography className={classes.descriptionText}>
                                The County’s Internal Services Division launched
                                Electrifyze in support of Goal 7, creating a
                                fossil fuel-free LA County. Electrifyze is a
                                program designed to simplify the transition to
                                electric cars for you and other LA County
                                employees.
                            </Typography>
                            <br />
                            <Typography className={classes.descriptionText}>
                                Electrifyze helps you learn about electric
                                vehicles, gives you access to experts who
                                provide unbiased advice, and helps you find the
                                best deal on an electric car if you choose to go
                                electric.
                            </Typography>
                            <br />
                            <Typography className={classes.descriptionText}>
                                We all share the burden and excitement of
                                becoming a fossil-fuel free LA County.
                                Electrifyze is here for you.
                            </Typography>
                            <br />

                            <iframe
                                className={classes.video}
                                src="https://player.vimeo.com/video/349545144?color=fde67f"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="video"
                            />
                        </div>
                    </div>

                    <div className={classes.electrifyzeLACountyBox}>
                        <Typography variant="h6">
                            Electrifyze is brought to you by
                        </Typography>
                        <br />
                        <img
                            src={ISDRideshareLogo}
                            style={{ width: 150, height: "auto" }}
                            alt="la-county-rideshare-logo"
                        />
                    </div>
                </div>
                <div style={{ position: "relative", width: "100%", bottom: 0 }}>
                    <Footer displayForm={false} />
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    getOrganization,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AuthenticatedAbout)
);
