import { ButtonBase, Typography } from "@mui/material";
import React from "react";

export default function AnswerBubble(props) {
    var handleResponseClick = props.handleResponseClick;

    const evaluateAnswer = () => {
        handleResponseClick(
            props.uniqueKey,
            props.response.explanation,
            props.response.correct
        );
    };

    var answerDecorator = props.classes.standardAnswer;

    if (props.clickedAnswer != null) {
        if (props.clickedAnswer === props.uniqueKey) {
            answerDecorator =
                props.correctAnswerID === props.uniqueKey
                    ? props.classes.rightAnswerPicked
                    : props.classes.wrongAnswer;
        }
    }

    if (
        props.clickedAnswer != null &&
        props.clickedAnswer !== props.correctAnswerID &&
        props.uniqueKey === props.correctAnswerID
    ) {
        answerDecorator = props.classes.rightAnswerNotPicked;
    }

    //console.log(props.clickedAnswer);
    var clickBehavior =
        props.clickedAnswer !== ""
            ? console.log("Answer already given!")
            : evaluateAnswer;

    return (
        <ButtonBase
            className={props.classes.answerButton}
            onClick={clickBehavior}
        >
            <li className={answerDecorator} key={props.uniqueKey}>
                <Typography>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props.response.answer,
                        }}
                    />
                </Typography>
            </li>
        </ButtonBase>
    );
}
