import React, { useEffect, useState } from "react";

//Styles
import { Snackbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MuiAlert from "@mui/material/Alert";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    warningBox: {
        height: 34,
        textAlign: "center",
        backgroundColor: "rgba(242, 38, 19, 1)",
        padding: 8,
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function InternetExplorerWarning(props) {
    const classes = useStyles();
    const [open, toggleOpen] = useState(true);

    useEffect(() => {
        toggleOpen(true);
    }, [props.history.location.pathname]);

    const handleClose = () => {
        toggleOpen(false);
    };

    return (
        <div>
            {
                /*@cc_on!@*/ (false || !!document.documentMode) && open && (
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={open}
                    >
                        <Alert severity="error" onClose={handleClose}>
                            WARNING: To get the best experience, please use
                            Google Chrome.
                            <br />
                            You can download it here (
                            <a
                                href="https://www.google.com/intl/en_ca/chrome/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                link
                            </a>
                            ).
                        </Alert>
                    </Snackbar>
                    /*  <div className={classes.warningBox}>
                    <Typography variant="h6" style={{color: "white"}}>
                        WARNING: Google Chrome is recommended for this site. We discourage the use of Internet Explorer.
                    </Typography>
                </div> */
                )
            }
        </div>
    );
}

export default withRouter(InternetExplorerWarning);
