import React from "react";
import { connect } from "react-redux";

//Actions
import { getOrganization } from "../../../actions/content_actions";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";

//Assets
import ElectrifyzeLogoWhite from "../../../static/images/Electrifyze-Logo-white.svg";

//Components
import EmailForm from "../Home/EmailForm";
import OurCountyBox from "../../la_county/OurCountyBox";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    footer: {
        height: 90,
        display: "flex",
        backgroundColor: "black",
        justifyContent: "center",
        alignItems: "center",
    },
    flexBox: {
        height: "100%",
        width: "100%",
        display: "flex",
    },
    box1: {
        height: "100%",
        flex: 3,
        msFlex: "3 0 auto",
        paddingLeft: 20,
        textAlign: "left",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    box2: {
        height: "100%",
        flex: 2,
        msFlex: "2 0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingRight: 25,
    },
    heading: {
        color: "white",
        fontSize: 40,
        margin: 0,
        fontWeight: 400,
        [theme.breakpoints.down('xl')]: {
            fontSize: 32,
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: 24,
        },
    },
    subheading: {
        width: "100%",
        color: "white",
        fontSize: 21,
        margin: 0,
        fontWeight: 400,
        [theme.breakpoints.down('xl')]: {
            fontSize: 16,
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: 10,
        },
    },
    footerNavBox: {
        flex: 6,
        display: "flex",
        marginLeft: 20,
    },
    logoImageBox: {
        height: 80,
        width: 250,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 5,
    },
    countyLogoImageBox: {
        height: 50,
        width: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 10,
    },
    countyText: {
        color: "white",
        textDecoration: "none !important",
        fontFamily: "Quicksand",
        fontWeight: 500,
    },
    countyDescriptionText: {
        [theme.breakpoints.down('lg')]: {
            fontSize: 9,
        },
    },
}));

function Footer(props) {
    const classes = useStyles(props);

    const { organization } = props;

    const pathname = useLocation().pathname || null;

    return (
        <footer
            className={classes.footer}
            style={
                organization.data !== null
                    ? {
                          backgroundColor: organization.data.backgroundColor,
                          color: organization.data.fontColor,
                      }
                    : {}
            }
        >
            <div className={classes.flexBox}>
                <div className={classes.box1}>
                    {organization.loading ? null : organization.data !== null &&
                      organization.data.organization === "la_county" &&
                      !(
                          pathname.startsWith("/coaching-signup") ||
                          pathname.startsWith("/la_county/coaching-signup")
                      ) ? (
                        <OurCountyBox />
                    ) : (
                        /* : organization.data !== null ?
                      <img src={organization.data.logo} alt="logo" style={{height: 80, width:300, objectFit: 'contain'}}/> */
                        <div style={{ width: "100%" }}>
                            <div
                                style={{
                                    height: 40,
                                    width: 250,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src={ElectrifyzeLogoWhite}
                                    alt="logo"
                                    style={{
                                        width: "100%",
                                        maxHeight: "100%",
                                        maxWidth: "100%",
                                    }}
                                />
                            </div>
                            <h2 className={classes.subheading}>
                                EV Car Shopping Made Easy
                            </h2>
                        </div>
                    )}
                </div>
                <div className={classes.box2}>
                    <Grid
                        container
                        style={{ height: "100%" }}
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        {props.displayForm && (
                            <Grid item xs container justifyContent="flex-end">
                                <EmailForm color="white" />
                            </Grid>
                        )}
                        {organization.data !== null &&
                            organization.data.organization === "la_county" &&
                            (window.location.hostname ===
                                "www.electrifyze.la" ||
                                pathname.startsWith("/la_county")) && (
                                <Grid
                                    item
                                    style={{ color: "white", padding: 0 }}
                                >
                                    <Typography
                                        variant="body1"
                                        className={
                                            classes.countyDescriptionText
                                        }
                                    >
                                        Brought to you by the LA County
                                        <br />
                                        ISD EV and Rideshare Programs.
                                        <br />
                                        Powered by Green Light Labs.
                                    </Typography>
                                </Grid>
                            )}
                    </Grid>
                </div>
            </div>
        </footer>
    );
}

const mapStateToProps = (state) => {
    return {
        organization: state.content.organization,
    };
};

const mapDispatchToProps = {
    getOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
