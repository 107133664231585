import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Styles
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import { Box, CircularProgress, Grid, Typography } from "@mui/material";

//Components
// import EVCoachMessage from "../../../../default/Login/EVCoachMessage";
import { CustomDialogTitle } from "../../../../default/Styled Components/DialogTitle";
import ReferralsHeader from "../../../../default/Refer/ReferralsHeader";
import ReferInfoBox from "./ReferInfoBox";

//Assets
import EarthCharacter from "../../../../../static/images/earth-only.png";
// import GiftCard250 from "../../../../../static/images/GiftCard-250.png";

//Actions


// Firebase
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics()

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        [theme.breakpoints.down('lg')]: {
            margin: 12,
        },
    },
    dialogBox: {
        marginTop: -20,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
        [theme.breakpoints.down('lg')]: {
            margin: 0,
        },
    },
    dialogContent: {
        paddingBottom: 10,
        msOverflowStyle: "scrollbar",
        [theme.breakpoints.down('xl')]: {
            padding: 10,
        },
    },
}))

function EarthDayReferDialog(props) {
    const classes = useStyles();
    const { open, toggleDialog, email } = props;

    return (
        <Dialog
            open={open}
            maxWidth="md"
            onBackdropClick={toggleDialog}
            classes={{ paper: classes.dialogPaper }}
        >
            <CustomDialogTitle onClose={toggleDialog} />
            <DialogContent classes={{ root: classes.dialogContent }}>
                <div className={classes.dialogBox}>
                    <div style={{ paddingTop: 25 }}>
                        <ReferralsHeader
                            characterImage={EarthCharacter}
                            title="Refer a Friend"
                            subheading="Help create a fossil fuel free community and get chances to win!"
                        />
                        
                        <ReferInfoBox
                            email={email}
                            campaign="2024-lac-earthday"
                        />
                        
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default EarthDayReferDialog;