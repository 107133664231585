import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions
import { updateUserProfile } from "../../../../actions/user_actions";
import { getCourseMetrics } from "../../../../actions/content_actions";

//Styles
import "../../../../static/styles/App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

//Assets
import {
    faAward,
    faCheck,
    faGift,
    faTimes,
    faTrophy,
} from "@fortawesome/free-solid-svg-icons";

// Firebase
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics()

function Scorecard(props) {
    const { tasks, isAssignment } = props;
    const correctQuantity = props.correctAnswers;
    const incorrectQuantity = props.totalAnswers - props.correctAnswers;
    const attainedPercentage =
        (parseFloat(correctQuantity) * 1.0) / parseFloat(props.totalAnswers);
    const singleTask = props.singleTask;

    const badgeIcon = (
        <FontAwesomeIcon icon={faAward} color={"#FFD500"} size={"lg"} />
    );

    const trophyIcon = (
        <FontAwesomeIcon icon={faTrophy} color={"#FFD500"} size={"4x"} />
    );

    useEffect(() => {}, []);

    const updateUser = () => {
        logEvent(analytics, "select_content", {
            content_type: "task",
            item_id: singleTask.id,
        });

        const uid = props.user.data.uid;
        let userProfile = props.userProfile.data;

        userProfile.completed_tasks[singleTask.id] = {
            points: Math.round(attainedPercentage * props.maxPoints),
            section_id: singleTask.sectionID,
            sub_section_id: singleTask.subSectionID,
            course_id: singleTask.courseID,
            type: singleTask.type,
            time: Date.now(),
        };

        /*
    console.log("attained percentage", attainedPercentage, props.correctAnswers, props.totalAnswers, props.maxPoints)
    console.log("user profile", userProfile.completed_tasks[singleTask.id])
    console.log("uid", uid)
    console.log(userProfile, uid) 
    */

        props.updateUserProfile(userProfile, uid);
        props.getCourseMetrics(userProfile, singleTask.courseID, uid);

        if (isAssignment) {
            props.history.push("/coaching");
        } else {
            props.history.push("/transition");
        }
    };

    return (
        <div className={props.classes.scorecardContainer}>
            <div className={props.classes.scorecardContent}>
                <div className={props.classes.scorecardHeader}>
                    <Typography variant="h5">Quiz Scorecard</Typography>
                    <Typography variant="h6">{singleTask.title}</Typography>
                </div>
                <PercentageBubble
                    score={attainedPercentage}
                    classes={props.classes}
                    trophyIcon={trophyIcon}
                    badgeIcon={badgeIcon}
                />
                <div className={props.classes.scorecardSecondRow}>
                    <div className={props.classes.scorecardAbsoluteValues}>
                        <AbsoluteValues
                            score={correctQuantity}
                            weight={"right"}
                            classes={props.classes}
                        />
                        <AbsoluteValues
                            score={incorrectQuantity}
                            weight={"wrong"}
                            classes={props.classes}
                            style={{ marginTop: "10px" }}
                        />
                    </div>
                    <PointsBubble
                        score={attainedPercentage * props.maxPoints}
                        maxScore={props.maxPoints}
                        classes={props.classes}
                    />
                    <ScorecardControls
                        updateUser={updateUser}
                        quizStateReset={props.quizStateReset}
                        classes={props.classes}
                        singleTask={props.singleTask}
                        isAssignment={isAssignment}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        tasks: state.content.tasks,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserProfile: (userProfile, uid) =>
            dispatch(updateUserProfile(userProfile, uid)),
        getCourseMetrics: (userProfile, courseID, userID) =>
            dispatch(getCourseMetrics(userProfile, courseID, userID)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Scorecard)
);

export function PercentageBubble(props) {
    let percentageValue = `${Math.round(props.score * 100)}%`;
    let badge = null;
    let message = "";

    let percentageStyle;
    let badgeStyle;
    let messageStyle;

    if (props.score < 0.7) {
        message = "Practice makes perfect!";
        percentageStyle = {
            color: "#FE4D3F",
            fontSize: "200%",
            fontWeight: "bold",
            display: "block",
            marginTop: "15px",
        };
    }

    if (props.score < 1 && props.score >= 0.7) {
        badge = props.badgeIcon;
        message = "Nice work!";
        percentageStyle = {
            color: "#4FE0B0",
            fontSize: "200%",
            fontWeight: "bold",
            display: "block",
            marginTop: "15px",
        };
        badgeStyle = { display: "inline-block", horizontalAlign: "top" };
        messageStyle = {
            display: "inline-block",
            horizontalAlign: "top",
            marginLeft: "10px",
        };
    }

    if (props.score === 1) {
        badge = props.trophyIcon;
        message = "AMAZING!";
        badgeStyle = { display: "inline-block", marginTop: "15px" };
        percentageStyle = {
            color: "#4FE0B0",
            fontSize: "200%",
            fontWeight: "bold",
            display: "block",
            marginTop: "25px",
        };
        messageStyle = { display: "block" };
    }

    let returnPattern =
        props.score === 1 ? (
            <div className={props.classes.scorecardPercentageHolder}>
                <div className="percentage-badge" style={badgeStyle}>
                    {badge}
                </div>
                <div
                    className="aesthetical-holder"
                    style={{ display: "inline-block", marginLeft: "15px" }}
                >
                    <div className="percentage-value" style={percentageStyle}>
                        {percentageValue}
                    </div>
                    <div className="percentage-message" style={messageStyle}>
                        {message}
                    </div>
                </div>
            </div>
        ) : (
            <div className={props.classes.scorecardPercentageHolder}>
                <div className="percentage-value" style={percentageStyle}>
                    {percentageValue}
                </div>
                <div className="percentage-badge" style={badgeStyle}>
                    {badge}
                </div>
                <div className="percentage-message" style={messageStyle}>
                    {message}
                </div>
            </div>
        );

    return returnPattern;
}

export function AbsoluteValues(props) {
    let toRender =
        props.weight === "right" ? (
            <FontAwesomeIcon icon={faCheck} color={"#4FE0B0"} size={"lg"} />
        ) : (
            <FontAwesomeIcon icon={faTimes} color={"#E3483E"} size={"lg"} />
        );

    return (
        <div
            className={props.classes.scorecardAbsolutesSub}
            style={props.style}
        >
            <div
                className={props.classes.scorecardAbsolutesBadge}
                style={{ marginLeft: "10px" }}
            >
                {toRender}
            </div>
            <div className={props.classes.scorecardAbsolutesValue}>
                {props.score}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    badgeContainer: {
        display: "inline-block",
    },
    giftIcon: {
        fontSize: 64,
        [theme.breakpoints.down('xl')]: {
            fontSize: 48,
        },
        [theme.breakpoints.down('lg')]: {
            marginTop: "5px",
        },
    },
    questionValue: {
        display: "inline-block",
        textAlign: "justify",
        marginLeft: "10px",
        marginTop: "25px",
        [theme.breakpoints.down('lg')]: {
            marginTop: "5px",
        },
    },
}));

export function PointsBubble(props) {
    const classes = useStyles();

    return (
        <div className={props.classes.pointsEarnedContainer}>
            <div className={classes.badgeContainer}>
                <FontAwesomeIcon
                    icon={faGift}
                    color={"#FFD500"}
                    className={classes.giftIcon}
                />
            </div>
            <div className={classes.questionValue}>
                <span
                    className="points-content"
                    style={{
                        display: "block",
                        fontWeight: "bold",
                        fontSize: "110%",
                    }}
                >
                    {Math.round(props.score)}
                </span>
                <span
                    className="points-content"
                    style={{ display: "block", fontSize: "95%" }}
                >
                    Points earned
                </span>
                <span
                    className="points-content"
                    style={{ display: "block", fontSize: "80%" }}
                >
                    Out of {props.maxScore} Quiz Total
                </span>
            </div>
        </div>
    );
}

export function ScorecardControls(props) {
    return (
        <div className="scorecard-controls">
            <button
                className={props.classes.upNextButton + " buttonAnimation"}
                onClick={props.updateUser}
            >
                {props.isAssignment ? "Complete Activity" : "Up Next"}
            </button>
            <button
                className={props.classes.retakeQuizButton + " buttonAnimation"}
                onClick={() => window.location.reload()}
            >
                Retake Quiz
            </button>
        </div>
    );
}
