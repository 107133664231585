import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import { DialogActions } from "@mui/material";

//Components
import CoachingSurveys from "./CoachingSurveys";
import { CustomDialogTitle } from "../Styled Components/DialogTitle";
import { StyledBlackButton } from "../Styled Components/Buttons";

// Firebase
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics()

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        [theme.breakpoints.down('lg')]: {
            margin: 16,
        },
    },
    dialogActions: {
        padding: 10,
        borderTop: "1px solid " + theme.palette.grey[400],
    },
}));

function CoachingSurveyDialog(props) {
    //Pass the color from the parent component to the styles
    const classes = useStyles(props);
    const [showNextButton, setShowNextButton] = useState(false);
    const [nextButtonClicked, setNextButtonClicked] = useState(false);
    const { visible, handleClose, email, fromTask } = props;

    useEffect(() => {
        if (visible) {
            logEvent(analytics, "select_content", {
                content_type: "coaching_surveys_dialog_opened",
            });
        }
    }, [visible]);

    const handleClick = () => {
        setShowNextButton(false);
        setNextButtonClicked(true);
    };

    return (
        <Dialog
            open={visible}
            maxWidth="md"
            classes={{ paper: classes.dialogPaper }}
        >
            <CustomDialogTitle onClose={handleClose}>
                Electric and Hybrid Car-Buying Simplified
            </CustomDialogTitle>
            {visible && (
                <CoachingSurveys
                    defaultEmail={email}
                    handleClose={handleClose}
                    showNextButton={() => setShowNextButton(true)}
                    nextButtonClicked={nextButtonClicked}
                    fromTask={fromTask}
                    open={visible}
                />
            )}
            {showNextButton && (
                <DialogActions className={classes.dialogActions}>
                    <StyledBlackButton onClick={handleClick}>
                        Next
                    </StyledBlackButton>
                </DialogActions>
            )}
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CoachingSurveyDialog);
