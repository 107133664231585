import React from "react";
import { connect } from "react-redux";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { CircularProgress, TextField, Typography } from "@mui/material";

//Actions
import { sendPasswordResetEmail } from "../../../actions/user_actions";

const useStyles = makeStyles((theme) => ({
    dialogBox: {
        margin: 20,
        [theme.breakpoints.down('xl')]: {
            margin: 0,
        },
    },
    textField: {
        width: "100%",
    },
}));

function ForgotPasswordModal(props) {
    //Pass the color from the parent component to the styles
    const classes = useStyles();

    const { visible, handleClose, emailAddress, passwordResetEmail, forward } =
        props;

    const [email, setEmail] = React.useState(emailAddress);

    const handleSubmit = () => {
        props.sendPasswordResetEmail({
            email,
            domain: window.location.host,
            forward: forward ? forward : null,
        });
    };

    return (
        <Dialog
            open={visible}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogContent style={{ padding: 5 }}>
                <div className={classes.dialogBox}>
                    {passwordResetEmail.loading ? (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    ) : passwordResetEmail.error !== null ? (
                        <div>
                            <Typography variant="h6">
                                Error: {passwordResetEmail.error}
                            </Typography>
                            <br />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </div>
                    ) : passwordResetEmail.data !== null ? (
                        <div>
                            <Typography variant="h6">
                                An email to reset your password has been sent to
                                your email address
                            </Typography>
                            <br />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Typography variant="h6">
                                Please enter the email address you used to
                                register:
                            </Typography>
                            <br />
                            <TextField
                                defaultValue={emailAddress}
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                                name="email"
                                variant="outlined"
                                label="Email"
                                autoFocus="true"
                                className={classes.textField}
                            />
                            <br />
                            <br />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                style={{ marginRight: 20 }}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        passwordResetEmail: state.user.passwordResetEmail,
    };
};

const mapDispatchToProps = {
    sendPasswordResetEmail,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordModal);
