import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Actions
import { openAlert } from "../actions/alert_actions";
import { verifyPasswordReset, resetPassword } from "../actions/user_actions";

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import {
    TextField,
    Button,
    CircularProgress,
    Typography,
    Paper,
    Grid,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "calc(100vh - 90px)",
    },
    main: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('xl')]: {
            height: 500,
        },
        [theme.breakpoints.down('lg')]: {
            height: 350,
        },
    },
}));

const paramsToObject = (entries) => {
    let result = {};
    for (let entry of entries) {
        // each 'entry' is a [key, value] tupple
        const [key, value] = entry;
        result[key] = value;
    }
    return result;
};

function PasswordReset(props) {
    const classes = useStyles();

    const { passwordResetVerification, passwordReset } = props;

    const urlParams = new URLSearchParams(props.location.search);
    const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
    const params = paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}

    const [invalidCode, setInvalidCode] = useState(false);

    const [auth, setAuth] = useState({
        email: "",
        password: "",
        confirm_password: "",
    });

    useEffect(() => {
        if (params.code) {
            props.verifyPasswordReset(params.code);
        } else {
            setInvalidCode(true);
        }
    }, []);

    useEffect(() => {
        //console.log("Verification", passwordResetVerification)
    }, [passwordResetVerification]);

    const handleSubmit = () => {
        if (auth.password === auth.confirm_password) {
            // Firebase needs very specfic parameters for resetting the password
            props.resetPassword({
                code: params.code,
                uid: passwordResetVerification.data.uid,
                password: auth.password,
            });
        } else {
            props.openAlert({
                text: "Passwords do not match. Please try again.",
            });
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                <Paper elevation={3} style={{ padding: 30 }}>
                    {passwordResetVerification.loading ||
                    passwordReset.loading ? (
                        <CircularProgress />
                    ) : passwordResetVerification.error != null ||
                      invalidCode ? (
                        <Typography variant="h6">
                            The password reset link is invalid.{" "}
                            {passwordResetVerification.error} Please try again.
                        </Typography>
                    ) : passwordReset.error != null ? (
                        <Typography variant="h6">
                            There was an error while resetting your password.
                            Please try again.
                        </Typography>
                    ) : passwordReset.data !== null ? (
                        <div>
                            <Typography variant="h6">
                                Your password has successfully been reset.
                            </Typography>
                            <Grid
                                container
                                justifyContent="flex-end"
                                style={{ marginTop: 15 }}
                            >
                                <Link
                                    to={`/login/?forward=${passwordResetVerification.data.forward}`}
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button variant="contained" color="primary">
                                        Login
                                    </Button>
                                </Link>
                            </Grid>
                        </div>
                    ) : passwordResetVerification.data != null ? (
                        <div>
                            <Typography variant="h6">
                                Reset the password for:
                                <br />
                                {passwordResetVerification.data.email}
                            </Typography>
                            <br />
                            <TextField
                                onChange={(event) =>
                                    setAuth({
                                        ...auth,
                                        password: event.target.value,
                                    })
                                }
                                name="password"
                                type="password"
                                className={classes.textField}
                                variant="outlined"
                                label="Password"
                            />
                            <br />
                            <br />
                            <TextField
                                onChange={(event) =>
                                    setAuth({
                                        ...auth,
                                        confirm_password: event.target.value,
                                    })
                                }
                                onKeyDown={(e) =>
                                    e.key === "Enter" && handleSubmit()
                                }
                                name="confirm_password"
                                type="password"
                                className={classes.textField}
                                variant="outlined"
                                label="Confirm Password"
                            />
                            <br />
                            <br />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    ) : null}
                </Paper>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        passwordResetVerification: state.user.passwordResetVerification,
        passwordReset: state.user.passwordReset,
    };
};

const mapDispatchToProps = {
    openAlert,
    verifyPasswordReset,
    resetPassword,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
);
