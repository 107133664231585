import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";

// Actions
import { signInUser } from "../../../actions/user_actions";

//Styles
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";

// Components
import CoachingProfileSelection from "./CoachingProfileSelection";

// Firebase
import { getFirestore, collection, query, getDocs } from "firebase/firestore"

// Helpers
import { paramsToObject } from "../../../helpers/functions";

// Get Firestore reference
var db = getFirestore()

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
}));

function CoachingProfiles(props) {
    const classes = useStyles();

    const urlParams = new URLSearchParams(props.location.search);
    const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
    const params = paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}

    const [users, setUsers] = useState({
        data: [],
        filteredData: [],
        newUserCount: 0,
        loading: false,
    });
    const [coachingProfiles, setCoachingProfiles] = useState({
        data: [],
        filteredData: [],
        newUserCount: 0,
        loading: false,
    });

    useEffect(() => {
        if (
            props.user.data != null &&
            props.userProfile.data != null &&
            props.userProfile.data.admin
        ) {
            if (!params.coachingProfile) {
                getUsers();
            }
            getCoachingProfiles();
        }
    }, []);

    const getUsers = async () => {
        setUsers({
            ...users,
            loading: true,
        });

        try {
            const q = query(collection(db, "users"));

            const querySnapshot = await getDocs(q);
            let data = [];
            querySnapshot.forEach(function (doc) {
                let user = doc.data();
                let points = 0;
                let completed_tasks_count = 0;

                for (let i in user.completed_tasks) {
                    points += user.completed_tasks[i].points;
                    completed_tasks_count += 1;
                }

                user.points = points;
                user.completed_tasks_count = completed_tasks_count;
                user.id = doc.id;
                data.push(user);
            });

            const newUsers = data.filter(
                (item) =>
                    item.date_created &&
                    moment(item.date_created.toDate()).isAfter(
                        moment().subtract(7, "day")
                    )
            );
            setUsers({
                ...users,
                data,
                filteredData: data,
                newUserCount: newUsers.length,
                loading: false,
            });
        } catch(error) {
            setUsers({
                ...users,
                loading: false,
            });
            console.log("Error getting document:", error);
        }
    };

    const getCoachingProfiles = () => {
        setCoachingProfiles({
            ...coachingProfiles,
            loading: true,
        });

        if (params.coachingProfile) {
            db.collection("coaching_profiles")
                .doc(params.coachingProfile)
                .get()
                .then(function (doc) {
                    let data = doc.data() ? [doc.data()] : [];

                    setCoachingProfiles({
                        ...coachingProfiles,
                        data,
                        loading: false,
                    });
                })
                .catch(function (error) {
                    setCoachingProfiles({
                        ...coachingProfiles,
                        loading: false,
                    });
                    console.log("Error getting document:", error);
                });
        } else {
            db.collection("coaching_profiles")
                .get()
                .then(function (querySnapshot) {
                    let data = [];
                    querySnapshot.forEach(function (doc) {
                        let user = doc.data();
                        data.push(user);
                    });

                    setCoachingProfiles({
                        ...coachingProfiles,
                        data,
                        loading: false,
                    });
                })
                .catch(function (error) {
                    setCoachingProfiles({
                        ...coachingProfiles,
                        loading: false,
                    });
                    console.log("Error getting document:", error);
                });
        }
    };

    return (
        <div className={classes.root}>
            {users.loading || coachingProfiles.loading ? (
                <CircularProgress color="primary" />
            ) : (
                <CoachingProfileSelection
                    users={users}
                    coachingProfiles={coachingProfiles}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    userProfile: state.user.userProfile,
});

const mapDispatchToProps = {
    signInUser,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CoachingProfiles)
);
