import {
    UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_BEGIN,
    UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_ERROR,
    UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_SUCCESS,
    UPDATE_SEND_GRID_CONTACT_BEGIN,
    UPDATE_SEND_GRID_CONTACT_ERROR,
    UPDATE_SEND_GRID_CONTACT_SUCCESS,
} from "../constants/action-types-sendgrid";

const initialState = {
    earthDayPreRegistrationContact: {
        data: null,
        loading: false,
        error: null,
    },
    sendGridContact: {
        data: null,
        loading: false,
        error: null,
    },
};

const sendgrid = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_BEGIN:
            return {
                ...state,
                earthDayPreRegistrationContact: {
                    data: null,
                    loading: true,
                    error: null,
                },
            };

        case UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_ERROR:
            return {
                ...state,
                earthDayPreRegistrationContact: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case UPDATE_EARTH_DAY_PRE_REGISTRATION_CONTACT_SUCCESS:
            return {
                ...state,
                earthDayPreRegistrationContact: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case UPDATE_SEND_GRID_CONTACT_BEGIN:
            return {
                ...state,
                sendGridContact: { data: null, loading: true, error: null },
            };

        case UPDATE_SEND_GRID_CONTACT_ERROR:
            return {
                ...state,
                sendGridContact: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case UPDATE_SEND_GRID_CONTACT_SUCCESS:
            return {
                ...state,
                sendGridContact: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        default:
            return state;
    }
};

export default sendgrid;
