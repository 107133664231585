import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions
import { getSingleTask, getTasks } from "../actions/content_actions";

// Assets
import IndependenceLadyStatue from "../static/images/2021-independence-lady-statue.png";
import GiftCardVisa100 from "../static/images/gift-card-visa-100.png";

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import {
    Box,
    Button,
    Grid,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faGrinBeam,
    faListAlt,
} from "@fortawesome/free-solid-svg-icons";
import StepConnector from "@mui/material/StepConnector";
import EVCoachMessage from "../components/default/Login/EVCoachMessage";
import { StyledTaskButton } from "../components/default/Styled Components/Buttons";
import TypeWriter from "../components/default/Styled Components/TypeWriter";
import ReferDialog from "../components/la_county/Refer/ReferDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        //overflowY: 'auto',
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "#141C3A",
        color: "white",
    },
    content: {
        maxWidth: 800,
        marginTop: 20,
        marginBottom: 20,
    },
    stepper: {
        //maxWidth: '100%',
        backgroundColor: "#141C3A",
    },
    title: {
        margin: 15,
    },
    subSectionOverview: {
        width: "100%",
        marginBottom: 10
    },
    nextButton: {
        backgroundColor: "#459df9",
        color: "white",
        "&:hover": {
            backgroundColor: "#1f64ad",
        },
    },
    backButton: {
        margin: 20,
        borderColor: "white",
        color: "white",
        "&:hover": {
            backgroundColor: "#000A2A",
        },
    },
    stepLabel: {
        color: "LightGray !important",
        [theme.breakpoints.down('xl')]: {
            textAlign: "left",
        },
    },
    stepActiveLabel: {
        color: "white !important",
        fontWeight: "900 !important",
    },
    bounce: {
        animation: `$bounce 3s ${theme.transitions.easing.easeInOut} infinite`,
    },
    "@keyframes bounce": {
        "0%, 33%, 7%, 17%, 27%": {
            webkitTransform: "translateY(0)",
            msTransform: "translateY(0)",
            transform: "translateY(0)",
        },
        "13%": {
            webkitTransform: "translateY(-30px)",
            msTransform: "translateY(-30px)",
            transform: "translateY(-30px)",
        },
        "20%": {
            webkitTransform: "translateY(-15px)",
            msTransform: "translateY(-15px)",
            transform: "translateY(-15px)",
        },
    },
}));

function StepIcon(props) {
    const { upcoming, userProfile, id, type } = props;

    var iconStyles = {
        width: 45,
        height: 45,
        color: "rgba(255, 213, 0, 1)",
    };

    if (
        userProfile.data != null &&
        userProfile.data.completed_tasks &&
        !userProfile.data.completed_tasks[id]
    )
        iconStyles.color = "white";

    return (
        <div style={{ zIndex: 1, minWidth: 80 }}>
            <Typography
                variant="body1"
                style={{
                    textTransform: "capitalize",
                    color: "white",
                    margin: 10,
                    textAlign: "center",
                }}
            >
                {type}
            </Typography>
            <FontAwesomeIcon
                icon={
                    userProfile.data != null &&
                    userProfile.data.completed_tasks &&
                    !userProfile.data.completed_tasks[id]
                        ? faListAlt
                        : faCheckCircle
                }
                style={iconStyles}
            />
        </div>
    );
}

const Connector = styled(StepConnector)(({ theme }) => ({
    ['&.MuiStepConnector-alternativeLabel']: {
        top: 65,
    },
    ['& .MuiStepConnector-line']: {
        borderTopWidth: 3,
        backgroundColor: "gray",
        borderRadius: 1,
        [theme.breakpoints.down('xl')]: {
            height: "auto",
            marginLeft: 26,
            marginTop: -5,
            width: 3,
        },
    },
}));

function Transition(props) {
    const classes = useStyles();

    const { tasks, singleTask, userProfile } = props;

    const [activeStep, setActiveStep] = useState(0);
    const [nextTaskID, setNextTaskID] = useState(0);
    const [visible, toggleModal] = useState(false);

    const [steps, setSteps] = useState([]);

    const getLocalStorageItem = (item) => {
        try {
            return window.localStorage.getItem(item);
        } catch(e) {
            console.log(e)
            return null
        }
    }
    const currentTaskID = getLocalStorageItem("currentTaskID");
    const currentSubSectionID = getLocalStorageItem("currentSubSectionID");

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    });

    const updateWidth = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        if (
            currentTaskID !== null &&
            currentTaskID !== "null" &&
            currentSubSectionID !== null &&
            currentSubSectionID !== "null"
        ) {
            props.getSingleTask(currentTaskID);
            props.getTasks(currentSubSectionID);
            try {
                window.localStorage.setItem("currentTaskID", null);
                window.localStorage.setItem("currentSubSectionID", null);
            } catch(e) {
                console.log(e)
            }
        }
    }, []);

    useEffect(() => {
        if (singleTask.data != null && tasks.data != null) {
            let taskGroup = tasks.data[singleTask.data.subSectionID];
            let stepList = [];
            let afterStep = false;
            let isNextTask = false;

            for (let i in taskGroup) {
                let task = taskGroup[i];
                if (afterStep) {
                    stepList.push({
                        type: task.type,
                        label: task.title,
                        id: task.id,
                        upcoming: true,
                    });
                } else {
                    stepList.push({
                        type: task.type,
                        label: task.title,
                        id: task.id,
                        upcoming: false,
                    });
                }

                if (task.id === singleTask.data.id) {
                    setActiveStep(parseInt(i));

                    if (parseInt(i) + 1 < taskGroup.length) {
                        let nextTask = taskGroup[parseInt(i) + 1];
                        isNextTask = true;
                        setNextTaskID(nextTask.id);
                    }

                    afterStep = true;
                }
            }

            const subSectionIndex = Object.keys(tasks.data).indexOf(
                singleTask.data.subSectionID
            );
            //console.log("Sub-section index", subSectionIndex)
            //console.log("Object keys", Object.keys(tasks.data))

            const nextSubSection = Object.keys(tasks.data)[subSectionIndex + 1];
            if (!isNextTask && nextSubSection) {
                const nextTask = tasks.data[nextSubSection][0];
                setNextTaskID(nextTask.id);
            }

            setSteps(stepList);
        } /* else {
            props.history.push('/')
        } */
    }, [tasks.data, singleTask.data]);

    return (
        <div className={classes.root}>
            {currentTaskID === "hqmtUf6vECz5BTgVPjKR" ||
            (singleTask.data != null &&
                singleTask.data.id === "hqmtUf6vECz5BTgVPjKR") ? (
                <div className={classes.content}>
                    <EVCoachMessage
                        //noSidePadding
                        coachImage={IndependenceLadyStatue}
                        message={
                            <div style={{ color: "black" }}>
                                <Typography
                                    variant="h4"
                                    align="center"
                                    style={{ fontWeight: 600 }}
                                >
                                    Hooray – You Did It !
                                </Typography>
                                <Box m={1.5} />
                                <Typography variant="h6">
                                    You just completed “Independence from Fossil
                                    Fuels”… We hope you enjoyed the experience!
                                </Typography>
                                <Box m={1.5} />
                                <Typography
                                    variant="h5"
                                    align="center"
                                    style={{ fontWeight: 600 }}
                                >
                                    Want to help make our community fossil fuel
                                    free?
                                </Typography>
                                <Box m={1.5} />
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={9}>
                                        <Typography variant="h6">
                                            Help your peers learn about electric
                                            cars too by referring them to the
                                            contest. Each referral earns you a
                                            chance to win a $100 gift card
                                            prize!
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <img
                                            src={GiftCardVisa100}
                                            style={{ maxWidth: "100%" }}
                                            alt="gift-card"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    />

                    <Grid container justifyContent="center">
                        <Grid item>
                            <StyledTaskButton
                                className={classes.bounce}
                                onClick={() => toggleModal(true)}
                            >
                                REFER A FRIEND
                            </StyledTaskButton>
                            <br />
                            <br />
                            <TypeWriter
                                italicize={true}
                                textToBeTyped={[
                                    "To help create a fossil fuel free LA!",
                                    "To start earning chances to win!",
                                    "To help your peers learn about electric cars",
                                ]}
                            />
                        </Grid>
                    </Grid>

                    <ReferDialog
                        visible={visible}
                        handleClose={() => toggleModal(false)}
                    />
                </div>
            ) : (
                <div className={classes.content}>
                    <FontAwesomeIcon
                        icon={faGrinBeam}
                        size="4x"
                        color="rgba(255, 213, 0, 1)"
                    />
                    <Typography variant="h3" className={classes.title}>
                        Hooray!
                    </Typography>
                    <Typography variant="h6">
                        Great job finishing that lesson. Keep going!
                    </Typography>

                    <div className={classes.subSectionOverview}>
                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel={width < 600 ? false : true}
                            connector={<Connector />}
                            className={classes.stepper}
                            orientation={
                                width < 600 ? "vertical" : "horizontal"
                            }
                        >
                            {steps.map((step) => (
                                <Step key={step.label}>
                                    <StepLabel
                                        classes={{
                                            label: classes.stepLabel,
                                            active: classes.stepActiveLabel,
                                        }}
                                        StepIconComponent={() => (
                                            <StepIcon
                                                upcoming={step.upcoming}
                                                userProfile={userProfile}
                                                id={step.id}
                                                type={step.type}
                                            />
                                        )}
                                    >
                                        <Typography
                                            variant="body1"
                                            style={{ fontWeight: "inherit" }}
                                        >
                                            {step.label}
                                        </Typography>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>

                    {nextTaskID ? (
                        <div>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    props.history.push(`/task/${nextTaskID}`)
                                }
                                className={classes.bounce}
                                classes={{ root: classes.nextButton }}
                            >
                                Start next task
                            </Button>
                        </div>
                    ) : null}

                    { nextTaskID ? (
                        // Show the normal "Back to My Syllabus" button if a next task exists.
                        <Button
                            variant="outlined"
                            onClick={() =>
                                singleTask.data !== null
                                    ? props.history.push(
                                          `/sections/${singleTask.data.courseID}`
                                      )
                                    : props.history.push("/contests")
                            }
                            classes={{ root: classes.backButton }}
                        >
                            Back to My Syllabus
                        </Button>
                    ) :(
                        // Show the bouncing highlighted "Back to My Syllabus" button if NO next task exists.
                        <Button
                            variant="outlined"
                            onClick={() =>
                                singleTask.data !== null
                                    ? props.history.push(
                                          `/sections/${singleTask.data.courseID}`
                                      )
                                    : props.history.push("/contests")
                            }
                            className={classes.bounce}
                            classes={{ root: classes.nextButton }}
                        >
                            Back to My Syllabus
                        </Button>
                    )}
                    
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
        singleTask: state.content.singleTask,
        tasks: state.content.tasks,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSingleTask: (id) => dispatch(getSingleTask(id)),
        getTasks: (id) => dispatch(getTasks(id)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Transition)
);
