export const ADD_PRE_REGISTRATION_SURVEY_BEGIN =
    "ADD_PRE_REGISTRATION_SURVEY_BEGIN";
export const ADD_PRE_REGISTRATION_SURVEY_ERROR =
    "ADD_PRE_REGISTRATION_SURVEY_ERROR";
export const ADD_PRE_REGISTRATION_SURVEY_SUCCESS =
    "ADD_PRE_REGISTRATION_SURVEY_SUCCESS";

export const GET_PRE_REGISTRATION_SURVEY_BEGIN =
    "GET_PRE_REGISTRATION_SURVEY_BEGIN";
export const GET_PRE_REGISTRATION_SURVEY_ERROR =
    "GET_PRE_REGISTRATION_SURVEY_ERROR";
export const GET_PRE_REGISTRATION_SURVEY_SUCCESS =
    "GET_PRE_REGISTRATION_SURVEY_SUCCESS";
