import {
    GET_ORGANIZATION_BEGIN,
    GET_ORGANIZATION_ERROR,
    GET_ORGANIZATION_SUCCESS,
    GET_COURSES_BEGIN,
    GET_COURSES_ERROR,
    GET_COURSES_SUCCESS,
    GET_SECTIONS_BEGIN,
    GET_SECTIONS_ERROR,
    GET_SECTIONS_SUCCESS,
    GET_SUB_SECTIONS_BEGIN,
    GET_SUB_SECTIONS_ERROR,
    GET_SUB_SECTIONS_SUCCESS,
    GET_TASKS_BEGIN,
    GET_TASKS_ERROR,
    GET_TASKS_SUCCESS,
    GET_ALL_TASKS_BEGIN,
    GET_ALL_TASKS_ERROR,
    GET_ALL_TASKS_SUCCESS,
    GET_QUIZ_BEGIN,
    GET_QUIZ_ERROR,
    GET_QUIZ_SUCCESS,
    GET_LESSON_BEGIN,
    GET_LESSON_ERROR,
    GET_LESSON_SUCCESS,
    GET_ACTIVITY_BEGIN,
    GET_ACTIVITY_ERROR,
    GET_ACTIVITY_SUCCESS,
    GET_COURSE_METRICS_BEGIN,
    GET_COURSE_METRICS_ERROR,
    GET_COURSE_METRICS_SUCCESS,
    GET_SINGLE_TASK_BEGIN,
    GET_SINGLE_TASK_ERROR,
    GET_SINGLE_TASK_SUCCESS,
    GET_HOME_PAGE_BEGIN,
    GET_HOME_PAGE_SUCCESS,
    GET_HOME_PAGE_ERROR,
    GET_CAMPAIGN_BEGIN,
    GET_CAMPAIGN_SUCCESS,
    GET_CAMPAIGN_ERROR,
    GET_ALL_CAMPAIGNS_BEGIN,
    GET_ALL_CAMPAIGNS_ERROR,
    GET_ALL_CAMPAIGNS_SUCCESS,
    GET_ASSIGNMENTS_BEGIN,
    GET_ASSIGNMENTS_ERROR,
    GET_ASSIGNMENTS_SUCCESS,
    GET_INCENTIVES_BEGIN,
    GET_INCENTIVES_SUCCESS,
    GET_INCENTIVES_ERROR,
    GET_EV101_BEGIN,
    GET_EV101_SUCCESS,
    GET_EV101_ERROR,
} from "../constants/action-types-content";

// Firebase
import { getFirestore, collection, getDoc, where, query, getDocs, doc } from "firebase/firestore"

//LogRocket
// import LogRocket from "logrocket";

// Get Firestore reference
const db = getFirestore();

export const getOrganizationBegin = () => ({ type: GET_ORGANIZATION_BEGIN });
export const getOrganizationSuccess = (result) => ({
    type: GET_ORGANIZATION_SUCCESS,
    payload: result,
});
export const getOrganizationError = (error) => ({
    type: GET_ORGANIZATION_ERROR,
    payload: error,
});

export const getOrganization = (organization) => async (dispatch) => {
    dispatch(getOrganizationBegin());
    try {
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "organizations"),
            where("organization", "==", organization)
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            data.push(doc.data());
        });
        dispatch(getOrganizationSuccess(data[0]));
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getOrganizationError(error));
    }
}

export const getCampaignBegin = () => ({ type: GET_CAMPAIGN_BEGIN });
export const getCampaignSuccess = (result) => ({
    type: GET_CAMPAIGN_SUCCESS,
    payload: result,
});
export const getCampaignError = (error) => ({
    type: GET_CAMPAIGN_ERROR,
    payload: error,
});

export const getCampaign = (campaignRef) => async (dispatch) => {
    dispatch(getCampaignBegin());
    try {
        const doc = await getDoc(campaignRef)
        if (doc.exists) {
            dispatch(getCampaignSuccess(doc.data()));
        } else {
            dispatch(getCampaignError("No such document!"));
        }
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getCampaignError(error));
    }
}

export const getCampaignFromKey = (key) => async (dispatch) => {
    dispatch(getCampaignBegin());
    try {
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "campaigns"),
            where("keyIdentifier", "==", key)
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            data.push(doc.data());
        });
        dispatch(getCampaignSuccess(data[0]));
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getCampaignError(error));
    }
};

export const getAllCampaignsBegin = () => ({ type: GET_ALL_CAMPAIGNS_BEGIN });
export const getAllCampaignsSuccess = (result) => ({
    type: GET_ALL_CAMPAIGNS_SUCCESS,
    payload: result,
});
export const getAllCampaignsError = (error) => ({
    type: GET_ALL_CAMPAIGNS_ERROR,
    payload: error,
});

export const getAllCampaigns = () => async (dispatch) => {
    dispatch(getAllCampaignsBegin());
    try {
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "campaigns"),
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            data.push(doc.data());
        });
        dispatch(getAllCampaignsSuccess(data));
    } catch(error) {
        dispatch(getAllCampaignsError(error));
    }
};

export const getHomePageBegin = () => ({ type: GET_HOME_PAGE_BEGIN });
export const getHomePageSuccess = (result) => ({
    type: GET_HOME_PAGE_SUCCESS,
    payload: result,
});
export const getHomePageError = (error) => ({
    type: GET_HOME_PAGE_ERROR,
    payload: error,
});

export const getHomePage = (organization) => async (dispatch) => {
    dispatch(getHomePageBegin());
    try {
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "homePage"),
            where("organization", "==", organization)
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            data.push(doc.data());
        });
        dispatch(getHomePageSuccess(data[0]));
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getHomePageError(error));
    }
};

export const getCoursesBegin = () => ({ type: GET_COURSES_BEGIN });
export const getCoursesSuccess = (result) => ({
    type: GET_COURSES_SUCCESS,
    payload: result,
});
export const getCoursesError = (error) => ({
    type: GET_COURSES_ERROR,
    payload: error,
});

export const getCourses = (organization) => async (dispatch) => {
    dispatch(getCoursesBegin());
    try {
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "courses"),
            where("organization", "array-contains", organization)
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            data.push(doc.data());
        });
        dispatch(getCoursesSuccess(data));
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getCoursesError(error));
    }
};

export const getSectionsBegin = () => ({ type: GET_SECTIONS_BEGIN });
export const getSectionsSuccess = (result) => ({
    type: GET_SECTIONS_SUCCESS,
    payload: result,
});
export const getSectionsError = (error) => ({
    type: GET_SECTIONS_ERROR,
    payload: error,
});

export const getSections = (courseID) => async (dispatch) => {
    dispatch(getSectionsBegin());
    try {
        const courseDocRef = doc(db, "fl_content", courseID)
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "sections"),
            where("course", "==", courseDocRef)
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            let section = doc.data();
            data.push(section);
        });
        dispatch(getSectionsSuccess(data));
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getSectionsError(error));
    }
};

export const getSubSectionsBegin = () => ({ type: GET_SUB_SECTIONS_BEGIN });
export const getSubSectionsSuccess = (result) => ({
    type: GET_SUB_SECTIONS_SUCCESS,
    payload: result,
});
export const getSubSectionsError = (error) => ({
    type: GET_SUB_SECTIONS_ERROR,
    payload: error,
});

export const getSubSections = (sectionID) => async (dispatch) => {
    dispatch(getSubSectionsBegin());
    try {
        const sectionDocRef = doc(db, "fl_content", sectionID)
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "sub-sections"),
            where("section", "==", sectionDocRef)
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            let subSection = doc.data();
            data.push(subSection);
        });
        dispatch(getSubSectionsSuccess({ data: data, section: sectionID }));
    } catch(error) {
        console.log("Error fetching sub-sections", error);
        // LogRocket.captureException(error);
        dispatch(getSubSectionsError(error));
    }
};

export const getTasksBegin = () => ({ type: GET_TASKS_BEGIN });
export const getTasksSuccess = (result) => ({
    type: GET_TASKS_SUCCESS,
    payload: result,
});
export const getTasksError = (error) => ({
    type: GET_TASKS_ERROR,
    payload: error,
});

export const getTasks = (sub_sectionID) => async (dispatch) => {
    dispatch(getTasksBegin());
    try {
        const subSectionDocRef = doc(db, "fl_content", sub_sectionID)
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "tasks"),
            where("sub-section", "==", subSectionDocRef)
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            let task = doc.data();
            data.push(task);
        });
        dispatch(getTasksSuccess({ data: data, sub_section: sub_sectionID }));
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getTasksError(error));
    }
};

export const getAllTasksBegin = () => ({ type: GET_ALL_TASKS_BEGIN });
export const getAllTasksSuccess = (result) => ({
    type: GET_ALL_TASKS_SUCCESS,
    payload: result,
});
export const getAllTasksError = (error) => ({
    type: GET_ALL_TASKS_ERROR,
    payload: error,
});

export const getAllTasks = (sub_sectionID) => async (dispatch) => {
    dispatch(getAllTasksBegin());
    try {
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "tasks"),
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            let task = doc.data();
            data.push(task);
        });
        dispatch(getAllTasksSuccess(data));
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getAllTasksError(error));
    }
};

export const getQuizBegin = () => ({ type: GET_QUIZ_BEGIN });
export const getQuizSuccess = (result) => ({
    type: GET_QUIZ_SUCCESS,
    payload: result,
});
export const getQuizError = (error) => ({
    type: GET_QUIZ_ERROR,
    payload: error,
});

export const getQuiz = (taskID) => async (dispatch) => {
    dispatch(getQuizBegin());
    try {
        const taskDocRef = doc(db, "fl_content", taskID)
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "quiz"),
            where("task", "array-contains", taskDocRef)
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            let task = doc.data();
            data.push(task);
        });
        dispatch(getQuizSuccess(data[0]));
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getQuizError(error));
    }
};

export const getLessonBegin = () => ({ type: GET_LESSON_BEGIN });
export const getLessonSuccess = (result) => ({
    type: GET_LESSON_SUCCESS,
    payload: result,
});
export const getLessonError = (error) => ({
    type: GET_LESSON_ERROR,
    payload: error,
});

export const getLesson = (taskID) => async (dispatch) => {
    dispatch(getLessonBegin());
    try {
        const taskDocRef = doc(db, "fl_content", taskID)
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "lessons"),
            where("task", "array-contains", taskDocRef)
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            let task = doc.data();
            data.push(task);
        });
        dispatch(getLessonSuccess(data[0]));
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getLessonError(error));
    }
};

export const getActivityBegin = () => ({ type: GET_ACTIVITY_BEGIN });
export const getActivitySuccess = (result) => ({
    type: GET_ACTIVITY_SUCCESS,
    payload: result,
});
export const getActivityError = (error) => ({
    type: GET_ACTIVITY_ERROR,
    payload: error,
});

export const getActivity = (taskID) => async (dispatch) => {
    dispatch(getActivityBegin());
    try {
        const taskDocRef = doc(db, "fl_content", taskID)
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "activities"),
            where("task", "array-contains", taskDocRef)
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            let task = doc.data();
            data.push(task);
        });
        dispatch(getActivitySuccess(data[0]));
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getActivityError(error));
    }
};

export const getCourseMetricsBegin = () => ({ type: GET_COURSE_METRICS_BEGIN });
export const getCourseMetricsSuccess = (courseID, result) => ({
    type: GET_COURSE_METRICS_SUCCESS,
    payload: { courseID, result },
});
export const getCourseMetricsError = (error) => ({
    type: GET_COURSE_METRICS_ERROR,
    payload: error,
});

export function getCourseMetrics(userProfile, courseID, userID) {
    return async (dispatch) => {
        dispatch(getCourseMetricsBegin());

        const completedTasks = userProfile.completed_tasks;

        let completedPointsCounter = 0;
        let totalPointsCounter = 0;
        let totalTasksCounter = 0;
        let timeCounter = 0;
        let quizCounter = 0;
        let allTasks = [];

        try {
            const courseDocRef = doc(db, "fl_content", courseID)
            const q1 = query(
                collection(db, "fl_content"),
                where("_fl_meta_.env", "==", "production"),
                where("_fl_meta_.locale", "==", "en-US"),
                where("_fl_meta_.schema", "==", "sections"),
                where("course", "==", courseDocRef)
            );
            let sections = await getDocs(q1);

            for (const section of sections.docs) {
                const sectionDocRef = doc(db, "fl_content", section.data().id)
                const q2 = query(
                    collection(db, "fl_content"),
                    where("_fl_meta_.env", "==", "production"),
                    where("_fl_meta_.locale", "==", "en-US"),
                    where("_fl_meta_.schema", "==", "sub-sections"),
                    where("section", "==", sectionDocRef)
                );
                let subSections = await getDocs(q2);

                for (const subSection of subSections.docs) {
                    const subSectionData = subSection.data();
                    const subSectionDocRef = doc(db, "fl_content", subSectionData.id)
                    const q3 = query(
                        collection(db, "fl_content"),
                        where("_fl_meta_.env", "==", "production"),
                        where("_fl_meta_.locale", "==", "en-US"),
                        where("_fl_meta_.schema", "==", "tasks"),
                        where("sub-section", "==", subSectionDocRef)
                    );
                    let tasks = await getDocs(q3);

                    let completedTaskForSubSectionCount = 0;
                    for (const task of tasks.docs) {
                        let taskData = task.data();
                        allTasks[taskData.id] = taskData;

                        if (taskData.points > 0)
                            totalPointsCounter += taskData.points;

                        if (taskData.time > 0) timeCounter += taskData.time;

                        if (taskData.type === "quiz") quizCounter += 1;

                        if (completedTasks[taskData.id]) {
                            completedTaskForSubSectionCount += 1;
                            if (
                                taskData.type === "quiz" &&
                                subSectionData.pointsBonus &&
                                completedTasks[taskData.id].points ===
                                    taskData.points
                            )
                                completedPointsCounter +=
                                    subSectionData.pointsBonusPoints || 20;
                        }

                        totalTasksCounter += 1;
                    }

                    if (
                        tasks.docs.length > 0 &&
                        subSectionData.completionBonus &&
                        completedTaskForSubSectionCount === tasks.docs.length
                    ) {
                        completedPointsCounter +=
                            subSectionData.completionBonusPoints || 10;
                    }
                }
            }

            let completedTasksCounter = 0;
            let perfectQuizScoreCounter = 0;

            for (let prop in completedTasks) {
                //Make sure the task hasn't been deleted
                if (allTasks[prop]) {
                    let points = completedTasks[prop].points;
                    if (points) {
                        if (points === parseInt(points, 10))
                            completedPointsCounter +=
                                completedTasks[prop].points;
                        else
                            completedPointsCounter += parseInt(
                                completedTasks[prop].points
                            );
                    }

                    if (completedTasks[prop].type === "quiz" && points === 30)
                        perfectQuizScoreCounter += 1;

                    completedTasksCounter += 1;
                }
            }

            let completedTasksPercentage =
                totalTasksCounter !== 0
                    ? Math.round(
                          (completedTasksCounter / totalTasksCounter) * 100
                      )
                    : 0;
            let bonusMultiplier = 1;

            /* if (completedTasksCounter === totalTasksCounter && totalTasksCounter !== 0) {
                bonusMultiplier += .1
            }

            if (perfectQuizScoreCounter === quizCounter && quizCounter !== 0) {
                bonusMultiplier += .5
            } */

            //Multiply completed points by bonus multiplier
            completedPointsCounter *= bonusMultiplier;

            let data = {
                completedPointsCounter: Math.round(completedPointsCounter),
                completedTasksCounter,
                totalPointsCounter,
                totalTasksCounter,
                timeCounter,
                completedTasksPercentage,
                bonusMultiplier,
            };
            console.log("action", courseID, data);
            dispatch(getCourseMetricsSuccess(courseID, data));
        } catch (error) {
            console.log(error)
            // LogRocket.captureException(error);
            dispatch(getCourseMetricsError(error));
        }
    };
}

export const getSingleTaskBegin = () => ({ type: GET_SINGLE_TASK_BEGIN });
export const getSingleTaskSuccess = (result) => ({
    type: GET_SINGLE_TASK_SUCCESS,
    payload: result,
});
export const getSingleTaskError = (error) => ({
    type: GET_SINGLE_TASK_ERROR,
    payload: error,
});

export function getSingleTask(id, appendData = true) {
    return async (dispatch) => {
        dispatch(getSingleTaskBegin());

        try {
            var taskData = {};
            const q = query(
                collection(db, "fl_content"),
                where("_fl_meta_.env", "==", "production"),
                where("_fl_meta_.locale", "==", "en-US"),
                where("_fl_meta_.schema", "==", "tasks"),
                where("id", "==", id)
            );
            let task = await getDocs(q);
            taskData = task.docs[0].data();

            if (appendData) {
                taskData["subSectionID"] = taskData["sub-section"].id;

                let subSection = await getDocs(query(
                    collection(db, "fl_content"),
                    where("_fl_meta_.env", "==", "production"),
                    where("_fl_meta_.locale", "==", "en-US"),
                    where("_fl_meta_.schema", "==", "sub-sections"),
                    where("id", "==", taskData["sub-section"].id)
                ))

                taskData["sectionID"] = subSection.docs[0].data().section.id;

                let section = await getDocs(query(
                    collection(db, "fl_content"),
                    where("_fl_meta_.env", "==", "production"),
                    where("_fl_meta_.locale", "==", "en-US"),
                    where("_fl_meta_.schema", "==", "sections"),
                    where("id", "==", taskData["sectionID"])
                ))

                taskData["courseID"] = section.docs[0].data().course.id;

                let course = await getDocs(query(
                    collection(db, "fl_content"),
                    where("_fl_meta_.env", "==", "production"),
                    where("_fl_meta_.locale", "==", "en-US"),
                    where("_fl_meta_.schema", "==", "courses"),
                    where("id", "==", taskData["courseID"])
                ))

                console.log("The course data is ", course.docs[0].data());
                taskData["organizations"] = course.docs[0].data().organization;
            }

            dispatch(getSingleTaskSuccess(taskData));
        } catch (error) {
            // LogRocket.captureException(error);
            dispatch(getSingleTaskError(error));
        }
    };
}

export const getAssignmentsBegin = () => ({ type: GET_ASSIGNMENTS_BEGIN });
export const getAssignmentsSuccess = (result) => ({
    type: GET_ASSIGNMENTS_SUCCESS,
    payload: result,
});
export const getAssignmentsError = (error) => ({
    type: GET_ASSIGNMENTS_ERROR,
    payload: error,
});

export const getAssignments = (assignmentsList) => async (dispatch) => {
    dispatch(getAssignmentsBegin());
    try {
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "tasks"),
            where("id", "in", assignmentsList)
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            let task = doc.data();
            data.push(task);
        });
        dispatch(getAssignmentsSuccess(data));
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getAssignmentsError(error));
    }
};

export const getIncentivesBegin = () => ({ type: GET_INCENTIVES_BEGIN });
export const getIncentivesSuccess = (result) => ({
    type: GET_INCENTIVES_SUCCESS,
    payload: result,
});
export const getIncentivesError = (error) => ({
    type: GET_INCENTIVES_ERROR,
    payload: error,
});

export const getIncentives = () => async (dispatch) => {
    dispatch(getIncentivesBegin());
    try {
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "incentives")
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            let incentive = doc.data();
            data.push(incentive);
        });
        dispatch(getIncentivesSuccess(data));
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getIncentivesError(error));
    }
};

export const getEV101Begin = () => ({ type: GET_EV101_BEGIN });
export const getEV101Success = (result) => ({
    type: GET_EV101_SUCCESS,
    payload: result,
});
export const getEV101Error = (error) => ({
    type: GET_EV101_ERROR,
    payload: error,
});

export const getEV101 = () => async (dispatch) => {
    dispatch(getEV101Begin());
    try {
        const q = query(
            collection(db, "fl_content"),
            where("_fl_meta_.env", "==", "production"),
            where("_fl_meta_.locale", "==", "en-US"),
            where("_fl_meta_.schema", "==", "ev101")
        );

        const result = await getDocs(q);
        let data = [];
        result.forEach(function (doc) {
            let incentive = doc.data();
            data.push(incentive);
        });
        dispatch(getEV101Success(data));
    } catch(error) {
        // LogRocket.captureException(error);
        dispatch(getEV101Error(error));
    }
};