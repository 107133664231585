import { SET_SELECTED_USER_PROFILE } from "../constants/action-types-admin";

const initialState = {
    selectedUserProfile: {
        data: null,
        loading: false,
        error: null,
    },
};

const admin = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_USER_PROFILE:
            return {
                ...state,
                selectedUserProfile: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        default:
            return state;
    }
};

export default admin;
