import React from "react";

//Styles
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
    overviewGridItem: {
        height: 100,
        margin: 15,
        marginLeft: 34,
        marginRight: 34,
        [theme.breakpoints.down('xl')]: {
            height: "auto",
            marginLeft: 10,
            marginRight: 10,
        },
    },
    yellowBorderItem: {
        width: 100,
        border: "5px solid gold",
        borderRadius: 15,
    },
}));

function OverviewGridItem(props) {
    //Pass the color from the parent component to the styles
    const classes = useStyles();
    const { faIcon, title, description } = props;

    return (
        <Grid item xs={12} container className={classes.overviewGridItem}>
            <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                className={classes.yellowBorderItem}
            >
                <FontAwesomeIcon icon={faIcon} size="3x" />
            </Grid>
            <Grid
                item
                xs
                container
                alignItems="center"
                style={{ textAlign: "left", marginLeft: 25 }}
            >
                <div>
                    <Typography
                        variant="h5"
                        style={{ textDecoration: "underline", fontWeight: 700 }}
                    >
                        {title}
                    </Typography>
                    <Typography variant="h6">{description}</Typography>
                </div>
            </Grid>
        </Grid>
    );
}

export default OverviewGridItem;
