import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";

//Actions
import { getHomePage, getOrganization } from "../../../../../actions/content_actions";

//Styles
import "../../../../../static/styles/App.css";
import {
    Button,
    Grid,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

//Components
import TextDivider from "../../../../default/Styled Components/TextDivider";
import { StyledGoldButton } from "../../../../default/Styled Components/Buttons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Assets
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebookF } from "@fortawesome/free-brands-svg-icons";

// Firebase
import { getAnalytics, logEvent } from "firebase/analytics";

import EmailReferDialog from "./EmailReferDialog";

const analytics = getAnalytics()

const useStyles = makeStyles((theme) => ({
    gridItemText: {
        marginTop: 15,
        marginLeft: 50,
        marginRight: 50,
        textAlign: "center",
    },
    divider: {
        width: "100%",
        backgroundColor: "gainsboro",
        height: 3,
    },
    buttonsGrid: {
        //maxWidth: 500,
        //margin: '0 auto',
        marginTop: 5,
        marginBottom: 5,
        [theme.breakpoints.down('xl')]: {
            marginLeft: -8,
            marginRight: -8,
        },
    },
    twitterButton: {
        backgroundColor: "#1b95e0",
        minWidth: 135,
        alignItems: "center",
        fontSize: "1.2rem",
        fontWeight: 600,
    },
    facebookButton: {
        backgroundColor: "#4267B2",
        minWidth: 135,
        alignItems: "center",
        fontSize: "1.2rem",
        fontWeight: 600,
    },
    emailButton: {
        minWidth: 135,
        alignItems: "center",
        fontSize: "1.2rem",
        fontWeight: 600,
    },
    urlText: {
        wordBreak: "break-all",
        [theme.breakpoints.down('lg')]: {
            fontSize: ".75rem",
        },
    },
    bounce: {
        animation: `$bounce 3s ${theme.transitions.easing.easeInOut} infinite`,
    },
    "@keyframes bounce": {
        "0%, 33%, 7%, 17%, 27%": {
            webkitTransform: "translateY(0)",
            msTransform: "translateY(0)",
            transform: "translateY(0)",
        },
        "13%": {
            webkitTransform: "translateY(-30px)",
            msTransform: "translateY(-30px)",
            transform: "translateY(-30px)",
        },
        "20%": {
            webkitTransform: "translateY(-15px)",
            msTransform: "translateY(-15px)",
            transform: "translateY(-15px)",
        },
    },
}));

const getCampaignEmails = (referralURL) => ({
    "2021-independence": {
        title: "Virtual Contest Invite: Declaring Our Independence from Fossil Fuels",
        text: `
Greetings –

In celebration of Independence Day, I signed up for this virtual contest to learn about Electric Cars! 

They’ll introduce how today’s electric cars help us achieve independence from fossil fuels, and how you can save thousands off the next time you’re buying a car… And they’re offering prizes to participate too!

I’m sharing my referral link so I can get credit for referring you :) 
${referralURL}

I signed up already - Hope you can join too - It’ll be fun! 
        `,
        facebook:
            "In Celebration of #IndependenceDay, I’m joining this Virtual Contest on #ElectricVehicles to declare our Independence from Fossil Fuels! Check it out – they’ve got prizes too! #FourthofJuly #EV",
        twitter:
            "In Celebration of #IndependenceDay, I’m joining this Virtual Contest on #ElectricVehicles to declare our Independence from Fossil Fuels! Check it out – they’ve got prizes too! #FourthofJuly #EV",
    },
    "2021-earthday": {
        title: "Virtual Event Invite: Electric Cars for Earth",
        text: `
Greetings – 

In celebration of Earth Day, I signed up for this virtual event to learn about Electric Cars! 

They’ll introduce the environmental benefit of electric cars, and how you can save thousands off the next time you’re buying a car… And they’re offering prizes to participate too!

I’m sharing my referral link so I can get credit for referring you :)
${referralURL}

I signed up already - Hope you can join too - It’ll be fun!
        `,
        facebook:
            "In celebration of #EarthDay, I’m joining this virtual event on #ElectricVehicles! Check it out – they’ve got prizes too!",
        twitter:
            "In celebration of #EarthDay, I’m joining this virtual event on #ElectricVehicles! Check it out – they’ve got prizes too!",
    },
    "2021-rideshare": {
        title: "Virtual Contest Invite: Clean Air Rideshare Fair",
        text: `
Greetings – 

In celebration of Clean Air Day, I signed up to join the County’s annual Clean Air Rideshare Fair!

They’ll introduce you to County-sponsored programs and incentives that help you save time, save money, and reduce hassle on your commute to work, and programs that can help you save thousands on your next car purchase… And they’re offering prizes to participate too!

I’m sharing my referral link so I can get credit for referring you :) 

${referralURL}

I signed up already - Hope you can join too - It’ll be fun! 
        `,
        facebook: "",
        twitter: "",
    },
    "2022-3ce-earthday": {
        title: "Sharing a Fun Event: Electric Cars For Earth",
        text: `
Greetings  – 

In celebration of Earth Day, I signed up to join this neat online event: "Electric Cars for Earth"!

They'll introduce you to electric cars, and how you can save thousands of dollars when you get one... And they're offering prizes to participate too!

I’m sharing my link so I can get credit for referring you :)
${referralURL}

I signed up already - Hope you can join too... It looks fun!
        `,
        facebook: "",
        twitter: "",
    },
    "2022-lac-earthday": {
        title: "Sharing a Fun Event: Electric Cars For Earth",
        text: `
Greetings  – 

In celebration of Earth Day, I signed up to join this neat online event for LA County employees: "Electric Cars for Earth"!

They'll introduce you to electric cars, and how you can save thousands of dollars when you get one... And they're offering prizes to participate too!

I’m sharing my link so I can get credit for referring you :)
${referralURL}

I signed up already - Hope you can join too... It looks fun!
        `,
        facebook: "",
        twitter: "",
    },
});

function ReferShareButtons(props) {
    const classes = useStyles();
    const location = useLocation();

    const shareMethodsToUse = {
        facebook: false,
        twitter: false,
        email: true
    }

    const { email, campaign } = props;

    const [openEmailDialog, setOpenEmailDialog] = useState(false);
    const referralURL = `https://share.electrifyze.com/c/${
        campaign || "2022-lac-earthday"
    }.html?ref=${btoa(email)}`;
    const campaignEmails = getCampaignEmails(referralURL);
    const emailTitle = campaign
        ? campaignEmails[campaign].title
        : campaignEmails["2022-lac-earthday"].title;
    const emailText = campaign
        ? campaignEmails[campaign].text
        : campaignEmails["2022-lac-earthday"].text;
    const twitterText = campaign
        ? campaignEmails[campaign].twitter
        : campaignEmails["2022-lac-earthday"].twitter;
    const facebookText = campaign
        ? campaignEmails[campaign].facebook
        : campaignEmails["2022-lac-earthday"].facebook;

    useEffect(() => {
        if (window.twttr) {
            //window.twttr.widgets.load()
        }

        if (window.FB) {
            //window.FB.XFBML.parse()
        }
    }, []);

    const [copied, setCopied] = useState(false);

    const copyToClipboard = (type) => {
        if (/*@cc_on!@*/ false || !!document.documentMode) {
            //Internet explorer has unique method
            window.clipboardData.setData(
                "Text",
                type === "email" ? email : referralURL
            );
        } else {
            navigator.clipboard.writeText(
                type === "email" ? email : referralURL
            );
        }

        setCopied(type);
    };

    const handleTwitterClick = () => {
        if (campaign === "2022-lac-earthday") {
            logEvent(analytics, "share", {
                content_type: "2022-lac-earthday_referral_link",
                method: "twitter",
            });
        }
    };

    const handleFacebookClick = () => {
        /* window.FB.getLoginStatus(function(response) {
            statusChangeCallback(response);
        }); */
        // window.FB.ui(
        //     {
        //         display: "popup",
        //         method: "share",
        //         href: referralURL,
        //         //href: `https://www.facebook.com/sharer/sharer.php?quote=${encodeURIComponent(facebookText)}&u=${encodeURIComponent(referralURL)}&amp;src=sdkpreparse`,
        //         //href: 'https://developers.facebook.com/docs/',
        //     },
        //     function (response) {}
        // );

        if (campaign === "2022-lac-earthday") {
            logEvent(analytics, "share", {
                content_type: "2022-lac-earthday_referral_link",
                method: "facebook",
            });
        }
    };

    const handleEmailClick = () => {
        if (campaign === "2022-lac-earthday") {
            logEvent(analytics, "share", {
                content_type: "2022-lac-earthday_referral_link",
                method: "email",
            });
        }

        setOpenEmailDialog(true);
    };

    const handleCopyClick = () => {
        copyToClipboard("url");

        if (campaign === "2022-lac-earthday") {
            logEvent(analytics, "share", {
                content_type: "2022-lac-earthday_referral_link",
                method: "copy",
            });
        }
    };

    return (
        <div
            style={{
                padding: 20,
                border: "1px solid black",
                borderRadius: 20,
                backgroundColor: "#D9D9D9",
            }}
        >
            {/* <Typography variant="h6" style={{marginBottom: 10}}>
                Automatically share with your peers:
            </Typography> */}

            <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
                className={classes.buttonsGrid}
            >
                {/* <Grid item xs container justify="center">
                    <div>
                        <a 
                            target="_blank" 
                            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(twitterText)}&url=${encodeURIComponent(referralURL)}`}
                            rel="noreferrer"
                            style={{textDecoration: 'none'}}
                            id="twitter-share-link"
                        >
                            <Button variant="contained" color='primary' onClick={handleTwitterClick} classes={{root: classes.twitterButton}} >
                                <FontAwesomeIcon icon={['fab', 'twitter']} style={{marginTop: -1}} />&nbsp;&nbsp;Tweet
                            </Button> 
                        </a>
                    </div>  
                </Grid>
                <Grid item xs container justify="center">
                    <div>
                        <Button variant="contained" color='primary' onClick={handleFacebookClick} classes={{root: classes.facebookButton}}>
                            <FontAwesomeIcon icon={['fab', 'facebook-f']} style={{marginTop: -1}} />&nbsp;&nbsp;Share
                        </Button> 
                    </div>  
                </Grid> */}
                <Grid item>
                    <Typography variant="h6" style={{ marginRight: 15 }}>
                        Click to Share: 
                    </Typography>
                </Grid>
                
                { shareMethodsToUse.facebook && (
                    <Grid item xs>
                        <div>
                            
                            <FacebookShareButton 
                                url={referralURL}
                                quote={'In celebration of Earth Day, I’m joining this virtual event on Electric Vehicles! Check it out – they’ve got prizes too!'}
                                hashtag={'#EarthDay'}
                                beforeOnClick={handleFacebookClick} // Can use this to fire GA analytics events when a button is clicked.
                                // onShareWindowClose={() => alert('Facebook window closed.')} // Can use this to fire a function when the share window is closed.
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    // onClick={handleEmailClick}
                                    // className={classes.bounce}
                                    classes={{ root: classes.facebookButton }}
                                >
                                    <FontAwesomeIcon
                                        icon={faFacebookF}
                                        style={{ marginTop: -1 }}
                                    />
                                    &nbsp;&nbsp;Share
                                </Button>

                            </FacebookShareButton>
                            
                        </div>
                    </Grid>
                )}

                { shareMethodsToUse.facebook && (
                    <Grid item xs>
                        <div>
                            
                            <TwitterShareButton 
                                url={referralURL}
                                title="In celebration of #EarthDay, I'm joining this virtual event on #ElectricVehicles! Check it out - they've got prizes too! #EarthDay2022"
                                // via='Test test via string'
                                // hashtags={[]} // Array of hashtags
                                beforeOnClick={handleTwitterClick} // Can use this to fire GA analytics events when a button is clicked.
                                // onShareWindowClose={() => alert('Twitter window closed.')} // Can use this to fire a function when the share window is closed.
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    // onClick={handleEmailClick}
                                    // className={classes.bounce}
                                    classes={{ root: classes.twitterButton }}
                                >
                                    <FontAwesomeIcon
                                        icon={faTwitter}
                                        style={{ marginTop: -1 }}
                                    />
                                    &nbsp;&nbsp;Share
                                </Button>

                            </TwitterShareButton>
                            
                        </div>
                    </Grid>
                )}

                { shareMethodsToUse.email && (
                    <Grid item xs>
                        <div>
                            {/* <a 
                                href={`mailto: ?subject=${emailTitle}&body=${encodeURIComponent(emailText)}`}
                                style={{textDecoration: 'none'}}
                                id="mail-share-link"
                            > */}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleEmailClick}
                                // className={classes.bounce}
                                classes={{ root: classes.emailButton }}
                            >
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    style={{ marginTop: -1 }}
                                />
                                &nbsp;&nbsp;Email
                            </Button>
                            {/* </a>  */}
                        </div>
                    </Grid>
                )}

            </Grid>

            <TextDivider variant="regular" text="or" />

            <Typography variant="h6" style={{ marginBottom: 10 }}>
                Manually share your referral link:
            </Typography>

            <div
                style={{
                    backgroundColor: "white",
                    padding: 10,
                    border: "1px solid black",
                    borderRadius: 10,
                }}
            >
                <Grid item container spacing={2} alignItems="center">
                    <Grid item xs container alignItems="center">
                        <Typography variant="body1" className={classes.urlText}>
                            {referralURL}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <StyledGoldButton
                            // variant="contained"
                            size="small"
                            onClick={handleCopyClick}
                        >
                            {/* copied === "url" ? 
                                <FontAwesomeIcon icon={faCheck} />
                            :
                                <FontAwesomeIcon icon={['far', 'copy']} />
                            */}
                            {copied === "url" ? "Copied" : "Copy"}
                        </StyledGoldButton>
                    </Grid>
                </Grid>
            </div>

            {openEmailDialog && (
                <EmailReferDialog
                    visible={openEmailDialog}
                    handleClose={() => setOpenEmailDialog(false)}
                    campaign={campaign}
                    email={email}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganization: (organization) =>
            dispatch(getOrganization(organization)),
        getHomePage: (organization) => dispatch(getHomePage(organization)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReferShareButtons)
);
