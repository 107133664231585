import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import { Grid, LinearProgress } from "@mui/material";
import {
    StyledTaskButton,
    StyledNextButton,
    StyledBackButton,
} from "../Styled Components/Buttons";
import { withRouter } from "react-router-dom";

//Actions
import { updateUserProfile } from "../../../actions/user_actions";
import { getCourseMetrics } from "../../../actions/content_actions";

//Utils
import imageMapResize from "image-map-resizer";
import CampaignSlide from "./CampaignSlide";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: "1 1 auto",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        outline: "none",
    },
    gridContainer: {
        flex: "1 1 auto",
        height: "100%",
        width: "100%",
    },
    barColor: {
        backgroundColor: "rgba(255, 213, 0, 1)",
    },
    bar: {
        borderRadius: 15,
    },
    taskTitle: {
        textTransform: "capitalize",
        marginLeft: 20,
        [theme.breakpoints.down('xl')]: {
            fontSize: ".8rem",
        },
    },
    titleGridItem: {
        [theme.breakpoints.down('xl')]: {
            maxWidth: "max-content",
            marginRight: 5,
        },
        [theme.breakpoints.down('lg')]: {
            display: "none",
        },
    },
    linearProgressGridItem: {},
    rightButtonsGridItem: {
        [theme.breakpoints.down('xl')]: {
            maxWidth: "max-content",
        },
    },
    nextBackButton: {
        margin: 20,
        [theme.breakpoints.down('xl')]: {
            margin: 10,
        },
    },
}));

function Slides(props) {
    //Pass theme styles as props
    const classes = useStyles(props);
    const { content, type, tasks, organization, isAssignment } = props;
    const steps = type === "campaign" ? content.length + 1 : content.length;
    const singleTask = props.singleTask;
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        imageMapResize();
    }, [activeStep]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        if (type === "quiz") {
            props.setNextQuestionClick();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div
            className={classes.root}
            onKeyDown={(e) =>
                e.keyCode === 37 && type !== "quiz" && type !== "campaign" && activeStep !== 0 // Added the type!=="campaign" condition to prevent back keypress from moving back one slide during login/registration flow in cookie trailed slides. 
                    ? handleBack()
                    : e.keyCode === 39 &&
                      type !== "quiz" &&
                      activeStep + 1 !== steps
                    ? handleNext()
                    : null
            }
            autoFocus
            tabIndex={0}
        >
            <Grid
                container
                direction="column"
                className={classes.gridContainer}
            >
                <Grid
                    item
                    xs
                    container
                    alignItems="center"
                    style={
                        type === "lesson" ||
                        type === "activity" ||
                        type === "campaign"
                            ? { padding: 20, overflowY: "auto" }
                            : { overflowY: "auto" }
                    }
                >
                    {content.map((item, index) => {
                        //We have to map all of them to start otherwise the image map won't work for each slide
                        return (
                            <div
                                key={index}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    display:
                                        index === activeStep ? "block" : "none",
                                }}
                            >
                                {item.element}
                            </div>
                        );
                    })}
                    {type === "campaign" && activeStep === steps - 1 && (
                        <CampaignSlide singleTask={singleTask} />
                    )}
                </Grid>
                {/* Do not fix the position of this grid item. It allows perfect dynamic sizing of top grid item as is --> , position: 'fixed', bottom: 0}}> */}
                <Grid
                    item
                    container
                    alignItems="center"
                    style={
                        organization.data && organization.data != null
                            ? {
                                  height: 100,
                                  backgroundColor:
                                      organization.data.backgroundColor,
                                  color: organization.data.fontColor,
                              }
                            : {
                                  height: 100,
                                  backgroundColor: "black",
                                  color: "white",
                              }
                    }
                >
                    <Grid
                        item
                        xs
                        container
                        alignItems="center"
                        className={classes.titleGridItem}
                    >
                        <Grid item xs>
                            <Typography
                                variant="h6"
                                className={classes.taskTitle}
                            >
                                {singleTask.title}
                            </Typography>
                        </Grid>
                    </Grid>

                    {type !== "quiz" && activeStep !== 0 && (
                        <Grid
                            item
                            style={{ width: "auto" }}
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <StyledBackButton
                                className={classes.nextBackButton}
                                onClick={handleBack}
                            />
                        </Grid>
                    )}

                    <Grid
                        item
                        xs
                        className={classes.linearProgressGridItem}
                        style={
                            type === "quiz" && activeStep < content.length - 2
                                ? { marginRight: 10 }
                                : type === "campaign" &&
                                  activeStep === steps - 1
                                ? { marginRight: 10 }
                                : activeStep === 0
                                ? { marginLeft: 10 }
                                : {}
                        }
                    >
                        <div style={{ position: "relative", bottom: 14 }}>
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                }}
                            >
                                <LinearProgress
                                    variant="determinate"
                                    value={
                                        type === "quiz" &&
                                        activeStep + 1 != steps
                                            ? ((activeStep + 1) / (steps - 1)) *
                                              100
                                            : ((activeStep + 1) / steps) * 100
                                    }
                                    style={{
                                        height: 30,
                                        borderRadius: 15,
                                        backgroundColor: "lightgray",
                                    }}
                                    classes={{
                                        barColorPrimary: classes.barColor,
                                        bar: classes.bar,
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    zIndex: 1,
                                    left: "47%",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    style={{
                                        fontWeight: 700,
                                        paddingTop: 4,
                                        color: "black",
                                    }}
                                >
                                    {type === "quiz" &&
                                    activeStep + 1 === content.length
                                        ? activeStep
                                        : activeStep + 1}{" "}
                                    / {type === "quiz" ? steps - 1 : steps}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        className={classes.rightButtonsGridItem}
                    >
                        {isAssignment &&
                        type === "lesson" &&
                        activeStep + 1 === content.length ? (
                            <StyledTaskButton
                                onClick={() =>
                                    props.updateUser(
                                        undefined,
                                        undefined,
                                        undefined,
                                        true
                                    )
                                }
                                style={{ marginLeft: 20 }}
                            >
                                Complete Activity
                            </StyledTaskButton>
                        ) : type === "lesson" &&
                          activeStep + 1 === content.length ? (
                            <StyledTaskButton
                                onClick={props.updateUser}
                                style={{ marginLeft: 20 }}
                            >
                                Next Task
                            </StyledTaskButton>
                        ) : type === "activity" &&
                          activeStep + 1 === content.length ? (
                            <StyledTaskButton
                                onClick={props.beginActivity}
                                style={{ marginLeft: 20 }}
                            >
                                Begin Activity
                            </StyledTaskButton>
                        ) : type === "quiz" &&
                          activeStep === content.length - 2 &&
                          props.questionAnswered[activeStep] ===
                              activeStep.toString() ? (
                            <StyledTaskButton
                                onClick={handleNext}
                                style={{ marginLeft: 20, marginRight: 10 }}
                            >
                                Submit
                            </StyledTaskButton>
                        ) : activeStep === steps - 2 ? (
                            <StyledNextButton
                                className={classes.nextBackButton}
                                onClick={handleNext}
                            />
                        ) : activeStep === steps - 1 ||
                          (type === "quiz" &&
                              props.questionAnswered[activeStep] !==
                                  activeStep.toString()) ? null : (
                            <StyledNextButton
                                className={classes.nextBackButton}
                                onClick={handleNext}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        tasks: state.content.tasks,
        organization: state.content.organization,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserProfile: (userProfile, uid) =>
            dispatch(updateUserProfile(userProfile, uid)),
        getCourseMetrics: (userProfile, courseID, userID) =>
            dispatch(getCourseMetrics(userProfile, courseID, userID)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Slides));
