import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Actions
import { signInUser, getUserProfile } from "../actions/user_actions";

//Assets
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Car from "../static/images/car-side-solid.svg";
import GraduationCap from "../static/images/graduation-cap-solid.svg";
import HandShake from "../static/images/hands-helping-solid.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
    root: {},
    main: {
        margin: 40,
        backgroundPosition: "center center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center center",
        [theme.breakpoints.down('xl')]: {
            height: 500,
        },
        [theme.breakpoints.down('lg')]: {
            height: 350,
        },
    },
    form: {
        padding: 40,
        borderRadius: 10,
        backgroundColor: "white",
    },
    courseBox: {
        marginTop: 10,
    },
    titleBox: {
        height: 120,
        width: "100%",
        backgroundColor: "#4472c4",
    },
    profileImg: {
        height: 100,
        width: 100,
    },
    courseImg: {
        height: 150,
        width: 150,
        marginBottom: 20,
        fill: "gray",
    },
    pointsImg: {
        height: 80,
        width: 80,
        objectFit: "contain",
        color: "white",
    },
    link: {
        textDecoration: "none",
    },
    contentCard: {
        height: 480,
        padding: 20,
        [theme.breakpoints.down("xl")]: {
            height: 610,
        },
    },
}));

function Dashboard(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container className={classes.titleBox}>
                <Grid
                    item
                    xs={1}
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <AccountCircleIcon
                        className={classes.profileImg}
                        style={{ color: "white" }}
                    />
                </Grid>
                <Grid item xs container alignItems="center">
                    <div>
                        <Typography
                            variant="h3"
                            style={{ fontWeight: 600, color: "white" }}
                        >
                            {props.userProfile.data &&
                                props.userProfile.data.first_name +
                                    " " +
                                    props.userProfile.data.last_name}
                        </Typography>
                        <Typography variant="h5" style={{ color: "white" }}>
                            Begin your EV learning journey!
                        </Typography>
                    </div>
                </Grid>
                {/* <Grid item xs={1} container alignItems="center">
                    <Grid item xs>
                        <CardGiftcardIcon className={classes.pointsImg} />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h5" style={{color: 'white'}}>
                            346 points
                        </Typography>
                    </Grid>
                </Grid> */}
            </Grid>
            <div className={classes.main}>
                <Grid container spacing={2}>
                    <Grid item lg={4} xs={12} className={classes.courseBox}>
                        <Typography variant="h4">Learn</Typography>
                        <Link to="/courses" className={classes.link}>
                            <Paper
                                elevation={5}
                                className={classes.contentCard}
                            >
                                <Grid
                                    container
                                    style={{ height: "100%" }}
                                    direction="column"
                                >
                                    <Grid item xs>
                                        <div
                                            style={{
                                                display: "grid",
                                                placeItems: "center center",
                                            }}
                                        >
                                            <img
                                                src={GraduationCap}
                                                className={classes.courseImg}
                                                alt="graduation-cap"
                                            />
                                        </div>
                                        <Typography variant="h5">
                                            Learn about electric cars and clean
                                            mobility options.
                                        </Typography>
                                        <Typography variant="h6">
                                            <ul>
                                                <li>
                                                    Learn how to save money and
                                                    save your planet
                                                </li>
                                                <li>
                                                    Earn points towards prizes
                                                    and giveaways
                                                </li>
                                            </ul>
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        style={{
                                            height: "auto",
                                            width: "15%",
                                            alignSelf: "flex-end",
                                            justifySelf: "flex-end",
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs
                                            container
                                            justifyContent="flex-end"
                                        >
                                            <Typography
                                                variant="body1"
                                                style={{ fontWeight: 700 }}
                                            >
                                                Start
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs
                                            container
                                            justifyContent="flex-end"
                                        >
                                            <ArrowForwardIcon />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item lg={4} xs={12} className={classes.courseBox}>
                        <Typography variant="h4">Acquire</Typography>
                        {/* <Link to="/courses" className={classes.link}> */}
                        <Paper elevation={1} className={classes.contentCard}>
                            <Grid
                                container
                                style={{ height: "100%" }}
                                direction="column"
                            >
                                <Grid item xs>
                                    <div
                                        style={{
                                            display: "grid",
                                            placeItems: "center center",
                                        }}
                                    >
                                        <img
                                            src={Car}
                                            className={classes.courseImg}
                                            alt="car"
                                        />
                                    </div>
                                    <Typography variant="h5">
                                        In the market to buy a car? Get help
                                        from your very own electric car buying
                                        coach.
                                    </Typography>
                                    <Typography variant="h6">
                                        <ul>
                                            <li>
                                                Take the hard work out of car
                                                shopping
                                            </li>
                                            <li>Find makes and models</li>
                                            <li>
                                                Compare options and test drive
                                            </li>
                                            <li>
                                                Get the best deals, just for
                                                [org] employees
                                            </li>
                                            <li>
                                                Claim incentives available just
                                                for you
                                            </li>
                                        </ul>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    style={{
                                        height: "auto",
                                        width: "15%",
                                        alignSelf: "flex-end",
                                        justifySelf: "flex-end",
                                    }}
                                >
                                    <Grid
                                        item
                                        xs
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <Typography
                                            variant="body1"
                                            style={{ fontWeight: 700 }}
                                        >
                                            Start
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <ArrowForwardIcon />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                        {/* </Link> */}
                    </Grid>
                    <Grid item lg={4} xs={12} className={classes.courseBox}>
                        <Typography variant="h4">
                            Earn {"&"} Champion
                        </Typography>
                        {/* <Link to="/courses" className={classes.link}> */}
                        <Paper elevation={1} className={classes.contentCard}>
                            <Grid
                                container
                                style={{ height: "100%" }}
                                direction="column"
                            >
                                <Grid item xs>
                                    <div
                                        style={{
                                            display: "grid",
                                            placeItems: "center center",
                                        }}
                                    >
                                        <img
                                            src={HandShake}
                                            className={classes.courseImg}
                                            alt="hand-shake"
                                        />
                                    </div>
                                    <Typography variant="h5">
                                        Already a proud electric car owner? Get
                                        the most out of your EV, and help your
                                        colleagues learn.
                                    </Typography>
                                    <Typography variant="h6">
                                        <ul>
                                            <li>
                                                Redeem employee benefits for
                                                clean commutes
                                            </li>
                                            <li>
                                                Learn how to save more money
                                                with smart charging
                                            </li>
                                            <li>
                                                Learn about workplace charging
                                            </li>
                                            <li>
                                                Help others learn about electric
                                                cars, and keep earning points
                                                towards prizes and giveaways
                                            </li>
                                        </ul>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    style={{
                                        height: "auto",
                                        width: "15%",
                                        alignSelf: "flex-end",
                                        justifySelf: "flex-end",
                                    }}
                                >
                                    <Grid
                                        item
                                        xs
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <Typography
                                            variant="body1"
                                            style={{ fontWeight: 700 }}
                                        >
                                            Start
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <ArrowForwardIcon />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                        {/* </Link> */}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signInUser: (params) => dispatch(signInUser(params)),
        getUserProfile: (id) => dispatch(getUserProfile(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
