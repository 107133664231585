import React from "react";
import Login from './Login';

function Home() {
    return (
        <div>
            <Login />
        </div>
    );
}

export default Home;
