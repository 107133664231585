import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//Routing
import {
    Route,
    Redirect,
    withRouter,
} from "react-router-dom";

//Actions
import {
    checkAuthentication,
} from "../../../actions/user_actions";
import { CircularProgress, Typography } from "@mui/material";
import DrawerMenu from "./DrawerMenu";

import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Suspense } from "react";
import { getOrganizationComponent } from "../../../helpers/functions";

const closedBarWidth = 90;
const openBarWidth = 380;

const useStyles = makeStyles((theme) => ({
    root: {
        flex: "1 1 auto",
        msFlex: "1 1 100vh",
        display: "flex",
    },
    content: {
        flexGrow: 1,
        //padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentMargin: {
        marginLeft: -openBarWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    progressOuterBox: {
        width: "100%",
        height: "calc(100vh - 150px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    progressInnerBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    circularProgress: {
        marginTop: 15,
    },
}));

function PrivateRoute(props) {
    const { user, userProfile, path, component, type, open, toggleDrawer } =
        props;
    const classes = useStyles(props);

    React.useEffect(() => {
        if (user.data === null || userProfile.data === null)
            props.checkAuthentication();
    }, []);

    /* const [OrganizationLoginComponent, setOrganizationLoginComponent] = useState()

    useEffect(() => {
        if (organizationFromURL) {
            //setOrganizationLoginComponent(getOrganizationComponent(organizationFromURL, 'LoginRegistration'))
            props.getOrganization(organizationFromURL)
        }
    },[]) */

    const [OrganizationDrawerMenu, setOrganizationDrawerMenu] = useState();

    useEffect(() => {
        if (userProfile.data != null) {
            setOrganizationDrawerMenu(
                getOrganizationComponent(
                    userProfile.data != null &&
                        (userProfile.data.currentOrganization ||
                            userProfile.data.organization.name ||
                            userProfile.data.organization),
                    "DrawerMenu"
                )
            );
        }
    }, [userProfile]);

    return (
        <Route path={path}>
            <div className={classes.root}>
                <Suspense
                    fallback={
                        <DrawerMenu open={open} toggleDrawer={toggleDrawer} />
                    }
                >
                    {OrganizationDrawerMenu && (
                        <OrganizationDrawerMenu
                            open={open}
                            toggleDrawer={toggleDrawer}
                        />
                    )}
                </Suspense>

                <div
                    className={clsx(classes.content, {
                        // This is so while loading the page is centered
                        [classes.contentMargin]: OrganizationDrawerMenu,
                        [classes.contentShift]: open,
                    })}
                >
                    {user.data != null && userProfile.data != null ? (
                        type !== "admin" ? (
                            component
                        ) : type === "admin" &&
                          userProfile.data.admin === true ? (
                            component
                        ) : (
                            <Redirect to="/" />
                        )
                    ) : user.loading || userProfile.loading ? (
                        <div className={classes.progressOuterBox}>
                            <div className={classes.progressInnerBox}>
                                <Typography variant="h3">
                                    Authenticating...
                                </Typography>
                                <CircularProgress
                                    className={classes.circularProgress}
                                />
                            </div>
                        </div>
                    ) : user.error != null || userProfile.error != null ? (
                        <Redirect
                            to={`/login/?forward=${props.location.pathname}`}
                        />
                    ) : null}
                </div>
            </div>
        </Route>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        checkAuthentication: () => dispatch(checkAuthentication()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
);
