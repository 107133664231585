import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import "../../../static/styles/App.css";

//Components
import Slides from "./Slides";

//Actions
import { getActivity } from "../../../actions/content_actions";

import parse from "html-react-parser";
import ActivityContent from "./ActivityElements/ActivityContent";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
}));

function Activity(props) {
    //Pass theme styles as props
    const classes = useStyles(props);
    const { taskID, activity, isAssignment } = props;
    const [slidesComplete, setSlidesComplete] = useState(false);

    useEffect(() => {
        props.getActivity(taskID);
    }, []);

    const getComponentsList = () => {
        const slides = activity.data.slides;
        let componentsList = [];

        for (let i in slides) {
            var htmlString = slides[i].content;
            componentsList.push({
                element: (
                    <div
                        style={
                            slides[i].type !== "text"
                                ? {
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                  }
                                : { margin: 40 }
                        }
                    >
                        {slides[i].type === "image" ? (
                            <img
                                src={slides[i].content}
                                style={{
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                    msFlex: "0 0 auto",
                                }}
                                alt="content"
                            />
                        ) : slides[i].type === "video" ? (
                            <iframe
                                style={{ width: "85%", height: "100%" }}
                                src={slides[i].content}
                                frameBorder="0"
                                allow="geolocation; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="side-content"
                            />
                        ) : (
                            parse(htmlString)
                        )}
                    </div>
                ),
            });
        }

        return componentsList;
    };

    const beginActivity = () => {
        setSlidesComplete(true);
    };

    return (
        <div className={classes.root}>
            {activity.data != null &&
            activity.data.slides.length > 0 &&
            !slidesComplete ? (
                <Slides
                    singleTask={props.singleTask}
                    content={getComponentsList()}
                    type={"activity"}
                    beginActivity={beginActivity}
                />
            ) : slidesComplete ? (
                <ActivityContent
                    activity={activity.data}
                    singleTask={props.singleTask}
                    updateUser={props.updateUser}
                    isAssignment={isAssignment}
                />
            ) : null}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        activity: state.content.activity,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getActivity: (id) => dispatch(getActivity(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
