import React, { useEffect, useRef } from "react";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Grid, TextField } from "@mui/material";
import { styled } from "@mui/system";

// Components
import CoachingSurveyDialog from "../Coaching/CoachingSurveyDialog";
import { StyledTaskButton } from "../Styled Components/Buttons";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
    },
    box1: {},
    box2: {},
    input: {
        height: 45,
        width: "100%",
        //border: 'none',
        /* borderBottom: 'solid', 
        borderColor: props => props.color,
        borderBottomWidth: 2,
        color: props => props.color,
        backgroundColor: 'transparent',
        fontSize: 24,
        outline: 'none',
        '&::placeholder': {
            color: props => props.color,
        }, */
        [theme.breakpoints.down('lg')]: {
            height: 30,
            fontSize: 16,
        },
    },
    button: {
        marginRight: 20,
        height: 50,
        fontWeight: 500,
        width: 200,
        borderRadius: 10,
        borderWidth: 0,
        fontSize: 24,
        backgroundColor: (props) => props.color,
        color: (props) => (props.color === "white" ? "black" : "white"),
        outline: "none",
        transitionDuration: "0.4s",
        textDecoration: "none",
        overflow: "hidden",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.7,
        },
        [theme.breakpoints.down('lg')]: {
            height: 30,
            fontSize: 16,
            width: 140,
        },
    },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
    root: {
        "& label": {
            color: (props) => props.color,
        },
        "& label.Mui-focused": {
            color: (props) => props.color,
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: (props) => props.color,
        },
        "& .MuiInput-underline:hover:not($disabled):before": {
            borderBottomColor: (props) => props.color,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: (props) => props.color,
        },
        "& input": {
            color: (props) => props.color,
        },
    },
}));

function EmailForm(props) {
    //Pass the color from the parent component to the styles
    const classes = useStyles(props);
    const { autoFocus, page } = props;

    const [visible, toggleModal] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const inputRef = useRef();

    useEffect(() => {
        if (page === "acquire") {
            window.addEventListener("scroll", () => {
                var scrollTop =
                    window.pageYOffset !== undefined
                        ? window.pageYOffset
                        : (
                              document.documentElement ||
                              document.body.parentNode ||
                              document.body
                          ).scrollTop;

                if (scrollTop === 0 && inputRef.current) {
                    inputRef.current.focus();
                }
            });
        }
    }, []);

    const checkEmail = () => {
        if (email.match(/\S+@\S+\.\S+/)) {
            toggleModal(true);
        } else {
            alert("Please enter a valid email before getting started");
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs className={classes.box1}>
                    <CustomTextField
                        className={classes.input}
                        onKeyPress={(event) =>
                            event.key === "Enter" ? checkEmail() : null
                        }
                        onChange={(event) => setEmail(event.target.value)}
                        type="text"
                        name="fname"
                        label="ENTER EMAIL"
                        autoFocus={autoFocus}
                        inputRef={inputRef}
                        color={props.color}
                    />
                </Grid>
                <Grid item className={classes.box2}>
                    {/* buttonAnimation css styling found in App.css file */}
                    <StyledTaskButton onClick={checkEmail}>
                        GET STARTED
                    </StyledTaskButton>
                </Grid>
            </Grid>

            {visible && (
                <CoachingSurveyDialog
                    email={email}
                    visible={visible}
                    handleClose={() => toggleModal(false)}
                />
            )}
        </div>
    );
}

export default EmailForm;
