export const GET_USER_BEGIN = "GET_USER_BEGIN";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

export const CREATE_USER_BEGIN = "CREATE_USER_BEGIN";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";

export const CREATE_USER_PROFILE_BEGIN = "CREATE_USER_PROFILE_BEGIN";
export const CREATE_USER_PROFILE_ERROR = "CREATE_USER_PROFILE_ERROR";
export const CREATE_USER_PROFILE_SUCCESS = "CREATE_USER_PROFILE_SUCCESS";

export const GET_USER_PROFILE_BEGIN = "GET_USER_PROFILE_BEGIN";
export const GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";

export const UPDATE_USER_PROFILE_BEGIN = "UPDATE_USER_PROFILE_BEGIN";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";

export const LOGOUT_BEGIN = "LOGOUT_BEGIN";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const SEND_PASSWORD_RESET_EMAIL_BEGIN =
    "SEND_PASSWORD_RESET_EMAIL_BEGIN";
export const SEND_PASSWORD_RESET_EMAIL_ERROR =
    "SEND_PASSWORD_RESET_EMAIL_ERROR";
export const SEND_PASSWORD_RESET_EMAIL_SUCCESS =
    "SEND_PASSWORD_RESET_EMAIL_SUCCESS";

export const VERIFY_PASSWORD_RESET_BEGIN = "VERIFY_PASSWORD_RESET_BEGIN";
export const VERIFY_PASSWORD_RESET_ERROR = "VERIFY_PASSWORD_RESET_ERROR";
export const VERIFY_PASSWORD_RESET_SUCCESS = "VERIFY_PASSWORD_RESET_SUCCESS";

export const RESET_PASSWORD_BEGIN = "RESET_PASSWORD_BEGIN";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const ADD_SENDGRID_CONTACT_BEGIN = "ADD_SENDGRID_CONTACT_BEGIN";
export const ADD_SENDGRID_CONTACT_ERROR = "ADD_SENDGRID_CONTACT_ERROR";
export const ADD_SENDGRID_CONTACT_SUCCESS = "ADD_SENDGRID_CONTACT_SUCCESS";

export const UPDATE_REFERRALS_BEGIN = "UPDATE_REFERRALS_BEGIN";
export const UPDATE_REFERRALS_ERROR = "UPDATE_REFERRALS_ERROR";
export const UPDATE_REFERRALS_SUCCESS = "UPDATE_REFERRALS_SUCCESS";

export const SET_USER = "SET_USER";
