import {
    SEND_TRIGGER_EMAIL_BEGIN,
    SEND_TRIGGER_EMAIL_SUCCESS,
    SEND_TRIGGER_EMAIL_ERROR,
    POST_SEND_GRID_EMAIL_BEGIN,
    POST_SEND_GRID_EMAIL_SUCCESS,
    POST_SEND_GRID_EMAIL_ERROR,
} from "../constants/action-types-email";

// Firebase
import { getFirestore, collection, addDoc } from "firebase/firestore"

// Get Firestore reference
const db = getFirestore();

export const sendTriggerEmailBegin = () => ({
    type: POST_SEND_GRID_EMAIL_BEGIN,
});
export const sendTriggerEmailSuccess = (result) => ({
    type: POST_SEND_GRID_EMAIL_SUCCESS,
    payload: result,
});
export const sendTriggerEmailError = (error) => ({
    type: POST_SEND_GRID_EMAIL_ERROR,
    payload: error,
});

export const sendTriggerEmail = (emailAddress, subject, html) => async (dispatch) => {
    dispatch(sendTriggerEmailBegin());
    try {
        const response = await addDoc(collection(db, "coaching_profiles"), {
            to: emailAddress,
            message: {
                subject: subject,
                text: "",
                html: html,
            },
        })
        console.log("Queued email for delivery!");
        dispatch(sendTriggerEmailSuccess(response));
    } catch(error) {
        console.log("Error sending email.");
        dispatch(sendTriggerEmailError(error));
    }
}

export const postSendGridEmailBegin = () => ({
    type: SEND_TRIGGER_EMAIL_BEGIN,
});
export const postSendGridEmailSuccess = (result) => ({
    type: SEND_TRIGGER_EMAIL_SUCCESS,
    payload: result,
});
export const postSendGridEmailError = (error) => ({
    type: SEND_TRIGGER_EMAIL_ERROR,
    payload: error,
});

export function postSendGridEmail(data) {
    return (dispatch) => {
        dispatch(postSendGridEmailBegin());
        return (
            //http://localhost:5001/electrifyze-fce77/us-central1/sendTriggerEmail
            fetch(
                "https://us-central1-electrifyze-fce77.cloudfunctions.net/sendTriggerEmail",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    console.log("Success:", data);
                    dispatch(postSendGridEmailSuccess(data));
                })
                .catch((error) => {
                    console.log("Error sending email.");
                    dispatch(postSendGridEmailError(error));
                })
        );
    };
}
