import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions
import { getHomePage, getOrganization } from "../../actions/content_actions";

//Styles
import "../../static/styles/App.css";
import { makeStyles } from "@mui/styles";

//Components
import ISDRideshareHeader from "../la_county/ISDRideshareHeader";
import ReferInfoBox from "./Refer/ReferInfoBox";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100%",
        //overflowY: 'auto'
    },
    content: {
        maxWidth: 960,
        margin: "0 auto",
        marginTop: 30,
        marginBottom: 40,
        [theme.breakpoints.down('xl')]: {
            marginLeft: 10,
            marginRight: 10,
        },
    },
    gridItemText: {
        margin: 15,
        marginLeft: 50,
        marginRight: 50,
        textAlign: "left",
    },
    divider: {
        width: "100%",
        backgroundColor: "gainsboro",
        height: 3,
    },
}));

function Refer(props) {
    const classes = useStyles();

    const { userProfile } = props;

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <ISDRideshareHeader
                    title="Refer a Friend"
                    subheading="Help others discover the benefits of Ridesharing to work!"
                />

                <ReferInfoBox
                    email={userProfile.data.email}
                    campaign="2021-rideshare"
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganization: (organization) =>
            dispatch(getOrganization(organization)),
        getHomePage: (organization) => dispatch(getHomePage(organization)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Refer));
