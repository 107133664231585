import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions
import {
    getHomePage,
    getOrganization,
    getAssignments,
} from "../actions/content_actions";

//Styles
import "../static/styles/App.css";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Grid,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

//Assets

//Components
import ISDRideshareHeader from "../components/la_county/ISDRideshareHeader";
import EVCoachMessage from "../components/default/Login/EVCoachMessage";
import ActivityItem from "../components/default/Coaching/ActivityItem";
import { ExpandMore } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100%",
        //overflowY: 'auto'
    },
    content: {
        maxWidth: 960,
        margin: "0 auto",
        marginTop: 30,
        marginBottom: 40,
        [theme.breakpoints.down('xl')]: {
            marginLeft: 10,
            marginRight: 10,
        },
    },
    coachText: {
        [theme.breakpoints.down('lg')]: {
            fontSize: ".8rem",
        },
    },
    tabs: {
        marginTop: 15,
        marginBottom: 20,
    },
    tabsFlexContainer: {
        gap: "20px",
    },
    tab: {
        flex: 1,
        maxWidth: "100% !important",
        backgroundColor: "gold",
        opacity: 1,
        textTransform: "none",
    },
    tabSelected: {
        color: "white",
        backgroundColor: "black",
    },
    indicator: {
        backgroundColor: "gold",
        height: 5,
    },
    tabContent: {
        marginBottom: 50,
    },
    tabDescription: {
        marginBottom: 10,
    },
    tabLabel: {
        fontWeight: 700,
        [theme.breakpoints.down('xl')]: {
            fontSize: ".8rem",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: ".6rem",
        },
    },
    list: {
        margin: "10px 0 10px 0",
    },
}));

function Coaching(props) {
    const classes = useStyles();

    const { user, userProfile, assignments, selectedUserProfile } = props;

    const [profile, setProfile] = useState(
        selectedUserProfile.data != null
            ? selectedUserProfile.data
            : userProfile.data
    );
    const uid =
        selectedUserProfile.data != null
            ? selectedUserProfile.data.id
            : user.data.uid;
    const [appointmentOpen, setAppointmentOpen] = useState(false);
    const [getStartedOpen, setGetStartedOpen] = useState(false);
    const [learnOpen, setLearnOpen] = useState(false);

    const [value, setValue] = useState(0);
    const incompleteNotArchivedAssignments =
        assignments.data != null &&
        assignments.data
            .filter((item) => !profile.completed_tasks[item.id])
            .filter(
                (item) =>
                    !profile.archived_tasks ||
                    (profile.archived_tasks &&
                        !profile.archived_tasks.includes(item.id))
            );
    const completeArchivedAssignments =
        assignments.data != null &&
        assignments.data.filter(
            (item) =>
                profile.completed_tasks[item.id] ||
                (profile.archived_tasks &&
                    profile.archived_tasks.includes(item.id))
        );

    useEffect(() => {
        setProfile(
            selectedUserProfile.data != null
                ? selectedUserProfile.data
                : userProfile.data
        );
    }, [userProfile, selectedUserProfile]);

    useEffect(() => {
        if (
            profile != null &&
            profile.assignments &&
            profile.assignments.length > 0
        ) {
            props.getAssignments(profile.assignments);
        }
    }, [profile]);

    const handleChange = (event, value) => {
        setValue(value);
    };

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <ISDRideshareHeader
                    title="Get the Best Deal"
                    subheading="We will help you find an electric car and save money!"
                />

                <EVCoachMessage
                    message={
                        <div className={classes.coachText}>
                            Hi! I’m your Electric Car coach and I’m here to:
                            <ol>
                                <li>
                                    Answer any questions you have about electric
                                    cars
                                </li>
                                <li>
                                    Secure offers from multiple dealer when
                                    you’re ready to buy
                                </li>
                                <li>
                                    Help you save up to $5,000 without
                                    negotiating with dealers!
                                </li>
                            </ol>
                            See below to get started.
                        </div>
                    }
                />

                <Tabs
                    value={value}
                    onChange={handleChange}
                    className={classes.tabs}
                    classes={{
                        flexContainer: classes.tabsFlexContainer,
                        indicator: classes.indicator,
                    }}
                >
                    <Tab
                        label={
                            <Typography
                                variant="h6"
                                className={classes.tabLabel}
                            >
                                New Activities
                            </Typography>
                        }
                        classes={{
                            root: classes.tab,
                            selected: classes.tabSelected,
                        }}
                    />

                    <Tab
                        label={
                            <Typography
                                variant="h6"
                                className={classes.tabLabel}
                            >
                                My Profile
                            </Typography>
                        }
                        classes={{
                            root: classes.tab,
                            selected: classes.tabSelected,
                        }}
                    />
                </Tabs>
                {value === 0 ? (
                    <div className={classes.tabContent}>
                        <Typography
                            variant="h6"
                            className={classes.tabDescription}
                        >
                            The activities below have been custom picked for
                            you. They will help you learn about EVs and find the
                            best deal.
                        </Typography>
                        {assignments.loading ? (
                            <CircularProgress />
                        ) : assignments.data != null ? (
                            <div>
                                <Accordion
                                    defaultExpanded={true}
                                    style={{
                                        boxShadow: "none",
                                        bordeRadius: 0,
                                    }}
                                >
                                    <AccordionSummary
                                        style={{
                                            backgroundColor: "gold",
                                            border: "1px solid black",
                                        }}
                                        expandIcon={<ExpandMore />}
                                    >
                                        <Typography variant="h5">
                                            Activity List
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        style={{
                                            marginTop: 20,
                                            padding: 20,
                                            paddingRight: 40,
                                            border: "1px solid black",
                                        }}
                                    >
                                        <Grid container spacing={3}>
                                            {incompleteNotArchivedAssignments.length >
                                            0 ? (
                                                incompleteNotArchivedAssignments.map(
                                                    (task, index) => {
                                                        return (
                                                            <ActivityItem
                                                                adminView={
                                                                    selectedUserProfile.data !=
                                                                        null &&
                                                                    userProfile
                                                                        .data
                                                                        .admin
                                                                }
                                                                task={task}
                                                                key={index}
                                                                uid={uid}
                                                                profile={
                                                                    profile
                                                                }
                                                                setProfile={(
                                                                    event
                                                                ) =>
                                                                    setProfile(
                                                                        event
                                                                    )
                                                                }
                                                            />
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <Typography
                                                    variant="h6"
                                                    style={{ margin: 10 }}
                                                >
                                                    You have no pending
                                                    activites at this time.
                                                </Typography>
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <br />
                                <Accordion
                                    style={{
                                        boxShadow: "none",
                                        bordeRadius: 0,
                                    }}
                                >
                                    <AccordionSummary
                                        style={{
                                            backgroundColor: "gold",
                                            border: "1px solid black",
                                        }}
                                        expandIcon={<ExpandMore />}
                                    >
                                        <Typography variant="h5">
                                            Archived / Completed
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        style={{
                                            marginTop: 20,
                                            padding: 20,
                                            paddingRight: 40,
                                            border: "1px solid black",
                                        }}
                                    >
                                        <Grid container spacing={3}>
                                            {completeArchivedAssignments.length >
                                            0 ? (
                                                completeArchivedAssignments.map(
                                                    (task, index) => {
                                                        return (
                                                            <ActivityItem
                                                                adminView={
                                                                    selectedUserProfile.data !=
                                                                        null &&
                                                                    userProfile
                                                                        .data
                                                                        .admin
                                                                }
                                                                task={task}
                                                                key={index}
                                                                uid={uid}
                                                                profile={
                                                                    profile
                                                                }
                                                                setProfile={(
                                                                    event
                                                                ) =>
                                                                    setProfile(
                                                                        event
                                                                    )
                                                                }
                                                            />
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <Typography
                                                    variant="h6"
                                                    style={{ margin: 10 }}
                                                >
                                                    You have no archived or
                                                    completed activities.
                                                </Typography>
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ) : (
                            <Typography
                                variant="h5"
                                align="center"
                                style={{ marginTop: 40 }}
                            >
                                No activities to display
                            </Typography>
                        )}
                    </div>
                ) : (
                    <div className={classes.tabContent}>
                        <Typography variant="h5">
                            {profile.first_name} {profile.last_name}
                        </Typography>
                        <Typography variant="body1">{profile.email}</Typography>

                        <br />

                        <div>
                            <Typography variant="h6">Saved Cars</Typography>
                            <ul className={classes.list}>
                                {profile.saved_cars &&
                                profile.saved_cars.length > 0 ? (
                                    profile.saved_cars.map((car, index) => {
                                        return (
                                            <li key={index}>
                                                <Typography variant="body1">
                                                    {car["Model Year"]}{" "}
                                                    {car.Make} {car.Model}
                                                </Typography>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <li>
                                        <Typography variant="body1">
                                            You do not have any saved cars.
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                        </div>

                        <div>
                            <Typography variant="h6">
                                Recommended Cars
                            </Typography>
                            <ul className={classes.list}>
                                {profile.recommended_cars &&
                                profile.recommended_cars.length > 0 ? (
                                    profile.recommended_cars.map(
                                        (car, index) => {
                                            return (
                                                <li key={index}>
                                                    <Typography variant="body1">
                                                        {car}
                                                    </Typography>
                                                </li>
                                            );
                                        }
                                    )
                                ) : (
                                    <li>
                                        <Typography variant="body1">
                                            You do not have any recommended
                                            cars.
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                )}

                {/* <TextDivider text="Getting Started" />
                
                <GrayTextBox variant="h6">
                    Let’s begin with a brief survey. This information will help our team of experts to understand your needs and provide more helpful and timely advice.
                    <div style={{marginTop: 10, display: 'flex', justifyContent: 'flex-end'}}>
                        <StyledTaskButton onClick={() => setGetStartedOpen(true)}>
                            Launch Survey
                        </StyledTaskButton>
                    </div>
                </GrayTextBox>

                <TextDivider text="Learn How Your Coach Gets the Best Deal For You" />

                <GrayTextBox variant="h6">
                    Electrifyze helps you save thousands of dollars: Your Electric Car Coach starts by quickly answering your questions about electric vehicles. When you are ready to buy, the coach will contact multiple dealerships to make you an offer. This competitive approach helps LA County employees save up to $5,000 more than they could get on their own - completely hassle free.
                    <div style={{marginTop: 10, display: 'flex', justifyContent: 'flex-end'}}>
                        <StyledTaskButton onClick={() => setLearnOpen(true)}>
                            Learn How It Works
                        </StyledTaskButton>
                    </div>
                </GrayTextBox>
                
                <TextDivider text="Book an Appointment" />

                <GrayTextBox variant="h6">
                    Are you ready to buy? Savvy car shoppers save thousands by negotiating with multiple dealers. Our team will provide expert advice by <i><u>phone, text {"&"} email</u></i> to find the best deal for you without the need to haggle with dealers.
                    <div style={{marginTop: 10, display: 'flex', justifyContent: 'flex-end'}}>
                        <StyledTaskButton onClick={() => setAppointmentOpen(true)}>
                            Talk to a Coach
                        </StyledTaskButton>
                    </div>
                </GrayTextBox>

                <TextDivider text="What Your Peers Are Saying" />

                <div style={{marginTop: 20, marginBottom: 40}}>
                    <ReviewsCarousel />
                </div>

                <AppointmentModal visible={appointmentOpen} handleClose={() => setAppointmentOpen(false)} />

                <LearnDialog visible={learnOpen} handleClose={() => setLearnOpen(false)} /> */}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    userProfile: state.user.userProfile,
    assignments: state.content.assignments,
    selectedUserProfile: state.admin.selectedUserProfile,
});

const mapDispatchToProps = {
    getOrganization,
    getHomePage,
    getAssignments,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Coaching)
);
