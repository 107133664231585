import React, { useEffect, useRef, useState } from "react";
// import NativeForms from "native-forms-react";
// import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Styles
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Grid, Typography } from "@mui/material";

//Components
import { CustomDialogTitle } from "../../../../default/Styled Components/DialogTitle";
import EVCoachMessage from "../../../../default/Login/EVCoachMessage"
import CCCEIndependenceReferDialog from '../Refer/ReferDialog';

//Assets
import LadyStatuePlugSmile from "../../../../../static/images/lady-statute-charging-plug-smile.png";

//Helpers
// import { paramsToObject } from "../../helpers/functions";
import { StyledBlackOutlineButton, StyledTaskButton } from "../../../../default/Styled Components/Buttons";
import MoreChancesToWinPopper from "./CampaignCompletion-MoreChancesToWinPopper";
import Confetti from "../../../../default/CampaignCompletion/CampaignCompletion-Confetti";


function CampaignCompletionDialog(props) {
    const { open, toggleDialog, userProfile } = props;
    const childFunc = useRef(null)
    const [openPopper, setOpenPopper] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [openReferDialog, toggleOpenReferDialog] = useState(false);

    const handleConfettiClick = () => {
        childFunc.current()
    }

    const handleClick = (event) => {
        // console.log("Clicked")
        setAnchorEl(event.currentTarget)
        setOpenPopper(true)
    }

    const handleClickAway = (event) => {
        setAnchorEl(null)
        setOpenPopper(false)
    }

    const handleBackToSyllabusClick = (event) => {
        handleClickAway()
        toggleDialog()
    }

    const handleReferClick = () => {
        handleClickAway();
        toggleOpenReferDialog(!openReferDialog);
    }

    return (
        <>
            <Dialog
                open={open}
                maxWidth="md"
                onBackdropClick={toggleDialog}
            >
                <CustomDialogTitle onClose={toggleDialog}>
                    Hooray – You Made It!
                </CustomDialogTitle>
                <DialogContent>
                    <EVCoachMessage
                        coachImage={LadyStatuePlugSmile}
                        message={
                            <div>
                                <Typography 
                                    variant="h4"
                                    align="center"
                                >
                                    <b>Congratulations - YOU Did It!</b>
                                </Typography>
                                <Typography
                                    variant="h5"
                                    align="center"
                                >
                                    You finished EVerything
                                </Typography>
                                <Box m={1.5} />
                                <Typography
                                    variant="h5"
                                    align="left"
                                >
                                    I got you some confetti to celebrate :)
                                </Typography>
                                <Box m={1.5} />
                                <Typography
                                    variant="h5"
                                    align="left"
                                >
                                    If you're still having fun, take a
                                    few minutes and earn more chances to win...
                                    Along the way, you'll help create a
                                    fossil fuel free community!
                                </Typography>
                                <Box m={2.0} />
                                <Grid container>
                                    <Grid item xs container justifyContent="center">
                                        <StyledBlackOutlineButton
                                            // onClick={handleClick}
                                            onClick={handleConfettiClick}
                                        >
                                            <Typography 
                                                variant='body1'
                                            >
                                                More Confetti, please!
                                            </Typography>
                                        </StyledBlackOutlineButton>
                                    </Grid>
                                    <Grid item xs container justifyContent="center">
                                        <StyledTaskButton 
                                            // onClick={() => window.open("https://lets.electrifyze.us/3ce/?signup=true", "_blank")}
                                            onClick={handleClick}
                                        >
                                            <Typography
                                                variant="h5"
                                            >
                                                <b>More Chances <br />to Win</b>
                                            </Typography>
                                        </StyledTaskButton>
                                        {openPopper && (
                                            <MoreChancesToWinPopper 
                                                anchorEl={anchorEl}  
                                                handleClickAway={handleClickAway}
                                                handleReferClick={handleReferClick}
                                                handleBackToSyllabusClick={handleBackToSyllabusClick}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    />
                </DialogContent>

                <Confetti childFunc={childFunc} />

            </Dialog>
            {openReferDialog && (
                <CCCEIndependenceReferDialog 
                    open={openReferDialog}
                    toggleDialog={() => toggleOpenReferDialog(!openReferDialog)}
                    email={userProfile.data.email}
                />
            )}

        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
    };
};

export default withRouter(
    connect(mapStateToProps)(CampaignCompletionDialog)
);
