import React from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Button, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { StyledTaskButton } from "../Styled Components/Buttons";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    outerGridContainer: {},
    innerGridContainer: {
        //width: '100%',
        //backgroundColor: 'black',
        overflowY: "auto",
    },
    textGridItem: {
        height: "auto",
        margin: "auto",
        [theme.breakpoints.down('xl')]: {
            minWidth: "100%",
        },
    },
    innerTextBox: {
        height: "100%",
        overflowY: "auto",
        margin: 20,
        textAlign: "left",
        [theme.breakpoints.down('lg')]: {
            margin: 20,
        },
    },
    taskTitle: {
        textTransform: "capitalize",
        marginLeft: 20,
        textAlign: "left",
    },
    videoGridItem: {
        height: "100%",
        padding: 15,
        [theme.breakpoints.down('xl')]: {
            height: 350,
        },
        [theme.breakpoints.down('lg')]: {
            height: 250,
        },
    },
    rightGridItem: {
        [theme.breakpoints.down('xl')]: {
            display: "none",
        },
    },
    nextButton: {
        margin: "20px !important",
    },
}));

function Video(props) {
    const classes = useStyles(props);

    const {
        organization,
        singleTask,
        userProfile,
        tasks,
        courses,
        sections,
        sub_sections,
        isAssignment,
    } = props;

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="column"
                style={{ height: "100%", textAlign: "center" }}
            >
                <Grid
                    item
                    xs
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.innerGridContainer}
                >
                    <Grid
                        item
                        xs={4}
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.textGridItem}
                    >
                        <div className={classes.innerTextBox}>
                            <Typography variant="h3">
                                {singleTask.title}
                            </Typography>
                            <Typography
                                variant="h6"
                                style={{ marginTop: 10, marginBottom: 10 }}
                            >
                                {singleTask.description}
                            </Typography>
                            <div style={{ textAlign: "left" }}>
                                {singleTask.context &&
                                    parse(singleTask.context)}
                            </div>
                            {singleTask.videoBackup && (
                                <div>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            marginTop: 20,
                                            marginBottom: 10,
                                        }}
                                    >
                                        Video not working? Try this link instead
                                    </Typography>
                                    <a
                                        href={singleTask.videoBackup}
                                        target="_blank"
                                        style={{ textDecoration: "none" }} rel="noreferrer"
                                    >
                                        <Button
                                            variant="contained"
                                            color="info"
                                        >
                                            Click here
                                        </Button>
                                    </a>
                                </div>
                            )}
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.videoGridItem}
                    >
                        {singleTask.content && (
                            <iframe
                                //className={classes.iframeContainer + " " + classes.iframe}
                                style={
                                    singleTask.type === "article"
                                        ? { width: "100%", height: "100%" }
                                        : { width: "100%", height: "85%" }
                                }
                                src={singleTask.content}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen="allowfullscreen"
                                mozallowfullscreen="mozallowfullscreen"
                                msallowfullscreen="msallowfullscreen"
                                oallowfullscreen="oallowfullscreen"
                                webkitallowfullscreen="webkitallowfullscreen"
                                title="content"
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    style={
                        organization.data && organization.data != null
                            ? {
                                  height: 100,
                                  backgroundColor:
                                      organization.data.backgroundColor,
                                  color: organization.data.fontColor,
                              }
                            : {
                                  height: 100,
                                  backgroundColor: "black",
                                  color: "white",
                              }
                    }
                >
                    <Grid item xs container alignItems="center">
                        <Grid item>
                            <Typography
                                variant="h6"
                                className={classes.taskTitle}
                            >
                                {singleTask.title}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        style={{ width: "auto" }}
                        container
                        justifyContent="center"
                    >
                        {userProfile.loading ||
                        courses.loading ||
                        sections.loading ||
                        sub_sections.loading ||
                        tasks.loading ? (
                            <CircularProgress />
                        ) : isAssignment ? (
                            <StyledTaskButton
                                onClick={() =>
                                    props.updateUser(
                                        undefined,
                                        undefined,
                                        undefined,
                                        isAssignment
                                    )
                                }
                                classes={{ root: classes.nextButton }}
                            >
                                Complete Activity
                            </StyledTaskButton>
                        ) : (
                            <StyledTaskButton
                                onClick={props.updateUser}
                                classes={{ root: classes.nextButton }}
                            >
                                Next Task
                            </StyledTaskButton>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        className={classes.rightGridItem}
                    ></Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        organization: state.content.organization,
        userProfile: state.user.userProfile,
        courses: state.content.courses,
        sections: state.content.sections,
        sub_sections: state.content.sub_sections,
        tasks: state.content.tasks,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Video);
