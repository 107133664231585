/* eslint-disable unused-imports/no-unused-imports */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";

//Actions
import { signInUser, logout } from "../../../../actions/user_actions";
import { getOrganization } from "../../../../actions/content_actions";

//Styles
import { makeStyles } from "@mui/styles";
import { ButtonBase, Typography } from "@mui/material";

//Assets
import ElectrifyzeLogoWhite from "../../../../static/images/Electrifyze-Logo-white.svg";
import TaskTitleBackBox from "../../../default/Header/TaskTitleBackBox";
import { StyledTaskButton } from "../../../default/Styled Components/Buttons";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        position: "relative",
        zIndex: 1201,
        maxWidth: "100%",
        height: 90,
        paddingBottom: 0,
        width: "100%",
        backgroundColor: "black",
        color: 'white',
        alignItems: "center",
        //boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.75)'
    },
    headerNone: {
        display: "none",
    },
    logoFlexBox: {
        height: 70,
        width: 250,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('lg')]: {
            width: 150,
        },
    },
    title: {
        color: "white",
        fontSize: 48,
        fontWeight: 300,
        margin: 0,
    },
    leftBox: {
        height: "100%",
        marginLeft: 20,
        flex: 6,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginRight: 20,
    },
    middleBox: {
        height: "100%",
        flex: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    rightBox: {
        height: "100%",
        flex: 6,
        display: "flex",
        marginRight: 20,
        justifyContent: "flex-end",
        alignItems: "center",
    },
    gridItem: {
        borderRight: "1px solid white",
        [theme.breakpoints.down('lg')]: {
            borderRight: "none",
        },
    },
    link: {
        textAlign: "center",
        textDecoration: "none",
        color: "white",
    },
    headerLinkText: {
        marginRight: 20,
        marginLeft: 20,
        fontWeight: 400,
        [theme.breakpoints.down('xl')]: {
            fontSize: 18,
            marginRight: 10,
            marginLeft: 10,
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 14,
            marginRight: 0,
        },
    },
    button: {
        borderColor: theme.palette.info.main,
        color: theme.palette.info.main,
    },
    icon: {
        marginLeft: 20,
        fontSize: 32,
        [theme.breakpoints.down('xl')]: {
            fontSize: 26,
        },
        [theme.breakpoints.down('xl')]: {
            marginTop: 5,
        },
    },
}));

function CampaignHeader(props) {
    const classes = useStyles(props);
    const { user, userProfile, organization, singleTask, location } = props;

    const pathname = useLocation().pathname || null;

    const handleGetStartedClick = () => {
        const landingPageURL = "https://lets.electrifyze.us/3ce/?signup=true";
        window.open(landingPageURL, "_blank")
    }

    useEffect(() => {
        if (userProfile.data !== null) {
            props.getOrganization(
                userProfile.data.currentOrganization ||
                    userProfile.data.organization.name ||
                    userProfile.data.organization
            );
        }
    }, [userProfile.data]);

    return (
        <header className={classes.header} >
            <div className={classes.leftBox}>
                <Typography sx={{
                    lineHeight: 1.2,
                    color: 'white',
                    fontSize: {
                        xs: 28,
                        sm: 36
                    },
                }}>
                    <b><i>EVs For Earth</i></b>
                </Typography>

            </div>
            <div className={classes.middleBox}>
                {organization.loading ||
                user.loading ||
                userProfile.loading ||
                singleTask.loading ? null : (pathname.startsWith("/task") ||
                    pathname.startsWith("/transition")) &&
                singleTask.data != null ? (
                    <TaskTitleBackBox />
                ) : null}
            </div>
            <div className={classes.rightBox}>
                <StyledTaskButton onClick={handleGetStartedClick}>
                    Get Started
                </StyledTaskButton>
            </div>

        </header>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        organization: state.content.organization,
        courses: state.content.courses,
        singleTask: state.content.singleTask,
        homePage: state.content.homePage,
    };
};

const mapDispatchToProps = {
    signInUser,
    logout,
    getOrganization,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignHeader));
