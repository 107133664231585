import {
    GET_PRE_REGISTRATION_SURVEY_BEGIN,
    GET_PRE_REGISTRATION_SURVEY_ERROR,
    GET_PRE_REGISTRATION_SURVEY_SUCCESS,
} from "../constants/action-types-survey";

const initialState = {
    preRegistrationSurvey: {
        data: null,
        loading: false,
        error: null,
    },
};

const survey = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRE_REGISTRATION_SURVEY_BEGIN:
            return {
                ...state,
                preRegistrationSurvey: {
                    data: null,
                    loading: true,
                    error: null,
                },
            };

        case GET_PRE_REGISTRATION_SURVEY_ERROR:
            return {
                ...state,
                preRegistrationSurvey: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case GET_PRE_REGISTRATION_SURVEY_SUCCESS:
            return {
                ...state,
                preRegistrationSurvey: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        default:
            return state;
    }
};

export default survey;
