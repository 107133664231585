import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions
import { getOrganization } from "../actions/content_actions";

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import {
    getOrganizationComponent,
} from "../helpers/functions";
import { Suspense } from "react";

//Components
import AuthenticatedHomeDefault from "../components/default/AuthenticatedHome/AuthenticatedHomeDefault";

const useStyles = makeStyles((theme) => ({}));

function AuthenticatedHome(props) {
    const classes = useStyles();
    const { userProfile } = props;
    const organizationName =
        userProfile.data.organization.name || userProfile.data.organization;

    const [AuthenticatedHomePage, setAuthenticatedHomePage] = useState(
        getOrganizationComponent(organizationName, "AuthenticatedHome")
    );

    useEffect(() => {
        if (organizationName) {
            setAuthenticatedHomePage(
                getOrganizationComponent(organizationName, "AuthenticatedHome")
            );
            props.getOrganization(organizationName);
        }
    }, []);

    return (
        <Suspense fallback={<AuthenticatedHomeDefault />}>
            <AuthenticatedHomePage />
        </Suspense>
    );
}

const mapStateToProps = (state) => {
    return {
        organization: state.content.organization,
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganization: (organization) =>
            dispatch(getOrganization(organization)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AuthenticatedHome)
);
