import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Styles
import makeStyles from "@mui/styles/makeStyles";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
} from "@mui/material";
import { StyledTaskButton } from "../../Styled Components/Buttons";
import { Suspense } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    rightGridItem: {
        [theme.breakpoints.down('xl')]: {
            display: "none",
        },
    },
}));

function ActivityContent(props) {
    //Pass theme styles as props
    const classes = useStyles(props);

    const { activity, organization, singleTask, isAssignment } = props;
    const [checked, setChecked] = useState([]);
    const [points, setPoints] = useState();
    const [CustomComponent, setCustomComponent] = useState(
        React.lazy(() =>
            import(`./Custom Activities/${activity.componentName}`).catch(
                (err) => {
                    //File does not exist
                }
            )
        )
    );

    const [position, setPosition] = useState();

    useEffect(() => {
        let steps = [];

        if (activity.customComponent) {
            steps.push(false);
        } else {
            for (let i in activity.steps) {
                steps.push(false);
            }

            navigator.geolocation.getCurrentPosition((position, error) => {
                //console.log(position.coords.latitude)
                //https://us1.locationiq.com/v1/reverse.php?key=YOUR_PRIVATE_TOKEN&lat=LATITUDE&lon=LONGITUDE&format=json
                if (error) {
                    setPosition(false);
                } else {
                    setPosition({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                }
            });
        }

        setChecked(steps);
    }, []);

    const handleChange = (index) => {
        let checkedNew = checked;
        checkedNew[index] = !checked[index];

        setChecked((checked) => [...checked]);
    };

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="column"
                style={{ height: "100%", textAlign: "center" }}
            >
                <Grid
                    item
                    xs
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ msFlex: "1 1 1", overflowY: "auto" }}
                >
                    {activity.showSteps && (
                        <Grid
                            item
                            xs={3}
                            style={{
                                backgroundColor: "#E8E8E8",
                                height: "100%",
                                overflowY: "auto",
                            }}
                        >
                            <div style={{ height: "100%", margin: 40 }}>
                                <Typography
                                    variant="h4"
                                    style={{ textAlign: "center" }}
                                >
                                    {activity.stepTitle}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 20,
                                        textAlign: "left",
                                    }}
                                >
                                    {activity.stepDescription}
                                </Typography>
                                <FormGroup style={{ marginBottom: 40 }}>
                                    {activity.steps.map((step, index) => {
                                        return (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={checked[index]}
                                                        onChange={() =>
                                                            handleChange(index)
                                                        }
                                                        color="primary"
                                                        style={{
                                                            padding: 3,
                                                            marginRight: 15,
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <div
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h5"
                                                            style={{
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            {step.title}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {step.description}
                                                        </Typography>
                                                    </div>
                                                }
                                                style={{
                                                    marginBottom: 15,
                                                    alignItems: "flex-start",
                                                }}
                                            />
                                        );
                                    })}
                                </FormGroup>
                            </div>
                        </Grid>
                    )}

                    <Grid item xs style={{ height: "100%" }}>
                        {!activity.customComponent ? (
                            <iframe
                                src={`${activity.contentLink}${
                                    position
                                        ? `?lat=${position.lat}&lng=${position.lng}`
                                        : ""
                                }`}
                                height="100%"
                                width="100%"
                                frameBorder="0"
                                title="activity"
                            />
                        ) : (
                            /* : !position ?
                            <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Typography variant="h4">
                                    Current location must be enabled for this app to work
                                </Typography>
                            </div> */
                            <Suspense fallback={<></>}>
                                <CustomComponent
                                    enableNextTask={() =>
                                        setChecked((checked) => [true])
                                    }
                                    setPoints={(points) => setPoints(points)}
                                />
                            </Suspense>
                        )}
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    alignItems="center"
                    style={
                        organization.data && organization.data != null
                            ? {
                                  height: 100,
                                  backgroundColor:
                                      organization.data.backgroundColor,
                                  color: organization.data.fontColor,
                              }
                            : {
                                  height: 100,
                                  backgroundColor: "black",
                                  color: "white",
                              }
                    }
                >
                    <Grid item xs container alignItems="flex-start">
                        <Typography
                            variant="h6"
                            style={{
                                textTransform: "capitalize",
                                marginLeft: 20,
                            }}
                        >
                            {singleTask.title}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs
                        container
                        alignItems="center"
                        justifyContent="center"
                    >
                        {isAssignment ? (
                            <StyledTaskButton
                                onClick={() =>
                                    points
                                        ? props.updateUser(
                                              undefined,
                                              undefined,
                                              points,
                                              true
                                          )
                                        : props.updateUser(
                                              undefined,
                                              undefined,
                                              undefined,
                                              true
                                          )
                                }
                                disabled={
                                    checked.filter((item) => item === false)
                                        .length > 0
                                }
                            >
                                Complete Activity
                            </StyledTaskButton>
                        ) : (
                            <StyledTaskButton
                                onClick={() =>
                                    points
                                        ? props.updateUser(
                                              undefined,
                                              undefined,
                                              points
                                          )
                                        : props.updateUser()
                                }
                                disabled={
                                    checked.filter((item) => item === false)
                                        .length > 0
                                }
                            >
                                Next Task
                            </StyledTaskButton>
                        )}
                    </Grid>
                    <Grid item xs className={classes.rightGridItem}></Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        organization: state.content.organization,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityContent);
