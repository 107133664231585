
import { navigatePurchaseList } from "./content";

export const getHTML = (user) => {
    return `
        <html>
        <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>ELECTRIFYZE Welcome Email</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        </head>
        <body style="margin: 0; padding: 0;">
            <table border="0" cellpadding="0" cellspacing="0" width="100%">	
                <tr>
                    <td style="padding: 10px 0 30px 0;">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border: 1px solid #cccccc; border-collapse: collapse;">
                            <tr>
                                <td align="center" bgcolor="black" style="padding: 20px 0 20px 0; color: #ffffff; font-size: 28px; font-weight: bold; font-family: Arial, sans-serif;">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/electrifyze-fce77.appspot.com/o/flamelink%2Fmedia%2FElectrifyze-Logo-white.png?alt=media&token=63ce9b4a-4b75-4f3a-b9a4-5b73d6c09fa6" alt="" width="auto" height="45" style="display: block; object-fit: contain" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/electrifyze-fce77.appspot.com/o/flamelink%2Fmedia%2Fcar-key-short.jpg?alt=media&token=f2c08a40-bc62-4936-afa0-ec56b0c47d63" alt="" width="100%" height="auto" style="display: block; object-fit: cover" />
                                </td>
                            </tr>
                            <tr>
                                <td bgcolor="#ffffff" style="padding: 40px 30px 40px 30px;">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tr>
                                            <td style="color: black; font-family: Arial, sans-serif; font-size: 24px;">
                                                <b>Your Car Buying Coach will be in touch soon!</b>
                                            </td>
                                        </tr>
                                        <tr style="font-family: Arial, sans-serif; font-size: 20px;">
                                            <td style="padding: 30px 0 30px 0;">
                                                
                                                Hi ${user.first_name},
                                                <br />
                                                <br />
                                                Thanks for signing up with Electrifyze!
                                                <br />
                                                <br />
                                                Car buying is complex, especially for electric cars.
                                                Your car buying coach will take the hard work and confusion out of learning about and buying an electric car.
                                                <br />
                                                <br />
                                                We’re excited to work with you!
                                                <br />
                                                <br />
                                                More soon,
                                                <br />
                                                <br />
                                                The Electrifyze Team
                                            </td>
                                        <tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td bgcolor="#000000" style="padding: 30px 30px 30px 30px;">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tr>
                                            <td style="color: #ffffff; font-family: Arial, sans-serif; font-size: 13px;" width="75%">
                                                <a href="https://www.electrifyze.com/" style="font-size: 25px; color: #ffffff;">
                                                    <font color="#ffffff">
                                                        <img src="https://firebasestorage.googleapis.com/v0/b/electrifyze-fce77.appspot.com/o/flamelink%2Fmedia%2FElectrifyze-Logo-white.png?alt=media&token=63ce9b4a-4b75-4f3a-b9a4-5b73d6c09fa6" alt="" height="37" width="auto" style="display: block; object-fit: contain" />
                                                    </font>
                                                </a>
                                                &nbsp;EV Learning and Car Buying Made Easy
                                            </td>
                </tr>
            </table>
        </body>
        </html>
        `;
};

const getNavigatePurchaseList = (user) => {
    let new_list = [];
    for (let i in user.navigate_purchase) {
        if (user.navigate_purchase[i] === true) {
            new_list.push(navigatePurchaseList[i].title);
        }
    }

    return new_list;
};

export const getHTMLAdmin = (user, contactID) => {
    return `
        <html>
        <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>New Car Coaching Survey Submission</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <style>
            table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
            }
            
            td, th {
                border: 1px solid #dddddd;
                text-align: left;
                padding: 8px;
            }
            th {
                background-color: #dddddd;
            }
        </style>
        </head>
        <body style="margin: 20px; padding: 0;">
            <h2 style="text-align: center;">
                New Car Coaching Survey Submission
            </h2>
            <table align="center" cellpadding="0" cellspacing="0" width="700" >
                <tr>
                    <th>Field</th>
                    <th>Value</th>
                </tr>        
                <tr>
                    <td>First name</td>
                    <td>${user.first_name}</td>
                </tr>
                <tr>
                    <td>Last name</td>
                    <td>${user.last_name}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>${user.email}</td>
                </tr>
                <tr>
                    <td>Phone number</td>
                    <td>${user.phone_number}</td>
                </tr>
                <tr>
                    <td>Hubspot Link</td>
                    <td>${
                        contactID
                            ? `https://app.hubspot.com/contacts/20171276/contact/${contactID}/`
                            : "Unknown"
                    }</td>
                </tr>
                ${user.additional_surveys
                    .map((survey) => {
                        const form = survey.formData;
                        return Object.keys(form)
                            .map((key, index) => {
                                if (
                                    key === "Email" ||
                                    key === "Phone Number" ||
                                    key === "First Name" ||
                                    key === "Last Name"
                                )
                                    return "";
                                else
                                    return `<tr key=${index}>
                                    <td>${key}</td>
                                    <td>${form[key]}</td>
                                </tr>`;
                            })
                            .join("");
                    })
                    .join("")}
            </table>
        </body>
        </html>
        `;
};

export const getCarMarketEmail = (userProfile, form) => {
    return `
        <html>
        <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>
            Someone is interested in buying a car!
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <style>
            table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
            }
            
            td, th {
                border: 1px solid #dddddd;
                text-align: left;
                padding: 8px;
            }
            th {
                background-color: #dddddd;
            }
        </style>
        </head>
        <body style="margin: 20px; padding: 0;">
            <h2 style="text-align: center;">
                A user selected "Yes" to the question "Are you in the market to buy a car?".
                <br />
                Navigate to https://app.nativeforms.com/${form} to view the response.
            </h2>
            <table align="center" cellpadding="0" cellspacing="0" width="700" >
                <tr>
                    <th>Field</th>
                    <th>Value</th>
                </tr>        
                <tr>
                    <td>First name</td>
                    <td>${userProfile.first_name}</td>
                </tr>
                <tr>
                    <td>Last name</td>
                    <td>${userProfile.last_name}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>${userProfile.email}</td>
                </tr>
                <tr>
                    <td>Form</td>
                    <td>${form}</td>
                </tr>
            </table>
        </body>
        </html>
        `;
};
