import React from "react";
import { connect } from "react-redux";

//Styles
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Typography } from "@mui/material";

//Assets
import { CustomDialogTitle } from "../../../../default/Styled Components/DialogTitle";

const useStyles = makeStyles((theme) => ({
    dialogBox: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down("xl")]: {
            margin: 0,
        },
    },
    closeButton: {
        minWidth: 0,
    },
}));

function EmailReferDialog(props) {
    //Pass the color from the parent component to the styles
    const classes = useStyles();

    const { visible, handleClose, campaign, email } = props;
    const referralURL = `https://share.electrifyze.com/c/${
        campaign || "2022-3ce-independence"
    }.html?ref=${btoa(email)}`;

    React.useEffect(() => {}, []);

    return (
        <Dialog open={visible} fullWidth={true} maxWidth="md">
            <CustomDialogTitle onClose={handleClose} custom={true}>
                <div>
                    <Typography variant="h6" style={{ fontWeight: 500 }}>
                        How to Get Credit for Referring Others
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 300 }}>
                        Send an email to your friends, family, co-workers, or
                        anyone! Be sure to include your referral link, here’s an
                        example:
                    </Typography>
                </div>
            </CustomDialogTitle>

            <DialogContent
                style={{ overflow: "auto", msOverflowStyle: "scrollbar" }}
            >
                <div className={classes.dialogBox}>
                    <div>
                        <div
                            style={{
                                marginBottom: 10,
                                border: "solid",
                                borderWidth: 1,
                                borderRadius: 15,
                            }}
                        >
                            <div style={{ height: "100%", margin: 15 }}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        height: "100%",
                                        paddingRight: 15,
                                        display: "inline",
                                        borderRight: "solid",
                                        borderRightWidth: 1,
                                    }}
                                >
                                    Subject
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        display: "inline",
                                        paddingLeft: 15,
                                    }}
                                >
                                    Sharing a Fun Event: Independence from
                                    Fossil Fuels
                                </Typography>
                            </div>
                        </div>

                        <div
                            style={{
                                border: "solid",
                                borderWidth: 1,
                                borderRadius: 15,
                            }}
                        >
                            <Typography variant="body1" style={{ margin: 15 }}>
                                <div>
                                    Greetings -
                                    <br />
                                    <br />
                                    In celebration of Independence Day, I signed
                                    up to join this neat online event:
                                    "Independence from Fossil Fuels"!
                                    <br />
                                    <br />
                                    They'll introduce you to electric cars, and
                                    how they help you gain independence from
                                    fossil fuels and from high gas prices.
                                    You’ll learn how you can save thousands of
                                    dollars when you get one... And they're
                                    offering prizes to participate too!
                                    <br />
                                    <br />
                                    I’m sharing my link so I can get credit for
                                    referring you :)
                                    <br />
                                    <a
                                        href={referralURL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {referralURL}
                                    </a>
                                    <br />
                                    <br />I signed up already - Hope you can
                                    join too... It looks fun!
                                </div>
                            </Typography>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailReferDialog);
