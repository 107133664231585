import { combineReducers } from "redux";

//Reducers
import user from "./user";
import content from "./content";
import alert from "./alert";
import email from "./email";
import survey from "./survey";
import sendgrid from "./sendgrid";
import coaching from "./coaching";
import hubspot from "./hubspot";
import admin from "./admin";

const rootReducer = combineReducers({
    user,
    content,
    alert,
    email,
    survey,
    sendgrid,
    coaching,
    hubspot,
    admin,
});

export default rootReducer;
