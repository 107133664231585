import { Typography } from "@mui/material";
import React from "react";

export default function QuestionHeader(props) {
    return (
        <div className={props.classes.quizHeader}>
            <Typography variant="h5">
                <div
                    dangerouslySetInnerHTML={{ __html: props.questionToRender }}
                />
            </Typography>
        </div>
    );
}
