import {
    CREATE_COACHING_PROFILE_BEGIN,
    CREATE_COACHING_PROFILE_ERROR,
    CREATE_COACHING_PROFILE_SUCCESS,
    GET_COACHING_PROFILE_BEGIN,
    GET_COACHING_PROFILE_ERROR,
    GET_COACHING_PROFILE_SUCCESS,
    UPDATE_COACHING_PROFILE_BEGIN,
    UPDATE_COACHING_PROFILE_ERROR,
    UPDATE_COACHING_PROFILE_SUCCESS,
} from "../constants/action-types-coaching";

// Firebase
import { getFirestore, addDoc, setDoc, getDoc } from "firebase/firestore"

//LogRocket
// import LogRocket from "logrocket";

// Get Firestore reference
const db = getFirestore();

export const createCoachingProfileBegin = () => ({
    type: CREATE_COACHING_PROFILE_BEGIN,
});
export const createCoachingProfileSuccess = (result) => ({
    type: CREATE_COACHING_PROFILE_SUCCESS,
    payload: result,
});
export const createCoachingProfileError = (error) => ({
    type: CREATE_COACHING_PROFILE_ERROR,
    payload: error,
});

export function createCoachingProfile(email, profile) {
    return async (dispatch) => {
        dispatch(createCoachingProfileBegin());
        try {
            const doc = await addDoc(doc(db, "coaching_profiles", email), profile)
            console.log("Document successfully written!", doc);
            dispatch(createCoachingProfileSuccess(profile));
        } catch(error) {
            console.error("Error creating document: ", error);
            // LogRocket.captureException(error);
            dispatch(createCoachingProfileError(error));
        }
    };
}

export const getCoachingProfileBegin = () => ({
    type: GET_COACHING_PROFILE_BEGIN,
});
export const getCoachingProfileSuccess = (result) => ({
    type: GET_COACHING_PROFILE_SUCCESS,
    payload: result,
});
export const getCoachingProfileError = (error) => ({
    type: GET_COACHING_PROFILE_ERROR,
    payload: error,
});

export function getCoachingProfile(email) {
    return async (dispatch) => {
        dispatch(getCoachingProfileBegin());
        try {
            const doc = await getDoc(doc(db, "coaching_profiles", email))
            if (doc.exists) {
                let coachingProfile = doc.data();
                console.log("Document data:", coachingProfile);
                dispatch(getCoachingProfileSuccess(coachingProfile));
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                dispatch(getCoachingProfileError("No such document!"));
            }
        } catch(error) {
            console.log("Error getting document:", error);
            // LogRocket.captureException(error);
            dispatch(getCoachingProfileError(error));
        }
    };
}

export const updateCoachingProfileBegin = () => ({
    type: UPDATE_COACHING_PROFILE_BEGIN,
});
export const updateCoachingProfileSuccess = (result) => ({
    type: UPDATE_COACHING_PROFILE_SUCCESS,
    payload: result,
});
export const updateCoachingProfileError = (error) => ({
    type: UPDATE_COACHING_PROFILE_ERROR,
    payload: error,
});

export function updateCoachingProfile(email, coachingProfile) {
    return async (dispatch) => {
        dispatch(updateCoachingProfileBegin());
        try {
            const doc = await setDoc(doc(db, "coaching_profiles", email), coachingProfile)
            console.log("Document successfully written!", doc);
            dispatch(updateCoachingProfileSuccess(coachingProfile));
        } catch(error) {
            console.error("Error writing document: ", error);
            // LogRocket.captureException(error);
            dispatch(updateCoachingProfileError(error));
        }
    };
}
