import React, { useEffect, useRef, useState } from "react";
// import NativeForms from "native-forms-react";
// import { useLocation } from "react-router-dom";

//Styles
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";

//Components
import { CustomDialogTitle } from "../../../default/Styled Components/DialogTitle";
import EVCoachMessage from "../../../default/Login/EVCoachMessage"

//Assets
import Earth from "../../../../static/images/earth-only.png";

//Helpers
// import { paramsToObject } from "../../helpers/functions";
import { StyledBlackOutlineButton, StyledTaskButton } from "../../../default/Styled Components/Buttons";
import LearnMorePopper from "./CampaignCompletion-LearnMorePopper";
import Confetti from "../../../default/CampaignCompletion/CampaignCompletion-Confetti";


function CampaignCompletionDialog(props) {
    const { open, toggleDialog, handleTryClick } = props;
    const childFunc = useRef(null)
    const [openPopper, setOpenPopper] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    const handleConfettiClick = () => {
        childFunc.current()
    }

    const handleClick = (event) => {
        console.log("Clicked")
        setAnchorEl(event.currentTarget)
        setOpenPopper(true)
    }

    const handleClickAway = (event) => {
        setAnchorEl(null)
        setOpenPopper(false)
    }

    return (
        <>
            <Dialog
                open={open}
                maxWidth="md"
                onBackdropClick={toggleDialog}
            >
                <CustomDialogTitle onClose={toggleDialog}>
                    Hooray – You Made It!
                </CustomDialogTitle>
                <DialogContent>
                    <EVCoachMessage
                        coachImage={Earth}
                        message={
                            <div>
                                <Typography 
                                    variant="h4"
                                    align="center"
                                >
                                    <b>Woohoo - YOU Did It!</b>
                                </Typography>
                                <Box m={1.5} />
                                <Typography
                                    variant="h5"
                                    align="left"
                                >
                                    You finished the entire demo campaign! Nice work.
                                </Typography>
                                <Box m={1.5} />
                                <Typography
                                    variant="h5"
                                    align="left"
                                >
                                    Ready to join the next EVs For Earth campaign? We'd love to help your audience learn about EVs.
                                </Typography>
                                <br />
                                <Grid container>
                                    <Grid item xs container justifyContent="center">
                                        <StyledBlackOutlineButton onClick={handleClick}>
                                            Learn More
                                        </StyledBlackOutlineButton>
                                        {openPopper && (
                                            <LearnMorePopper 
                                                anchorEl={anchorEl}  
                                                handleClick={handleClickAway}
                                                handleConfettiClick={handleConfettiClick} 
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs container justifyContent="center">
                                        <StyledTaskButton onClick={() => window.open("https://lets.electrifyze.us/3ce/?signup=true", "_blank")}>
                                            Join EVs For Earth
                                        </StyledTaskButton>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    />
                </DialogContent>

                <Confetti childFunc={childFunc} />

            </Dialog>

        </>
    );
}

export default CampaignCompletionDialog;
