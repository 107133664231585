import {
    GET_USER_BEGIN,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    CREATE_USER_BEGIN,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    CREATE_USER_PROFILE_BEGIN,
    CREATE_USER_PROFILE_ERROR,
    CREATE_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_BEGIN,
    GET_USER_PROFILE_ERROR,
    GET_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_BEGIN,
    UPDATE_USER_PROFILE_ERROR,
    UPDATE_USER_PROFILE_SUCCESS,
    LOGOUT_BEGIN,
    LOGOUT_ERROR,
    LOGOUT_SUCCESS,
    SET_USER,
    SEND_PASSWORD_RESET_EMAIL_BEGIN,
    SEND_PASSWORD_RESET_EMAIL_ERROR,
    SEND_PASSWORD_RESET_EMAIL_SUCCESS,
    VERIFY_PASSWORD_RESET_BEGIN,
    VERIFY_PASSWORD_RESET_ERROR,
    VERIFY_PASSWORD_RESET_SUCCESS,
    RESET_PASSWORD_BEGIN,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS,
    ADD_SENDGRID_CONTACT_BEGIN,
    ADD_SENDGRID_CONTACT_ERROR,
    ADD_SENDGRID_CONTACT_SUCCESS,
    UPDATE_REFERRALS_BEGIN,
    UPDATE_REFERRALS_ERROR,
    UPDATE_REFERRALS_SUCCESS,
} from "../constants/action-types-user";

const initialState = {
    user: {
        data: null,
        loading: false,
        error: null,
    },
    newUser: {
        data: null,
        loading: false,
        error: null,
    },
    userProfile: {
        data: null,
        loading: false,
        error: null,
    },
    passwordResetEmail: {
        data: null,
        loading: false,
        error: null,
    },
    passwordResetVerification: {
        data: null,
        loading: false,
        error: null,
    },
    passwordReset: {
        data: null,
        loading: false,
        error: null,
    },
    sendGridContact: {
        data: null,
        loading: false,
        error: null,
    },
    referrals: {
        data: null,
        loading: false,
        error: null,
    },
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_BEGIN:
            return {
                ...state,
                user: { data: null, loading: true, error: null },
            };

        case GET_USER_ERROR:
            return {
                ...state,
                user: { data: null, loading: false, error: action.payload },
            };

        case GET_USER_SUCCESS:
            return {
                ...state,
                user: { data: action.payload, loading: false, error: null },
            };

        case CREATE_USER_BEGIN:
            return {
                ...state,
                user: { data: null, loading: true, error: null },
            };

        case CREATE_USER_ERROR:
            return {
                ...state,
                user: {
                    data: null,
                    loading: false,
                    error: action.payload.message,
                },
            };

        case CREATE_USER_SUCCESS:
            return {
                ...state,
                user: { data: action.payload, loading: false, error: null },
            };

        case CREATE_USER_PROFILE_BEGIN:
            return {
                ...state,
                userProfile: { data: null, loading: true, error: null },
            };

        case CREATE_USER_PROFILE_ERROR:
            return {
                ...state,
                userProfile: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case CREATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case GET_USER_PROFILE_BEGIN:
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    loading: true,
                    error: null,
                },
            };

        case GET_USER_PROFILE_ERROR:
            return {
                ...state,
                userProfile: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case UPDATE_USER_PROFILE_BEGIN:
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    loading: true,
                    error: null,
                },
            };

        case UPDATE_USER_PROFILE_ERROR:
            return {
                ...state,
                userProfile: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case SET_USER:
            return {
                ...state,
                user: { data: action.payload, loading: false, error: null },
            };

        case LOGOUT_BEGIN:
            return {
                ...state,
                user: { ...state.user, loading: true, error: null },
                userProfile: {
                    ...state.userProfile,
                    loading: false,
                    error: null,
                },
            };

        case LOGOUT_ERROR:
            return {
                ...state,
                user: { loading: false, error: action.payload },
                userProfile: { loading: false, error: action.payload },
            };

        case LOGOUT_SUCCESS:
            // Clear both the user and user profile from redux state when logging out
            return {
                ...state,
                user: { data: null, loading: false, error: null },
                userProfile: { data: null, loading: false, error: null },
            };

        case SEND_PASSWORD_RESET_EMAIL_BEGIN:
            return {
                ...state,
                passwordResetEmail: { data: null, loading: true, error: null },
            };

        case SEND_PASSWORD_RESET_EMAIL_ERROR:
            return {
                ...state,
                passwordResetEmail: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case SEND_PASSWORD_RESET_EMAIL_SUCCESS:
            return {
                ...state,
                passwordResetEmail: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case VERIFY_PASSWORD_RESET_BEGIN:
            return {
                ...state,
                passwordResetVerification: {
                    data: null,
                    loading: true,
                    error: null,
                },
            };

        case VERIFY_PASSWORD_RESET_ERROR:
            return {
                ...state,
                passwordResetVerification: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case VERIFY_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                passwordResetVerification: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case RESET_PASSWORD_BEGIN:
            return {
                ...state,
                passwordReset: { data: null, loading: true, error: null },
            };

        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                passwordReset: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordReset: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case ADD_SENDGRID_CONTACT_BEGIN:
            return {
                ...state,
                sendGridContact: { data: null, loading: true, error: null },
            };

        case ADD_SENDGRID_CONTACT_ERROR:
            return {
                ...state,
                sendGridContact: {
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            };

        case ADD_SENDGRID_CONTACT_SUCCESS:
            return {
                ...state,
                sendGridContact: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case UPDATE_REFERRALS_BEGIN:
            return {
                ...state,
                referrals: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case UPDATE_REFERRALS_ERROR:
            return {
                ...state,
                referrals: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        case UPDATE_REFERRALS_SUCCESS:
            return {
                ...state,
                referrals: {
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            };

        default:
            return state;
    }
};

export default user;
