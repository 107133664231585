import React from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

//Styles
import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";

//Assets
import ElectrifyzeLogoBlack from "../../static/images/Electrifyze-Logo-black.svg";
import ElectrifyzeLogoWhite from "../../static/images/Electrifyze-Logo-white.svg";

//Helpers
import { getOrganizationRouteForPath } from "../../helpers/functions";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('xl')]: {
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
        },
    },
    logoImageBox: {
        height: "100%",
        width: 250,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: 10,
        [theme.breakpoints.down('xl')]: {
            width: 200,
        },
        [theme.breakpoints.down('lg')]: {
            width: 150,
            borderRight: "none !important",
            paddingRight: 0,
        },
    },
    countyLogoImageBox: {
        height: "100%",
        width: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 10,
        [theme.breakpoints.down('xl')]: {
            width: 40,
        },
        [theme.breakpoints.down('lg')]: {
            width: 30,
        },
    },
    img: {
        maxWidth: "100%",
        maxHeight: "100%",
        msFlex: "0 0 auto",
    },
    countyText: {
        color: "white",
        textDecoration: "none !important",
        fontFamily: "Quicksand",
        fontWeight: 500,
        [theme.breakpoints.down('xl')]: {
            fontSize: 24,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 20,
        },
    },
    link: {
        textDecoration: "none",
    },
    countyBoxLink: {
        marginLeft: 10,
        [theme.breakpoints.down('lg')]: {
            marginLeft: 0,
        },
    },
}));

function OurCountyBox(props) {
    const classes = useStyles(props);
    const { organization, login, color, registrationModal, userProfile } =
        props;

    const pathname = useLocation().pathname || null;

    const campaignPage =
        pathname.startsWith("/la_county/campaign") ||
        pathname.startsWith("/campaign");
    const preLoginPage =
        pathname === "/" ||
        (pathname.startsWith("/la_county") &&
            !pathname.startsWith("/la_county/campaign")) ||
        pathname.startsWith("/about") ||
        pathname.startsWith("/learn") ||
        pathname.startsWith("/acquire") ||
        pathname.startsWith("/champion") ||
        pathname.startsWith("/browse") ||
        pathname.startsWith("/leaderboard") ||
        pathname.startsWith("/ev101") ||
        pathname.startsWith("/incentives") ||
        pathname.startsWith("/charging");

    const earthIndependenceDayUser =
        userProfile.data != null &&
        userProfile.data.campaigns &&
        (userProfile.data.campaigns[userProfile.data.campaigns.length - 1] ===
            "earth_day" ||
            userProfile.data.campaigns[
                userProfile.data.campaigns.length - 1
            ] === "2021-independence");

    return (
        <div className={classes.root}>
            <div className={classes.root}>
                {preLoginPage && !registrationModal ? (
                    <Link
                        to={
                            login
                                ? "/home"
                                : organization.data !== null
                                ? getOrganizationRouteForPath(
                                      "/",
                                      organization.data.organization
                                  )
                                : "#"
                        }
                        className={classes.link}
                    >
                        <div
                            className={classes.logoImageBox}
                            style={
                                preLoginPage
                                    ? {}
                                    : color === "black"
                                    ? { borderRight: "1px solid black" }
                                    : { borderRight: "1px solid white" }
                            }
                        >
                            <img
                                src={
                                    color === "black"
                                        ? ElectrifyzeLogoBlack
                                        : ElectrifyzeLogoWhite
                                }
                                alt="logo"
                                className={classes.img}
                            />
                        </div>
                    </Link>
                ) : (
                    <div
                        className={classes.logoImageBox}
                        style={
                            preLoginPage
                                ? { borderRight: "1px solid white" }
                                : {}
                        }
                    >
                        <img
                            src={
                                color === "black"
                                    ? ElectrifyzeLogoBlack
                                    : ElectrifyzeLogoWhite
                            }
                            alt="logo"
                            className={classes.img}
                        />
                    </div>
                )}

                {
                    //(!registrationModal && !campaignPage && !earthIndependenceDayUser && !preLoginPage)
                    preLoginPage && (
                        <Link
                            to={
                                !preLoginPage
                                    ? "/info"
                                    : organization.data !== null
                                    ? getOrganizationRouteForPath(
                                          "/about",
                                          organization.data.organization
                                      )
                                    : "#"
                            }
                            className={
                                classes.link + " " + classes.countyBoxLink
                            }
                        >
                            <Button style={{ textTransform: "none" }}>
                                <Typography
                                    variant="h5"
                                    style={
                                        campaignPage
                                            ? {}
                                            : color === "black"
                                            ? { color: "black" }
                                            : { color: "white" }
                                    }
                                >
                                    About
                                </Typography>
                                {/* <div className={classes.countyLogoImageBox}>
                                <img src={OurCountyLogo} alt="logo" className={classes.img} />
                            </div>
                            <Typography variant="h4" className={classes.countyText} style={color === 'black' ? {color: 'black'} : {color: 'white'}}>
                                OurCounty
                            </Typography> */}
                            </Button>
                        </Link>
                    )
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    organization: state.content.organization,
    userProfile: state.user.userProfile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OurCountyBox);
