import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";

//Actions
import { getAllCampaigns } from "../actions/content_actions";

//Styles
import { makeStyles } from "@mui/styles";
import {
    CircularProgress,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100%",
        //overflowY: 'auto'
    },
    main: {
        maxWidth: 960,
        margin: "0 auto",
        paddingTop: 40,
        paddingBottom: 40,
    },
    formControl: {
        minWidth: 200,
        marginTop: 15,
    },
    divider: {
        height: 5,
        marginTop: 30,
        marginBottom: 30,
    },
}));

function UserExport(props) {
    const classes = useStyles();
    const { allCampaigns } = props;
    const [selectedCampaign, setSelectedCampaign] = useState();

    useEffect(() => {
        props.getAllCampaigns();
    }, []);

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedCampaign(value);
    };

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                {allCampaigns.loading ? (
                    <CircularProgress
                        color="primary"
                        style={{ paddingTop: 10 }}
                    />
                ) : allCampaigns.data != null ? (
                    <div>
                        <Typography variant="h6">
                            Select a previous campaign from the list below:
                        </Typography>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                        >
                            <InputLabel>Campaign</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={selectedCampaign}
                                onChange={handleChange}
                                label="Campaign"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {allCampaigns.data.map((campaign, index) => (
                                    <MenuItem value={campaign} key={index}>
                                        {campaign.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Divider className={classes.divider} />
                    </div>
                ) : (
                    <Typography variant="h5">
                        No campaigns to display.
                    </Typography>
                )}

                {selectedCampaign && parse(selectedCampaign.welcomePage)}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        allCampaigns: state.content.allCampaigns,
    };
};

const mapDispatchToProps = {
    getAllCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserExport);
