import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Styles
import "../../../static/styles/App.css";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Button, CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Assets
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";

//Components
import { StyledTaskButton } from "../Styled Components/Buttons";

//Actions
import { updateUserProfile } from "../../../actions/user_actions";
import { getCourseMetrics } from "../../../actions/content_actions";

import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
    buttonLabel: {
        height: "100%",
    },
    twoLines: {},
    rightGridItem: {
        [theme.breakpoints.down('xl')]: {
            display: "none",
        },
    },
    articleLink: {
        marginTop: 20,
        width: "100%",
        textDecoration: "none",
        color: "black",
    },
}));

function Article(props) {
    //Pass theme styles as props
    const classes = useStyles(props);
    const {
        singleTask,
        userProfile,
        tasks,
        courses,
        sections,
        sub_sections,
        organization,
        isAssignment,
    } = props;

    const [articleClicked, setArticleClicked] = useState(false);
    const [articleData, setArticleData] = useState(undefined);

    useEffect(() => {
        // Get the meta tag information for the article link
        fetch(
            "https://api.linkpreview.net/?key=b9ffb2dfed116c21e1017ca65284511c&q=" +
                singleTask.content,
            {
                method: "GET",
            }
        )
            .then((response) => response.json())
            .then((res) => {
                setArticleData(res);
            })
            .catch((error) => {
                console.log("error from api call: ", error);
            });
    }, []);

    return (
        <Grid
            container
            direction="column"
            style={{ height: "100%", textAlign: "center" }}
        >
            <Grid
                item
                xs
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: "100%", width: "100%", overflowY: "auto" }}
            >
                <div style={{ margin: 10, maxWidth: 600 }}>
                    <Typography variant="h4">{singleTask.title}</Typography>
                    <Typography variant="h6" style={{ marginBottom: 10 }}>
                        {singleTask.description}
                    </Typography>
                    <div style={{ textAlign: "left", marginBottom: 20 }}>
                        {singleTask.context && parse(singleTask.context)}
                    </div>
                    <a
                        href={singleTask.content}
                        target="_blank"
                        className={classes.articleLink}
                        rel="noreferrer"
                    >
                        <Button
                            variant="contained"
                            onClick={() => setArticleClicked(true)}
                            style={{
                                padding: 15,
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faNewspaper}
                                color={"black"}
                                size={"3x"}
                            />
                            <Typography
                                variant="h4"
                                style={{ marginLeft: 20, fontWeight: 500 }}
                            >
                                View Article
                            </Typography>
                        </Button>
                    </a>
                </div>
            </Grid>
            <Grid
                item
                container
                alignItems="center"
                style={
                    organization.data && organization.data != null
                        ? {
                              height: 100,
                              backgroundColor:
                                  organization.data.backgroundColor,
                              color: organization.data.fontColor,
                          }
                        : {
                              height: 100,
                              backgroundColor: "black",
                              color: "white",
                          }
                }
            >
                <Grid item xs container alignItems="center">
                    <Grid item>
                        <Typography
                            variant="h6"
                            style={{
                                textTransform: "capitalize",
                                marginLeft: 20,
                                textAlign: "left",
                            }}
                        >
                            {singleTask.title}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs
                    style={{ width: "auto" }}
                    container
                    justifyContent="center"
                >
                    {userProfile.loading ||
                    courses.loading ||
                    sections.loading ||
                    sub_sections.loading ||
                    tasks.loading ? (
                        <CircularProgress />
                    ) : isAssignment ? (
                        <StyledTaskButton
                            disabled={!articleClicked}
                            onClick={() =>
                                props.updateUser(
                                    undefined,
                                    undefined,
                                    undefined,
                                    isAssignment
                                )
                            }
                            style={{ margin: 10 }}
                        >
                            Complete Activity
                        </StyledTaskButton>
                    ) : (
                        <StyledTaskButton
                            disabled={!articleClicked}
                            onClick={props.updateUser}
                            style={{ margin: 10 }}
                        >
                            Next Task
                        </StyledTaskButton>
                    )}
                </Grid>
                <Grid
                    item
                    xs
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.rightGridItem}
                ></Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
        courses: state.content.courses,
        sections: state.content.sections,
        sub_sections: state.content.sub_sections,
        tasks: state.content.tasks,
        organization: state.content.organization,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserProfile: (userProfile, uid) =>
            dispatch(updateUserProfile(userProfile, uid)),
        getCourseMetrics: (userProfile, courseID, userID) =>
            dispatch(getCourseMetrics(userProfile, courseID, userID)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Article)
);
