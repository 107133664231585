export const GET_ORGANIZATION_BEGIN = "GET_ORGANIZATION_BEGIN";
export const GET_ORGANIZATION_ERROR = "GET_ORGANIZATION_ERROR";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";

export const GET_CAMPAIGN_BEGIN = "GET_CAMPAIGN_BEGIN";
export const GET_CAMPAIGN_ERROR = "GET_CAMPAIGN_ERROR";
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";

export const GET_ALL_CAMPAIGNS_BEGIN = "GET_ALL_CAMPAIGNS_BEGIN";
export const GET_ALL_CAMPAIGNS_ERROR = "GET_ALL_CAMPAIGNS_ERROR";
export const GET_ALL_CAMPAIGNS_SUCCESS = "GET_ALL_CAMPAIGNS_SUCCESS";

export const GET_HOME_PAGE_BEGIN = "GET_HOME_PAGE_BEGIN";
export const GET_HOME_PAGE_ERROR = "GET_HOME_PAGE_ERROR";
export const GET_HOME_PAGE_SUCCESS = "GET_HOME_PAGE_SUCCESS";

export const GET_COURSES_BEGIN = "GET_COURSES_BEGIN";
export const GET_COURSES_ERROR = "GET_COURSES_ERROR";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";

export const GET_SECTIONS_BEGIN = "GET_SECTIONS_BEGIN";
export const GET_SECTIONS_ERROR = "GET_SECTIONS_ERROR";
export const GET_SECTIONS_SUCCESS = "GET_SECTIONS_SUCCESS";

export const GET_SUB_SECTIONS_BEGIN = "GET_SUB_SECTIONS_BEGIN";
export const GET_SUB_SECTIONS_ERROR = "GET_SUB_SECTIONS_ERROR";
export const GET_SUB_SECTIONS_SUCCESS = "GET_SUB_SECTIONS_SUCCESS";

export const GET_TASKS_BEGIN = "GET_TASKS_BEGIN";
export const GET_TASKS_ERROR = "GET_TASKS_ERROR";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";

export const GET_ALL_TASKS_BEGIN = "GET_ALL_TASKS_BEGIN";
export const GET_ALL_TASKS_ERROR = "GET_ALL_TASKS_ERROR";
export const GET_ALL_TASKS_SUCCESS = "GET_ALL_TASKS_SUCCESS";

export const GET_SINGLE_TASK_BEGIN = "GET_SINGLE_TASK_BEGIN";
export const GET_SINGLE_TASK_ERROR = "GET_SINGLE_TASK_ERROR";
export const GET_SINGLE_TASK_SUCCESS = "GET_SINGLE_TASK_SUCCESS";

export const GET_QUIZ_BEGIN = "GET_QUIZ_BEGIN";
export const GET_QUIZ_ERROR = "GET_QUIZ_ERROR";
export const GET_QUIZ_SUCCESS = "GET_QUIZ_SUCCESS";

export const GET_LESSON_BEGIN = "GET_LESSON_BEGIN";
export const GET_LESSON_ERROR = "GET_LESSON_ERROR";
export const GET_LESSON_SUCCESS = "GET_LESSON_SUCCESS";

export const GET_ACTIVITY_BEGIN = "GET_ACTIVITY_BEGIN";
export const GET_ACTIVITY_ERROR = "GET_ACTIVITY_ERROR";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";

export const GET_COURSE_METRICS_BEGIN = "GET_COURSE_METRICS_BEGIN";
export const GET_COURSE_METRICS_ERROR = "GET_COURSE_METRICS_ERROR";
export const GET_COURSE_METRICS_SUCCESS = "GET_COURSE_METRICS_SUCCESS";

export const GET_ASSIGNMENTS_BEGIN = "GET_ASSIGNMENTS_BEGIN";
export const GET_ASSIGNMENTS_ERROR = "GET_ASSIGNMENTS_ERROR";
export const GET_ASSIGNMENTS_SUCCESS = "GET_ASSIGNMENTS_SUCCESS";

export const GET_INCENTIVES_BEGIN = "GET_INCENTIVES_BEGIN";
export const GET_INCENTIVES_ERROR = "GET_INCENTIVES_ERROR";
export const GET_INCENTIVES_SUCCESS = "GET_INCENTIVES_SUCCESS";

export const GET_EV101_BEGIN = "GET_EV101_BEGIN";
export const GET_EV101_ERROR = "GET_EV101_ERROR";
export const GET_EV101_SUCCESS = "GET_EV101_SUCCESS";
