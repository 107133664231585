import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import clsx from "clsx";

//Actions
import {
    signInUser,
    getUserProfile,
    updateUserProfile,
    updateUserProfileField,
} from "../../../actions/user_actions";
import { getTasks } from "../../../actions/content_actions";

//Assets
import CheckMark from "../../../static/images/check-solid.svg";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import ListAltIcon from "@mui/icons-material/ListAlt";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import {
    Button,
    MenuItem,
    Typography,
    Menu,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Assets
import {
    faCheck,
    faEllipsisH,
    faTasks,
} from "@fortawesome/free-solid-svg-icons";

// Firebase
import { getFirestore, collection, query, getDocs, where } from "firebase/firestore"

const db = getFirestore()

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
        position: "relative",
    },
    button: {
        display: "block",
        height: "100%",
        width: "100%",
        textTransform: "none",
        padding: 15,
        textAlign: "left",
        border: "4px solid gold",
        boxShadow: "2px 2px 6px 0px rgba(0,0,0,0.5)",
    },
    triangle: {
        position: "absolute",
        top: 0,
        right: 0,
        width: 0,
        height: 0,
        borderTop: "35px solid gold",
        borderLeft: "35px solid transparent",
    },
    greenTriangle: {
        borderTop: "35px solid green",
    },
    iconButton: {
        position: "absolute",
        minWidth: "auto",
        zIndex: 1000,
        top: -5,
        right: -5,
        fontSize: 20,
        padding: 6,
        borderRadius: 20,
        backgroundColor: "white",
        border: "4px solid gold",
        "&:hover": {
            opacity: "1 !important",
            backgroundColor: "white !important",
        },
    },
    greenBorder: {
        border: "4px solid green",
    },
    buttonLabel: {
        height: "100%",
    },
    taskLabelGridContainer: {
        marginTop: -5,
        marginBottom: -3,
        [theme.breakpoints.down('lg')]: {
            paddingRight: "0px !important",
        },
    },
    taskLabelImageGridItem: {
        [theme.breakpoints.down('lg')]: {
            marginRight: 0,
            marginBottom: 5,
        },
    },
    iconBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    icon: {
        fontSize: 10,
        width: 20,
        height: 20,
    },
    iconComplete: {
        fontSize: 10,
        width: 12,
        height: 12,
    },
    goldIconWrapper: {
        width: 20,
        height: 20,
        borderRadius: 10,
        backgroundColor: "rgba(255, 213, 0, 1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    link: {
        textDecoration: "none",
    },
    pointsGridItem: {
        [theme.breakpoints.down('lg')]: {
            display: "flex",
            alignItems: "flex-end",
            paddingTop: "0px !important",
            paddingBottom: "0px !important",
            marginTop: -10,
            marginBottom: 10,
        },
    },
    pointsCountText: {
        display: "inline",
        fontWeight: 500,
    },
    pointsLabelText: {
        [theme.breakpoints.down('lg')]: {
            marginLeft: 10,
        },
    },
}));

function ActivityItem(props) {
    const classes = useStyles();
    const { task, uid, profile, adminView } = props;

    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const isCompletedTask =
        profile != null &&
        profile.completed_tasks &&
        profile.completed_tasks[task.id];
    const isArchivedTask =
        profile != null &&
        profile.archived_tasks &&
        profile.archived_tasks.includes(task.id);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getImageForType = (type) => {
        switch (type) {
            case "lesson":
                return (
                    <div className={classes.iconBox}>
                        <NoteAddIcon className={classes.icon} />
                    </div>
                );

            case "video":
                return (
                    <div className={classes.iconBox}>
                        <OndemandVideoIcon className={classes.icon} />
                    </div>
                );

            case "article":
                return (
                    <div className={classes.iconBox}>
                        <AssignmentIcon className={classes.icon} />
                    </div>
                );

            case "quiz":
                return (
                    <div className={classes.iconBox}>
                        <CheckBoxIcon className={classes.icon} />
                    </div>
                );

            case "survey":
                return (
                    <div className={classes.iconBox}>
                        <HelpOutlineIcon className={classes.icon} />
                    </div>
                );

            case "action":
                return (
                    <div className={classes.iconBox}>
                        <DirectionsWalkIcon className={classes.icon} />
                    </div>
                );

            case "project":
                return (
                    <div className={classes.iconBox}>
                        <ListAltIcon className={classes.icon} />
                    </div>
                );

            case "activity":
                return (
                    <div className={classes.iconBox}>
                        <FontAwesomeIcon
                            icon={faTasks}
                            className={classes.icon}
                        />
                    </div>
                );

            case "complete":
            default:
                return (
                    <div className={classes.iconBox}>
                        <div className={classes.goldIconWrapper}>
                            <img
                                src={CheckMark}
                                className={classes.iconComplete}
                                alt="checkmark"
                            />
                        </div>
                    </div>
                );
        }
    };

    const updateTaskData = async (task) => {
        let taskData = task;
        // This should be a redux action but there was most likely a time constraint
        try {
            taskData["subSectionID"] = taskData["sub-section"].id;

            const q1 = query(
                collection(db, "fl_content"),
                where("_fl_meta_.env", "==", "production"),
                where("_fl_meta_.locale", "==", "en-US"),
                where("_fl_meta_.schema", "==", "sub-sections"),
                where("id", "==", taskData["sub-section"].id)
            );
            let subSection = await getDocs(q1);

            taskData["sectionID"] = subSection.docs[0].data().section.id;

            const q2 = query(
                collection(db, "fl_content"),
                where("_fl_meta_.env", "==", "production"),
                where("_fl_meta_.locale", "==", "en-US"),
                where("_fl_meta_.schema", "==", "sections"),
                where("id", "==", taskData["sectionID"])
            );
            let section = await getDocs(q2);

            taskData["courseID"] = section.docs[0].data().course.id;
        } catch (error) {
            console.log("Error fetching task data.");
        }

        return taskData
    }

    const handleCompleteClick = async () => {
        let completed_tasks = profile.completed_tasks || [];
        setLoading(true);

        const taskData = await updateTaskData(task)

        const data = {
            points: taskData.points,
            section_id: taskData.sectionID,
            sub_section_id: taskData.subSectionID,
            course_id: taskData.courseID,
            type: taskData.type,
            time: Date.now(),
        };

        completed_tasks[task.id] = data;

        let newUserProfile = profile;
        newUserProfile.completed_tasks = completed_tasks;

        props.updateUserProfile(newUserProfile, uid);
        setLoading(false);
        handleClose();
    };

    const handleArchiveClick = () => {
        let archived_tasks = profile.archived_tasks || [];

        if (isArchivedTask) {
            archived_tasks = archived_tasks.filter((item) => item !== task.id);
        } else {
            archived_tasks.push(task.id);
        }

        const data = {
            archived_tasks,
        };

        props.updateUserProfileField(uid, data);
        props.setProfile({
            ...profile,
            archived_tasks,
        });
    };

    const handleRemoveClick = () => {
        let assignments = profile.assignments || [];
        assignments.splice(assignments.indexOf(task.id), 1);

        const data = {
            assignments: assignments,
        };

        props.updateUserProfileField(uid, data);
        props.setProfile({
            ...profile,
            assignments,
        });
    };

    const handleMarkCompleteClick = async () => {
        let completed_tasks = profile.completed_tasks || [];
        let archived_tasks = profile.archived_tasks || [];

        const taskData = await updateTaskData(task)

        const completedTaskdata = {
            points: taskData.points,
            section_id: taskData.sectionID,
            sub_section_id: taskData.subSectionID,
            course_id: taskData.courseID,
            type: taskData.type,
            time: Date.now(),
        };

        completed_tasks[task.id] = completedTaskdata;

        if (archived_tasks.includes(task.id)) {
            archived_tasks = archived_tasks.filter((item) => item !== task.id);
        }

        const data = {
            completed_tasks,
            archived_tasks,
        };

        props.setProfile({
            ...profile,
            completed_tasks,
            archived_tasks,
        });
        props.updateUserProfileField(uid, data);
    };

    const handleMarkIncompleteClick = () => {
        let completed_tasks = profile.completed_tasks || [];

        if (completed_tasks[task.id]) {
            delete completed_tasks[task.id];
        }

        const data = {
            completed_tasks: completed_tasks,
        };

        props.setProfile({
            ...profile,
            completed_tasks,
        });
        props.updateUserProfileField(uid, data);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Grid item xs={6} className={classes.root}>
            <Button
                className={clsx(classes.iconButton, {
                    [classes.greenBorder]: isCompletedTask,
                })}
                onClick={handleClick}
            >
                <FontAwesomeIcon
                    icon={isCompletedTask ? faCheck : faEllipsisH}
                    color={isCompletedTask ? "green" : "black"}
                />
            </Button>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {/* loading || userProfile.loading ?
                    <div style={{width: '100%'}}>
                        <CircularProgress style={{display: 'block', margin: '0 auto'}}/>
                    </div>

                : profile != null && profile.completed_tasks && !profile.completed_tasks[task.id] && (
                    <MenuItem onClick={handleCompleteClick}>
                        Mark Complete
                    </MenuItem>
                ) */}

                {isArchivedTask || !isCompletedTask ? (
                    <MenuItem onClick={handleArchiveClick}>
                        {isArchivedTask ? "Unarchive" : "Archive"}
                    </MenuItem>
                ) : !adminView ? (
                    <MenuItem disabled onClick={handleClose}>
                        No Option Available
                    </MenuItem>
                ) : null}

                {adminView && (
                    <MenuItem disabled>
                        <Typography variant="caption">
                            Admin Functions
                        </Typography>
                    </MenuItem>
                )}
                {adminView && (
                    <MenuItem onClick={handleRemoveClick}>Remove</MenuItem>
                )}
                {adminView && isCompletedTask ? (
                    <MenuItem onClick={handleMarkIncompleteClick}>
                        Mark Incomplete
                    </MenuItem>
                ) : (
                    adminView && (
                        <MenuItem onClick={handleMarkCompleteClick}>
                            Mark Complete
                        </MenuItem>
                    )
                )}
            </Menu>

            <Link
                to={{
                    pathname: `/task/${task.id}`,
                    state: {
                        isAssignment: true,
                    },
                }}
                className={classes.link}
                // No need to navigate if coming from admin view
                //style={profile ? {pointerEvents: 'none'} : {}}
            >
                <Button
                    className={clsx(classes.button, {
                        [classes.greenBorder]:
                            profile != null &&
                            profile.completed_tasks &&
                            profile.completed_tasks[task.id],
                    })}
                    classes={{ label: classes.buttonLabel }}
                >
                    <div
                        className={clsx(classes.triangle, {
                            [classes.greenTriangle]:
                                profile != null &&
                                profile.completed_tasks &&
                                profile.completed_tasks[task.id],
                        })}
                    />
                    <Typography variant="h6" style={{ fontWeight: 600 }}>
                        {task.title}
                    </Typography>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        className={classes.taskLabelGridContainer}
                    >
                        <Grid item className={classes.taskLabelImageGridItem}>
                            {getImageForType(
                                task.typeLabel ? task.typeLabel : task.type
                            )}
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                style={{ textTransform: "uppercase" }}
                            >
                                {task.typeLabel ? task.typeLabel : task.type}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            {task.time && (
                                <Typography variant="caption">
                                    {task.time} {task.time > 1 ? "mins" : "min"}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Typography variant="body1">
                        This is the description of the task.
                        <br />
                        {task.previewDescription}
                    </Typography>
                </Button>
            </Link>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    userProfile: state.user.userProfile,
    tasks: state.content.tasks,
});

const mapDispatchToProps = {
    signInUser,
    getUserProfile,
    getTasks,
    updateUserProfile,
    updateUserProfileField,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ActivityItem)
);
