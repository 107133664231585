import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Actions
import { getCourseMetrics } from "../../../actions/content_actions";

//Assets
import {
    faAward,
    faGift,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Button, Typography, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import CourseMetricsPopper from "./CourseMetricsPopper";

const useStyles = makeStyles((theme) => ({
    paper: {
        minHeight: 170,
        padding: 15,
        paddingBottom: 5,
        border: "solid",
        borderWidth: 1,
        borderTopWidth: 8,
    },
    courseBox: {
        width: "100%",
        //margin: 10,
        maxWidth: 700,
    },
    pointsImg: {
        height: 80,
        width: 80,
        objectFit: "contain",
        color: "white",
    },
    link: {
        textDecoration: "none",
        color: "black",
    },
    typography: {
        padding: theme.spacing(2),
    },
}));

function CourseItem(props) {
    const classes = useStyles();
    const { course, userProfile, courseMetrics, index } = props;

    const [anchorEl, setAnchorEl] = React.useState();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickAway = (event) => {
        setAnchorEl();
    };

    const open = Boolean(anchorEl);

    React.useEffect(() => {
        if (courseMetrics.data === null || !courseMetrics.data[course.id]) {
            props.getCourseMetrics(
                userProfile.data,
                course.id,
                props.user.data.uid
            );
        }
    }, []);

    const getBorderColor = () => {
        const colors = [
            "#4472c4",
            "green",
            "purple",
            "orange",
            "red",
            "gold",
            "blue",
        ];
        if (index && colors[index]) return colors[index];
        else return colors[0];
    };
    return (
        <Grid item lg={6} xs={12} className={classes.courseBox}>
            <Link
                to={{
                    pathname: `/sections/${course.id}`,
                    /* state: {
                        id: course.id,
                        title: course.title
                    } */
                }}
                className={classes.link}
            >
                <Paper
                    elevation={3}
                    className={classes.paper}
                    style={{ borderColor: getBorderColor() }}
                >
                    <Grid container>
                        <Grid item xs>
                            <Typography
                                variant="h5"
                                style={{ fontWeight: 400 }}
                            >
                                {course.title}
                            </Typography>
                        </Grid>
                        <Grid item style={{ width: "auto" }}>
                            <Typography variant="caption">
                                <i>
                                    {courseMetrics.data !== null &&
                                        courseMetrics.data[course.id] &&
                                        (courseMetrics.data[course.id]
                                            .timeCounter < 60
                                            ? courseMetrics.data[course.id]
                                                  .timeCounter + " mins"
                                            : Math.floor(
                                                  courseMetrics.data[course.id]
                                                      .timeCounter / 60
                                              ) === 1
                                            ? Math.floor(
                                                  courseMetrics.data[course.id]
                                                      .timeCounter / 60
                                              ) +
                                              " hr and " +
                                              (courseMetrics.data[course.id]
                                                  .timeCounter %
                                                  60) +
                                              " mins"
                                            : Math.floor(
                                                  courseMetrics.data[course.id]
                                                      .timeCounter / 60
                                              ) +
                                              " hrs and " +
                                              (courseMetrics.data[course.id]
                                                  .timeCounter %
                                                  60) +
                                              " mins")}
                                </i>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography variant="body1" style={{ height: 48 }}>
                        {course.description}
                    </Typography>
                    {courseMetrics.loading ? (
                        <Grid
                            container
                            style={{ width: "100%", height: 80 }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                        </Grid>
                    ) : courseMetrics.data !== null &&
                      courseMetrics.data[course.id] ? (
                        <Grid
                            container
                            style={{ marginTop: 10, alignItems: "center" }}
                        >
                            <Grid item xs>
                                <div style={{ height: 40 }}>
                                    <div
                                        style={{ position: "relative", top: 0 }}
                                    >
                                        <CircularProgress
                                            variant="determinate"
                                            value={100}
                                            style={{ color: "lightgray" }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            position: "relative",
                                            zIndex: 1,
                                            top: -44,
                                        }}
                                    >
                                        <CircularProgress
                                            variant="determinate"
                                            value={
                                                courseMetrics.data[course.id]
                                                    .completedTasksPercentage
                                            }
                                            style={{
                                                color: "rgba(255, 213, 0, 1)",
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            position: "relative",
                                            top: -88,
                                            height: 40,
                                            width: 40,
                                            zIndex: 2,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            style={{ fontWeight: 700 }}
                                        >
                                            {
                                                courseMetrics.data[course.id]
                                                    .completedTasksPercentage
                                            }
                                            %
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item style={{ width: 40 }}>
                                <FontAwesomeIcon
                                    icon={faGift}
                                    color={
                                        courseMetrics.data[course.id]
                                            .completedPointsCounter >=
                                        courseMetrics.data[course.id]
                                            .totalPointsCounter
                                            ? "#2ab151"
                                            : "#c0c0c0"
                                    }
                                    size="2x"
                                />
                            </Grid>
                            <Grid item style={{ width: "auto" }}>
                                <Button
                                    id="pointsButton"
                                    onMouseOver={handleClick}
                                    onMouseLeave={handleClickAway}
                                    style={{
                                        textTransform: "none",
                                        display: "block",
                                        textAlign: "left",
                                        lineHeight: 1.2,
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        style={{
                                            fontWeight: 700,
                                            lineHeight: 1,
                                        }}
                                    >
                                        {Math.round(
                                            courseMetrics.data[course.id]
                                                .completedPointsCounter
                                        )}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        style={{ lineHeight: 1 }}
                                    >
                                        of{" "}
                                        {
                                            courseMetrics.data[course.id]
                                                .totalPointsCounter
                                        }
                                        <br />
                                        earned
                                    </Typography>
                                    <br />
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        color="#c0c0c0"
                                        style={{
                                            marginTop: 5,
                                            height: 15,
                                            width: 15,
                                        }}
                                    />
                                </Button>
                            </Grid>

                            <Grid item style={{ width: 30 }}>
                                <FontAwesomeIcon
                                    icon={faAward}
                                    color={
                                        courseMetrics.data[course.id]
                                            .bonusMultiplier > 1
                                            ? "#2ab151"
                                            : "#c0c0c0"
                                    }
                                    size="2x"
                                />
                            </Grid>
                            <Grid item style={{ width: "auto" }}>
                                <Button
                                    id="bonusButton"
                                    onMouseOver={handleClick}
                                    onMouseLeave={handleClickAway}
                                    style={{
                                        textTransform: "none",
                                        display: "block",
                                        textAlign: "left",
                                        minWidth: "inherit",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        style={{ fontWeight: 700 }}
                                    >
                                        +
                                        {Math.round(
                                            (courseMetrics.data[course.id]
                                                .bonusMultiplier -
                                                1) *
                                                100
                                        )}
                                        %
                                    </Typography>
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        color="#c0c0c0"
                                        style={{ height: 15, width: 15 }}
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    ) : null}
                </Paper>
            </Link>
            <CourseMetricsPopper anchorEl={anchorEl} open={open} />
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        courses: state.content.courses,
        sections: state.content.tasks,
        tasks: state.content.tasks,
        courseMetrics: state.content.courseMetrics,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCourseMetrics: (userProfile, courseID, userID) =>
            dispatch(getCourseMetrics(userProfile, courseID, userID)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseItem);
