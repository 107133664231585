import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions
import { getHomePage, getOrganization } from "../actions/content_actions";

//Styles
import "../static/styles/App.css";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Components
import ISDRideshareHeader from "../components/la_county/ISDRideshareHeader";
import TextDivider from "../components/default/Styled Components/TextDivider";
import { GrayTextBox } from "../components/default/Styled Components/GrayTextBox";
import { StyledTaskButton } from "../components/default/Styled Components/Buttons";
import NativeFormsDialog from "../components/default/Contact/NativeFormsDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100%",
        //overflowY: 'auto'
    },
    content: {
        maxWidth: 960,
        margin: "0 auto",
        marginTop: 30,
        marginBottom: 40,
        textAlign: "center",
        [theme.breakpoints.down('xl')]: {
            marginLeft: 10,
            marginRight: 10,
        },
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            flexDirection: "column",
        },
    },
    contactGridItem: {
        marginLeft: 20,
        backgroundColor: "white",
        border: "1px solid black",
        [theme.breakpoints.down('lg')]: {
            marginLeft: 0,
            marginTop: 15,
        },
    },
}));

function Contact(props) {
    const classes = useStyles();

    const { userProfile } = props;
    const [open, toggleDialog] = useState(false);

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <ISDRideshareHeader
                    title="Contact Us"
                    subheading={"Got any questions or feedback? Get in touch."}
                />

                <TextDivider text="Share Your Thoughts" />

                <GrayTextBox variant="h6">
                    We are working hard to charge ahead towards creating a
                    fossil fuel free LA – This is a big goal! Along the way,
                    we’re excited to help everyone learn about and get the best
                    deal on an electric car. Have thoughts, suggestions,
                    feedback, praise, or complaints you’d like to share? Your
                    feedback helps us improve the program, and we’d love to hear
                    from you!
                    <div
                        style={{
                            marginTop: 10,
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <StyledTaskButton onClick={() => toggleDialog(true)}>
                            Share Your Thoughts
                        </StyledTaskButton>
                    </div>
                </GrayTextBox>

                <TextDivider text="Electrifyze.LA Program" />

                <GrayTextBox variant="h6">
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs>
                            Do you have any suggestions for improvement or
                            questions about Electrifyze? We’d love to hear from
                            you, please get in touch.
                        </Grid>
                        <Grid
                            item
                            xs
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={classes.contactGridItem}
                        >
                            Name: Sam Saxena
                            <br />
                            Email: hello@electrifyze.la
                        </Grid>
                    </Grid>
                </GrayTextBox>

                <TextDivider text="LA County Internal Services Department" />

                <GrayTextBox variant="h6">
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs>
                            Do you have any questions about why LA County is
                            promoting electric cars to employees? Contact the
                            Internal Services Department to learn more.
                        </Grid>
                        <Grid
                            item
                            xs
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={classes.contactGridItem}
                        >
                            Name: Eddie Washington
                            <br />
                            Email: EWashington@isd.lacounty.gov
                        </Grid>
                    </Grid>
                </GrayTextBox>
            </div>

            <NativeFormsDialog
                open={open}
                toggleDialog={() => toggleDialog(false)}
                formURL="https://surveys.electrifyze.com/IVkdl1jZmgnZzkEcL1Db"
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganization: (organization) =>
            dispatch(getOrganization(organization)),
        getHomePage: (organization) => dispatch(getHomePage(organization)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Contact)
);
