import React from "react";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";

//Styles
import { ButtonBase, Divider, Drawer } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

//Utils
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faMedal,
    faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

//Actions
import { logout } from "../../../actions/user_actions";
import TaskWarningDialog from "../Header/TaskWarningDialog";

//Assets
import ElectrifyzeLogoBlack from "../../../static/images/Electrifyze-Logo-black.svg";

const closedBarWidth = 70;
const openBarWidth = 380;

const useStyles = (height) =>
    makeStyles((theme) => ({
        titleGridOpen: {
            width: openBarWidth,
            height: height < 575 ? 60 : height < 700 ? 70 : 80,
            overflow: "hidden",
        },
        titleGridClosed: {
            height: height < 575 ? 60 : height < 700 ? 70 : 80,
        },
        menuTopButton: {
            width: "100%",
            minWidth: 0,
            display: "block",
            padding: 6,
        },
        menuButtonLabel: {
            lineHeight: "normal",
        },
        goButton: {
            marginTop: 20,
            display: "block",
            width: 80,
            padding: 6,
            backgroundColor: "white",
            /* '&:hover': {
            backgroundColor: 'green'
        } */
        },
        resetButton: {
            marginTop: 20,
            display: "block",
            padding: 6,
            backgroundColor: "white",
        },
        icon: {
            marginTop: 5,
        },
        drawer: {
            width: openBarWidth,
            flexShrink: 0,
            display: "flex",
        },
        drawerPaper: {
            width: openBarWidth,
            backgroundColor: "#dcdcdc55",
        },
        /* drawer: {
        width: openBarWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        borderRight: "2px solid black"
    },
    drawerOpen: {
        width: openBarWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        overflow: 'hidden'
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: closedBarWidth,
        overflow: 'hidden',
    }, */
        menuGridItem: {
            height: height < 575 ? 30 : height < 700 ? 40 : 55,
            minWidth: 400,
            overflow: "hidden",
            textAlign: "left",
        },
        faIcon: {
            fontSize: height < 575 ? 18 : height < 700 ? 22 : 26,
        },
        menuItem: {
            marginTop: 5,
            marginBottom: 5,
        },
        closeIconGridItem: {
            width: "auto",
            marginRight: 10,
        },
        iconGridItem: {
            width: 70,
        },
        menuItemSelected: {
            backgroundColor: "gold",
            marginTop: 5,
            marginBottom: 5,
        },
        link: {
            textDecoration: "none",
            color: "black",
            whiteSpace: "normal !important",
        },
        divider: {
            backgroundColor: "black",
        },
    }));

function DrawerMenu(props) {
    const { open, toggleDrawer } = props;

    const [height, setHeight] = React.useState(window.innerHeight);
    const classes = useStyles(height)(props);
    const pathname = useLocation().pathname || null;

    const [openWarning, setOpenWarning] = React.useState(false);
    const [path, setPath] = React.useState("");

    React.useEffect(() => {
        window.addEventListener("resize", updateHeight);
        return () => window.removeEventListener("resize", updateHeight);
    });

    const updateHeight = () => {
        setHeight(window.innerHeight);
    };

    const handleWarningClickOpen = (itemPath) => {
        setPath(itemPath);
        setOpenWarning(true);
    };

    const handleWarningClose = () => {
        setOpenWarning(false);
    };

    const isEVLearningCourse =
        pathname.startsWith("/sections/MHgsJRbT7SFxkIMSqvcy") ||
        pathname.startsWith("/sections/yVEsa4RikC8AdBuMfqM5") ||
        pathname.startsWith("/sections/6d8x5222dO5jh8Z9D7y1") ||
        pathname.startsWith("/sections/tApOKIrqfElJIg8cKCs3") ||
        pathname.startsWith("/sections/LyRZ5GXZ19zZ37YHHwtS");

    return (
        <Drawer
            anchor="left"
            open={open}
            variant="persistent"
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <Grid
                container
                direction="column"
                style={
                    open
                        ? { height: "100%", marginTop: 20 }
                        : { height: "100%" }
                }
            >
                <Grid container direction="column">
                    <div style={open ? { height: 71 } : { height: 90 }} />

                    <Grid
                        container
                        alignItems="center"
                        className={
                            open
                                ? classes.titleGridOpen
                                : classes.titleGridClosed
                        }
                    >
                        {open && (
                            <Grid item xs>
                                <div
                                    style={{
                                        height: 70,
                                        width: 250,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginLeft: 20,
                                    }}
                                >
                                    <img
                                        src={ElectrifyzeLogoBlack}
                                        alt="logo"
                                        style={{
                                            height: "100%",
                                            maxHeight: "100%",
                                            maxWidth: "100%",
                                        }}
                                    />
                                </div>
                            </Grid>
                        )}
                        <Grid
                            item
                            className={
                                open
                                    ? classes.closeIconGridItem
                                    : classes.iconGridItem
                            }
                        >
                            <Button
                                classes={{
                                    root: classes.menuTopButton,
                                    label: classes.menuButtonLabel,
                                }}
                                //style={{minWidth: 80}}
                                onClick={toggleDrawer}
                            >
                                {props.open ? (
                                    <FontAwesomeIcon
                                        icon={["far", "times-circle"]}
                                        className={classes.faIcon}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faBars}
                                        className={classes.faIcon}
                                    />
                                )}
                            </Button>
                        </Grid>
                    </Grid>

                    <ButtonBase
                        className={
                            pathname.startsWith("/courses") ||
                            (pathname.startsWith("/sections") &&
                                !isEVLearningCourse)
                                ? classes.menuItemSelected
                                : classes.menuItem
                        }
                        onClick={() =>
                            pathname.startsWith("/task")
                                ? handleWarningClickOpen("/courses")
                                : props.history.push("/courses")
                        }
                    >
                        <Grid
                            container
                            alignItems="center"
                            className={classes.menuGridItem}
                        >
                            <Grid
                                item
                                className={classes.iconGridItem}
                                container
                                justifyContent="center"
                            >
                                <FontAwesomeIcon
                                    icon={faMedal}
                                    className={classes.faIcon}
                                />
                            </Grid>
                            <Grid item xs style={{ marginLeft: 20 }}>
                                <Typography variant="h5">
                                    Campaign - Earn Points
                                </Typography>
                            </Grid>
                        </Grid>
                    </ButtonBase>

                    <Divider className={classes.divider} />
                </Grid>

                <Grid item xs container alignItems="flex-end">
                    <ButtonBase
                        className={classes.menuItem}
                        onClick={props.logout}
                    >
                        <Grid
                            container
                            alignItems="center"
                            className={classes.menuGridItem}
                        >
                            <Grid
                                item
                                className={classes.iconGridItem}
                                container
                                justifyContent="center"
                            >
                                <FontAwesomeIcon
                                    icon={faSignOutAlt}
                                    className={classes.faIcon}
                                />
                            </Grid>
                            <Grid item xs style={{ marginLeft: 20 }}>
                                <Typography variant="h5">Logout</Typography>
                            </Grid>
                        </Grid>
                    </ButtonBase>
                </Grid>
            </Grid>

            <TaskWarningDialog
                open={openWarning}
                toggleDialog={handleWarningClose}
                path={path}
            />
        </Drawer>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    logout,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DrawerMenu)
);
