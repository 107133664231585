import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";

//Styles
import "../../../static/styles/App.css";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Assets
import CoachGold from "../../../static/images/Coach-Gold.png";
import { faCaretLeft, faCaretUp } from "@fortawesome/free-solid-svg-icons";

//Components
import { GrayTextBox } from "../Styled Components/GrayTextBox";

const useStyles = makeStyles((theme) => ({
    grid: {
        padding: 25,
        [theme.breakpoints.down('xl')]: {
            padding: 15,
        },
        [theme.breakpoints.down('lg')]: {
            flexDirection: "column",
            padding: 5,
        },
    },
    noSidePadding: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    textGridContainer: {
        [theme.breakpoints.down('lg')]: {
            flexDirection: "column",
        },
    },
    coachImage: {
        maxHeight: 175,
        objectFit: "contain",
        [theme.breakpoints.down('lg')]: {
            maxHeight: 120,
        },
    },
    coachText: {
        borderRadius: 15,
    },
    leftArrow: {
        marginTop: 35,
        marginRight: -5,
        color: "gainsboro",
        [theme.breakpoints.down('lg')]: {
            display: "none",
        },
    },
    upArrow: {
        color: "gainsboro",
        marginTop: -40,
        marginBottom: -35,
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
}));

function EVCoachMessage(props) {
    //Pass the color from the parent component to the styles
    const classes = useStyles();
    const theme = useTheme();
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('lg'));

    const { message, coachImage, noSidePadding } = props;

    return (
        <Grid
            container
            spacing={isExtraSmall ? 1 : 2}
            className={clsx(classes.grid, {
                [classes.noSidePadding]: noSidePadding,
            })}
        >
            <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                style={{ width: "auto", height: "100% !important" }}
            >
                <img
                    src={coachImage || CoachGold}
                    className={classes.coachImage}
                    alt="gold-coach"
                />
            </Grid>
            <Grid
                item
                xs
                container
                alignItems="center"
                className={classes.textGridContainer}
            >
                <Grid item>
                    <FontAwesomeIcon
                        icon={faCaretLeft}
                        size="6x"
                        className={classes.leftArrow}
                    />
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        size="6x"
                        className={classes.upArrow}
                    />
                </Grid>
                <Grid item xs>
                    <GrayTextBox variant="h6" className={classes.coachText}>
                        {message}
                    </GrayTextBox>
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EVCoachMessage);
