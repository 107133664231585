import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

//Actions
import { signInUser, getUserProfile } from "../../../actions/user_actions";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import {
    TextField,
    Button,
    CircularProgress,
    Typography,
    Paper,
} from "@mui/material";

// Components
import ForgotPasswordModal from "../Login/ForgotPasswordModal";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 30,
        [theme.breakpoints.down('lg')]: {
            padding: 20,
        },
    },
    form: {
        flex: "0 0 auto",
        margin: 20,
        borderRadius: 10,
        backgroundColor: "white",
        [theme.breakpoints.down('lg')]: {
            margin: 10,
        },
    },
    textField: {
        width: 300,
    },
}));

function LoginForm(props) {
    const classes = useStyles();

    const { forward } = props;

    const [email, setEmail] = React.useState();
    const [password, setPassword] = React.useState();
    const [visible, toggleModal] = React.useState(false);

    //props.signInUser({email: email, password: password})
    const handleSubmit = () => {
        props.signInUser({
            email: email,
            password: password,
        });
    };

    return (
        <div className={classes.form}>
            {props.user.data != null &&
            props.userProfile.data != null &&
            forward &&
            forward != null ? (
                <Redirect to={forward} />
            ) : props.user.data != null && props.userProfile.data != null ? (
                <Redirect to="/home" />
            ) : null}
            {props.user.loading || props.userProfile.loading ? (
                <CircularProgress color="primary" />
            ) : (
                <div>
                    <Paper elevation={3} className={classes.paper}>
                        <Typography variant="h6">Login</Typography>
                        <br />
                        <TextField
                            onChange={(event) => setEmail(event.target.value)}
                            name="email"
                            className={classes.textField}
                            variant="outlined"
                            label="Email"
                            autoFocus={true}
                        />
                        <br />
                        <br />
                        <TextField
                            onChange={(event) =>
                                setPassword(event.target.value)
                            }
                            onKeyDown={(e) =>
                                e.key === "Enter" && handleSubmit()
                            }
                            name="password"
                            type="password"
                            className={classes.textField}
                            variant="outlined"
                            label="Password"
                        />
                        <br />
                        <br />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                        <br />
                        <br />
                        <Button
                            variant="outlined"
                            onClick={() => toggleModal(true)}
                        >
                            Forgot password?
                        </Button>
                    </Paper>
                </div>
            )}

            {visible && (
                <ForgotPasswordModal
                    emailAddress={email}
                    visible={visible}
                    handleClose={() => toggleModal(false)}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signInUser: (params) => dispatch(signInUser(params)),
        getUserProfile: (id) => dispatch(getUserProfile(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
