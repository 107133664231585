import React, { useState } from "react";
import { connect } from "react-redux";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";

//Actions
import { getQuiz } from "../../../actions/content_actions";
import Slides from "./Slides";

// Quiz specific elements
import QuizAppContainer from "./QuizElements/QuizAppContainer";
import Scorecard from "./QuizElements/Scorecard";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: "1 1 auto",
        height: "100%",
    },
    quizContainer: {
        height: "100%",
        width: "100%",
        backgroundColor: "#10162F",
        color: "white",
        textAlign: "center",
    },
    quizContentContainer: {
        width: "100%",
        height: "100%",
        margin: "0 auto",
        alignContent: "center",
        overflow: "auto",
    },
    scrollableQuizContainer: {
        height: "100%",
        maxWidth: 960,
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('xl')]: {
            marginLeft: 10,
            marginRight: 10,
        },
    },
    quizHeader: {
        width: "60%",
        margin: "0 auto",
        marginTop: 30,
        marginBottom: 10,
    },
    answerContainer: {
        listStyle: "none",
        textAlign: "justify",
        width: "100%",
        padding: "0",
        margin: "auto",
    },
    answerButton: {
        width: "100%",
        textAlign: "left",
        marginBottom: "10px",
        "&:hover": {
            opacity: 0.7,
        },
    },
    standardAnswer: {
        backgroundColor: "#141C3A",
        border: "1px solid #1E387A",
        height: "100%",
        width: "100%",
        minHeight: "40px",
        padding: "20px",
        [theme.breakpoints.down('xl')]: {
            padding: 10,
        },
    },
    wrongAnswer: {
        backgroundColor: "#661917",
        border: "1px solid #E63833",
        width: "100%",
        minHeight: "40px",
        padding: "20px",
        [theme.breakpoints.down('xl')]: {
            padding: 10,
        },
    },
    rightAnswerPicked: {
        backgroundColor: "#164032",
        border: "1px solid #35bd3f",
        width: "100%",
        minHeight: "40px",
        padding: "20px",
    },
    rightAnswerNotPicked: {
        backgroundColor: "#164032",
        border: "1px solid #35bd3f",
        width: "100%",
        minHeight: "40px",
        padding: "20px",
        [theme.breakpoints.down('xl')]: {
            padding: 10,
        },
    },
    quizExplanatoryDiv: {
        width: "100%",
        height: "100px",
        position: "relative",
        zIndex: 100,
        backgroundColor: "#141C3A",
        outline: "1px solid #1E387A",
    },
    explanatoryContainer: {
        width: ".45",
        height: "100px",
        margin: "0 auto",
        position: "relative",
    },
    explanatoryBadgeContainer: {
        display: "inline-block",
        position: "absolute",
        left: "0",
        top: "10px",
        justifyContent: "center",
        width: "15%",
        height: "10px",
        margin: "0 auto",
    },
    explanatoryTextContainer: {
        textAlign: "justify",
        display: "inline-block",
        justifyContent: "center",
        height: "80px",
        marginTop: "10px",
    },
    scorecardContainer: {
        height: "100%",
        width: "100%",
        backgroundColor: "#10162F",
        color: "white",
        textAlign: "center",
        position: "relative",
        overflow: "auto",
        boxSizing: "border-box",
    },
    scorecardHeader: {
        margin: "0 auto",
        marginBottom: "25px",
        marginTop: "25px",
    },
    scorecardContent: {
        maxWidth: 600,
        margin: "0 auto",
        padding: "20px",
    },
    scorecardPercentageHolder: {
        width: "100%",
        display: "block",
        backgroundColor: "#141C3A",
        border: "1px solid gray",
        borderRadius: "20px",
        height: "120px",
    },
    scorecardSecondRow: {
        marginTop: "10px",
    },
    scorecardAbsoluteValues: {
        display: "inline-block",
        position: "relative",
        width: "46%",
    },
    scorecardAbsolutesSub: {
        backgroundColor: "#141C3A",
        position: "relative",
        borderRadius: "5px",
        textAlign: "center",
        margin: "2px",
        border: "1px solid gray",
        width: "100%",
        display: "block",
        height: "50px",
        verticalAlign: "middle",
        lineHeight: "50px",
    },
    scorecardAbsolutesValue: {
        display: "inline-block",
        textAlign: "center",
    },
    scorecardAbsolutesBadge: {
        display: "inline-block",
        position: "absolute",
        left: 0,
    },
    pointsEarnedContainer: {
        width: "46%",
        display: "inline-block",
        border: "1px solid gray",
        borderRadius: "20px",
        backgroundColor: "#141C3A",
        marginLeft: "15px",
        height: "115px",
        verticalAlign: "top",
    },
    upNextButton: {
        width: "100%",
        backgroundColor: "#FFD500",
        color: "black",
        borderRadius: "20px",
        height: "40px",
        fontWeight: "bold",
        fontSize: "110%",
        marginTop: "10px",
    },
    retakeQuizButton: {
        width: "100%",
        height: "40px",
        fontWeight: "bold",
        backgroundColor: "transparent",
        border: "1px solid white",
        color: "white",
        borderRadius: "20px",
        fontSize: "110%",
        marginTop: "10px",
    },
    Subject: {
        color: "#37D1B0",
    },
}));

function questionShuffle(questionList) {
    let counter = questionList.length;
    while (counter > 0) {
        let index = Math.floor(Math.random() * counter);
        counter--;
        let temp = questionList[counter];
        questionList[counter] = questionList[index];
        questionList[index] = temp;
    }

    return questionList;
}

function Quiz(props) {
    //Pass theme styles as props
    const classes = useStyles(props);
    const [questionsAnswered, setQuestionsAnswered] = React.useState([]);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [nextQuestionClick, setNextQuestionClick] = useState(false);
    const [listAlreadyShuffled, setListAlreadyShuffled] = useState(false);

    const { taskID, isAssignment } = props;

    //console.log("questions Answered", questionsAnswered)

    React.useEffect(() => {
        props.getQuiz(taskID);
        setCorrectAnswers(0);
        setListAlreadyShuffled(false);
    }, []);

    const handleCorrectAnswer = (check) => {
        if (check) {
            setCorrectAnswers(correctAnswers + 1);
        }
    };

    const setQuestionAnswered = (index) => {
        setQuestionsAnswered((questionsAnswered) => [
            ...questionsAnswered,
            index,
        ]);
    };

    const quizStateReset = () => {
        setCorrectAnswers(0);
    };

    const getQuizComponentsList = () => {
        let questionList =
            props.quiz.data != null ? props.quiz.data.questionList : null;

        if (
            questionList != null &&
            !props.quiz.data.questionsOrdered &&
            !listAlreadyShuffled
        ) {
            questionShuffle(questionList);
            setListAlreadyShuffled(true);
        }

        let quizComponentsList = [];
        if (questionList && questionList.length > 0) {
            for (let i in questionList) {
                quizComponentsList.push({
                    element: (
                        <QuizAppContainer
                            {...questionList[i]}
                            classes={classes}
                            handleCorrectAnswer={handleCorrectAnswer}
                            setQuestionAnswered={setQuestionAnswered}
                            nextQuestionClick={nextQuestionClick}
                            index={i}
                        />
                    ),
                });

                // return (
                //   <Scorecard
                //     classes={classes}
                //     screenType={"scorecard"}
                //     quizStateReset={quizStateReset}
                //     correctAnswers={5}
                //     maxPoints={30}
                //     totalAnswers={7}
                //   />
                // );
                /* 
          return (
            <QuizAppContainer
              {...questionList[i]}
              classes={classes}
              handleCorrectAnswer={handleCorrectAnswer}
              setQuestionAnswered={setQuestionAnswered}
              nextQuestionClick={nextQuestionClick}
              responseList={questionList[i].responses}
              index={i}
            />
          ); */
            }

            quizComponentsList.push({
                element: (
                    <Scorecard
                        classes={classes}
                        screenType={"scorecard"}
                        quizStateReset={quizStateReset}
                        correctAnswers={correctAnswers}
                        maxPoints={30}
                        totalAnswers={questionList.length}
                        singleTask={props.singleTask}
                        isAssignment={isAssignment}
                    />
                ),
            });
        }

        return quizComponentsList;
    };

    return (
        <div className={classes.root}>
            {getQuizComponentsList().length > 0 && (
                <Slides
                    singleTask={props.singleTask}
                    content={getQuizComponentsList()}
                    type="quiz"
                    questionAnswered={questionsAnswered}
                    setNextQuestionClick={() =>
                        setNextQuestionClick(
                            (nextQuestionClick) => !nextQuestionClick
                        )
                    }
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        quiz: state.content.quiz,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getQuiz: (id) => dispatch(getQuiz(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
