import React from "react";

function TaskPageContainer(props) {
    return (
        <div 
            // This needs to be wrapped in a flex box to scale properly
            style={{flex: '1 1 auto', display: 'flex', msFlex: '1 1 100vh'}}
        >
            <div style={{flexGrow: 1}}>
                {props.children}
            </div>
        </div>
    );
}

export default TaskPageContainer;
