export const CREATE_COACHING_PROFILE_BEGIN = "CREATE_COACHING_PROFILE_BEGIN";
export const CREATE_COACHING_PROFILE_ERROR = "CREATE_COACHING_PROFILE_ERROR";
export const CREATE_COACHING_PROFILE_SUCCESS =
    "CREATE_COACHING_PROFILE_SUCCESS";

export const GET_COACHING_PROFILE_BEGIN = "GET_COACHING_PROFILE_BEGIN";
export const GET_COACHING_PROFILE_ERROR = "GET_COACHING_PROFILE_ERROR";
export const GET_COACHING_PROFILE_SUCCESS = "GET_COACHING_PROFILE_SUCCESS";

export const UPDATE_COACHING_PROFILE_BEGIN = "UPDATE_COACHING_PROFILE_BEGIN";
export const UPDATE_COACHING_PROFILE_ERROR = "UPDATE_COACHING_PROFILE_ERROR";
export const UPDATE_COACHING_PROFILE_SUCCESS =
    "UPDATE_COACHING_PROFILE_SUCCESS";
