import { OPEN_ALERT, CLOSE_ALERT } from "../constants/action-types-alert";

const initialState = {
    alert: {
        data: null,
    },
};

const alert = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_ALERT:
            return { ...state, alert: { data: action.payload } };

        case CLOSE_ALERT:
            return { ...state, alert: { data: null } };

        default:
            return state;
    }
};

export default alert;
