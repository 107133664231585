import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Actions
import { getOrganization } from "../actions/content_actions";

//Styles
import "../static/styles/App.css";
import { makeStyles } from "@mui/styles";

//Helpers
import { getCampaignComponent } from "../helpers/functions";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    content: {
        width: "100%",
        minHeight: "calc(100vh - 90px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

function PreRegister(props) {
    const classes = useStyles();
    const { organization } = props;
    const campaignFromURL = props.match.params.campaign_key; // || getOrganizationForDomain()
    const [PreRegisterPage, setPreRegisterPage] = useState();

    useEffect(() => {
        if (campaignFromURL) {
            setPreRegisterPage(
                getCampaignComponent("preregister", campaignFromURL)
            );
            //props.getOrganization(organizationFromURL)
        }
    }, []);

    return (
        <Suspense fallback={<></>}>
            {PreRegisterPage ? (
                <PreRegisterPage />
            ) : !campaignFromURL ? (
                <div className={classes.content}>
                    <Typography variant="h4">Page does not exist.</Typography>
                </div>
            ) : null}
        </Suspense>
    );
}

const mapStateToProps = (state) => {
    return {
        organization: state.content.organization,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganization: (organization) =>
            dispatch(getOrganization(organization)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PreRegister)
);
