import React from "react";
import clsx from "clsx";

//Styles
import MuiDialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        margin: 0,
        padding: theme.spacing(2),
        borderBottom: "1px solid " + theme.palette.grey[400],
        [theme.breakpoints.down('lg')]: {
            height: 30,
            padding: theme.spacing(1),
        },
    },
    dark: {
        backgroundColor: "black",
        color: "white",
    },
    title: {
        [theme.breakpoints.down('lg')]: {
            fontSize: "1rem",
        },
    },
    smallBar: {
        padding: theme.spacing(0.8),
    },
    hideBorder: {
        borderBottom: "none",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(0.5),
        },
    },
    smallButton: {
        padding: 2,
    },
}));

export const CustomDialogTitle = (props) => {
    const {
        children,
        onClose,
        small,
        hideBorder,
        dark,
        custom,
        ...other
    } = props;
    const classes = useStyles(props);

    return (
        <MuiDialogTitle
            disableTypography
            className={clsx(classes.root, {
                [classes.dark]: dark,
                [classes.hideBorder]: hideBorder,
                [classes.smallBar]: small,
            })}
            {...other}
        >
            {custom ? (
                children
            ) : (
                <Typography variant="h5" className={classes.title}>
                    {children}&nbsp;
                </Typography>
            )}
            {onClose ? (
                <IconButton
                    className={clsx(classes.closeButton, {
                        [classes.smallButton]: small,
                    })}
                    onClick={onClose}
                    size="large"
                >
                    <CloseIcon
                        className={clsx({
                            [classes.dark]: dark,
                        })}
                    />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};
