import React, { useEffect } from "react";

//Firebase
// Firebase
import { getAnalytics, logEvent, setCurrentScreen } from "firebase/analytics";
import { useLocation } from "react-router";

const analytics = getAnalytics()

const TrackRoute = () => {
    const location = useLocation();
    //const history = useHistory();

    useEffect(() => {
        if (typeof analytics === "function") {
            const page_path = location.pathname + location.search;
            setCurrentScreen(page_path);
            logEvent(analytics, "page_view", { page_path });
        }
    }, [location]);

    return <div></div>;
};

export default TrackRoute;
